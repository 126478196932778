import { COMPILER_OPTIONS, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { EnvironmentsService } from 'src/app/services/environments/environments.service';
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';
import { getLanguageCookie } from 'src/assets/language';
import {apiOfflineAsync} from "../../../../../layouts/pages/pages.component";

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent {
  @Input() parameters: any = {}
  @Output() toggleInstanceViewUserModal: EventEmitter<void> = new EventEmitter<void>();

  isLoading = false

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  task: any = {
    color:  'accent'
  };

  instance: any = {
    currentRowIndex: 0,
    currentPage: 1,
    itemsPerPage: 5,
    searchText: '',
    isLoading: false,
    // displayedColumns: ['Id', 'Name'],
    displayedColumns: ['Name'],
    dataSource: new MatTableDataSource<any>([])
  }

  data: any = {
    //valor daquele user passado por parametro
   }

  constructor(
    private cookieService: CookieService,
    private environmentsService: EnvironmentsService,
    private userEnvironment: UserEnvironmentsService
  ){}

  async ngOnInit(){
    await this.getInstances()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }
    this.toggleInstanceViewUserModal.emit();
  }

  async getInstances(){
    this.isLoading =  true;
    try {
      const response = await this.userEnvironment.getUserEnvironmentsMe(this.parameters.uuid)
      if (response && response.data) {
        this.instance.dataSource = new MatTableDataSource<any>(response.data)
        this.instance.filteredInstanceArray = response.data
      }
      this.isLoading =  false;

    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  customFilterPredicate(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      const searchTermLower = filter.toLowerCase();
      return (
        data.name.toLowerCase().includes(searchTermLower)
      );
    };
    return filterFunction;
  }

  applyFilter(): void {
    this.instance.filteredDataSource.filter = this.instance.searchText.trim().toLowerCase();
  }

  choseItemsPage(number: number){
    this.instance.itemsPerPage = number;
    this.getDisplayedRows()

  }

  onPageChange(newPage: number): void {

    this.instance.currentPage = newPage;

  }

  getDisplayedRows(): any[] {
    const startIndex = (this.instance.currentPage - 1) * this.instance.itemsPerPage;
    const endIndex = startIndex + this.instance.itemsPerPage;
    return this.instance.dataSource.data.slice(startIndex, endIndex);
  }

  getTotalPages(): number {

    return Math.ceil(this.instance.dataSource.data.length / this.instance.itemsPerPage);

  }

  async checkValue(event: any) {

    let total = await this.getTotalPages()
    if (this.instance.currentPage > total) {
      this.instance.currentPage = total;
    }

  }

}
