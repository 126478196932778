import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { CorporationsSettingsService } from 'src/app/services/corporations-settings/corporations-settings.service';
import { FunctionService } from 'src/app/services/function.service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-form-standards',
  templateUrl: './form-standards.component.html',
  styleUrls: ['./form-standards.component.scss']
})
export class FormStandardsComponent {
  @Input() requestParameters: any 
  @Output() exitForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() getCorporationSettings: EventEmitter<void> = new EventEmitter<void>();

  showBanner: boolean = true
  parameters: any
  mandatoryArray: any = [
    {
      en: 'Mandatory',
      pt: 'Obrigatório',
      slug: 'mandatory'
    },
    {
      en: 'Not mandatory',
      pt: "Não obrigatório",
      slug: 'not_mandatory'
    }
  ]

  selectedObligatoriness: any
  searchObligatoriness: string = ''
  
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['tab', 'action'];

  task: any = {
    color: 'accent'
  }

  backupItem: any
  isShowChangeFormGuideline: boolean = false;
  flagNgOnInit: boolean = false
  flagRequestParams: boolean = false

  constructor(private cookieService: CookieService,
    private businessSolutionsService: BusinessSolutionsService,
    private corporationsSettingsService: CorporationsSettingsService,
    public sharedData: FunctionService
  ){
    this.dataSource = new MatTableDataSource();
    this.parameters = {...this.requestParameters}
  }

  async ngOnInit(){
    await this.getOptionsArray()
    this.parameters = {...this.requestParameters}
    this.backupItem = {...this.parameters.item}
    this.backupItem.tabs = this.parameters.item?.tabs ? [...this.parameters.item?.tabs] : []
    this.flagNgOnInit = true

    if(this.parameters.item?.name == ''){
      this.parameters.item.tabs = this.dataSource.data
      .filter((item: any) => item.slug)
      .map((item: any) => item.slug);    
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestParameters']) {
      if(this.flagNgOnInit){
        if((JSON.stringify(this.parameters.item) !== JSON.stringify(this.backupItem))){
          this.isShowChangeFormGuideline = true
          this.flagRequestParams = true
        }else{
          this.ngOnInit()
        }
      }
    }
  }

  getSelectedObligatoriness(): string{
    this.selectedObligatoriness = this.parameters?.item?.mandatory == true ? this.mandatoryArray[0] : this.mandatoryArray[1] 
    return this.selectedObligatoriness
  }

  getLanguageBannerLevel(): string {
    if(this.parameters?.item?.level){
      return `guideline/form/level_${this.parameters?.item?.level}`;
    }
    return 'null';
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language'))?.lang]
  }

  choseItemSelect(type: string, value: any){
    switch (type) {
      case 'obligatoriness':
        this.parameters.item.mandatory = value.slug == 'mandatory';
        break;
      default:
        break;
    }
  }

  async getOptionsArray() {
    try {
      const response: any = await this.businessSolutionsService.getOptionsArray();
      if (response && response.data && response.data.tabArray) {
        this.dataSource.data = response.data.tabArray.filter((item: any) => item.slug != 'addCode')
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  setSelection(slug: string){
    if(this.parameters.item.tabs.includes(slug)){
      this.parameters.item.tabs = this.parameters.item.tabs.filter((item: string) => item != slug)
    }else{
      this.parameters.item.tabs.push(slug)
    }
  }

  getSelection(slug: string): boolean{
    return this.parameters.item.tabs.includes(slug)
  }

  async save(){
    this.parameters.item.mandatory = this.selectedObligatoriness.slug == 'mandatory'
    let flagEdit = false; 
    
    if(this.parameters.item.name == '' || this.parameters.item.acronym == ''){
      addAlert('orange', this.getLanguages('auth/alert/voidInput'))
      return
    }

    if(this.parameters.formEdit){
      for (let key in this.parameters.items) {
        if (this.parameters.items[key].level == this.parameters.item.level) {
          this.parameters.items[key] = this.parameters.item;
          flagEdit = true;
          break;
        }
      }
    }

    if (!flagEdit) {        
      const newKey = `level_${this.parameters.item.level}`;
      this.parameters.items[newKey] = this.parameters.item;
    }


    await this.saveStandards()
  }

  exit(){
    if(this.parameters.formEdit && (JSON.stringify(this.parameters.item) !== JSON.stringify(this.backupItem))){
      this.isShowChangeFormGuideline = true
      return
    }

    if(!this.parameters.formEdit && this.parameters.item?.name !== '' && this.parameters.item?.acroym !== '' ){
      this.isShowChangeFormGuideline = true
      return
    }
    this.exitForm.emit()
  }

  async saveStandards(){
    try {
      const response: any = await this.corporationsSettingsService.setCorporationSettings({json: this.parameters.items, uuid: this.parameters.uuid})
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
        if(this.flagRequestParams){
          this.flagRequestParams = false
          this.ngOnInit()
          return       
        }
        this.exitForm.emit()
        this.getCorporationSettings.emit(this.parameters.items)
      } else{
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  cancel(){
    this.parameters.isEdit = false
    this.parameters.item = {...this.backupItem}
    this.parameters.tabs = [...this.backupItem.tabs]
  }

  closeModalChange(){
    this.isShowChangeFormGuideline = false
  }

  async toggleChangeTree(){
    this.isShowChangeFormGuideline = false

    if(this.flagRequestParams){
      this.flagRequestParams = false
      this.ngOnInit()
      return       
    }

    this.exitForm.emit()
  }
}
