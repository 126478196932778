import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { localStorageToJsonAsync } from 'src/app/helpers/cookieToJson';
import { getLanguageCookie } from 'src/assets/language';
import { v4 } from "uuid";
import * as moment from 'moment';
import 'moment-business-days';
import { addAlert } from 'src/app/layouts/pages/pages.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent {
  @Input() parameters!: any

  @ViewChild('scrollContainerBox') scrollContainerBox!: ElementRef;
  @ViewChild('scrollContainerRadio') scrollContainerRadio!: ElementRef;

  showArrowLevel1: boolean = false;
  showArrowLevel2: boolean = false;

  selectedLevel1: any = {
    uuid: '',
    label: ''
  } 
  selectedLevel2: any = {
    uuid: '',
    label: ''
  }

  searchProject: string = ''
  searchSubProject: string = ''
  
  currentPageProject = 1;
  itemsPerPageProject = 5;
  totalPagesProject = 1;
  indexTableProject: any = { startIndex: 0, endIndex: 0 }

  properties: any = {}

  constructor(
    private   router: Router,
    private cdr: ChangeDetectorRef
  ){}

  async ngOnInit(){
    if(this.parameters?.arrayGuidelineProject?.length){
      this.parameters.arrayGuidelineProject = sortArrayOfObjects(this.parameters.arrayGuidelineProject)
      this.activateLevel1(this.parameters.arrayGuidelineProject[0])
    }
    await this.getDisplayedRows()
    this.properties = await localStorageToJsonAsync()
  }

  ngOnDestroy(){
    const project = this.parameters.item.project;

    for (const key in project) {
      if (project.hasOwnProperty(key)) {
        project[key] = project[key].filter((item: any) => item.task_name && item.task_name.trim() !== '');
      }
    }
  }

  async ngAfterViewInit() {
    if(!this.scrollContainerBox || !this.scrollContainerBox.nativeElement){
      return
    }

    this.scrollContainerBox.nativeElement.scrollLeft = 0;

    await this.checkOverflow(1)

    if(!this.scrollContainerRadio || !this.scrollContainerRadio.nativeElement){
      return
    }

    await this.checkOverflow(2)
    
    this.scrollContainerRadio.nativeElement.scrollLeft = 0;
  }

  async checkOverflow(type: number){
    let div
    if(type == 1){
      
      if(!this.scrollContainerBox || !this.scrollContainerBox.nativeElement){
        return
      }
      
      div = this.scrollContainerBox.nativeElement;
      this.showArrowLevel1 = div.scrollWidth > div.clientWidth;
      this.cdr.detectChanges();
    }else{
      
      if(!this.scrollContainerRadio || !this.scrollContainerRadio.nativeElement){
        return
      }

      div = this.scrollContainerRadio.nativeElement;
      this.showArrowLevel2 = div.scrollWidth > div.clientWidth;
      this.cdr.detectChanges();
    }
  }

  scrollLeft(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;

    if(param == 'box'){
      this.scrollContainerBox.nativeElement.scrollLeft -= 25 * vwToPixels;
    }else{
      this.scrollContainerRadio.nativeElement.scrollLeft -= 25 * vwToPixels;
    }
  }

  scrollRight(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;

    if(param == 'box'){
      this.scrollContainerBox.nativeElement.scrollLeft += 25 * vwToPixels;
    }else{
      this.scrollContainerRadio.nativeElement.scrollLeft += 25 * vwToPixels;
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async activateLevel1(item: any){
    if(item?.uuid != this.selectedLevel1?.uuid){
      delete this.selectedLevel2  
    }

    this.selectedLevel1 = {
      label: item.label,
      uuid: item.uuid,
      children: item.children
    }

    if(item?.children?.length){
      item.children = sortArrayOfObjects(item.children)
      await this.activateLevel2(item.children[0])
      this.cdr.detectChanges();
    }

    await this.getTotalPages(true);
    await this.getDisplayedRows();
    await this.checkOverflow(2)
  }

  async activateLevel2(item: any){

    this.selectedLevel2 = {
      label: item.label,
      uuid: item.uuid
    }
    
    if(!this.parameters?.item?.project[item.uuid]?.length){
      this.parameters.item.project[item.uuid] = []
    }

    await this.getTotalPages(true);
    await this.getDisplayedRows()
  }  

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    const tr = textarea.closest('tr');
    
    if (!tr) {
      return;
    }

    const textareas = tr.querySelectorAll('textarea');
    let maxHeight = 0;

    textareas.forEach((ta: HTMLTextAreaElement) => {
      ta.style.height = 'auto'; 
      const taHeight = ta.scrollHeight;
      if (taHeight > maxHeight) {
        maxHeight = taHeight;
      }
    });

    textareas.forEach((ta: HTMLTextAreaElement) => {
      ta.style.height = `${maxHeight}px`;
    });
  }

  allowNumbersOnly(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  limitValue(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = Number(input.value);
    if (value < 0) {
      input.value = '0';
    } else if (value > 100) {
      input.value = '100';
    }
  }

  allowNumbersAndDecimal(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    const charStr = String.fromCharCode(charCode);

    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
    }

    if (charStr === '.' && (event.target as HTMLInputElement).value.includes('.')) {
      event.preventDefault();
    }
  }

  onInputChange(event: Event, uuid: string) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const regex = /^\d{0,2}(\.\d{0,1})?$/;

    if (!regex.test(value)) {
      input.value = value.slice(0, -1);
    }

    if(uuid && this.parameters?.item?.project[this.selectedLevel2.uuid].find((item: any) => item.uuid == uuid)?.start_date){
      this.calculateEndDate(uuid)
    }
  }

  onDateInputChange(event: Event, uuid: string) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 8) {
      value = value.slice(0, 8); 
    }

    if (value.length > 4) {
      value = value.replace(/^(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
    } else if (value.length > 2) {
      value = value.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
    }

    input.value = value;

    if(value.length == 10){
      this.calculateEndDate(uuid)
    }
  }

  focusInput(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const input = target.querySelector('input');
    if (input) {
      input.focus();
    }
  }

  calculateEndDate(uuid: string) {
    this.parameters.item.project[this.selectedLevel2.uuid].forEach((item: any) => {
      if (item.uuid === uuid) {
        let duration = 0

        duration = parseInt(item.duration, 10) || 0;
        
        if(duration != item.duration){
          duration++
        }

        if (moment(item.start_date, 'DD/MM/YYYY', true).isValid()) {
          item.end_date = this.addBusinessDays(item.start_date, duration);
        } else {
          addAlert('orange', this.getLanguages('error/invalidStartDate'))
          item.end_date = '';         
        }
      }
    });
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, uuid: string) {
    const selectedDate = event.value;
    if (selectedDate) {
      const formattedDate = moment(selectedDate).format('DD/MM/YYYY');
      this.calculateEndDate(uuid);
    }
  }

  addBusinessDays(date: string, days: number): string {
    const format = 'DD/MM/YYYY';
    const momentDate = moment(date, format);
    const resultDate = momentDate.businessAdd(days);
    return resultDate.format(format);
  }

  async addRow(){
    this.parameters.item.project[this.selectedLevel2.uuid].push(
      {
        uuid: v4(),
        task_name: '',
        concluded: '',
        plan: '',
        duration: '',
        start_date: '',
        end_date: '',
      }
    )
    await this.getTotalPages(true);
    await this.getDisplayedRows()
  }

  async getDisplayedRows(){
    this.indexTableProject.startIndex = (this.currentPageProject - 1) * this.itemsPerPageProject;
    this.indexTableProject.endIndex = this.indexTableProject.startIndex + this.itemsPerPageProject;
  }

  getRows(){
    return this.parameters.item.project[this.selectedLevel2.uuid].slice(this.indexTableProject.startIndex, this.indexTableProject.endIndex)
  }

  async getTotalPages(isLastPage: boolean = false){
    if(!this.selectedLevel2?.uuid){
      return
    }

    this.totalPagesProject = Math.ceil(this.parameters?.item?.project[this.selectedLevel2?.uuid].length / this.itemsPerPageProject)
    if (this.totalPagesProject == 0) this.totalPagesProject = 1
    if ((this.currentPageProject >= this.totalPagesProject && this.totalPagesProject > 0) || isLastPage) {
      this.currentPageProject = this.totalPagesProject;
    }

    await this.getDisplayedRows()
  }

  async choseItemsPage(number: number) {    
    this.currentPageProject = 1;
    this.itemsPerPageProject = number;
  }

  getCurrentPages(): number{
    return this.currentPageProject
  }

  setCurrentPages(param: number){
    return this.currentPageProject = param
  }

  getItemsPerPage(): number{
    return this.itemsPerPageProject
  }

  getTotalNumberPages(){
    return this.totalPagesProject
  }

  async removeItem(uuid: string){
    this.parameters.item.project[this.selectedLevel2.uuid] = this.parameters.item.project[this.selectedLevel2.uuid].filter((item: any) => item.uuid != uuid)
    await this.getTotalPages(true);
    await this.getDisplayedRows()
  }

  getAccessGuideline(){
    let flag: boolean = false;

    this.properties?.screens?.administrator?.forEach((module: any)=> {
      if(module?.slug == 'settings'){
        module.screens.forEach((screen: any) => {
          if(screen.slug == 'standards'){
            flag = true
          }
        });
      }
    })

    return flag
  }

  navigate(url: string){
    this.router.navigate([url])
  }
}
