import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { IResponse } from "../../interfaces/IResponse";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class VersionsService {
  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // outros cabeçalhos personalizados aqui, se necessário
    })
  };

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) {
      this.API_PATH = environment.apiUrl;
  }

  getVersions(): Observable<IResponse> {
    const url = `${this.API_PATH}business-solutions/versions`;

    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

  async setVersions(body: any) {
    return await this.httpClient.put<IResponse>(
      `${this.API_PATH}business-solutions/versions/${body.uuid}`,
      body,
      this.requestOptions
    ).toPromise();
  }

  async deleteVersion(uuid: string) {
    // return await this.httpClient.delete<IResponse>(
    //   `${this.API_PATH}business-solutions/versions/${id}`,
    //   this.requestOptions
    // ).toPromise();
    try {
      return await this.httpClient.delete<IResponse>(`${this.API_PATH}business-solutions/versions/${uuid}`).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }


}


