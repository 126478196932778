import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IBusinessSolution } from 'src/app/interfaces/IBusinessSolution';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { AuthService } from "../../services/auth/auth.service";
import { EventService } from 'src/app/services/event/event.service';
import { asyncForEach } from 'src/app/helpers/asyncForEach';
import { to64decode, to64encode} from "../../helpers/base64";
import {CookieService} from "ngx-cookie-service";
import { getLanguageCookie } from 'src/assets/language';
import { Router } from '@angular/router';
import {apiOfflineAsync} from "../../layouts/pages/pages.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  corporationTree?: any;
  selectedNode: any;
  isSelectedNode = false;
  searchText: string = '';
  isTreeEmpty = true;
  corporation_parent = [
    // {
    //   name: 'corporation_pai_1',
    //   info: [
    //     {
    //       name: 'corporation_filho_1',
    //       info: [
    //         {
    //           name: 'corporation_neto_1',
    //           info: []
    //         },
    //         {
    //           name: 'corporation_neto_2',
    //           info: [
    //             {
    //               name: 'corporation_bisneto_1',
    //               info: [
    //                 {
    //                 name: 'corporation_tataraneto',
    //                 info: []
    //                 },
    //               ]
    //             },
    //           ]
    //         },
    //       ]
    //     },
    //     {
    //       name: 'corporation_filho_2',
    //       info: []
    //     },
    //     {
    //       name: 'corporation_filho_3',
    //       info: []
    //     },
    //   ]
    // }
  ]

  corporation = [
      // {
      //   name: 'corporation_pai_1',
      //   icon: 'fa-building',
      //   expanded: 'fa-regular',
      //   size: 3,
      //   isPrincipal: true,
      //   info: [
      //     {
      //       name: 'corporation_filho_1',
      //       icon: 'fa-building',
      //       expanded: 'fa-regular',
      //       size: 2,
      //       info: [
      //         {
      //           name: 'corporation_neto_1',
      //           icon: 'fa-building',
      //           expanded: 'fa-regular',
      //           size: 0,
      //           info: []
      //         },
      //         {
      //           name: 'corporation_neto_2',
      //           icon: 'fa-building',
      //           expanded: 'fa-regular',
      //           size: 1,
      //           info: [
      //             {
      //               name: 'corporation_bisneto_1',
      //               icon: 'fa-building',
      //               expanded: 'fa-regular',
      //               size: 1,
      //               info: [
      //                 {
      //                 name: 'corporation_tataraneto',
      //                 icon: 'fa-building',
      //                 size: 0,
      //                 expanded: 'fa-regular',
      //                 info: []
      //                 },
      //               ]
      //             },
      //           ]
      //         },
      //       ]
      //     },
      //     {
      //       name: 'corporation_filho_2',
      //       icon: 'fa-building',
      //       expanded: 'fa-regular',
      //       size: 0,
      //       info: []
      //     },
      //     {
      //       name: 'corporation_filho_3',
      //       icon: 'fa-building',
      //       expanded: 'fa-regular',
      //       size: 0,
      //       info: []
      //     },
      //   ]
      // }
  ]

  selectedCorporation: any = { name: 'Loading...' };
  objectModal: any = {};

  treeControl = new NestedTreeControl<IBusinessSolution>((node) => node.info);
  // dataSource: any = new MatTreeNestedDataSource<any>();
  dataSource = new MatTreeNestedDataSource<IBusinessSolution>();

  isLoading: boolean = false;

  constructor(
    private eventService: EventService,
    private router: Router,
    private corporationService: CorporationService,
    private cookieService: CookieService) {
  }

  async ngOnInit() {
    await this.getCorporationTree()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  togleCreateProfile(event: Event, node?: any) {

    if(!node || node == null){
      this.objectModal.isPrincipal = true;
    }else{
      this.objectModal.isPrincipal = false;
      this.objectModal.reference_parent = node;
    }

    this.eventService.emitModalProfile({
      ...this.objectModal,
    });

    event.stopPropagation();
  }

  togleCreateProfileEdit(event: Event, node?: any) {
    // this.objectModal = node;
    // this.eventService.emitModalProfileEdit({
    //   ...this.objectModal,
    // });

    // event.stopPropagation();
  }


  async getCorporationTree() {
    this.dataSource = new MatTreeNestedDataSource<any>();
    this.isLoading = true;

    try {
      const response: any = await this.corporationService.getCorporationTree();

      if (response && response.data) {
        this.dataSource = await response.data;
        this.isLoading = false;

        this.isTreeEmpty = response.data.length == 0;
      } else {
        throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      this.isLoading = false;
      await apiOfflineAsync(error)
    }
  }

  buildTreeData(nodes: any[]): any[] {
    return nodes.map((node) => {
      const children = node.info ? this.buildTreeData(node.info) : [];
      return {
        ...node,
        info: children
      };
    });
  }

  choseDomainTree(corporation: any) {
    this.corporation = corporation.slug;
    this.corporationTree = undefined
    this.selectedCorporation.name = corporation.name;
  }

  hasChild = (_: number, node: IBusinessSolution) => !!node.info && node.info.length > 0;

  async expandedNode(node: any) {
    if (node.is_corporation) {
      this.corporationTree = node;
    }
  }

  async selectNode(node: any = {}, show = true) {
    this.selectedNode = node;
    if (node == '') {
      this.isSelectedNode = false;
    } else {
      this.isSelectedNode = show;
    }
  }

  expandAllNodes() {
    const data = this.dataSource;
    this.expandRecursive(data, this.treeControl);
  }

  expandRecursive(nodes: any, treeControl: NestedTreeControl<any>) {

    for (const node of nodes) {
      treeControl.expand(node);
      if (node.info && node.info.length > 0) {
        this.expandRecursive(node.info, treeControl);
      }
    }
  }

  collapseAllNodes() {
    const data = this.dataSource;
    this.collapseRecursive(data, this.treeControl);
  }

  collapseRecursive(nodes: any, treeControl: NestedTreeControl<any>) {
    for (const node of nodes) {
      if (treeControl.isExpanded(node)) {
        treeControl.collapse(node);
      }
      if (node.info && node.info.length > 0) {
        this.collapseRecursive(node.info, treeControl);
      }
    }
  }

  expandTreeAndHighlight(searchText: string) {
    this.expandAllNodes();
    this.highlightNodes(this.dataSource, searchText);
  }

  highlightNodes(nodes: any, searchText: string) {
    for (const node of nodes) {
      node.highlight = false; // Remover destaque de nós anteriores

      if (node.name && searchText && node.name.toLowerCase().includes(searchText.toLowerCase())) {
        node.arrayText = node.name.split(new RegExp(`(.*?)(${searchText})`, 'gi')).filter(Boolean);
      }

      if (node.info && node.info.length > 0) {
        this.highlightNodes(node.info, searchText); // Chamar recursivamente para nós filhos
      }
    }
  }

  async navigate(node: any){
    // localStorage.removeItem('corporation')
     localStorage.setItem('corporation', to64encode(node))
    if(to64decode(localStorage.getItem('corporation'))){
      await this.router.navigate(['/business-solutions']);
    }
    //erro ao passar o cookie
  }


}

