<div class="all-content" *ngIf="!showCrop">
    <div class="top">
      <div class="toggle"></div>

      <div class="div-icon-button">
        <div class="save principal" (click)="deleteEnvironment()" *ngIf="getSystemPermission('administrator', 'environments', 'delete') && !isEdit">
          {{ getLanguages('delete')}}
        </div>
        <div class="save principal" (click)="setEnvironment()" *ngIf="isEdit">
            {{ getLanguages('save')}}
        </div>
        <div class="save principal" (click)="editCancel()" [ngClass]="isEdit ? 'edit' : ''">
            {{ !isEdit ? getLanguages('edit') : getLanguages('cancel')}}
        </div>
        <i class="fas fa-xmark" (click)="exitModal()"></i>
      </div>
    </div>
    <div class="row">
      <div class="title">
        <span>{{ getLanguages('instances/modal/edit/title') }}</span>
      </div>
    </div>

    <div class="div-icon-button responsive">
      <div class="save" (click)="deleteEnvironment()" *ngIf="getSystemPermission('administrator', 'environments', 'delete') && !isEdit">
        {{ getLanguages('delete')}}
      </div>
      <div class="save" (click)="setEnvironment()" *ngIf="isEdit">
          {{ getLanguages('save')}}
      </div>
      <div class="save" (click)="editCancel()" [ngClass]="isEdit ? 'edit' : ''">
        {{ !isEdit ? getLanguages('edit') : getLanguages('cancel')}}
      </div>
    </div>

    <!-- <div class="row image">
      <div class="item w-1 div-circle">
        <span>{{ getLanguages('instances/content/image') }}: </span>
        <div class="circle-image">
          <img [src]="displayedImage" *ngIf="displayedImage"/>
          <span *ngIf="!displayedImage">
            {{ getName() }}
          </span>
        </div>
        <div class="attachments">
          <button class="button-upload " (click)="openFileInput()" [ngClass]="!isEdit ? 'edit' : 'pointer'" [disabled]="!isEdit">
            <i class="fas fa-image"></i>
            {{ getLanguages('instances/content/upload_image') }}
          </button>
          <input type="file" class="button-upload" #fileInput style="display: none" (change)="fileChangeEvent($event)" >
        </div>
      </div>


    </div> -->

    <div class="row">
        <div class="item w-1">
            <span>{{ getLanguages('instances/content/table/name') }}: </span>
            <input type="text" [(ngModel)]="temp.name" [disabled]="!isEdit">
        </div>
    </div>
    <div class="row">
        <div class="item w-1">
            <span>{{ getLanguages('users/modal/create/status') }} </span>
            <button class="select-nav" [matMenuTriggerFor]="status" [disabled]="!isEdit" [ngClass]="!isEdit ? 'disabled no-pointer' : ''">
              <span>
                  <ng-container>
                    {{ selectedStatus && selectedStatus.name ? getNames(selectedStatus.name) : getLanguages('search') }}
                  </ng-container>
              </span>
              <i class="fas fa-caret-down"></i>
            </button>
            <mat-menu #status="matMenu">
              <div class="menu">
                <div class="scroll">
                  <ng-container *ngFor="let status of statusArray">
                      <div class="item-menu" (click)="choseStatus(status)">
                        <span [ngClass]="{'valid-span' : status.value == temp.is_active}">
                          {{ getNames(status.label) }}
                        </span>
                      </div>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
          </div>
    </div>
</div>

<div class="showImageCrop" *ngIf="showCrop">
  <div class="content-crop">
    <i class="fas fa-xmark" (click)="saveAndClose()"></i>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 4"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>

    <button (click)="saveAndClose()">
      {{ getLanguages('save')}}
    </button>

  </div>
</div>

<div class="background-modals" *ngIf="isLoading">
  <app-loading *ngIf="isLoading"></app-loading>
</div>
