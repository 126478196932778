export const extractTasksFromBpmnXml = (xml: string): { uuid: string, label: string }[] => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'application/xml');
  
    // Seleciona todas as tasks dentro do XML
    const tasks = xmlDoc.getElementsByTagName('task');
    const taskArray: { uuid: string, label: string }[] = [];
  
    // Itera sobre as tasks e monta o array de {uuid, label}
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      const id = task.getAttribute('id');
      let uuid;
      
      // Verifica se o ID começa com "Task_" ou "Activity_"
      if (id?.startsWith('Task_')) {
        uuid = id.replace('Task_', ''); // Remove o prefixo "Task_"
      } else if (id?.startsWith('Activity_')) {
        uuid = id.replace('Activity_', ''); // Remove o prefixo "Activity_"
      }
      
      const label = task.getAttribute('name') || '';
    
      if (uuid) {
        taskArray.push({ uuid, label });
      }
    }
    
  
    return taskArray;
  };
  