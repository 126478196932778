import { Component, ViewChildren, QueryList, Renderer2, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthService } from "../../services/auth/auth.service";
import { Router, RouterOutlet } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';
import { getLanguageCookie, getLanguage } from 'src/assets/language';
import { UsersService } from "../../services/users/users.service";
import { cookieToJsonAsync, cookieToJson, localStorageToJsonAsync } from "../../helpers/cookieToJson";
import { systemPermissions } from "../../helpers/systemPermissions";
import { HomeService } from "../../services/home/home.service";
import { LoginComponent } from "../../pages/login/login.component";
import { EditInstanceComponent } from 'src/app/components/modal/instance/edit-instance/edit-instance.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subscription, map } from 'rxjs';
import { FunctionService } from 'src/app/services/function.service';
import {setLogoLocalStorage} from "../../helpers/helpers";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {
  @ViewChild('scrollTarget') scrollTarget!: ElementRef;

  static queueAlert: any = []
  static queueTime: any = []

  cards: any = {
    enterprise: [],
    environment: [],
    corporations: [],
    users: [],
    business_solutions: [],
  }

  fliped: string = '';
  flag = false;
  flagClickedIndicators: boolean = false;

  properties: any = {
    environment: {},
    corporation: {},
    language: { lang: 'en', file: 'us.svg'},
    me: {},
    token: null
  }

  selectedCorporation: any = { uuid: '', name: ''};
  corporation = {
    array: [{ uuid: '', name: ''}],
    filteredArray: [{ uuid: '', name: ''}],
    search: ''
  }

  selectedItem!: string;

  selectedInstance: any = { uuid: '', name: ''};
  routerStateSnapshot: any = null
  parameters: any = {}

  isLoadingChooseInstance = false;

  me: any = {};
  instancesArray: any = [];
  instanceFilteredArray: any = []
  searchInstance! : string;
  lang = 'us'
  selectedFlag: any = { lang: 'en', file: 'us.svg' };
  flagArray: any = [
    { lang: 'en', file: 'us.svg'},
    { lang: 'pt', file: 'br.svg'}
  ]
  configArray: any = [
    { name: {en: 'Exit', pt: 'Sair'}, route: 'login', exit: true}
  ]
  configSystemArray: any = []
  selectedUser: any = { name: ''}
  intervalId: any = 0
  editInstance = EditInstanceComponent;
  @ViewChildren(RouterOutlet) routerOutlets!: QueryList<RouterOutlet>;

  menu: any = []
  displayedImage: any = null;
  showMenuType: string = ''
  public routerPages: any

  private subscription: Subscription | undefined;

  isShowConfigurations: boolean = false
  constructor(
    public authService: AuthService,
    public router: Router,
    private userEnvironmentService: UserEnvironmentsService,
    private userService: UsersService,
    private homeService: HomeService,
    public sharedData: FunctionService,
    private cdr: ChangeDetectorRef

    ) {
    this.routerPages = router
    this.authService.setCookieLanguage()
  }

  async ngOnInit() {
    // await this.getMe();
    this.properties = { ...await localStorageToJsonAsync(), config: { is_login: false }, ...await localStorageToJsonAsync(true) }
    await this.getUserInstance()
    await this.getUserCorporations()

    this.selectedInstance = this.properties.environment;
    this.selectedCorporation = this.properties.corporation;
    
    if (localStorage.getItem('image_corporation') !== null) {
      this.displayedImage = localStorage.getItem('image_corporation')
    }

    if(localStorage.getItem('flagIndicator') && localStorage.getItem('flagIndicator') != ''){
      this.flagClickedIndicators = true;
    }
  }

  async ngAfterViewInit() {
    this.subscription = this.sharedData.getUserCorporation$.subscribe(() => {
      this.selectedCorporation = to64decode(localStorage.getItem('corporation'))
      if (localStorage.getItem('image_corporation') != null) {
        this.displayedImage = localStorage.getItem('image_corporation')
      }
      this.cdr.detectChanges(); 
    });
  }

  /**
   *
   */
  async getMe() {
    try {
      const response = await this.authService.getMe();
      if (![200].includes(response.status)) {
        return this.setAddAlert('danger', response.message)
      }

      this.properties = { ...response.data, ...await localStorageToJsonAsync(true) }
      localStorage.setItem('image_corporation', this.properties.corporation.url)
      delete this.properties.corporation.url
      await this.authService.setCookie(this.properties)
      await this.userService.setUserMe(this.properties)
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getLanguageItem(item: any){
    return item[this.properties.language.lang]
  }

  getClassItem(item: any){
    return `fas icon ${item} `
  }

  showMenu(slug: string){
    this.showMenuType = slug
  }

  async getUserInstance(){
    try{
      let response = await this.userEnvironmentService.getUserEnvironmentsMe(this.properties.me.uuid)
      if( response && response.data){
        this.instancesArray = response.data;
        this.instanceFilteredArray = response.data
      }
    }catch{

    }
  }

  async getUserCorporations(){
    try{
      let response = await this.userEnvironmentService.getUserCorporations()
      if(response && response.data){
        this.corporation.array = response.data;
        this.corporation.filteredArray = sortArrayOfObjects(this.corporation.array);
      }
    }catch{

    }
  }

  async selectFlag(flag: any) {
    this.properties.language = flag;
     localStorage.setItem('language', to64encode(this.properties.language))
  }

  async chooseCorporation(corporation: any){
    this.displayedImage = null
    localStorage.setItem('image_corporation', corporation.url)
    delete corporation.url
    this.isLoadingChooseInstance = true;
    this.selectedCorporation = corporation;
    this.selectedItem = corporation;
    this.corporation.search = '';

    localStorage.removeItem('corporation');
    await this.authService.setCookie({corporation: corporation})

    await this.getMe()

    setTimeout(() => {
      this.isLoadingChooseInstance = false;
      window.location.reload()
    }, 1000);
  }

  filterByArray(slug: string) {
    if(slug == 'corporation'){
      this.corporation.filteredArray = this.corporation.array.filter((item : { name: string}) =>
      item?.name?.toLowerCase().includes(this.corporation.search.toLowerCase())
      )

      this.corporation.filteredArray = sortArrayOfObjects(this.corporation.filteredArray)
    }
    if(slug == 'instance'){
      this.instanceFilteredArray = this.instancesArray.filter((item : { environment: {name: string} }) =>
        item.environment?.name?.toLowerCase().includes(this.searchInstance.toLowerCase())
      )
    }
  }

  navigate(route: string, exit: boolean = false) {
    if (exit) {
      if (this.sharedData.isChangeTree) {
        this.sharedData.isShowModalChangeTree = true;
        this.sharedData.route = route;
        return;
      }
  
      // Remove os itens do localStorage
      localStorage.removeItem('image_corporation');
  
      // Lista de chaves para manter
      const keepKeys = ['language'];
  
      // Remove todos os itens do localStorage, exceto aqueles na lista de chaves para manter
      Object.keys(localStorage).forEach(key => {
        if (!keepKeys.includes(key)) {
          localStorage.removeItem(key);
        }
      });
    }
  
    this.router.navigate([route]);
  }
  

  async chooseEnvironment(environment: any) {
   
    this.showMenuType = ''

    this.selectedInstance = environment;
    this.isLoadingChooseInstance = true;

    localStorage.removeItem('environment');
    localStorage.removeItem('corporation');
     localStorage.setItem('environment', to64encode(environment));
    this.selectedItem = this.selectedInstance;

    await this.getMe()

    setTimeout(() => {
      this.isLoadingChooseInstance = false;
      this.router.routerState.snapshot.url == '/' ? window.location.reload() : window.location.href = '/'
    }, 2000);
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(this.properties.permissions, module, screen, action, false)
  }

  getName(name: string | undefined | null){
    if (name) {
        return name.substring(0, 2).toUpperCase();
    }
    return '';
  }

  async initCardEnterprise(){
    switch (this.router.url) {
      case '/business-solutions':
        this.fliped = 'business_solutions'
        break;
      case '/corporations':
        this.fliped = 'corporations'
        break;
      case '/users':
        this.fliped = 'users'
        break;
      case '/environments':
        this.fliped = 'environments'
        break;
      default:
        this.fliped = 'home'
        break;
    }
  }

  getCardArray(): any{
    return this.cards[this.fliped]
  }

  async getCardsArrays(){
    try {
      const response: any = await this.homeService.getCardsArrays(this.fliped).toPromise()
      if (response.data) {
        this.cards[this.fliped] = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async flipedCard(text: string) {
    this.fliped = text;
    // await this.getCardsArrays();

    this.flagClickedIndicators = true;
     localStorage.setItem('flagIndicator', to64encode({flag: 'true'}))
  }

  scrollScreen(event?: any) {
    // const scroolTarget = this.scrollTarget.nativeElement;
    //   if (scroolTarget) {
    //     scroolTarget.scrollIntoView({ behavior: 'smooth' });
    //   }
  }

  getIndicators(){
    if(this.router.url == '/activities' || this.router.url == '/environments'){
      return false
    }
    // if(this.router.url == '/' || this.router.url == '/home'){
    if(this.router.url == '/' || this.router.url == '/home'){

      return true
    }
    return this.flagClickedIndicators
  }

  navigationSideBar(event: any){
    this.initCardEnterprise()
    localStorage.removeItem('flagIndicator')
    this.flagClickedIndicators = false;
    // this.flipedCard('home')
    this.cards.enterprise.forEach((item: any) => {
      item.flip = false
    });
    this.fliped = 'home'
  }

  async getCards () {
    try {
      const response: any = await this.homeService.getCards(this.fliped).toPromise()
      if (response && response.data && response.data.cards) {
        this.cards.enterprise = response.data.cards
        // response.data.breed
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async setJobParameters () {
    await this.homeService.setJobParameters({ name: 'lpj-make-api by UI' })
  }

  toggleConfigurations(){
    this.isShowConfigurations = !this.isShowConfigurations
  }

  getQueueAlert (): any {
    return PagesComponent.queueAlert
  }

  getQueueTime () {
    return PagesComponent.queueTime
  }

  setAddAlert (type: string = '', text: string = '') {
    addAlert(type, text)
  }

  setRemoveAlert (id: number, event?: Event) {
    removeAlert(id, event)
  }

  protected readonly Object = Object;
}

export function addAlert(type: string = '', text: string = '') {
  const someAlert = PagesComponent.queueAlert.find((alert: { text: string }) => alert.text === text);

  if (someAlert) {
    removeAlert(someAlert.id)
    clearTimeout(PagesComponent.queueTime[someAlert.id]);
  }

  PagesComponent.queueAlert.push({ id: PagesComponent.queueAlert.length, type, text })
  PagesComponent.queueTime[PagesComponent.queueAlert.length] = setTimeout(() => {
    removeAlert(PagesComponent.queueAlert.length)
  }, 3000)
}

export function removeAlert(id: number, event?: Event){
  PagesComponent.queueAlert.splice(PagesComponent.queueAlert.findIndex((item: {id: number;}) => item.id == id), 1)
}

export async function apiOfflineAsync (error: any) {
  if (!error.status) {
    return
  }

  let cookie = await localStorageToJsonAsync(true)

  if ([401, 511].includes(error.status) || ([500].includes(error.status) && error.error.error == 'jwt expired')) {
    if ([511].includes(error.status)) {
      addAlert('danger', getLanguage('errors/system/out', cookie['language']['lang']))
      return setTimeout(() => {
        return navigate('/login');
      }, 2000)
    }
    return navigate('/login');
  }

  addAlert(
    error.status == 0 ? 'danger' : 'orange',
    error.status == 0 ?
      getLanguage('errors/system/out', cookie['language']['lang']) :
      (error && error.error ? error.error.message: '')
  )
}

export function apiOffline (error: any) {
  let cookie = cookieToJson(true)

  if (!error.status) {
    return
  }

  if ([401, 511].includes(error.status)) {
    if ([511].includes(error.status)) {
      addAlert('danger', getLanguage('errors/system/out', cookie['language']['lang']))
      return setTimeout(() => {
        return navigate('/login');
      }, 2000)
    }
    return navigate('/login');
  }

  addAlert(
    error.status == 0 ? 'danger' : 'orange',
    error.status == 0 ?
      getLanguage('errors/system/out', cookie['language']['lang']) :
      (error && error.error && error.error.error ? error.error.error :
        (error.error.message ? error.error.message: ''))
  )
}

export function navigate(route: string) {
  window.location.href = route
}

export function setValueById (id: string, value: any = null) {
  if (id == '') return

  if (document && document.getElementById(id) != null) {
    // @ts-ignore
    document.getElementById(id).value = value
  }
}
