import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAuth } from '../../interfaces/IAuth';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { to64encode } from "../../helpers/base64";
import {GlobalVariableInterceptor} from "../../guards/interceptors/global-variable.interceptor";
import { IResponse } from 'src/app/interfaces/IResponse';
import {apiOffline, apiOfflineAsync} from "../../layouts/pages/pages.component";

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  API_PATH: any = '';

  constructor(
    private httpClient: HttpClient,
    private router: Router,

  ) {
    this.API_PATH = environment.apiUrl
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }

    await apiOfflineAsync(error)

    return null;
  }


  async getCountries(acronym: string = 'BR') {
    try {
      const result = await this.httpClient.get<IResponse>(
        `${this.API_PATH}countries${acronym != '' ? '?acronym=BR' : ''}`
      ).toPromise();

      return result?.data
    } catch (error: any) {
      return await this.redirectError(error)
    }
  }

  async getStates(uuid: string) {
    try {
      const result =  await this.httpClient.get<IResponse>(
        `${this.API_PATH}states?country_uuid=${uuid}&orderBy=acronym`
      ).toPromise();
      return result?.data

    } catch (error: any) {
      return await this.redirectError(error)
    }
  }

  async getCities(uuid: string) {
    try {
      const result = await this.httpClient.get<IResponse>(
        `${this.API_PATH}cities?state_uuid=${uuid}`
      ).toPromise();
      return result?.data

    } catch (error: any) {
      return await this.redirectError(error)
    }
  }
}
