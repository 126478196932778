
<div class="all-content">
    <div class="row row-center" *ngIf="isShowPrincipal">
        <div class="card-instance shadow-2" name='Principal-corporation' [ngClass]="properties?.corporation?.uuid === principalCorporation?.uuid ? 'no-pointer clicked-card' : 'pointer'" (click)="chooseCorporation(principalCorporation)">
            <div class="title">
                {{ principalCorporation?.name }}
            </div>
            <div class="sub-title">
                {{ getLanguages('activities/principal') }}
            </div>
            <div class="toggle-right"></div>
        </div>
    </div>
    <div class="row" [ngStyle]="{ 'padding': '1vh 4vh' }" *ngIf="getViewCorporationDashboard()">
        <div class="arrow arrow-left" (click)="scrollLeft('corporations')" name="Left-arrow">
            <i class="fas fa-angle-left"></i>
        </div>
        <div class="cards" #scrollContainer>
            <div class="card-instance shadow-2" name='Corporation-card' *ngFor="let corporation of corporationsArray" [ngClass]="properties.corporation?.uuid === corporation?.uuid ? 'no-pointer clicked-card' : 'pointer'" (click)="chooseCorporation(corporation)">
                <div class="title">
                    {{ corporation?.name }}
                </div>
                <div class="sub-title">
                    {{ getLanguages('activities/corporation') }}
                </div>
                <div class="toggle-right"></div>
            </div>
        </div>
        <div class="arrow arrow-right" (click)="scrollRight('corporations')" name="Right-arrow">
            <i class="fas fa-angle-right"></i>
        </div>
    </div>

    <div class="item" *ngIf="indicators.line1.length" [ngStyle]="{ 'padding': '1vh 5vh' }">
        <span>
            {{ getLanguages('home/enterprise') }}
        </span>
        <div class="row row-center">
            <div class="card-indicator shadow-2" [attr.id]="'Card-' + card.slug" *ngFor="let card of indicators.line1" (click)="choseLine2(card)" [ngClass]="fliped.slug === card.slug ? 'clicked-card no-pointer' : 'pointer'">
                <div class="title">
                    {{ getName(card.name) }}
                </div>
                <div class="sub-title">
                    {{ card?.value }}
                </div>
            </div>
        </div>
    </div>
    <div class="item" *ngIf="indicators.line2?.length > 0" [ngStyle]="{ 'padding': '1vh 5vh' }" >
        <span>
            {{ getTitle() }}
        </span>
        <div class="row row-center">
            <div class="card-indicator shadow-2" *ngFor="let card of indicators.line2">
                <div class="title">
                    {{ card.name ? getName(card.name) : '' }}
                </div>
                <div class="sub-title">
                    {{ card.value }}
                </div>
            </div>
        </div>
    </div>

    <div class="row row-class">
        <div class="item-class">
            <div class="item-toggle" [ngStyle]="{'background-color': '#B2419A'}"></div>
            <div class="title-class">
                {{ breedArray[0] ? breedArray[0] : 0 }}
            </div>
            <div class="subtitle-class">
                {{ getLanguages('home/core') }}
            </div>
        </div>
        <div class="item-class">
            <div class="item-toggle" [ngStyle]="{'background-color': '#DB2F7B'}"></div>
            <div class="title-class">
                {{ breedArray[1] ? breedArray[1] : 0 }}
            </div>
            <div class="subtitle-class">
                {{ getLanguages('home/corporate') }}
            </div>
        </div>
        <div class="item-class">
            <div class="item-toggle" [ngStyle]="{'background-color': '#CC46AE'}"></div>
            <div class="title-class">
                {{ breedArray[2] ? breedArray[2] : 0 }}
            </div>
            <div class="subtitle-class">
                {{ getLanguages('home/management') }}
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isLoadingChooseInstance || isLoading">
    <div class="background-modals">
      <app-loading-instance [instance]="selectedCorporation" *ngIf="isLoadingChooseInstance"> </app-loading-instance>
      <app-loading *ngIf="isLoading"></app-loading>
    </div>
</ng-container>
