import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import {BusinessSolutionsService} from "../../../../services/business-solutions/business-solutions.service";
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { parse } from "circular-json"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { PageTechnologiesService } from 'src/app/services/page-technologies/page-technologies.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent {
  @Output() toggleImportModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();
  @Input() properties: any = {}
  @Input() parameters: any = {}
  @Input() type: string = ''
  selectedFileName: string = '';
  selectedFileHave: boolean = false;
  fileInputRef: any;
  isShowButton = false;
  isLoading = false;

  alert: any

  business_solutions_import: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  xlsFiles: any = []

  template: any = []
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor(
    private cookieService: CookieService,
    private businessSolutionsService: BusinessSolutionsService,
    private technologiesService: PageTechnologiesService
  ) {}

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async exitModal(hide: boolean = true){
    this.toggleImportModal.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([this.exitModal()]);
  }

  handleFileInput(event: any) {
    this.xlsFiles = event.target.files;
    this.selectedFileHave = this.xlsFiles && this.xlsFiles.length > 0;
    this.selectedFileName = this.selectedFileHave ? this.xlsFiles[0].name : '';
    this.isShowButton = true;
  }

  clearFileSelection() {
    this.selectedFileName = '';
    this.selectedFileHave = false;

    this.fileInputRef = null; // Limpa o valor do input
    this.xlsFiles = [];
    this.isShowButton = false;
  }

  onFileInputRef(input: any) {
    this.fileInputRef = input;
  }

  async set(){
    switch (this.type) {
      case 'business_solution':
        await this.setBusinessTreeImportXls()
        break;
      case 'technologies':
        await this.setTechnologyTreeImportXls()
        break;
      default:
        break;
    }
  }

  async getTemplate(){
    switch (this.type) {
      case 'business_solution':
        this.template = [
          { 
            Code: '05', 
            Name: 'Journey 1', 
            Description: 'Description', 
            Procedures: 'Procedure 1; Procedure 2',  
            Integrations: 'Integration 1; Integration 2',
            FAQ: 'Question 1; Question 2'
          },
          { 
            Code: '05.05', 
            Name: 'Process 1', 
            Description: 'Description', 
            Procedures: 'Procedure 1; Procedure 2',  
            Integrations: 'Integration 1; Integration 2',
            FAQ: 'Question 1; Question 2'
          },
          { 
            Code: '05.10', 
            Name: 'Process 2', 
            Description: 'Description', 
            Procedures: 'Procedure 1; Procedure 2',  
            Integrations: 'Integration 1; Integration 2',
            FAQ: 'Question 1; Question 2'
          },
          {
            Code: '10', 
            Name: 'Journey 2', 
            Description: 'Description', 
            Procedures: 'Procedure 1; Procedure 2',  
            Integrations: 'Integration 1; Integration 2',
            FAQ: 'Question 1; Question 2'
          }, 
          { 
            Code: '10.05', 
            Name: 'Process 1', 
            Description: 'Description', 
            Procedures: 'Procedure 1; Procedure 2',  
            Integrations: 'Integration 1; Integration 2',
            FAQ: 'Question 1; Question 2'
          },
        ]
        this.exportToExcel('business_solution')
        break;
      case 'technologies':
        this.template = [
          { 
            Code: '05', 
            Name: 'ERP 1', 
            Version: '-', 
            Date_installation: '-',  
            Manufacturer: '-',
            Specification: '-',
            Description: 'Description'
          },
          { 
            Code: '05.05', 
            Name: 'Module 1', 
            Version: 'Version', 
            Date_installation: 'MM/AA',  
            Manufacturer: 'Manufacturer',
            Specification: 'Specification',
            Description: 'Description'
          },
          { 
            Code: '05.10', 
            Name: 'Module 2', 
            Version: 'Version', 
            Date_installation: 'MM/AA',  
            Manufacturer: 'Manufacturer',
            Specification: 'Specification',
            Description: 'Description'
          },
          { 
            Code: '10', 
            Name: 'ERP 2', 
            Version: '-', 
            Date_installation: '-',  
            Manufacturer: '-',
            Specification: '-',
            Description: 'Description'
          },
          { 
            Code: '10.05', 
            Name: 'Module 1', 
            Version: 'Version', 
            Date_installation: 'MM/AA',  
            Manufacturer: 'Manufacturer',
            Specification: 'Specification',
            Description: 'Description'
          },
        ]
        this.exportToExcel('technologies')
        break
      default:
        break;
    }
  }

  /**
   *
   */
  async setBusinessTreeImportXls () {
    this.isLoading = true;
    try {
      const response: any = await this.businessSolutionsService.setBusinessTreeImportXls(this.xlsFiles[0])
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message)
        this.clearFileSelection()

        this.isLoading = false;
        this.business_solutions_import = {}
        this.business_solutions_import.json = parse(response.data)

        this.updateFiles.emit({files: this.business_solutions_import.json});
      } else {
        addAlert('danger', response.message)
        this.isLoading = false;
        await this.modalClose()
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;
    await this.modalClose()
  }

  async setTechnologyTreeImportXls () {
    this.isLoading = true;
    try {
      const response: any = await this.technologiesService.setTechnologiesImportXls(this.xlsFiles[0])
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message)
        this.clearFileSelection()

        this.isLoading = false;
        this.business_solutions_import = {}
        this.business_solutions_import.json = parse(response.data)

        this.updateFiles.emit({files: this.business_solutions_import.json});
      } else {
        addAlert('danger', response.message)
        this.isLoading = false;
        await this.modalClose()
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;
    await this.modalClose()
  }

  exportToExcel(name: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.template);
    
    const workbook: XLSX.WorkBook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveExcelFile(excelBuffer, name);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    saveAs(data, `${fileName}_template.xls`);
  }
}
