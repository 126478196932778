import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, UrlTree } from '@angular/router';
import { getLanguage, getLanguageCookie } from 'src/assets/language';
import { CookieService } from 'ngx-cookie-service';
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { localStorageToJsonAsync }
 from "../../../helpers/cookieToJson";
import { navigate } from 'src/app/layouts/pages/pages.component';
import { version_ui } from 'src/app/environments/tag_version';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  rememberMe = false;
  showPass = false;
  showDanger = false;
  showSuccess = false;
  showWait = false;
  showAttention = false;

  isLoading: boolean = false
  language: any

  flagArray: any = [
    { lang: 'en', file: 'us.svg'},
    { lang: 'pt', file: 'br.svg'}
  ]

  queueAlert: any = []
  queueTime: any = []

  user: any = {
    name: '',
    lastname: null,
    email: '',
    telephone: '',
    document: '',
  }
  version_ui: any

  constructor(
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService
  ) {
    this.language = this.flagArray[1]

    if(localStorage.getItem('language')){
      this.language =  to64decode(localStorage.getItem('language'));
    }else{
      this.language = this.flagArray[1]
    }
    this.version_ui = version_ui

    this.selectFlag()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async navigate(route: string, redirectLogin: boolean = false){
    if (redirectLogin) {
      this.showWait = false;
    }
    await this.router.navigate([route]);
  }

  formatPhoneNumber(event: any) {
    const input = event.target;
    let formattedValue = input.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

    const maxLength = 11; // Máximo de 11 dígitos para o número de telefone

    if (formattedValue.length > maxLength) {
      formattedValue = formattedValue.substr(0, maxLength); // Limita a quantidade máxima de dígitos
    }

    const countryCode = formattedValue.substr(0, 2); // Captura o código do país (2 primeiros dígitos)
    const areaCode = formattedValue.substr(2, 5); // Captura o código de área (5 dígitos)
    const phoneNumber = formattedValue.substr(7, 4); // Captura o número de telefone (4 últimos dígitos)

    let formattedPhoneNumber = "";

    if (countryCode) {
      formattedPhoneNumber += `(${countryCode}) `;
    }
    if (areaCode) {
      formattedPhoneNumber += `${areaCode}-`;
    }
    if (phoneNumber) {
      formattedPhoneNumber += phoneNumber;
    }

    input.value = formattedPhoneNumber;
  }

  /**
   *
   */
  async register() {
    this.isLoading = true
    try {
      let msg = ``
      let input: any = null
      if (!this.user.name || this.user.name == '') {
        input = `Name: *`
        msg += (msg === `` ? `Preencha os seguintes campos: ` : `, `) + input
      }
      if (!this.user.email || this.user.email == '') {
        input = `Email: *`
        msg += (msg === `` ? `Preencha os seguintes campos: ` : `, `) + input
      }
      if (!this.user.document || this.user.document == '') {
        input = `Document: *`
        msg += (msg === `` ? `Preencha os seguintes campos: ` : `, `) + input
      }
      if (!this.user.telephone || this.user.telephone == '') {
        input = `Phone: *`
        msg += (msg === `` ? `Preencha os seguintes campos: ` : `, `) + input
      }

      if (msg != ``) {
        this.addAlertRegister('orange', this.getLanguages('auth/alert/voidInput'))
        this.isLoading = false
        return
      }

      const response: any = await this.authService.register(this.user)
      if (response && [201, 202].includes(response.status)) {
        // await this.sleepTimeVariables()
        this.showWait = true;
        this.addAlertRegister('success', response.message)

        setTimeout(async () => {
          await this.navigate('/')
        }, 3000)
      }
    } catch (error: any) {
      await this.apiOfflineAsyncRegister(error)
    }
    this.isLoading = false
  }

  formatCpf(event: any) {
    const input = event.target;
    let value = input.value.replace(/\D/g, '');

    const maxLength = 11;

    if (value.length > maxLength) {
      value = value.substr(0, maxLength);
    }

    // Formatação do CPF: xxx.xxx.xxx-xx
    let formattedCpf = value.replace(/(\d{3})(\d)/, '$1.$2');
    formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2');
    formattedCpf = formattedCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    input.value = formattedCpf;
  }

  async selectFlag(flag: any = null) {
    if(flag){
      this.language = flag;
    }
     localStorage.setItem('language', to64encode(this.language))
  }

  addAlertRegister(type: string = '', text: string = '') {
    const someAlert = this.queueAlert.find((alert: { text: string }) => alert.text === text);

    if (someAlert) {
      this.removeAlertRegister(someAlert.id)
      clearTimeout(this.queueTime[someAlert.id]);
    }

    this.queueAlert.push({ id: this.queueAlert.length, type, text })
    this.queueTime[this.queueAlert.length] = setTimeout(() => {
      this.removeAlertRegister(this.queueAlert.length)
    }, 3000)
  }

  removeAlertRegister(id: number, event?: Event){
    this.queueAlert.splice(this.queueAlert.findIndex((item: {id: number;}) => item.id == id), 1)
  }

  async apiOfflineAsyncRegister (error: any) {
    if (!error.status) {
      return
    }

    let cookie = await localStorageToJsonAsync(true)

    if (error.status == 401) {
      return navigate('/login');
    }

    this.addAlertRegister(
      error.status == 0 ? 'danger' : 'orange',
      error.status == 0 ? getLanguage('errors/system/out', cookie['language']['lang']) : error.error.message
    )
  }
}
