<div class="all-content">
  <div class="top">
    <div class="toggle-user"></div>
    <i class="fas fa-xmark" (click)="exitModal()"></i>
  </div>

  <div class="row">
    <div class="title">
      {{ getLanguages('instances/modal/user/viewUser/title') }}
    </div>
  </div>

  <div class="row">
    <div class="item w-3">
        <span> {{ getLanguages('instances/modal/user/viewUser/name') }} </span>
        <input type="text" [(ngModel)]="parameters.name" disabled>
    </div>
    <div class="item w-3">
      <span> {{ getLanguages('instances/modal/user/viewUser/phone') }} </span>
      <input type="text" [(ngModel)]="parameters.telephone" disabled>
    </div>
    <div class="item w-3">
      <span> {{ getLanguages('instances/modal/user/viewUser/email') }} </span>
      <input type="text" [(ngModel)]="parameters.email" disabled>
    </div>
  </div>

  <div class="row content">
    <div class="div-table">
      <table mat-table [dataSource]="getDisplayedRows()" class="table">
<!--        <ng-container matColumnDef="Id">-->
<!--          <th mat-header-cell *matHeaderCellDef class="columnTitle"> <span> Id </span>  </th>-->
<!--          <td mat-cell *matCellDef="let element">-->
<!--          <span> {{element.environment.uuid}} </span>-->
<!--          </td>-->
<!--        </ng-container>-->

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef class="columnTitle"> <span> {{ getLanguages('instances/modal/user/name') }} </span></th>
            <td mat-cell *matCellDef="let element"  >
            <span> {{element.environment.name}} </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="instance.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: instance.displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="div-paginator">
      <div class="paginator">
        <span>
          {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
        </span>
        <button [matMenuTriggerFor]="menu" class="itens-per-page">
          {{ instance.itemsPerPage }}
        </button>
        <mat-menu #menu="matMenu" >
          <button mat-menu-item (click)="choseItemsPage(5)">5</button>
          <button mat-menu-item (click)="choseItemsPage(10)">10</button>
          <button mat-menu-item (click)="choseItemsPage(20)">20</button>
        </mat-menu>
        <span>
          {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
        </span>
        <input disabled class="totalPages" class="input-paginator" [ngModel]="getTotalPages()">
        <button [ngClass]="instance.currentPage === 1 ? 'disabled-arrow' : 'arrow' " [disabled]="instance.currentPage === 1" (click)="onPageChange(instance.currentPage - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <input type="number" [(ngModel)]="instance.currentPage"  (blur)="checkValue($event)" class="input-paginator">
        <button [ngClass]="instance.currentPage == getTotalPages() ? 'disabled-arrow' : 'arrow' "  [disabled]="instance.currentPage == getTotalPages()" (click)="onPageChange(instance.currentPage + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
