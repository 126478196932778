import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { parse } from 'circular-json';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { to64decode } from 'src/app/helpers/base64';
import { localStorageToJsonAsync }
 from 'src/app/helpers/cookieToJson';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { FunctionService } from 'src/app/services/function.service';
import { HomeService } from 'src/app/services/home/home.service';
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';
import { UsersService } from 'src/app/services/users/users.service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @Output() clickedCard = new EventEmitter<string>();

  private subscription: Subscription | undefined;

  properties: any = {}

  corporationsArray: any
  selectedCorporation: any
  isLoadingChooseInstance: boolean = false;
  fliped: any = { slug: ''} 
  indicators: any = {
    line1: [],
    line2: []
  }
  breedArray: any = []
  isLoading: boolean = false
  
  principalCorporation: any


  isShowPrincipal = false;

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private userService: UsersService,
    private userEnvironmentService: UserEnvironmentsService,
    private homeService: HomeService,
    private businessSolutionsService: BusinessSolutionsService,
    private router: Router,
    private sharedService: FunctionService
  ){
    this.principalCorporation = {name: '', uuid: ''}
  }

  async ngOnInit(){
    this.properties = await localStorageToJsonAsync()
    await this.getCardsArrays(),
    await this.getUserCorporations()
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async ngAfterViewInit() {
    if(!this.scrollContainer || !this.scrollContainer.nativeElement){
      return
    }
    this.scrollContainer.nativeElement.scrollLeft = 0;

    this.subscription = this.sharedService.reloadDashboard$.subscribe(() => {
      Promise.all([
        this.getCardsArrays(),
        this.getUserCorporations()
      ])
      this.getCardsArraysLine2(this.fliped.slug)
    });
  }

  getViewCorporationDashboard(): boolean{
    if(this.corporationsArray?.length < 2 && !this.corporationsArray[0]?.name){
      return false
    }
        
    return true
  }


  scrollLeft(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;

    if(param == 'corporations'){
      this.scrollContainer.nativeElement.scrollLeft -= 25 * vwToPixels;
    }
  }

  scrollRight(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;
  
    if(param == 'corporations'){
      this.scrollContainer.nativeElement.scrollLeft += 25 * vwToPixels;
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getName(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  async getUserCorporations(){
    try{
      let response = await this.userEnvironmentService.getUserCorporations()
      if(response && response.data){

        response.data.forEach((item: any) => {
          if(item.is_principal){
            this.isShowPrincipal = true
          }
        })

        this.corporationsArray = [...response.data].filter((corporation: any) => !corporation.is_principal)
        this.principalCorporation = [...response.data].filter((corporation: any) => corporation.is_principal)[0]
        const backupArray = [...response.data].filter((corporation: any) => !corporation.is_principal)
        const uuid = to64decode(localStorage.getItem('corporation')).uuid 

        if(uuid == null || uuid == undefined || to64decode(localStorage.getItem('corporation')).is_principal){
          return
        }

        if(this.corporationsArray.length == 1){
          return
        }

        const index = this.corporationsArray.findIndex((corporation: any) => corporation.uuid === uuid);
        this.corporationsArray[0] = backupArray[index]
        this.corporationsArray[index] = backupArray[0]
      }
    }catch(error: any){
      console.error(error.message)
    }
  }

  async chooseCorporation(corporation: any){
    if (this.properties?.corporation?.uuid === corporation?.uuid) {
      return
    }
    localStorage.setItem('image_corporation', corporation.url)
    delete corporation.url
    this.isLoadingChooseInstance = true;
    this.selectedCorporation = corporation
    await this.authService.setCookie({corporation: corporation})
    await this.getMe()
    setTimeout(() => {
      this.isLoadingChooseInstance = false;
      window.location.reload()
    }, 1000);
  }

  async getMe() {
    try {
      const response = await this.authService.getMe();
      if (![200].includes(response.status)) {
        return this.setAddAlert('danger', response.message)
      }

      this.properties = { ...response.data, ...await localStorageToJsonAsync(true) }
      delete this.properties.corporation.url
      await this.authService.setCookie(this.properties)
      await this.userService.setUserMe(this.properties)
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async choseLine2(card: any){
    if (this.fliped.slug === card.slug) {
      return
    }

    this.clickedCard.emit(card)
    this.fliped = card
    await this.getCardsArraysLine2(card.slug)
    await this.router.navigate([card.url]);
  }

  async getCardsArrays(){
    try {
      const response: any = await this.homeService.getCards('home').toPromise()
      if (response.data.cards) {
        for(let i = 0; i < 4; i++){
          this.indicators.line1[i] = {}
          this.indicators.line1[i].name = response.data.cards[i].name
          this.indicators.line1[i].value = response.data.cards[i].value
          this.indicators.line1[i].slug = response.data.cards[i].slug
          this.indicators.line1[i].url = response.data.cards[i].url
        }
      }
      if (response.data.breed) {
       this.breedArray[0] = response.data.breed.core
       this.breedArray[1] = response.data.breed.corporate
       this.breedArray[2] = response.data.breed.management
      }

    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getCardsArraysLine2(fliped: any){
    try {
      const response: any = await this.homeService.getCardsArrays(fliped).toPromise()
      if (response.data) {
        for(let i = 0; i < 4; i++){
          this.indicators.line2[i] = {}
          this.indicators.line2[i].name = response.data[i].name
          this.indicators.line2[i].value = response.data[i].value
          this.indicators.line2[i].slug = response.data[i].slug
        }
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }  

  setAddAlert (type: string = '', text: string = '') {
    addAlert(type, text)
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getTitle(): any{
    if(this.fliped.slug == 'environment'){
      return this.getNames({en: 'Environment', pt: 'Ambiente'})
    }
    if(this.fliped.slug == 'corporations'){
      return this.getNames({en: 'Corporation', pt: 'Corporação'})
    }
    if(this.fliped.slug == 'users'){
      return this.getNames({en: 'Users', pt: 'Usuários'})
    }
    if(this.fliped.slug == 'business_solutions'){
      return this.getNames({en: 'Process', pt: 'Processos'})
    }
    return this.getNames({en: 'Enterprise', pt: 'Empresarial'})
  }
}

