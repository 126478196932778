<div class="all-content">
    <div class="top">
        <div class="toggle"></div>
        <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="row">
        <span class="title">
            {{ type == 'edit' ? getLanguages('versions/edit/name') : getLanguages('versions/create/name') }}
        </span>
    </div>
    <div class="row">
        <div class="item">
            <div class="alert-save" *ngIf="type != 'edit'">
                <!-- <div class="toggle-save"></div> -->
                {{ getLanguages('auth/alert/choseVersion') }}  
            </div>
        </div>
    </div>
    <div class="row">
        <div class="item">
            <span> {{ getLanguages('auth/create/name') }} </span>
            <input type="text" [(ngModel)]="version.name">
        </div>
    </div>
    <div class="row">
        <div class="item">
            <span> {{ getLanguages('businessSolution/modal/addCode/description') }} </span>
            <textarea [(ngModel)]="version.reason"></textarea>
        </div>
    </div>
    <div class="row bottom">
        <button
          [ngClass]="version.name == '' || (version.name == parameters?.name && version.reason == parameters?.reason) ? 'inactive' : 'active'"
          [disabled]="version.name == '' || (version.name == parameters?.name && version.reason == parameters?.reason)"
          (click)="save()">
            {{ getLanguages('save') }}
        </button>
    </div>
</div>
