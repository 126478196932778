<div class="all-content">
    <div class="top">
      <div class="toggle"></div>
      
      <div class="div-icon-button">
        <div class="save" *ngIf="isEdit" (click)="saveCorporationDimensions()">
          {{ getLanguages('save')}}
        </div>
        <div class="save" [ngClass]="isEdit ? 'edit' : ''" (click)="editCancel()">
          {{ isEdit ? getLanguages('cancel'): getLanguages('edit') }}
        </div>
        <i class="fas fa-xmark" (click)="exitModal()"></i>
      </div>    
    </div>

    <div class="row">
      <div class="title">
        {{ getLanguages('corporation/dimension/title') }} {{ getDimension() }}
      </div>
    </div>

    <div class="row">
        <div class="item">
          <span> {{ getLanguages('businessSolution/modal/addCode/business') }} </span>
          <input disabled type="text" name="business" [ngModel]="parameters.corporation.corporation_name">
        </div>
    </div>
    <div class="row">
      <div class="roll">
        <span (click)="toggleDimension(1)" [class.active]="viewDimension === 1"> {{ getLanguages('corporation/dimension/strategy') }} </span>
        <span (click)="toggleDimension(2)" [class.active]="viewDimension === 2"> {{ getLanguages('corporation/dimension/skills') }} </span>
        <!-- <span (click)="toggleDimension(3)" [class.active]="viewDimension === 3"> {{ getLanguages('corporation/dimension/technology') }} </span> -->
      </div>
    </div>

    <div class="row buttons">
        <button *ngIf="isEdit" (click)="removeSelectedRows()">{{ getLanguages('businessSolution/modal/people/info/removeRow') }}</button>
        <button *ngIf="isEdit" (click)="addRow()">{{ getLanguages('businessSolution/modal/people/info/newRow') }}</button>
    </div>

    <div class="row content">
        <div class="div-table">
            <p-table [value]="getRows()" class="table">
                <ng-template pTemplate="header">
                    <tr>
                        <th
                            *ngIf="isEdit"
                            [ngClass]="isEdit ? 'matColumn' : 'matColumn-disabled' ">
                            <!-- <mat-checkbox class="checkbox"
                                (click)="toggleAllRows()"
                                [color]="task.color"
                                [checked]="isAllSelected()"
                                [disabled]="!isEdit"
                                ></mat-checkbox> -->
                            </th>
                        <th>
                            <span class="columnTitle"> ID </span>
                        </th>
                        <th>
                            <span class="columnTitle"> {{ getLanguages('name') }} </span>
                        </th>
                        <th *ngIf="viewDimension == 3">
                          <span class="columnTitle"> {{ getLanguages('link') }} </span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-element let-rowIndex="rowIndex">
                    <tr *ngIf="!element.is_delete">
                        <td
                            *ngIf="isEdit"
                            [ngClass]="isEdit ? 'matColumn' : 'matColumn-disabled' ">
                            <mat-checkbox
                                class="checkbox"
                                (click)="$event.stopPropagation(); setSelection(element)"
                                [color]="task.color"
                                [checked]="getSelection(element)"
                                [disabled]="!isEdit || element.in_use"
                                >
                            </mat-checkbox>
                        </td>
                        <td>
                            <input
                                disabled
                                class="columnText"
                                type="text"
                                [value]="(( getCurrentPages() - 1) * getItemsPerPage()) + (rowIndex + 1)">
                        </td>
                        <td>
                            <input [disabled]="!isEdit || element.in_use" class="columnText" type="text" [(ngModel)]="element.name">
                        </td>
                        <td *ngIf="viewDimension == 3">
                          <input [disabled]="!isEdit" class="columnText" type="text" [(ngModel)]="element.link">
                        </td>
                    </tr>
                </ng-template>
                
            </p-table>
        </div>
        <div class="div-paginator">
            <div class="paginator">
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
                </span>
                <button [matMenuTriggerFor]="menu" class="itens-per-page">
                    {{ getItemsPerPage()}}
                </button>
                <mat-menu #menu="matMenu" >
                  <button mat-menu-item (click)="choseItemsPage(5)">5</button>
                  <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                  <button mat-menu-item (click)="choseItemsPage(20)">20</button>
                </mat-menu>
                <span>
                      {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
                  </span>
                <span class="totalPages">
                      {{ getTotalNumberPages() }}
                  </span>
                <button
                  [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() === 1"
                  (click)="setCurrentPages(getCurrentPages() - 1); getDisplayedRows();">
                  <i class="fa-solid fa-angle-left"></i>
                </button>
                <input type="number" disabled [ngModel]="getCurrentPages()" (blur)="getTotalPages()">
                <button
                  [ngClass]="getCurrentPages() >= getTotalNumberPages()  ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() >= getTotalNumberPages()"
                  (click)="setCurrentPages(getCurrentPages() + 1);getTotalPages();">
                  <i class="fa-solid fa-angle-right"></i>
                </button>
            </div>
        </div>
    </div>
  </div>
