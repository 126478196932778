import { Injectable, Query } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse, IResponseBusiness } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()


  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  async setNodeToClipboard(body: any) {

    return await this.httpClient.post<IResponse>(
      `${this.API_PATH}corporations-clipboard`,
      body,
      this.requestOptions
    ).toPromise();
  }

  getNodeInClipboard(): Observable<IResponse> {
    const url = `${this.API_PATH}corporations-clipboard`;
    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

}
