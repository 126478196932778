import { Component, isDevMode } from '@angular/core';
import {AuthService} from "./services/auth/auth.service";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'lpj_make_ui';

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
  ) {

  }

  async ngOnInit() {
    console.log(isDevMode() ? 'Development!' : 'Production!')
  }

}
