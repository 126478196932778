<div class="all-content">
    <!-- <div class="row row-scroll" *ngIf="parameters?.arrayGuidelineProject?.length > 0">
        <div class="arrow-scroll" *ngIf="showArrowLevel1" (click)="scrollLeft('box')">
            <i class="fas fa-angle-left"></i>
        </div>
        <div class="cards" #scrollContainerBox>
            <div class="box" (click)="activateLevel1(item)" [ngClass]="selectedLevel1?.uuid == item?.uuid ? 'activated' : ''" *ngFor="let item of parameters?.arrayGuidelineProject">
                {{ item?.label }}
            </div>
        </div>
        <div class="arrow-scroll" *ngIf="showArrowLevel1" (click)="scrollRight('box')">
            <i class="fas fa-angle-right"></i>
        </div>
    </div>

    <div class="row row-scroll" *ngIf="selectedLevel1?.children?.length > 0">
        <div class="arrow-scroll" *ngIf="showArrowLevel2" (click)="scrollLeft('radio')">
            <i class="fas fa-angle-left"></i>
        </div>
        <div class="cards" #scrollContainerRadio *ngIf="selectedLevel2?.uuid">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedLevel2.uuid">
            <mat-radio-button *ngFor="let item of selectedLevel1?.children" [value]="item?.uuid" (click)="activateLevel2(item)">
              {{ item?.label }}
            </mat-radio-button>
          </mat-radio-group>          
        </div>
        <div class="arrow-scroll" *ngIf="showArrowLevel2" (click)="scrollRight('radio')">
            <i class="fas fa-angle-right"></i>
        </div>
    </div> -->

    <div class="row">
      <div class="item item-40">
        <span> {{ getLanguages('businessSolution/modal/project/project') }} </span>
        <button class="button-menu" [matMenuTriggerFor]="projectMenu">
          <span>
            {{ selectedLevel1.label }}
          </span>
          <i class="fa-solid fa-caret-down"></i>
        </button>
    
        <mat-menu #projectMenu="matMenu">
          <div class="menu">
              <div class="input" (click)="$event.stopPropagation()">
                <input type="text" [(ngModel)]="searchProject" placeholder="{{ getLanguages('search') }}">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
                <ng-container *ngFor="let project of parameters?.arrayGuidelineProject">
                  <ng-container *ngIf="project.label.toLowerCase().includes(searchProject.toLowerCase())">
                    <div class="item-menu" (click)="activateLevel1(project)">
                      <span [ngClass]="project.uuid == selectedLevel1.uuid ? 'valid-span' : 'invalid-span'">
                        {{ project.label }}
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
        </mat-menu> 
      </div>

      <div class="item item-40">
        <span> {{ getLanguages('businessSolution/modal/project/subproject') }} </span>
        <button class="button-menu" [matMenuTriggerFor]="subprojectMenu">
          <span>
            {{ selectedLevel2?.label }}
          </span>
          <i class="fa-solid fa-caret-down"></i>
        </button>
    
        <mat-menu #subprojectMenu="matMenu">
          <div class="menu">
              <div class="input" (click)="$event.stopPropagation()">
                <input type="text" [(ngModel)]="searchSubProject" placeholder="{{ getLanguages('search') }}">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
                <ng-container *ngFor="let project of selectedLevel1?.children">
                  <ng-container *ngIf="project.label.toLowerCase().includes(searchSubProject.toLowerCase())">
                    <div class="item-menu" (click)="activateLevel2(project)">
                      <span [ngClass]="project.uuid == selectedLevel2.uuid ? 'valid-span' : 'invalid-span'">
                        {{ project.label }}
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
        </mat-menu> 
      </div>
    </div>

    <div class="guidelineLink" *ngIf="!parameters?.arrayGuidelineProject?.length || !selectedLevel1?.children?.length">
        <div class="i">
          <i class="fa-solid fa-exclamation"></i>
        </div>    
  
        <span  (click)="navigate('project_guideline')">
          {{ getLanguages('businessSolution/modal/project/table/guideline') }}
        </span>
  
        <button (click)="navigate('project_guideline')" *ngIf="getAccessGuideline()">
          <i class="fas fa-sliders"></i>
          {{ getLanguages('businessSolution/content/info/goToGuideline') }}
        </button>
    </div>

    <div class="new" *ngIf="selectedLevel2?.uuid && parameters.isEdit" (click)="addRow()">
        {{ getLanguages('new') }}
    </div>

    <div class="row" *ngIf="selectedLevel2?.uuid">
        <p-table [value]="getRows()" class="table-project" [sortField]="'start_date'" [sortOrder]="1">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="task_name">
                  <span>{{ getLanguages('businessSolution/modal/project/table/task_name') }}</span>
                  <p-sortIcon field="task_name"></p-sortIcon>
                </th>
                <th pSortableColumn="concluded">
                  <span>{{ getLanguages('businessSolution/modal/project/table/concluded') }}</span>
                  <p-sortIcon field="concluded"></p-sortIcon>
                </th>
                <th pSortableColumn="plan">
                  <span>{{ getLanguages('businessSolution/modal/project/table/plan') }}</span>
                  <p-sortIcon field="plan"></p-sortIcon>
                </th>
                <th pSortableColumn="duration">
                  <span>{{ getLanguages('businessSolution/modal/project/table/duration') }}</span>
                  <p-sortIcon field="duration"></p-sortIcon>
                </th>
                <th pSortableColumn="start_date">
                  <span>{{ getLanguages('businessSolution/modal/project/table/start_date') }}</span>
                  <p-sortIcon field="start_date"></p-sortIcon>
                </th>
                <th pSortableColumn="end_date">
                  <span>{{ getLanguages('businessSolution/modal/project/table/end_date') }}</span>
                  <p-sortIcon field="end_date"></p-sortIcon>
                </th>
                <th *ngIf="parameters.isEdit">
                  <span>{{ getLanguages('businessSolution/modal/project/table/action') }}</span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-element let-rowIndex="rowIndex">
              <tr>
                <td>
                  <i class="fas fa-circle-exclamation" *ngIf="element.task_name == ''" [matTooltip]="getLanguages('businessSolution/modal/project/table/name_task_null')"></i>
                  <textarea [disabled]="!parameters.isEdit" [(ngModel)]="element.task_name" (input)="autoResize($event)"></textarea>
                </td>
                <td>
                  <textarea (keypress)="allowNumbersOnly($event)" (input)="limitValue($event)" [disabled]="!parameters.isEdit" [(ngModel)]="element.concluded"></textarea>
                </td>
                <td>
                  <textarea (keypress)="allowNumbersOnly($event)" (input)="limitValue($event)" [disabled]="!parameters.isEdit" [(ngModel)]="element.plan"></textarea>
                </td>
                <td>
                  <textarea placeholder="00.0" (keypress)="allowNumbersAndDecimal($event)" (input)="onInputChange($event, element.uuid)" [disabled]="!parameters.isEdit" [(ngModel)]="element.duration"></textarea>
                </td>
                <td class="td-date" (click)="focusInput($event)">
                  <div>
                    <input matInput [(ngModel)]="element.start_date" [disabled]="!parameters.isEdit" (dateChange)="onDateChange($event, element.uuid)" [matDatepicker]="picker" (keypress)="allowNumbersOnly($event)" (input)="onDateInputChange($event, element.uuid)">
                    <mat-datepicker #picker></mat-datepicker>
                    <button [disabled]="!parameters.isEdit" (click)="picker.open()">
                      <i class="fas fa-calendar-days"></i>
                    </button>
                  </div>
                </td>
                <td class="disabled-textarea">
                  <textarea disabled [(ngModel)]="element.end_date"></textarea>
                </td>
                <td *ngIf="parameters.isEdit" class="colunmAction">
                  <i class="fas fa-trash pointer" (click)="removeItem(element.uuid)"></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
          
        <div class="div-paginator">
            <div class="paginator">
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
                </span>
                <button [matMenuTriggerFor]="menu" class="itens-per-page">
                    {{ getItemsPerPage()}}
                </button>
                <mat-menu #menu="matMenu" >
                  <button mat-menu-item (click)="choseItemsPage(5)">5</button>
                  <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                  <button mat-menu-item (click)="choseItemsPage(20)">20</button>
                </mat-menu>
                <span>
                      {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
                  </span>
                <span class="totalPages">
                      {{ getTotalNumberPages() }}
                  </span>
                <button
                  [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() === 1"
                  (click)="setCurrentPages(getCurrentPages() - 1); getDisplayedRows();">
                  <i class="fa-solid fa-angle-left"></i>
                </button>
                <input type="number" disabled [ngModel]="getCurrentPages()" (blur)="getTotalPages()">
                <button
                  [ngClass]="getCurrentPages() >= getTotalNumberPages()  ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() >= getTotalNumberPages()"
                  (click)="setCurrentPages(getCurrentPages() + 1); getTotalPages();">
                  <i class="fa-solid fa-angle-right"></i>
                </button>
            </div>
        </div> 
    </div>
</div>
