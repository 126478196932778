import { Component, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { v4 } from "uuid";
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';


@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['name','action'];
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  typeArray: any = []
  searchType: string = '';
  selectedType: any = {
    name: {
      en: 'Input',
      pt: 'Entrada',
    },
    slug: 'input'
  }

  is_new_integration: boolean = true;

  currentIntegration: any = {}


  constructor(
    private businessSolutionsService: BusinessSolutionsService,
    private cookieService: CookieService,
  ) {
    this.dataSource = new MatTableDataSource();
    this.newIntegration()
  }

  async ngOnInit(){
    await this.getOptionsArray()
    this.dataSource.data = this.parameters.item.integrations ?? []
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isData(){
    return this.dataSource.data.length > 0
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dataSource.data.slice(startIndex, endIndex);
  }

  getColumns(){
    return this.displayedColumns.slice(1)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.currentPage = newPage;
  }

  getTotalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.itemsPerPage);
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  choseItemSelect(type: string, value: any, item: any){
    switch (type) {
      case 'type':
        this.selectedType = value;
        item.type = value.slug;
        break;
      default:
        break;
    }
  }

  async getOptionsArray() {
    this.typeArray = sortArrayOfObjects(this.parameters.optionsArray.typeIntegration)
  }

  async selectIntegration(item: any){
    this.currentIntegration = { ...item }
    this.is_new_integration = false;
    this.typeArray.forEach((type:any) => {
      if(type.slug == item.type){
        this.selectedType = type
      }
    });
  }

  async newIntegration(){
    this.currentIntegration = {
      uuid: v4(),
      name: '',
      type: '',
      description: '',
      technical_script: '',
      to: '',
      from: ''
    };

    this.is_new_integration = true;
  }

  async saveIntegration(){
    let inputs: any = []

    if(!this.parameters.isEdit){
      return
    }

    if (!this.currentIntegration.name || this.currentIntegration.name == '') {
      inputs.push('Name')
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return
    }
    this.currentIntegration.type = this.selectedType.slug

    if(this.is_new_integration){
      this.dataSource.data.push(this.currentIntegration)
    }else{
      this.dataSource.data.find((item: any, index: number) => {
        if(item.uuid == this.currentIntegration.uuid){
          this.dataSource.data[index] = this.currentIntegration
        }
      })
    }

    await this.newIntegration()
  }

  async deleteIntegration(uuid: string) {
    this.dataSource.data = this.dataSource.data.filter(item => item.uuid !== uuid);
    this.parameters.item.integrations = this.dataSource.data
    await this.newIntegration()
  }
}
