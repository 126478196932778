export const generateBpmnXml = (tasks: any[], name: string): string => {
  const header = `<?xml version="1.0" encoding="UTF-8"?>
  <definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI" xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" id="sid-38422fae-e03e-43a3-bef4-bd33b32041b2" targetNamespace="http://bpmn.io/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="17.7.1">
    <process id="Process_1" isExecutable="false">
      <laneSet>
        <lane id="Lane_1" name="${name}">
          <flowNodeRef>StartEvent_1</flowNodeRef>
          ${tasks.map(task => `<flowNodeRef>Task_${task.uuid}</flowNodeRef>`).join('')}
          <flowNodeRef>EndEvent_1</flowNodeRef>
        </lane>
      </laneSet>`;

  const startEvent = `
      <startEvent id="StartEvent_1" name="Start">
        <outgoing>SequenceFlow_0</outgoing>
      </startEvent>`;

  const footer = `
      <endEvent id="EndEvent_1" name="End">
        <incoming>Flow_EndEvent</incoming>
      </endEvent>
    </process>
    <bpmndi:BPMNDiagram id="BpmnDiagram_1">
      <bpmndi:BPMNPlane id="BpmnPlane_1" bpmnElement="Process_1">
        <bpmndi:BPMNShape id="Lane_1_di" bpmnElement="Lane_1">
          <omgdc:Bounds x="100" y="10" width="800" height="400" />
        </bpmndi:BPMNShape>
        <bpmndi:BPMNShape id="StartEvent_1_di" bpmnElement="StartEvent_1">
          <omgdc:Bounds x="150" y="102" width="36" height="36" />
          <bpmndi:BPMNLabel>
            <omgdc:Bounds x="134" y="145" width="73" height="14" />
          </bpmndi:BPMNLabel>
        </bpmndi:BPMNShape>`;

  let tasksXml = '';
  let sequenceFlowsXml = '';
  let shapesXml = '';
  let edgesXml = '';

  let xCoordinate = 240;
  const yCoordinate = 80;

  tasks.forEach((task, index) => {
    const nextTaskIndex = index + 1;
    const nextTaskUuid = tasks[nextTaskIndex] ? tasks[nextTaskIndex].uuid : null;

    tasksXml += `
      <task id="Task_${task.uuid}" name="${task.label}">
        <incoming>SequenceFlow_${index === 0 ? '0' : task.uuid}</incoming>
        ${nextTaskUuid ? `<outgoing>SequenceFlow_${task.uuid}</outgoing>` : '<outgoing>Flow_EndEvent</outgoing>'}
      </task>`;

    if (index === 0) {
      sequenceFlowsXml += `
        <sequenceFlow id="SequenceFlow_0" sourceRef="StartEvent_1" targetRef="Task_${task.uuid}" />`;
    }

    if (nextTaskUuid) {
      sequenceFlowsXml += `
        <sequenceFlow id="SequenceFlow_${task.uuid}" sourceRef="Task_${task.uuid}" targetRef="Task_${nextTaskUuid}" />`;
    } else {
      sequenceFlowsXml += `
        <sequenceFlow id="Flow_EndEvent" sourceRef="Task_${task.uuid}" targetRef="EndEvent_1" />`;
    }

    shapesXml += `
      <bpmndi:BPMNShape id="Task_${task.uuid}_di" bpmnElement="Task_${task.uuid}">
        <omgdc:Bounds x="${xCoordinate}" y="${yCoordinate}" width="100" height="80" />
      </bpmndi:BPMNShape>`;

    if (index === 0) {
      edgesXml += `
        <bpmndi:BPMNEdge id="SequenceFlow_0_di" bpmnElement="SequenceFlow_0">
          <omgdi:waypoint x="186" y="120" />
          <omgdi:waypoint x="${xCoordinate}" y="${yCoordinate + 40}" />
        </bpmndi:BPMNEdge>`;
    }

    if (nextTaskUuid) {
      edgesXml += `
        <bpmndi:BPMNEdge id="SequenceFlow_${task.uuid}_di" bpmnElement="SequenceFlow_${task.uuid}">
          <omgdi:waypoint x="${xCoordinate + 100}" y="${yCoordinate + 40}" />
          <omgdi:waypoint x="${xCoordinate + 150}" y="${yCoordinate + 40}" />
        </bpmndi:BPMNEdge>`;
    } else {
      edgesXml += `
        <bpmndi:BPMNEdge id="Flow_EndEvent_di" bpmnElement="Flow_EndEvent">
          <omgdi:waypoint x="${xCoordinate + 100}" y="${yCoordinate + 40}" />
          <omgdi:waypoint x="${xCoordinate + 150}" y="${yCoordinate + 40}" />
        </bpmndi:BPMNEdge>`;
    }

    xCoordinate += 150;
  });

  shapesXml += `
    <bpmndi:BPMNShape id="EndEvent_1_di" bpmnElement="EndEvent_1">
      <omgdc:Bounds x="${xCoordinate}" y="${yCoordinate + 20}" width="36" height="36" />
      <bpmndi:BPMNLabel>
        <omgdc:Bounds x="${xCoordinate - 10}" y="${yCoordinate + 60}" width="66" height="14" />
      </bpmndi:BPMNLabel>
    </bpmndi:BPMNShape>`;

  const xml = header + startEvent + tasksXml + sequenceFlowsXml + footer + shapesXml + edgesXml + `
      </bpmndi:BPMNPlane>
    </bpmndi:BPMNDiagram>
  </definitions>`;

  return xml;
};

export const addTaskXml = (xml: string, array: any[]): string => {
  const processTagEnd = '</process>';
  const diagramTagEnd = '</bpmndi:BPMNPlane>';
  
  let tasksXml = '';
  let shapesXml = '';
  let edgesXml = '';

  let xCoordinate = 700; 
  const yCoordinate = 300; 

  array.forEach((task, index) => {
    tasksXml += `
      <task id="Task_${task.uuid}" name="${task.label}">
        <incoming>Flow_StartEvent</incoming>
        <outgoing>Flow_EndEvent</outgoing>
      </task>`;

    shapesXml += `
      <bpmndi:BPMNShape id="Task_${task.uuid}_di" bpmnElement="Task_${task.uuid}">
        <omgdc:Bounds x="${xCoordinate}" y="${yCoordinate}" width="100" height="80" />
      </bpmndi:BPMNShape>`;

    xCoordinate += 150;
  });

  const newXml = xml
    .replace(processTagEnd, tasksXml + processTagEnd)
    .replace(diagramTagEnd, shapesXml + diagramTagEnd);

  return newXml;
};

export const updateTaskXml = (xml: string, array: { label: string; uuid: string }[]): string => {
  array.forEach((task) => {
    const regex = new RegExp(`(<task|<activity) id="(Task|Activity)_${task.uuid}" name="[^"]*"`, 'g');
    xml = xml.replace(regex, `$1 id="$2_${task.uuid}" name="${task.label}"`);
  });

  return xml;
};


export const deleteTasksXml = (xml: string, tasksToDelete: string[]): string => {
  tasksToDelete.forEach((uuid) => {
    const taskRegex = new RegExp(`<(?:task|activity) id="(?:Task|Activity)_${uuid}"[^>]*>(.*?)</(?:task|activity)>`, 'gs');
    xml = xml.replace(taskRegex, '');

    const shapeRegex = new RegExp(`<bpmndi:BPMNShape[^>]+bpmnElement="(?:Task|Activity)_${uuid}"[^>]*>(.*?)</bpmndi:BPMNShape>`, 'gs');
    xml = xml.replace(shapeRegex, '');

    const flowRegex = new RegExp(`<sequenceFlow id="[^"]+" (sourceRef|targetRef)="(?:Task|Activity)_${uuid}" [^>]+/>`, 'g');
    xml = xml.replace(flowRegex, '');

    const flowNodeRefRegex = new RegExp(`<flowNodeRef>(?:Task|Activity)_${uuid}</flowNodeRef>`, 'g');
    xml = xml.replace(flowNodeRefRegex, '');
  });

  return xml;
};











