import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { AuthService } from "../../../../services/auth/auth.service";
import { EventService } from 'src/app/services/event/event.service';
import { CookieService } from "ngx-cookie-service";
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { ActivatedRoute} from "@angular/router";
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { cookieToJsonAsync, localStorageToJsonAsync} from "../../../../helpers/cookieToJson";
import { addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { TreeNode } from 'primeng/api';
import { ClipboardService } from 'src/app/services/clipboard/clipboard.service';
import { PersonasService } from 'src/app/services/personas/personas.service';
import { parse } from 'circular-json';
import {v4} from "uuid";
import { to64decode } from 'src/app/helpers/base64';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}
  @Output() parametersParent = new EventEmitter<string>();

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  files!: TreeNode[];
  filesOld!: TreeNode[]

  openTree: boolean = false;
  openSkills: boolean = false;
  properties: any = {}

  personas: any = {}

  isShow: boolean = false;
  isShowEdit: boolean = false;
  isShowDelete: boolean = false;

  isEdited = false;
  noData = true;

  isLoading: boolean = false;
  selectedNode: any;
  isSelectedNode = false;
  searchText: string = '';
  text: string = '';

  objectModal: any = {}

  data: any
  selectedFile: any;

  skillsArray: any = []
  isShowSkills: boolean = false;
  // dataSource: any = []

  searchClassification: string = ''
  /**
   *
   * @param cookieService
   * @param cdr
   */
  constructor(
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private personasService: PersonasService
  ) {}

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    this.noData = true
    this.files = []
    this.filesOld = []
    await this.getPersonasTree()
    if (this.parameters.personas.length > 0) {
      this.noData = false
    }

    if(!this.parameters.item.rows.skills){
      this.parameters.item.rows.skills = []
    }
    this.skillsArray = this.parameters.item.rows.skills ?? []
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  searchNodes(): void {
    this.files = this.filesOld.filter((node) => this.filterNode(node, this.text, true));
    this.verifyNoData()
  }

  verifyNoData() {
    if(this.files.length == 0){
      this.noData = true;
      return
    }
    this.noData = false;
  }

  private filterNode(node: TreeNode, query: string, includeParents: boolean = false): boolean {
    if(!node.label){
      return false
    }
    const labelMatches = node.label.toLowerCase().includes(query.toLowerCase());

    if (labelMatches) {
      return true;
    }

    if (node.children) {
      const anyChildMatches = node.children.some((child) => this.filterNode(child, query, true));

      if (anyChildMatches) {
        return true;
      }
    }

    return false;
  }

  async flipTree(){
    this.openTree = !this.openTree;
    if(this.openTree){
      return this.expandAll()
    }
    return this.collapseAll()
  }

  async expandAll() {
    this.files.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  async collapseAll() {
    if(!this.files || !this.files.length){
      return
    }
    this.files.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(this.properties.permissions, module, screen, action, false)
  }

  getParentCount(node: any): number {
    let parentCount = 0;
    let parentNode = node;

    while (parentNode && parentNode.parent) {
      parentCount++;
      parentNode = parentNode.parent;
    }

    // Adicione 1 para incluir o próprio nó na contagem
    return parentCount + 1;
  }

  findCost(uuid: string): number {
    const item = this.parameters.item.personas.find((item: any) => item.uuid === uuid);
    if (item) {
      return item.cost;
    } else {
      return 0;
    }
  }

  findClassification(uuid: string): string {
    const item = this.parameters?.item?.personasClassification?.find((item: any) => item.uuid === uuid);
    if (item) {
      return item.class;
    } else {
      return '';
    }
  }

  async updateCost(newCost: number, uuid: string) {
    const index = this.parameters.item.personas.findIndex((item: any) => item.uuid === uuid);
    if (index !== -1) {
      this.parameters.item.personas[index].cost = newCost;
    }else{
      this.parameters.item.personas.push({
        uuid: uuid,
        cost: newCost
      })
    }
    await this.sumFte()
  }

  async sumFte(){
    this.parameters.item.description.fte_cost -= this.parameters.item.description.fte_cost_one
    this.parameters.item.description.fte -= this.parameters.item.description.fte_one

    this.parameters.item.description.fte_cost_one = 0
    this.parameters.item.description.fte_one = 0

    this.parameters.item.personas?.forEach((persona: any) => {
      this.parameters.item.description.fte_cost_one += (iterateTreeAndFindNode(this.files, persona.uuid)  * persona.cost)
      this.parameters.item.description.fte_one += persona.cost;
    });

    this.parameters.item.description.fte += this.parameters.item.description.fte_one
    this.parameters.item.description.fte_cost += this.parameters.item.description.fte_cost_one
  }

  flipSkills(){
    this.openSkills = !this.openSkills
  }

  addSkill(){
    if(!this.parameters.isEdit){
      return
    }
    this.openSkills = true
    this.skillsArray.push(
      {
        uuid: v4(),
        name: ''
      }
    )
  }

  removeSkill(uuid: string){
    if(!this.parameters.isEdit){
      return
    }
    let array_temp: any = []

    this.skillsArray.forEach((item: any) => {
      if(item.uuid != uuid){
        array_temp.push(item)
      }
    });

    this.skillsArray = array_temp
    this.parameters.item.rows.skills = array_temp
  }

  async exitModalSkills(node?: any){
    this.isShowSkills = !this.isShowSkills;
    this.parameters.item.rows.skills = this.skillsArray;

    if(this.isShowSkills){
      this.parameters.item.rows.skills = this.skillsArray
    }
    this.objectModal = {}
    if(node){
      this.objectModal.item = node
      this.objectModal.files = this.filesOld
    }else{
      this.objectModal = this.parameters
      this.objectModal.is_search = true
    }
  }

  async getArraySkillsPersona(event: any){
    if(!this.objectModal.is_search){
      this.files = event
      this.filesOld = this.files
      return
    }

    let tempArray = this.skillsArray.filter((item: any) => { return !item.created_at })

    this.skillsArray = []
    event.forEach((item: any) => {
      this.skillsArray.push(item)
    });
    tempArray.forEach((item: any) => {
      this.skillsArray.push(item)
    });
  }

  getSkillsPercentage(node: any){
    if (!node.rows || !node.rows.skills) {
      node.rows = node.rows ? {...node.rows, skills: []} : {skills: []}
      // node.rows.skills = []
    }
    if(!this.skillsArray || this.skillsArray.length == 0 || node.rows.skills.length == 0){
      return `${0}%`
    }

    let total = 0;
    if(node.rows.skills){
      node.rows.skills.forEach((item: any) => {
        this.skillsArray.forEach((child: any) => {
          if(item.uuid == child.uuid){
            total++
          }
        })
      });
    }
    return `${(total / this.skillsArray.length * 100).toFixed(2)}%`
  }

  async getPersonasTree() {
    this.noData = false
    this.isLoading = true;

    try {
      const response: any = await this.personasService.getPersonasTree(this.parameters).toPromise();
      this.isLoading = false;

      if (response && response.data) {
        this.personas = parse(response.data)

        this.files = this.personas.json
        this.filesOld = this.personas.json

        this.isLoading = false;
        this.noData = this.files.length == 0

        this.files?.sort((a: any, b: any) => {
          if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
            return -1;
          } else if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
            return 1;
          } else {
            return 0;
          }
        });

        this.files?.forEach((item: any) => {
          this.recursiveOrderPersonasOrganogram(item);
        });
      } else {
        throw new Error('Erro ao consultar àrvore de personas!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  recursiveOrderPersonasOrganogram(item: any) {
    if (item.children && item.children.length > 0) {
      item.children.sort((a: any, b: any) => {
        if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
          return -1;
        } else if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return
  }

  choseItemSelect(type: string, value: any, node: any) {
    switch (type) {
      case 'persona_classification':
        if (value == 'void') {
          this.updateCost(0, node);
        }
  
        // Garantir que personasClassification exista como um array
        if (!this.parameters.item.personasClassification) {
          this.parameters.item.personasClassification = [];
        }
  
        // Procurar o índice do item no array
        const index = this.parameters.item.personasClassification.findIndex((item: any) => item.uuid === node);
  
        if (index !== -1) {
          // Atualiza o item se encontrado
          this.parameters.item.personasClassification[index].class = value;
        } else {
          // Adiciona novo item se não encontrado
          this.parameters.item.personasClassification.push({
            uuid: node,
            class: value
          });
        }
        break;
      default:
        break;
    }
  }
  

  getClassification(slug: string): string{
    let text: any

    this.parameters.optionsArray.personaParticipationEnum = sortArrayOfObjects(this.parameters.optionsArray.personaParticipationEnum)

    this.parameters.optionsArray?.personaParticipationEnum.forEach((item: any) => {
      if(item.slug == slug){
        text = item.name
      }
    })

    return text ? text : ''
  }

}

function iterateTreeAndFindNode(tree: any[], uuidToFind: string): number{
  for (const node of tree) {
    if (node.uuid === uuidToFind) {
      return node.data.cost;
    }

    if (node.children && node.children.length > 0) {
      const foundNode = iterateTreeAndFindNode(node.children, uuidToFind);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return 0;
}
