<div class="all-content">
  <!-- <div class="row row-scroll" *ngIf="parameters?.arrayTechnologies?.length > 0">
    <div class="arrow-scroll" *ngIf="showArrowLevel1" (click)="scrollLeft('box')">
        <i class="fas fa-angle-left"></i>
    </div>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedLevel1.uuid">
      <mat-radio-button *ngFor="let item of parameters?.arrayTechnologies" [value]="item?.uuid" (click)="activateLevel1(item)">
        {{ item?.label }}
      </mat-radio-button>
    </mat-radio-group>

    <div class="arrow-scroll" *ngIf="showArrowLevel1" (click)="scrollRight('box')">
        <i class="fas fa-angle-right"></i>
    </div>
  </div> -->

  <div class="row">
    <div class="item item-40">
      <span> {{ getLanguages('technologies/landscape') }} </span>
      <button class="button-menu item-40" [matMenuTriggerFor]="landscapeMenu">
        <span>
          {{ selectedLevel1.label }}
        </span>
        <i class="fa-solid fa-caret-down"></i>
      </button>
  
      <mat-menu #landscapeMenu="matMenu">
        <div class="menu">
            <div class="input" (click)="$event.stopPropagation()">
              <input type="text" [(ngModel)]="searchLandscape" placeholder="{{ getLanguages('search') }}">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let landscape of parameters?.arrayTechnologies">
                <ng-container *ngIf="landscape.label.toLowerCase().includes(searchLandscape.toLowerCase())">
                  <div class="item-menu" (click)="activateLevel1(landscape)">
                    <span [ngClass]="landscape.uuid == selectedLevel1.uuid ? 'valid-span' : 'invalid-span'">
                      {{ landscape.label }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
      </mat-menu> 
    </div>
  </div>
 

  <div class="new" *ngIf="selectedLevel1?.uuid && parameters.isEdit" (click)="addRow()">
    {{ getLanguages('new') }}
  </div>

  <div class="guidelineLink" *ngIf="!parameters?.arrayTechnologies?.length">
    <div class="i">
      <i class="fa-solid fa-exclamation"></i>
    </div>    

    <span (click)="navigate('technologies')">
      {{ getLanguages('technologies/technologies_empty') }}
    </span>

    <button (click)="navigate('technologies')" *ngIf="getAccessGuideline()">
      <i class="fas fa-sliders"></i>
      {{ getLanguages('businessSolution/content/info/goToTechnologies') }}
    </button>
  </div>
  <div class="row" *ngIf="selectedLevel1?.uuid">
      <p-table [value]="getRows()" class="table-project">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <span>{{ getLanguages('businessSolution/modal/technology/technology') }}</span> <b> * </b>
              </th>
              <th>
                <span>{{ getLanguages('businessSolution/modal/technology/module') }}</span>
              </th>
              <th>
                <span>{{ getLanguages('businessSolution/modal/technology/routine') }}</span>
              </th>
              <th>
                <span>{{ getLanguages('businessSolution/modal/technology/aplication') }}</span>
              </th>
              <th>
                <span>{{ getLanguages('businessSolution/modal/technology/transaction') }}</span>
              </th>
              <th>
                <span>
                  <i class="fas fa-paperclip"></i>
                </span>
              </th>
              <th *ngIf="parameters.isEdit">
                <span>{{ getLanguages('businessSolution/modal/project/table/action') }}</span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element let-rowIndex="rowIndex">
            <tr>
              <td>
                <i class="fas fa-circle-exclamation" *ngIf="element.technology == ''" [matTooltip]="getLanguages('technologies/name_technology_null')"></i>
                <!-- <textarea [disabled]="!parameters.isEdit" [(ngModel)]="element.technology"></textarea> -->
                <div class="item">
                  <button class="button-menu-table" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'no-pointer' : ''" [matMenuTriggerFor]="technology">
                    <span>
                      {{ getNameSelectedBox(element.technology?.uuid) }}
                    </span>
                    <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
                  </button>
            
                  <mat-menu #technology="matMenu">
                    <div class="menu">
                      <div class="input" (click)="$event.stopPropagation()">
                        <input type="text" [(ngModel)]="searchTechnology" placeholder="{{ getLanguages('search') }}">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div class="scroll">
                        <ng-container *ngFor="let item of selectedLevel1?.children">
                          <ng-container *ngIf="item?.label?.toLowerCase().includes(searchTechnology?.toLowerCase())">
                            <div class="item-menu" (click)="choseItemSelect('technology', item, element)">
                              <span [ngClass]="item?.uuid == element?.technology?.uuid ? 'valid-span' : 'invalid-span'">
                                {{ item.label }}
                              </span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </td>
              <td>
                <div class="item">
                  <button class="button-menu-table" [disabled]="!parameters.isEdit || !element?.technology?.uuid" [ngClass]="!parameters.isEdit ? 'no-pointer' : ''" [matMenuTriggerFor]="module">
                    <span>
                      {{ getNameSelectedBox(element.module?.uuid) }}
                    </span>
                    <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit && element?.technology?.uuid"></i>
                  </button>
            
                  <mat-menu #module="matMenu">
                    <div class="menu">
                      <div class="input" (click)="$event.stopPropagation()">
                        <input type="text" [(ngModel)]="searchModule" placeholder="{{ getLanguages('search') }}">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div class="scroll">
                        <ng-container *ngFor="let item of getArray(element.technology.uuid)">
                          <ng-container *ngIf="item?.label?.toLowerCase().includes(searchModule?.toLowerCase())">
                            <div class="item-menu" (click)="choseItemSelect('module', item, element)">
                              <span [ngClass]="item?.uuid == element?.module?.uuid ? 'valid-span' : 'invalid-span'">
                                {{ item.label }}
                              </span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </mat-menu>
                </div>            
              </td>
              <td>
                <textarea [disabled]="!parameters.isEdit" [(ngModel)]="element.routine"></textarea>
              </td>
              <td>
                <textarea [disabled]="!parameters.isEdit" [(ngModel)]="element.aplication"></textarea>
              </td>
              <td>
                <div class="item">
                  <button class="button-menu-table" [disabled]="!parameters.isEdit || !element?.module?.uuid" [ngClass]="!parameters.isEdit ? 'no-pointer' : ''" [matMenuTriggerFor]="transaction">
                    <span>
                      {{ getNameSelectedBox(element.transaction?.uuid) }}
                    </span>
                    <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit && element?.module?.uuid"></i>
                  </button>
            
                  <mat-menu #transaction="matMenu">
                    <div class="menu">
                      <div class="input" (click)="$event.stopPropagation()">
                        <input type="text" [(ngModel)]="searchTransaction" placeholder="{{ getLanguages('search') }}">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div class="scroll">
                        <ng-container *ngFor="let item of getArray(element?.module?.uuid)">
                          <ng-container *ngIf="item?.label?.toLowerCase().includes(searchTransaction?.toLowerCase())">
                            <div class="item-menu" (click)="choseItemSelect('transaction', item, element)">
                              <span [ngClass]="item?.uuid == element?.transaction?.uuid ? 'valid-span' : 'invalid-span'">
                                {{ item.label }}
                              </span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </td>
              <td>
                <mat-checkbox
                  class="checkbox"
                  [color]="task.color"
                  [(ngModel)]="element.clip"
                  [disabled]="!parameters.isEdit"
                  >
                </mat-checkbox>
              </td>
              <td *ngIf="parameters.isEdit" class="colunmAction">
                <i class="fas fa-trash pointer" (click)="removeItem(element.uuid)"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
        
      <div class="div-paginator">
          <div class="paginator">
              <span>
                  {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
              </span>
              <button [matMenuTriggerFor]="menu" class="itens-per-page">
                  {{ getItemsPerPage()}}
              </button>
              <mat-menu #menu="matMenu" >
                <button mat-menu-item (click)="choseItemsPage(5)">5</button>
                <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                <button mat-menu-item (click)="choseItemsPage(20)">20</button>
              </mat-menu>
              <span>
                    {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
                </span>
              <span class="totalPages">
                    {{ getTotalNumberPages() }}
                </span>
              <button
                [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
                [disabled]="getCurrentPages() === 1"
                (click)="setCurrentPages(getCurrentPages() - 1); getDisplayedRows();">
                <i class="fa-solid fa-angle-left"></i>
              </button>
              <input type="number" disabled [ngModel]="getCurrentPages()" (blur)="getTotalPages()">
              <button
                [ngClass]="getCurrentPages() >= getTotalNumberPages()  ? 'disabled-arrow' : 'arrow'"
                [disabled]="getCurrentPages() >= getTotalNumberPages()"
                (click)="setCurrentPages(getCurrentPages() + 1); getTotalPages();">
                <i class="fa-solid fa-angle-right"></i>
              </button>
          </div>
      </div> 
  </div>

</div>

