import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {to64decode, to64encode} from "../../helpers/base64";
import {version_ui} from "../../environments/tag_version";

@Injectable()
export class GlobalVariableInterceptor implements HttpInterceptor {

  constructor (private cookieService: CookieService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Aqui você pode modificar a requisição antes de ser enviada
    // Por exemplo, adicionar um cabeçalho personalizado

    const headers: any = {}
    if (localStorage.getItem('token') != '' && localStorage.getItem('token') != undefined) {
      headers.Authorization = 'Bearer '+ localStorage.getItem('token')
    }
    if (localStorage.getItem('environment') != '' && localStorage.getItem('environment') != undefined) {
      headers.environment = to64decode(localStorage.getItem('environment')).slug
    }
    if (localStorage.getItem('corporation') != '' && localStorage.getItem('corporation') != undefined) {
      headers.corporation = to64decode(localStorage.getItem('corporation')).slug
    }
    if (localStorage.getItem('language') != '' && localStorage.getItem('language') != undefined) {
      headers.language = to64decode(localStorage.getItem('language')).lang
    }

    delete headers['Cookie'];

    const modifiedRequest = request.clone({
      setHeaders: headers
    });

    // Continue o fluxo da requisição para o próximo manipulador
    return next.handle(modifiedRequest);
  }
}
