<div class="all-content">
  <div class="top">
    <div class="title">
      {{ parameters.isCreate ? getLanguages('personas/add') : parameters.isEdit ? getLanguages('personas/edit') : getLanguages('personas/view')}}
    </div>

    <i class="fas fa-xmark" (click)="exitModal()"></i>
  </div>
  <div class="buttons">
    <div class="save" (click)="setPersonasTree()" *ngIf="(isEdit || parameters.isCreate) && getSystemPermission('operation', 'business_solutions', 'update')">
      {{ getLanguages('save')}}
    </div>
    <div class="save" [ngClass]="isEdit? 'edit' : '' " (click)="editBusinessTree()" *ngIf="!parameters.isCreate && getSystemPermission('operation', 'business_solutions', 'update')">
      {{ isEdit ? getLanguages('cancel') : getLanguages('edit') }}
    </div>
  </div>


  <div class="row">
      <div class="item item-1">
          <span> {{ getLanguages('personas/name') }} </span>
          <input [disabled]="!parameters.isEdit" type="text" [(ngModel)]="parameters.item.label">
      </div>
  </div>
  <div class="row">
    <div class="item item-2" *ngIf="selectedPersonas.slug == 'persona'">
        <span> {{ getLanguages('personas/cost') }} </span>
        <input [disabled]="!parameters.isEdit" type="number" min="0"  [(ngModel)]="parameters.item.data.cost">
    </div>

    <div class="item" [ngClass]="selectedPersonas.slug != 'persona' ? 'item-1' : 'item-2' ">
      <span> {{ getLanguages('personas/type') }} </span>
      <button class="button-menu" 
      [matMenuTriggerFor]="personasMenu" 
      [disabled]="!parameters.isEdit || parameters?.item?.master == 'ROOT' || (parameters?.item?.size > 0 && parameters?.item?.data?.type == 'organogram')" 
      [ngClass]="!parameters.isEdit || parameters?.item?.master == 'ROOT' || (parameters?.item?.size > 0 && parameters?.item?.data?.type == 'organogram') ? 'disabled' : ''">
        <span>
          {{ getNames(selectedPersonas.name) }}
        </span>
        <i class="fa-solid fa-caret-down" [ngClass]="!parameters.isEdit ? 'disabled' : ''"></i>
      </button>
      <mat-menu #personasMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchPersonas" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let personas of personasArray">
              <ng-container *ngIf="getNames(personas.name).toLowerCase().includes(searchPersonas.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('personas', personas)">
                  <span [ngClass]="getNames(personas.name) == getNames(selectedPersonas.name) ? 'valid-span' : 'invalid-span'">
                    {{ getNames(personas.name) }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>

    <div class="title-skills" *ngIf="parameters?.item?.data?.type !== 'organogram'">
      <span> {{ getLanguages('personas/skills') }} </span>
      <i class="fa-solid fa-chevron-right" [ngClass]="!openSkills ? 'openned' : 'closed'" (click)="flipSkills()"></i>
      <i class="fa-solid fa-plus" (click)="addSkill()" [ngClass]="!parameters.isEdit ? 'no-pointer' : 'pointer'"></i>

      <button class="save button-skills" [disabled]="!parameters.isEdit" (click)="exitModalSkills()" [ngClass]="!parameters.isEdit ? 'no-pointer disabled' : 'pointer'">
        {{ getLanguages('businessSolution/modal/skills/search') }}
      </button>
    </div>

    <div class="skills" *ngIf="parameters.item.data.type !== 'organogram'" [ngClass]="openSkills ? 'openned-skills' : 'closed-skills'">
      <div class="skill" *ngFor="let skill of skillsArray">
        <input type="text" [(ngModel)]="skill.name" [disabled]='!parameters.isEdit || skill.in_use'>
        <i class="fas fa-xmark" (click)="removeSkill(skill.uuid)" [ngClass]="!parameters.isEdit ? 'no-pointer' : 'pointer'"></i>
      </div>

      <div class="noData" *ngIf="skillsArray && skillsArray.length == 0">
        <div class="icon">
            <i class="fa-solid fa-exclamation"></i>
        </div>
        <span>
            {{ getLanguages('errors/noData')}}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="background-modals" *ngIf="isShowSkills">
  <app-skills-crud (toggleExit)="exitModalSkills()" (getArray)="getArraySkillsPersona($event)" [parameters]="parameters"></app-skills-crud>
</div>
