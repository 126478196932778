import { Component, Input } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { getLanguageCookie } from 'src/assets/language';
import { v4 } from 'uuid';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent {
  @Input() parameters: any = {}

  openSkills: boolean = true;
  skillsArray: any = []
  isShowSkills: boolean = false;
  objectModal: any = {}

  files!: TreeNode[];
  filesOld!: TreeNode[]
  
  constructor(
  ) {
  }

  async ngOnInit(){
    if(!this.parameters.item.rows.skills){
      this.parameters.item.rows.skills = []
    }
    this.skillsArray = this.parameters.item.rows.skills ?? []
    this.files = []
    this.filesOld = []
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  flipSkills(){
    this.openSkills = !this.openSkills
  }

  addSkill(){
    if(!this.parameters.isEdit){
      return
    }
    this.openSkills = true
    this.skillsArray.push(
      {
        uuid: v4(),
        name: ''
      }
    )
  }

  removeSkill(uuid: string){
    if(!this.parameters.isEdit){
      return
    }
    let array_temp: any = []

    this.skillsArray.forEach((item: any) => {
      if(item.uuid != uuid){
        array_temp.push(item)
      }
    });

    this.skillsArray = array_temp
    this.parameters.item.rows.skills = array_temp
  }

  async exitModalSkills(node?: any){
    this.isShowSkills = !this.isShowSkills;
    this.parameters.item.rows.skills = this.skillsArray;

    if(this.isShowSkills){
      this.parameters.item.rows.skills = this.skillsArray
    }
    this.objectModal = {}
    if(node){
      this.objectModal.item = node
      this.objectModal.files = this.filesOld
    }else{
      this.objectModal = this.parameters
      this.objectModal.is_search = true
    }
  }

  getSkillsPercentage(node: any){
    if (!node.rows || !node.rows.skills) {
      node.rows = node.rows ? {...node.rows, skills: []} : {skills: []}
      // node.rows.skills = []
    }
    if(!this.skillsArray || this.skillsArray.length == 0 || node.rows.skills.length == 0){
      return `${0}%`
    }

    let total = 0;
    if(node.rows.skills){
      node.rows.skills.forEach((item: any) => {
        this.skillsArray.forEach((child: any) => {
          if(item.uuid == child.uuid){
            total++
          }
        })
      });
    }
    return `${(total / this.skillsArray.length * 100).toFixed(2)}%`
  }

  async getArraySkillsPersona(event: any){
    if(!this.objectModal.is_search){
      this.files = event
      this.filesOld = this.files
      return
    }

    let tempArray = this.skillsArray.filter((item: any) => { return !item.created_at })

    this.skillsArray = []
    event.forEach((item: any) => {
      this.skillsArray.push(item)
    });
    tempArray.forEach((item: any) => {
      this.skillsArray.push(item)
    });
  }

}
