import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { FunctionService } from 'src/app/services/function.service';
import { asyncForEach } from "../../../../helpers/asyncForEach";
import { UsersService } from 'src/app/services/users/users.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { getLanguageCookie } from 'src/assets/language';
import { CookieService } from 'ngx-cookie-service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {ActivatedRoute} from "@angular/router";
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { to64decode } from 'src/app/helpers/base64';
import {AdminGroup} from "../../../../app.module";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent {
  @Output() toggleUserModalEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() getUsers: EventEmitter<void>  = new EventEmitter<void>();

  @Input() properties: any = {}
  @Input() parameters: any = {}

  @ViewChild(MatTable) table!: MatTable<any>;

  isLoading!: boolean;
  isTreeEmpty!: boolean;

  me: any = {};
  isMe: boolean = false;

  task: any = {
    color: 'seccondary',
  };

  statusArray = [ { value: true , label: {en: 'Active', pt: 'Ativo'} }, { value: false , label: {en: 'Inactive', pt: 'Inativo'}}]
  selectedStatus: any = {};

  selection: any = [];
  setSelectionCorporationsProfilesArray: any = [];
  dataSource: any;

  environment_administrator: boolean = false

  displayedColumns: any = ['Select', 'Corporation', 'Profile', 'Status']

  // statusArray = [ 'ACTIVE', 'INACTIVE']
  // selectedStatus: string = 'SELECIONE';

  profiles = [
    { id: 1 , name: 'Master Profile'},
    { id: 2 , name: 'Administrator'},
    { id: 3 , name: 'User Profile'},
    { id: 4 , name: 'Viewer'}
  ]
  profileArray: any = []
  selectedProfile: any = {id: null, name: 'Select'}
  searchProfile: string = ""
  filteredProfileArray: any = [
    { id: 1 , name: 'Master Profile'},
    { id: 2 , name: 'Administrator'},
    { id: 3 , name: 'User Profile'},
    { id: 4 , name: 'Viewer'}
  ];

  user: any = {}
  users: any = []

  constructor(
    private functionService: FunctionService,
    private userService: UsersService,
    private profileService: ProfileService,
    private cookieService: CookieService,
    private corporationService: CorporationService,
    private activatedRoute: ActivatedRoute
    ) {
  }


  //type edit 0 é pra editar 1 usuário

  async ngOnInit(){
    this.user = this.parameters.user;

    if (!this.parameters.isOneUser) {
      this.users = this.parameters.users;
      this.selectedStatus.name;
    }else{
      this.selectedStatus.name =  await getLanguageCookie(`users/content/table/status/${this.user.is_active.toString()}`, localStorage.getItem('language'), true)
    }
    this.environment_administrator = this.parameters.user.is_admin == AdminGroup.ENVIRONMENT
    await this.getCorporationsProfiles()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  choseStatus(status: any) {
    this.user.is_active = status.value;
    this.selectedStatus.name = status.label
  }

  formatCpf(event: any) {
    const input = event.target;
    let value = input.value.replace(/\D/g, '');

    const maxLength = 11;

    if (value.length > maxLength) {
      value = value.substr(0, maxLength);
    }

    let formattedCpf = value.replace(/(\d{3})(\d)/, '$1.$2');
    formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2');
    formattedCpf = formattedCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    input.value = formattedCpf;
  }

  formatPhoneNumber(event: any) {
    const input = event.target;
    let formattedValue = input.value.replace(/\D/g, "");
    const maxLength = 11;
    if (formattedValue.length > maxLength) {
      formattedValue = formattedValue.substr(0, maxLength);
    }
    const countryCode = formattedValue.substr(0, 2);
    const areaCode = formattedValue.substr(2, 5);
    const phoneNumber = formattedValue.substr(7, 4);
    let formattedPhoneNumber = "";
    if (countryCode) {
      formattedPhoneNumber += `(${countryCode}) `;
    }
    if (areaCode) {
      formattedPhoneNumber += `${areaCode}-`;
    }
    if (phoneNumber) {
      formattedPhoneNumber += phoneNumber;
    }
    input.value = formattedPhoneNumber;
  }

  async setUserRoute(){
    if(this.parameters.isOneUser){
      this.users = []
      await this.setUser()
    }else{
      this.user = {}
      await this.setUsersCorporationProfile()
    }
  }

  async validationProfile () {
    let inputs: any = []

    if (!this.environment_administrator) {
      if (this.setSelectionCorporationsProfilesArray.length == 0) {
        addAlert('orange', `Por favor selecione uma corporação!`)
        return false
      }

      let selectedCount: number = 0
      await asyncForEach(this.setSelectionCorporationsProfilesArray, async (item: any) => {
        if (item.selected) selectedCount++
        if (item.selected && (!item.status || !item.profile)) {
          inputs.push(`${item.corporation.name}`)
        }
      })

      if (selectedCount == 0) {
        addAlert('orange', `Por favor selecione uma corporação!`)
        return false
      }
    }

    if (inputs.length > 0) {
      addAlert('orange', `Por favor selecione um perfil|status para as corporações: ${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  async setUser(){
    if (!await this.validationProfile()) {
      return;
    }

    let body = {
      user: this.user,
      corporationsprofiles: this.setSelectionCorporationsProfilesArray
    }
    // console.log(body)

    try {
      const response:any = await this.userService.setUsers({}, body, false);
      if (response && [201, 202].includes(response.status) ) {
        addAlert('success', response.message)
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    await this.modalClose()
  }

  async setUsersCorporationProfile(){
    if (!await this.validationProfile()) {
      return;
    }
    let body = {
      users: this.users,
      corporationsprofiles: this.setSelectionCorporationsProfilesArray
    }
    try {
      const response:any = await this.userService.setUsersCorporationProfile(body, this.selectedStatus);
      if (response && [201, 202].includes(response.status) ) {
        addAlert('success', response.message)
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    await this.modalClose()
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }
    this.getUsers.emit();
    this.toggleUserModalEdit.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  async getCorporationsProfiles() {
    this.isLoading = true;
    this.dataSource = new MatTableDataSource<any>();
    try {
      const response: any = await this.corporationService.getCorporationsProfilesTree(this.parameters, this.user.uuid);
      if (response && response.data){
        this.setSelectionCorporationsProfilesArray = response.data
        this.dataSource = new MatTableDataSource<any>(response.data)
        this.isLoading = false;
        this.isTreeEmpty = response.data.length == 0;
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  isAllSelected() {
    let numSelected: number = 0;
    this.dataSource.data.map((item: { selected: any; }) => { numSelected = numSelected + (item.selected ? 1 : 0) });
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  async toggleAllRows() {
    let status: any = !this.isAllSelected()
    await asyncForEach(this.dataSource.data, async (item: any, index: number) => {
      await this.setSelectionCorporationProfile(item, null, -1, { isAll: true, status: status ? 'ACTIVE' : 'INACTIVE' })
    });
  }

  // checkboxLabel(row?: any): string {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  // }

  async setSelectionCorporationProfile (element: any = null, profile: any = null, index: number = -1, { isAll = false, status }: any = {}) {
    if (isAll) element.selected = status == 'ACTIVE'

    if (element && !profile && !status) {
      let indexRemove: any = -1;

      await asyncForEach(this.setSelectionCorporationsProfilesArray, async (item: any, item_index: any) => {
        if (item.corporation.uuid == element.corporation.uuid) indexRemove = item_index
      })

      if (indexRemove >= 0) {
        if (!isAll) element.selected = !element.selected

        if (!element.selected) this.setSelectionCorporationsProfilesArray[indexRemove].status = 'INACTIVE'
        return;
      }

      if (!isAll) element.selected = true;

      this.setSelectionCorporationsProfilesArray.push({ corporation: element.corporation })
      element.index = this.setSelectionCorporationsProfilesArray.length - 1
    }

    if (!element && profile && !status && index >= 0) {
      this.setSelectionCorporationsProfilesArray[index] = { ...this.setSelectionCorporationsProfilesArray[index], profile }
    }

    if (!element && !profile && status && index >= 0) {
      this.setSelectionCorporationsProfilesArray[index] = { ...this.setSelectionCorporationsProfilesArray[index], status }
    }
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  setUserAdministrator(){
    this.environment_administrator = !this.environment_administrator
    if (this.environment_administrator) {
      this.user.is_admin = AdminGroup.ENVIRONMENT
    }
    if (!this.environment_administrator) {
      this.user.is_admin = AdminGroup.OTHERS
    }
  }

  protected readonly AdminGroup = AdminGroup;
}
