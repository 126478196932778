import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { AddCodeComponent } from '../../tree/add-code/add-code.component';
import { CookieService } from 'ngx-cookie-service';
import { getLanguageCookie } from 'src/assets/language';
import { addAlert } from "../../../../layouts/pages/pages.component";
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { v4 } from 'uuid';
import { to64decode } from 'src/app/helpers/base64';

@Component({
  selector: 'app-personas-crud',
  templateUrl: './personas-crud.component.html',
  styleUrls: ['./personas-crud.component.scss']
})
export class PersonasCrudComponent {
  @Output() toggleModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getPersonasTree: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();

  @Input() properties: any = {}
  @Input() parameters: any = {}

  protected readonly Object = Object;

  selectedFlag: any = { lang: 'en', file: 'us.svg' };

  isEdit: boolean = false;
  isShowSkills: boolean = false;
  item: any

  skillsArray: any
  openSkills: boolean = true;

  temp: any = {}

  newNode: any
  currentPersonas: any;
  personasArray: any = [
    {
      slug: 'persona',
      name: {
        en: 'Persona',
        pt: 'Persona',
      }
    },
    {
      slug: 'organogram',
      name: {
        en: 'Organogram',
        pt: 'Organograma',
      }
    }
  ]
  searchPersonas: string = '';
  selectedPersonas: any = {
    name: {
      en: 'Organogram',
      pt: 'Organogram',
    },
    slug: 'organogram'
  }

  /**
   *
   * @param cookieService
   */
  constructor(
    private cookieService: CookieService,
  ) {

  }

  /**
   *
   */
  async ngOnInit() {
    this.parameters.item.data.type = this.parameters.item.data.type ?? this.selectedPersonas.slug
    this.temp = JSON.parse(JSON.stringify(this.parameters.item));
    this.personasArray.forEach((item: any) => {
      if(item?.slug == this.parameters?.item?.data?.type){
        this.choseItemSelect('personas', item)

        if(item.slug != 'persona'){
          this.parameters.item.data.cost = 0
        }
      }
    });

    if(!this.parameters.item || !this.parameters.item.rows || !this.parameters.item.rows.skills){
      this.parameters.item.rows.skills = []
    }
    this.skillsArray = this.parameters.item.rows.skills ?? []
  }

  choseItemSelect(type: string, value: any){
    switch (type) {
      case 'personas':
        this.selectedPersonas = value;
        this.parameters.item.data.type = value.slug
        break;
      default:
        break;
    }
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }
    this.toggleModal.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  async editBusinessTree() {
    this.isEdit = !this.isEdit;
    this.parameters.isEdit = this.isEdit

    if(!this.isEdit){
      this.parameters.item = JSON.parse(JSON.stringify(this.temp));
      this.ngOnInit()
    }
  }

  async parametersValidation () {
    let inputs: any = []

    if(!this.parameters.item.label){
      inputs.push(this.getLanguages('personas/name'))
    }

    if(!this.parameters.item.data.cost){
      this.parameters.item.data.cost = 0
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  async setPersonasTree() {
    this.parameters.item.rows.skills = this.skillsArray

    if (this.parameters.isCreate){
      if (!await this.parametersValidation()) {
        return
      }

      this.newNode = {
        uuid: v4(),
        data: this.parameters.item.data,
        label: this.parameters.item.label,
        master: this.parameters.item.master,
        rows: this.parameters.item.rows,
        children: [],
      }

      if (this.newNode.master == 'ROOT') {
        this.parameters.personas.push(this.newNode)
      } else {
        this.recursiveForEach(this.parameters.personas)
      }

      this.updateFiles.emit({ files: this.parameters.personas } )
      await this.modalClose()
      return
    }

    this.updateTree(this.parameters.personas, this.parameters.item);

    this.updateFiles.emit({ files: this.parameters.personas, item: this.parameters.item });
    await this.modalClose();
  }

  recursiveForEach(array: any){
    array.forEach((item: any) => {
      if(item.uuid == this.parameters.item.master){
        item.children.push(this.newNode)
      }else{
        if(item.children.length > 0){
          this.recursiveForEach(item.children)
        }
      }
    });
  }

  updateTree(array: any, updatedItem: any) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      if (item.uuid === updatedItem.uuid) {
        array[i] = {
          ...item,
          ...updatedItem,
        };
      } else if (item.children && item.children.length > 0) {
        this.updateTree(item.children, updatedItem);
      }
    }
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(this.properties.permissions, module, screen, action, false)
  }

  exitModalSkills(){
    this.parameters.is_search = true
    this.isShowSkills = !this.isShowSkills;
  }

  getArraySkillsPersona(event: any){
    let tempArray = this.skillsArray.filter((item: any) => { return !item.created_at })

    this.skillsArray = []
    event.forEach((item: any) => {
      this.skillsArray.push(item)
    });
    tempArray.forEach((item: any) => {
      this.skillsArray.push(item)
    });

    this.parameters.item.rows.skills = this.skillsArray
  }

  flipSkills(){
    this.openSkills = !this.openSkills
  }

  addSkill(){
    if(!this.parameters.isEdit){
      return
    }
    this.openSkills = true
    this.skillsArray.push(
      {
        uuid: v4(),
        name: '',
        // is_created_now: true
      }
    )
  }

  removeSkill(uuid: number){
    if(!this.parameters.isEdit){
      return
    }
    let array_temp: any = []

    this.skillsArray.forEach((item: any) => {
      if(item.uuid != uuid){
        array_temp.push(item)
      }
    });

    this.skillsArray = array_temp
    this.parameters.item.rows.skills = array_temp
  }
}


