<div class="all-content">
    <div class="top">
      <div class="title">
        {{ parameters.isCreate ? getLanguages('technologies/add') : parameters.isEdit ? getLanguages('technologies/edit') : getLanguages('technologies/view')}}
      </div>
  
      <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="buttons">
      <div class="save" (click)="setTechnologiesTree()" *ngIf="(isEdit || parameters.isCreate) && getSystemPermission('operation', 'business_solutions', 'update')">
        {{ getLanguages('save')}}
      </div>
      <div class="save" [ngClass]="isEdit? 'edit' : '' " (click)="editBusinessTree()" *ngIf="!parameters.isCreate && getSystemPermission('operation', 'business_solutions', 'update')">
        {{ isEdit ? getLanguages('cancel') : getLanguages('edit') }}
      </div>
    </div>
  
    <div class="row">
        <div class="item item-1"> 
            <span *ngIf="parameters?.item?.level == 2"> {{ getLanguages('technologies/name_platform') }} </span>
            <span *ngIf="parameters?.item?.level == 3"> {{ getLanguages('technologies/name_module') }} </span>
            <span *ngIf="parameters?.item?.level == 4"> {{ getLanguages('technologies/id_trasaction') }} </span>
            <input [disabled]="!parameters.isEdit" *ngIf="parameters?.item?.level > 1"  type="text" [(ngModel)]="parameters.item.label">
        </div>
        <div class="item item-1" *ngIf="parameters?.item?.level == 1"> 
            <span>{{ getLanguages('technologies/landscape') }}</span>
            <button class="select-nav" [matMenuTriggerFor]="select" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled no-pointer' : '' ">
              <span>
                  <ng-container *ngIf="selectedLandscape?.name; else searchBlock">
                     {{ selectedLandscape.name }}
                  </ng-container>
                  <ng-template #searchBlock>
                      {{ getLanguages('search') }}
                  </ng-template>
              </span>
              <i class="fas fa-caret-down" *ngIf="parameters.isEdit"></i>
            </button>
            <mat-menu #select="matMenu">
                <div class="menu">
                    <div class="input" (click)="$event.stopPropagation()">
                        <input type="text" [(ngModel)]="searchLandscape" placeholder="{{ getLanguages('search') }}">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div class="scroll">
                        <ng-container *ngFor="let item of corporationLandscapeArray">
                            <div class="item-menu" (click)="choseLandscapeCorporation(item)" *ngIf="item?.name?.toLowerCase().includes(searchLandscape.toLowerCase())">
                                <span [ngClass]="{'valid-span' : item.name == selectedLandscape.name}"> {{ item.name }} </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </mat-menu> 
        </div>
    </div>

    <div class="row" *ngIf="parameters?.item?.level == 2">
        <div class="item item-3">
            <span> {{ getLanguages('technologies/version') }}</span>
            <input [disabled]="!parameters.isEdit" type="text" [(ngModel)]="parameters.item.version">
        </div>
        <div class="item item-3">
            <span> {{ getLanguages('technologies/date_installation') }}</span>
            <input [disabled]="!parameters.isEdit" 
              type="text" 
              [(ngModel)]="parameters.item.date_instalation" 
              (input)="formatDate()" 
              (keypress)="restrictToNumbers($event)"
              placeholder="{{ getLanguages('date/month_year') }}"
              maxlength="7"
              inputmode="numeric"> 
        </div>
        <div class="item item-3">
            <span> {{ getLanguages('technologies/manufacturer') }}</span>
            <input [disabled]="!parameters.isEdit" type="text" [(ngModel)]="parameters.item.manufacturer">
        </div>
    </div>

    <div class="row" *ngIf="parameters?.item?.level > 1">
        <div class="item item-1">
            <span> {{ getLanguages('businessSolution/modal/addCode/description') }}</span>
            <textarea [disabled]="!parameters.isEdit" [(ngModel)]="parameters.item.description"></textarea>
        </div>
    </div>

    <div class="row" *ngIf="parameters?.item?.level == 2">
        <div class="item item-1">
            <span> {{ getLanguages('technologies/specification') }}</span>
            <textarea [disabled]="!parameters.isEdit" [(ngModel)]="parameters.item.specification"></textarea>
        </div>
    </div>

</div>

<div class="background-modals" *ngIf="createLandscape">
  <div class="modal-create-type">
    <div class="modal-create-top">
      <div class="modal-create-toggle"></div>

      <div class="div-icon-button">
        <div class="save" (click)="setCorporationLandscape()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="createLandscape = false"></i>
      </div>
    </div>

    <div class="modal-create-row">
      <div class="modal-create-title">
        {{ getLanguages('technologies/title_new_landscape') }}
      </div>
    </div>

    <div class="modal-create-row">
      <div class="modal-create-item">
        <span> {{ getLanguages('technologies/new_landscape') }}</span>
        <input type="text" [(ngModel)]="newLandscape.name">
      </div>
    </div>
  </div>
</div>