<div class="all-content">
    <div class="title">
        {{ getLanguages('businessSolution/modal/maturity/maturity') }} : {{ getValue() }}%
    </div>

    <div class="items">
        <ng-container *ngFor="let item of dataSource.data">
            <div class="item" *ngIf="item.is_active">
                <span class="title"> {{ item.question }} </span>
                <div class="div-slide">
                    <!-- <p-slider [(ngModel)]="parameters.item.maturity[item.uuid]" [step]="25" [disabled]="!parameters.isEdit"></p-slider> -->
                    
                    <div class="div-stars">
                        <i class="fas fa-star"  [ngClass]="{
                            'pointer': parameters.isEdit,
                            'no-pointer': !parameters.isEdit,
                            'checked-star': parameters.item?.maturity[item.uuid] >= star,
                            'no-checked-star': !(parameters.item?.maturity[item.uuid] >= star)
                          }" *ngFor="let star of stars" (click)="toggleStar(item.uuid, star)"></i>
                    </div>

                    <!-- <div class="label">
                        <span>min</span>
                        <span>max</span>
                    </div> -->
                </div>
            </div>
        </ng-container>
        <div class="noData" *ngIf="noData">
            <div class="icon">
                <i class="fa-solid fa-exclamation"></i>
            </div>
            <span>
                {{ getLanguages('errors/noData')}}
            </span>
          </div>
    </div>
</div>
