<div class="all-content">
    <div class="top">
        <div class="toggle"></div>
        <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="row">
        <div class="circle">
            <i class="fas fa-exclamation"></i>
        </div>
    </div>
    <div class="row">
        <span class="title">
            {{ getLanguages('delete/title') }}
        </span>
    </div>
    <div class="row">
        <section>
            <span>
                {{ component }}
            </span>
        </section>
    </div>

    <div class="row" *ngIf="type == 4 || type == 5 || type == 1">
        <span class="warnning" *ngIf="type == 4"> {{ getLanguages('delete/persona') }} </span>
        <span class="warnning" *ngIf="type == 5"> {{ getLanguages('delete/technologies') }} </span>
        <span class="warnning" *ngIf="type == 1 && parameters?.item?.is_cloned"> {{ getLanguages('delete/clone') }} </span>
    </div>

    <div class="row buttons">
        <button class="confirm" (click)="delete()">
            {{ getLanguages('delete/confirm') }}
        </button>
        <button class="cancel" (click)="exitModal()">
            {{ getLanguages('delete/cancel') }}
        </button>
    </div>
</div>
