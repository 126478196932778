import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { localStorageToJsonAsync }
 from 'src/app/helpers/cookieToJson';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { getLanguageCookie } from 'src/assets/language';
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { VersionsService } from 'src/app/services/versions/versions.service';


@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent {
  @Input() parameters: any = {}

  @Output() toggleModalIcon: EventEmitter<any> = new EventEmitter<any>();

  selectedIcon: string = ''

  icons: any = [
    {
      class: 'fa-solid fa-building-columns'
    },
    {
      class: 'fa-solid fa-bell-concierge'
    },
    {
      class: 'fa-solid fa-bag-shopping'
    },
    {
      class: 'fa-solid fa-file-contract'
    },
    {
      class: 'fa-solid fa-address-book'
    },
    {
      class: 'fa-solid fa-users-between-lines'
    },
    {
      class: 'fa-solid fa-industry'
    },
    {
      class: 'fa-solid fa-bullhorn'
    },
    {
      class: 'fa-solid fa-chess-knight'
    },
    {
      class: 'fa-solid fa-hospital-user'
    },
    {
      class: 'fa-solid fa-file-signature'
    },
    {
      class: 'fa-solid fa-file-invoice'
    },
    {
      class: 'fa-solid fa-cash-register'
    },
    {
      class: 'fa-solid fa-calculator'
    },
    {
      class: 'fa-solid fa-triangle-exclamation'
    },
    {
      class: 'fa-solid fa-cart-shopping'
    },
    {
      class: 'fa-solid fa-user-tie'
    },
    {
      class: 'fa-solid fa-file-invoice-dollar'
    },
    {
      class: 'fa-solid fa-shop'
    },
    {
      class: 'fa-solid fa-phone'
    },
    {
      class: 'fa-solid fa-headset'
    },
    {
      class: 'fa-solid fa-credit-card'
    },
    {
      class: 'fa-solid fa-pen-ruler'
    },
    {
      class: 'fa-solid fa-gears'
    },
    {
      class: 'fa-solid fa-medal'
    }
  ]

  constructor(
    private businessSolutionsService: BusinessSolutionsService,
    private corporationService: CorporationService,
    private versionsService: VersionsService,
    private cookieService: CookieService
    ){}

  ngOnInit(){
    this.selectedIcon = this.parameters.icon
  }
  choseIcon(param: string){
    this.selectedIcon = param
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async exitModal(){
   return this.toggleModalIcon.emit(this.selectedIcon);
  }
}
