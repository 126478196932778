<div class="all-content">
  <div class="top">
    {{ getLanguages('users/content/top') }}
  </div>
  <div class="row">
    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="searchText" (keyup.enter)="searchUser()">
      </div>
      <button (click)="searchUser()">
          {{ getLanguages('search') }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="tabs">
      <span (click)="selectButton()" class="tab" [ngClass]="isListUsers ? 'active' : ''"> <div class="circle"></div>
        {{ getLanguages('users/content/userList') }}
      </span>
      <span (click)="selectButton(1)" class="tab" [ngClass]="isListApproval ? 'active' : ''"> <div class="circle"></div>
        {{ getLanguages('users/content/approval') }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="buttons">
      <ng-container *ngIf="(isListUsers || isListProfiles) && getSystemPermission('register', 'users', 'update')">
        <button (click)="togleCreateUserEdit($event)" [disabled]="!isShowEdit()" [ngClass]="!isShowEdit() ? 'disabled' : isShowUserEdit ? 'edit': '' ">
          {{ getLanguages('edit') }}
        </button>
      </ng-container>
      <button (click)="togleCreateUser($event)" *ngIf="isListUsers && getSystemPermission('register', 'users', 'create')"> {{ getLanguages('users/modal/create/title') }}</button>
      <button (click)="feedbackUser()" *ngIf="isListApproval" [disabled]="selectionApproval.selected.length === 0" [ngClass]="selectionApproval.selected.length === 0 ? 'no-pointer disabled': ''"> {{ getLanguages('users/modal/approve') }}</button>
      <button (click)="feedbackUser(false)" *ngIf="isListApproval" [disabled]="selectionApproval.selected.length === 0" [ngClass]="selectionApproval.selected.length === 0 ? 'no-pointer disabled': ''"> {{ getLanguages('users/modal/refuse') }}</button>
    </div>
  </div>

  <div class="div-table">
    <div class="table">
        <table mat-table [dataSource]="getDisplayedRows()" *ngIf="!isLoading && !noData()">
          <ng-container matColumnDef="Select" *ngIf="getSystemPermission('register', 'users', 'update') || isListApproval" >
            <th mat-header-cell *matHeaderCellDef >
            <!-- <mat-checkbox
                (change)="$event ? toggleAllRow() : null"
                [color]="task.color"
                [checked]="isAllSelected()"
                [aria-label]="checkboxLabelUsers()"
                class="checkbox"
                >
            </mat-checkbox> -->
            </th>
            <td mat-cell *matCellDef="let element;let index;">
            <mat-checkbox
                *ngIf="
                  element &&
                  properties &&
                  properties.me &&
                  properties.me.uuid && element &&
                  element.uuid !== properties.me.uuid &&
                  (
                    (properties.me.is_admin == AdminGroup.MASTER && element.is_admin != AdminGroup.MASTER) ||
                    (properties.me.is_admin == AdminGroup.ENVIRONMENT && element.is_admin == AdminGroup.OTHERS) ||
                    (properties.profile.slug == 'administrator' && element.profile && element.profile.slug != 'administrator')
                  )"
                (click)="$event.stopPropagation(); setSelection(element)"
                [color]="task.color"
                [checked]="getSelection(element)"
                [aria-label]="checkboxLabelUsers(index, element)"
                class="checkbox">
            </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let row"> {{ row.uuid }} </td>
          </ng-container>

          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/name') }}  </th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/email') }} </th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)">
                <span> {{element.email}} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Admin" *ngIf="isListUsers && properties.me.is_admin != AdminGroup.OTHERS">
            <th mat-header-cell *matHeaderCellDef > {{ getLanguages('users/content/table/admin') }} </th>
            <td mat-cell *matCellDef="let element"  (click)="togleCreateUserEdit($event, true, element.uuid)">
              <span> {{ getLanguages('users/content/table/admin/' + element.is_admin.toLowerCase()) }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Profile" *ngIf="isListUsers">
            <th mat-header-cell *matHeaderCellDef > {{ getLanguages('users/content/table/profile') }} </th>
            <td mat-cell *matCellDef="let element"  (click)="togleCreateUserEdit($event, true, element.uuid)">
              <span>  {{ element.profile && element.profile.name ? element.profile.name : ''  }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Corporation" *ngIf="isListUsers">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/corporation') }} </th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)">
              <span>  {{ element.corporation && element.corporation.name ? element.corporation.name : ''  }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Status" *ngIf="isListUsers">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/status') }} </th>
            <td mat-cell *matCellDef="let element">
              {{ element.is_admin == AdminGroup.OTHERS ? (element?.user_corporation?.status === 'ACTIVE' ? getLanguages('users/content/table/status/true') : getLanguages('users/content/table/status/false')) : '' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Telephone"  *ngIf="isListApproval">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/telephone') }} </th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)"> {{element.telephone}} </td>
          </ng-container>

          <ng-container matColumnDef="Approver"  *ngIf="isListUsers">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/approver') }} </th>
            <td mat-cell *matCellDef="let element"  (click)="togleCreateUserEdit($event, true, element.uuid)">
              <mat-slide-toggle
                *ngIf="
                  task.color &&
                  element.user_corporation &&
                  ((properties.me.is_admin == AdminGroup.MASTER && element.is_admin != AdminGroup.MASTER) ||
                  (properties.me.is_admin == AdminGroup.ENVIRONMENT && element.is_admin == AdminGroup.OTHERS))"
                [color]="task.color"
                [checked]="element.user_corporation.is_approval"
                class="checkbox"
                (click)="setUserApprover($event, element.user_corporation)">
              </mat-slide-toggle>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns();"></tr>

          <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4"></td>
          </tr>
        </table>

        <app-loading *ngIf="isLoading"></app-loading>

        <div class="no-data" *ngIf="noData()">

          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>

          {{ getLanguages('errors/noData') }}
        </div>
    </div>

    <div class="div-paginator" *ngIf="!noData() && !isLoading">
      <div class="paginator">
        <span>
          {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
        </span>
        <button [matMenuTriggerFor]="menu" class="itens-per-page">
          {{ getItemsPerPage() }}
        </button>
        <mat-menu #menu="matMenu" >
          <button mat-menu-item (click)="choseItemsPage(5)">5</button>
          <button mat-menu-item (click)="choseItemsPage(10)">10</button>
        </mat-menu>
        <span>
          {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
        </span>
        <span class="totalPages">
          {{ getTotalPages()}}
        </span>
        <button
          [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() === 1"
          (click)="onPageChange(getCurrentPages() - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <input type="number" [ngModel]="getCurrentPages()" disabled (blur)="checkValue($event)">
        <button
          [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() == getTotalPages()"
          (click)="onPageChange(getCurrentPages() + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="background-modals" *ngIf="isShowUserCreate || isShowUserEdit ">
    <ng-container *ngIf="isShowUserCreate">
      <app-create-user (toggleUserModal)="togleCreateUser()" [properties]="properties" [parameters]="parameters" (getUsers)="getUsers()"></app-create-user>
    </ng-container>

    <ng-container *ngIf="isShowUserEdit">
      <app-edit-user (toggleUserModalEdit)="togleCreateUserEdit()" [properties]="properties" [parameters]="parameters" (getUsers)="getUsers()"></app-edit-user>
    </ng-container>
  </div>
