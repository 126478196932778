import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RemoveCookieInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Cloning the request to modify the headers
    const clonedRequest = req.clone({
      // Setting headers without the specific cookie
      headers: req.headers.delete('Cookie')
    });
    // Returning the modified request to the next handler
    return next.handle(clonedRequest);
  }
}
