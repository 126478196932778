import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { getLanguageCookie } from 'src/assets/language';
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { parse } from "circular-json"
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import {VersionsService} from "../../../../services/versions/versions.service";

@Component({
  selector: 'app-name-versions',
  templateUrl: './name-versions.component.html',
  styleUrls: ['./name-versions.component.scss']
})
export class NameVersionsComponent {
  @Output() toggleModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveTree: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveVersion: EventEmitter<any> = new EventEmitter<any>();
  @Input() type!: any;
  @Input() parameters!: any;
  @Input() properties!: any;

  isShowButton = false;
  version: any = {}
  formData: FormData = new FormData()

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  /**
   *
   * @param cookieService
   * @param businessSolutionsService
   * @param versionsService
   */
  constructor(
    private cookieService: CookieService,
    private businessSolutionsService: BusinessSolutionsService,
    private versionsService: VersionsService,
  ) {}

  async ngOnInit(){
    if (this.parameters) {
      this.version = { ...this.parameters }
    }

    if(!this.version.name){
      this.version.name = ''
    }
  
    if(!this.version.reason){
      this.version.reason = ''
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async exitModal(){
    this.toggleModal.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([this.exitModal()]);
  }

  async save() {
    if(this.type == 'edit'){
      await this.saveVersionName()
      this.saveVersion.emit()
    }else{
      this.saveTree.emit(this.version)
    }
    await Promise.all([this.exitModal()]);
  }

  async saveVersionName() {
    try {
      const response: any = await this.versionsService.setVersions(this.version)
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
      } else{
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

}
