<div class="all-content">
  <div class="top">
    {{ getLanguages('personas') }}
  </div>
  <div class="row">
    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="text" (keyup.enter)="searchNodes()">
      </div>
      <button (click)="searchNodes()">
          {{ getLanguages('search') }}
      </button>
    </div>
    <div class="div-buttons">
      <div class="button-save">
          <button (click)="saveTree()" matTooltip="{{ getLanguages('save') }}" [disabled]="!isEdited" class="save button-tooltip" [ngClass]="!isEdited ? 'disabled no-pointer' : ''" *ngIf="getSystemPermission('operation', 'business_solutions', 'create')">
            {{ getLanguages('save') }}
          </button>
      </div>
      <div class="button-save" *ngIf="getSystemPermission('operation', 'business_solutions', 'create')">
        <button class="save" [matMenuTriggerFor]="selectExport" *ngIf="filesOld && filesOld.length > 0">
          {{ getLanguages('businessSolution/top/info/export') }}
          <i class="fas fa-angle-down" style="margin-left: 2vh;"></i>
        </button>
        <mat-menu #selectExport="matMenu">
          <button (click)="getTreeXls('json')" class="menu-button">
            {{ getLanguages('businessSolution/top/info/export/json') }}
          </button>
          <button (click)="getTreeXls('xls')" class="menu-button">
            {{ getLanguages('businessSolution/top/info/export/xls') }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="title">
      <span>
        {{ getLanguages('personas/tree') }}
      </span>
      <i class="fa-solid fa-chevron-right" [ngClass]="!openTree ? 'openned' : 'closed'" (click)="flipTree()"></i>
      <i class="fas fa-plus" (click)="loadModal(1)" *ngIf="getSystemPermission('operation', 'business_solutions', 'create')"></i>
    </div>
  <div class="content-content">
    <div class="tree" [ngStyle]="{'width': isResponsive() && (isShow || isShowEdit) ? '50%' : '100%'}">
      <div class="noData" *ngIf="noData && !isLoading">
        <div class="icon">
            <i class="fa-solid fa-exclamation"></i>
        </div>
        <span>
            {{ getLanguages('errors/noData')}}
        </span>

        <b> {{ getLanguages('personas/import') }} </b>


        <button class="button-menu" [matMenuTriggerFor]="slaUnitMenu">
          <span>
            {{ selectedCorporationPeronas?.name ? selectedCorporationPeronas.name : getLanguages('select') }}
          </span>
          <i class="fa-solid fa-caret-down"></i>
        </button>

        <mat-menu #slaUnitMenu="matMenu">
          <div class="menu">
            <div class="input" (click)="$event.stopPropagation()">
              <input type="text" [(ngModel)]="searchCorporationPersonas" placeholder="{{ getLanguages('search') }}">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let corporationPersona of corporationPersonasArray">
                <ng-container *ngIf="corporationPersona.name.toLowerCase().includes(searchCorporationPersonas.toLowerCase())">
                  <div class="item-menu" (click)="choseItemSelect('corporationPersona', corporationPersona)">
                    <span [ngClass]="corporationPersona?.uuid == selectedCorporationPeronas?.uuid ? 'valid-span' : 'invalid-span' ">
                      {{ corporationPersona.name }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </mat-menu>
  

      </div>
      <p-tree class="ptree"
        [value]="files"
        *ngIf="!isLoading && !noData"
        selectionMode="single"
        [(selection)]="selectedFile"
        (onNodeSelect)="loadModal(2, $event)"
        >
          <ng-template let-node pTemplate="default">
            <div class="pcontent">
              <div class="ptitle">
                <i class="fas" [ngClass]="node.data.type == 'organogram' ? 'fa-sitemap' : 'fa-user-tie'"></i>
                <b> {{ (node && node.label ? node.label : '') }} </b>
              </div>
              <div class="picons">
                  <div class="button" matTooltip="{{ getLanguages('personas/delete') }}" (click)="showDeleteItemModal(node, $event)"
                  *ngIf="(!node.children || node.children.length == 0) && (!node.clones || node.clones.length == 0)">
                    <i class="fas fa-trash i"></i>
                  </div>
                  <div class="button" matTooltip="{{ getLanguages('personas/add') }}" (click)="loadModal(1, node, $event)"
                    *ngIf="getSystemPermission('operation', 'business_solutions', 'create') && node.data.type == 'organogram'">
                    <i class='fas fa-plus i'></i>
                  </div>
              </div>
            </div>
          </ng-template>
        </p-tree>
      </div>
      <div class="form-modal"  *ngIf="isShow || isShowEdit" [ngStyle]="{'width': !isResponsive() ? '100%' : 'calc(50% - 1vh)'}">
        <app-personas-crud *ngIf="isShow"  (toggleModal)="loadModal(0)" (getPersonasTree)="getPersonasTree()" [properties]="properties" [parameters]="objectModal" (updateFiles)="updateFiles($event)"></app-personas-crud>
        <app-personas-crud *ngIf="isShowEdit" (toggleModal)="loadModal(0)" (getPersonasTree)="getPersonasTree()" [properties]="properties" [parameters]="objectModal" (updateFiles)="updateFiles($event)"></app-personas-crud>
      </div>
    </div>
  </div>
</div>

<div class="background-modals" *ngIf="isShowDelete || isLoading">
  <app-loading *ngIf="isLoading"></app-loading>
  <app-delete-component *ngIf="isShowDelete" (toggleDelete)="openCloseModalDelete(false)" (updateFiles)="updateFiles($event)" [parameters]="objectModal" [type]="4"></app-delete-component>
</div>
