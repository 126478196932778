import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";
import { Observable, Subject, takeUntil } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CorporationsSettingsService {
  parameters: any = {}
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  API_PATH: string

  requestOptionsFormData: any = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


    constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return null;
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  } 

  async getCorporationService(parameters: any = {}){
    let url = `${this.API_PATH}corporations-settings`
    if(parameters.corporation_uuid){
      url = `${url}?corporation_uuid=${parameters.corporation_uuid}`
    }
    return await this.httpClient.get<IResponse>(
      url,
    ).toPromise();
  }

  async setCorporationSettings(body: any) {
    if (body.uuid) {
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}corporations-settings/${body.uuid}`,
        body,
        this.requestOptionsFormData
      ).toPromise();
    }
    return await this.httpClient.post<IResponse>(
      `${this.API_PATH}corporations-settings`,
      body,
      this.requestOptionsFormData
    ).toPromise();
  }

}
