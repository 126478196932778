<div class="all-content">
  <div class="left">
    <img src="../../../assets/logo_title_white.png" class="logo-title">
  </div>
  <div class="right" *ngIf="!showConfirm">
    <div class="title">
      <span>
        <!-- {{getLanguages('auth/login')}} -->
         Login
      </span>
      <div class="tog"></div>
    </div>

    <div class="inputs">
      <div class="input">
        <input type="text" placeholder="{{ getLanguages('auth/login/email')}} " (keyup.enter)="login()" name='E-mail' [(ngModel)]="email" autocapitalize="none">
      </div>
      <div class="password">
        <input type="text" placeholder="{{ getLanguages('auth/login/password')}} " (keyup.enter)="login()" name='Password'  [type]="showPass ? 'text' : 'password'" [(ngModel)]="password" id="inputPassword" autocapitalize="none">
        <i [ngClass]="showPass ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash' " (click)="showPass = !showPass"></i>
      </div>
      <div class="forgot" >
        <span (click)="showConfirm = !showConfirm" name="button-forgot"> {{getLanguages('auth/login/forgot')}} </span>
      </div>
      <button class="buttom" (click)="login()" name="button-login">
        {{ getLanguages('auth/login') }}
      </button>
      <div class="create">
        <!-- <a (click)="navigate('register')" id="buttonRegister"> {{getLanguages('auth/login/create')}} </a> -->
      </div>
    </div>
  </div>

  <div class="right" *ngIf="showConfirm">

    <div class="back">
      <i class="fas fa-arrow-left" (click)="showConfirm = !showConfirm"></i>
    </div>

    <div class="title">
      <span>
        {{getLanguages('auth/forgot')}}
      </span>
      <div class="tog"></div>
      <span class="sub">
        {{getLanguages('auth/forgot/sub')}}
      </span>
    </div>

    <div class="inputs">
      <div class="input">
        <input type="text" placeholder="{{getLanguages('auth/forgot/type')}}" name='E-mail' (keyup.enter)="sendResetPassword()" [(ngModel)]="emailConfirm" autocapitalize="none">
      </div>

      <button class="buttom" (click)="sendResetPassword()" name="button-reset">
        {{getLanguages('auth/forgot/send')}}
      </button>
      <div class="create">
        <a (click)="showConfirm = !showConfirm" id="buttonRegister"> {{getLanguages('auth/login')}} </a>
      </div>
    </div>
  </div>

  <div class="bottom">
    <div class="tag">
      <span>UI: {{ versions?.version_ui }}</span>
      <span>API: {{ versions?.version_api }}</span>
    </div>

    <button [matMenuTriggerFor]="flagMenu" matTooltip="{{getLanguages('pages/info/language')}}" class="flag-button">
      <i class="fas fa-globe"></i>
      <span> {{ language && language.lang ? language.lang.toUpperCase() : 'en' }} </span>
      <i class="fas fa-caret-down"></i>
    </button>

    <mat-menu #flagMenu="matMenu" class="flag-menu">
      <button *ngFor="let flag of flagArray" (click)="selectFlag(flag)" class="button-icon">
        <span> {{ flag.lang.toUpperCase() }} </span>
      </button>
    </mat-menu>
  </div>

  <div class="loading" *ngIf="isLoading">
    <div class="loading-spinner"></div>
    <span>
      {{ getLanguages('loading') }}
    </span>
  </div>
</div>

<div class="alerts">
  <ng-container *ngFor="let alert of queueAlert">
    <app-alert text="{{alert.text}}" type="{{alert.type}}" (remove)="removeAlertLogin(alert.id, $event)"></app-alert>
  </ng-container>
</div>

<!-- Adicione o contêiner do componente externo aqui -->


<!--
   (contextmenu)="onRightClick($event)"
  <div class="context-menu"  *ngIf="isContextMenuVisible" [style.left.px]="contextMenuPosition.left" [style.top.px]="contextMenuPosition.top">
  <div class="title-context-menu">
    <span>
      OnePage - Login
    </span>
  </div>
</div> -->
