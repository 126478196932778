import { Component, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { localStorageToJsonAsync }
 from 'src/app/helpers/cookieToJson';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() type: any // 1 

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  properties: any = {}

  constructor(private cookieService: CookieService){}

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

}
