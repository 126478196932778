import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddressService } from 'src/app/services/address/address.service';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { FunctionService } from 'src/app/services/function.service';
import { asyncForEach } from "../../../../helpers/asyncForEach";
import { CookieService } from 'ngx-cookie-service';
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { cookieToJsonAsync, localStorageToJsonAsync} from "../../../../helpers/cookieToJson";
import { v4 } from 'uuid';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { stringify } from 'circular-json';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';


@Component({
  selector: 'app-create-foundation',
  templateUrl: './create-foundation.component.html',
  styleUrls: ['./create-foundation.component.scss'],
})
export class CreateFoundationComponent {
  @Input() properties: any = {}
  @Input() parameters: any = {}

  @Output() toggleFoundationModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getCorporationTree: EventEmitter<void> = new EventEmitter<void>();
  private subscription: Subscription = new Subscription();

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  isPrincipal = false;
  isEdit = true;
  createType = false;
  createSegment = false;

  newType = {name: ''};
  newSegment = {name: null};

  selectedRegistrationType: string = '';
  statusArray = [ { value: true , label: {en: 'Active', pt: 'Ativo'} }, { value: false , label: {en: 'Inactive', pt: 'Inativo'}}]
  searchType: string = '';
  searchFollow: string = '';
  selectedStatus: any =  { name: this.statusArray[0].label };

  formData: FormData = new FormData()
  file: any = null
  showCrop = false;

  data: any = {
    corporation: {
      corporation_uuid: null,
      corporation_type_uuid: null,
      corporation_name: '',
      follow_up: '',
      is_active: null,
      acronym: ''
    },
  }

  corporations: any = []
  corporation: any = {
    search: ''
  }

  selectedCorporation: any = { name: 'Select' };

  selectedCorporationType : any = { name: 'Select' };
  selectedSegment : any = { name: 'Select' };

  corporationTypeArray: any = []
  segmentArray: any = []
  imageChangedEvent: any = '';
  croppedImage: any = '';
  displayedImage: any;

  corporationsArray: any = []
  constructor(
    private functionService: FunctionService,
    private addressService: AddressService,
    private corporationService: CorporationService,
    private cookieService: CookieService,
    private userEnvironmentService: UserEnvironmentsService,
  ) { }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()

    await this.getCorporationType()
    await this.getCorporationSegments()

    this.selectedCorporation = { ...this.parameters.reference_parent };
    this.isPrincipal = this.parameters.isPrincipal;

    this.data.corporation.is_active = this.statusArray[0].value;
    if (!this.isPrincipal) {
      this.data.corporation.corporation_uuid = this.parameters.reference_parent.uuid;
    }

    this.corporationsArray = sortArrayOfObjects(this.properties.users_corporations?.corporations || [])

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language'))?.lang]
  }

  async initCorporationType(){
    this.corporationTypeArray = []
    this.corporationTypeArray.push({name: 'New +'})
  }

  async initSegment(){
    this.segmentArray = []
    this.segmentArray.push({name: 'New +'})
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  setValidsInputs(){
    let inputs: any = []

    if (!this.data.corporation.corporation_name || this.data.corporation.corporation_name == '') {
      inputs.push(this.getLanguages('corporation/modal/corporationName'))
    }
    
    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  async setCorporation(){
    if (!this.setValidsInputs()) {
      return;
    }

    
    try {
      this.data.corporation.name = this.data.corporation.corporation_name 
      this.data.corporation.is_principal = this.isPrincipal;
      await this.setFormData()
    
      const response:any = await this.corporationService.setCorporation(this.formData);
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message)
        await this.modalClose()
        window.location.reload()
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  choseTypeCorporation(type: any) {
    if(type.name == "New +"){
      this.createType = true;
      return
    }
    this.selectedCorporationType.name = type.name;
    this.data.corporation.corporation_type_uuid = type.uuid
  }

  async setCorporationType(){
    try {
      const response:any = await this.corporationService.setCorporationType(this.newType);
      if (response && [201, 202].includes(response.status) ) {
        addAlert('success', response.message)
        await this.getCorporationType();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.createType = false;
    this.newType.name = ''
  }

  async getCorporationType() {
    try {
      const response: any = await this.corporationService.getCorporationType();
      if (response && response.data) {
        await this.initCorporationType();
        this.corporationTypeArray.push(...sortArrayOfObjects(response.data))

      } else {
        throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  chooseCorporation(corporation: any) {
    this.selectedCorporation.name = corporation.name;
    this.data.corporation.corporation_uuid = corporation.uuid
  }

  chooseSegment(type: any) {
    if(type.name == "New +"){
      this.createSegment = true;
      return
    }
    this.selectedSegment.name = type.name;
    this.data.corporation.follow_up = type.uuid
  }

  /**
   *
   */
  async setSegment() {
    try {
      const response: any = await this.corporationService.setSegment(this.newSegment)
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message);
        this.createSegment = false;
        await this.getCorporationSegments();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.createSegment = false;
    this.newSegment.name = null
  }

  /**
   *
   */
  async getCorporationSegments() {
    await this.initSegment();
    try {
      const response: any = await this.corporationService.getCorporationSegments();
      if (response && response.data) {
        await this.initSegment();
        this.segmentArray.push(...sortArrayOfObjects(response.data))
      } else {
        throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.getCorporationTree.emit()
    this.toggleFoundationModal.emit();
  }

  choseRegistrationType(RegistrationType: any) {
    this.selectedRegistrationType = RegistrationType.name;
  }

  choseStatus(state: any) {
    this.data.corporation.is_active = state.value;
    this.selectedStatus.name = state.value ? {en: 'Active', pt: 'Ativo'} : {en: 'Inactive', pt: 'Inativo'}
  }

  filterByArray(slug: string) {
    if(slug == 'corporation'){
      this.corporation.filteredArray = this.corporation.array.filter((item : { name: string}) =>
      item.name.toLowerCase().includes(this.corporation.search.toLowerCase())
      )
    }
  }

  @ViewChild('fileInput') fileInput!: ElementRef;
  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const fileSize = fileInput.files[0].size;
      const maxSizeInBytes = 2 * 1024 * 1024;

      if (fileSize > maxSizeInBytes) {
        addAlert('orange', this.getLanguages('error/maxSizeBytes'))
        fileInput.value = '';

        return
      }
    }


    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    const file = event.target.files[0];

    if (!allowedFormats.includes(file.type)) {
      addAlert('orange', this.getLanguages('error/onlyPng'))
      return;
    }

    this.imageChangedEvent = event;
    this.showCrop = true;
  }

  saveAndClose(flag: boolean = true){
    this.showCrop = false;

    if(!flag){
      return
    }

    this.displayedImage = URL.createObjectURL(this.croppedImage.blob);


    const blob = this.croppedImage.blob;
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(blob);
    fileReader.onload = () => {
      let arrayBuffer = fileReader.result as ArrayBuffer;
      this.file = new File([arrayBuffer], `${v4()}.png`, { type: 'image/png' })
    };
  }

  async setFormData() {
    this.formData = new FormData()
    this.formData.append('body', stringify(this.data))
    this.formData.append('file', this.file)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

}
