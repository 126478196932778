import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  API_PATH: string

  constructor(private httpClient: HttpClient, private router: Router,
    ) {
    this.API_PATH = environment.apiUrl;
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async getProfiles() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}profiles`).toPromise();
  }

}
