import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { cookieToJsonAsync, localStorageToJsonAsync} from "../../helpers/cookieToJson";
import { addAlert, apiOfflineAsync} from "../../layouts/pages/pages.component";
import { TreeNode } from 'primeng/api';
import { v4 } from 'uuid';
import { parse, stringify } from "circular-json";
import { ProjectService } from 'src/app/services/project/project.service';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { PersonasService } from 'src/app/services/personas/personas.service';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';

@Component({
  selector: 'app-page-project',
  templateUrl: './page-project.component.html',
  styleUrls: ['./page-project.component.scss']
})
export class PageProjectComponent {
  @Input() parameters: any = {}
  private subscription: Subscription = new Subscription();

  files!: TreeNode[];
  filesOld!: TreeNode[]

  queueAlert: any = []
  queueTime: any = []

  openTree: boolean = false;

  properties: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  guidelineProject: any = {}
  formData: FormData = new FormData()

  isShow: boolean = false;
  isShowEdit: boolean = false;
  isShowDelete: boolean = false;

  isEdited = false;
  noData = true;

  isLoading: boolean = false;
  selectedNode: any;
  isSelectedNode = false;
  searchText: string = '';
  text: string = '';

  objectModal: any = {}

  data: any
  selectedFile: any;

  corporationGuidelineProjectArray: any = []
  searchCorporationGuidelineProject: string = ''
  selectedCorporationGuidelineProject: any = {
    name: '',
    uuid: ''
  }

  optionsArray: any = []
  // organogramArray: any = []
  
  constructor(
    private cdr: ChangeDetectorRef,
    private projectGuidelineService: ProjectService,
    private businessSolutionsService: BusinessSolutionsService,
    private userEnvironmentService: UserEnvironmentsService
    ) {

  }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    
    await this.getGuidelineProjectTree();
    await this.getOptionsArray()

    this.updateFiles({}, true)
    await this.getUserCorporations()
  }

  async getUserCorporations(){
    try{
      let response = await this.userEnvironmentService.getUserCorporations()
      if(response && response.data){
        this.corporationGuidelineProjectArray =  sortArrayOfObjects([...response.data.filter((item: any) => item?.uuid != this.properties?.corporation?.uuid)]);
      }
    }catch{

    }
  }


  /**
   *
   */

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async choseItemSelect(type: string, value: any){
    switch (type) {
      case 'corporationGuidelineProject':
        this.selectedCorporationGuidelineProject = value;
        this.isEdited = true
        await this.getGuidelineProjectTree()
        break;
    }
  }

  async getOptionsArray() {
    try {
      const response: any = await this.businessSolutionsService.getOptionsArray();
      this.objectModal.optionsArray = {}
      if (response && response.data && response.data.frequency) {
        this.optionsArray = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getGuidelineProjectTree() {
    this.noData = false
    this.isLoading = true;

    try {
      const response: any = await this.projectGuidelineService.getProjectGuidelineTree(({...this.parameters, corporation_uuid: this.selectedCorporationGuidelineProject.uuid})).toPromise();
      this.isLoading = false;

      if (response && response.data) {
        this.guidelineProject = parse(response.data)

        if(this.selectedCorporationGuidelineProject.uuid){
          delete this.selectedCorporationGuidelineProject
          delete this.guidelineProject.uuid
        }

        this.files = this.guidelineProject.json;
        this.filesOld = this.guidelineProject.json;
        this.isLoading = false;
        this.noData = this.files.length == 0
        await this.flipTree()
       } else {
        throw new Error('Erro ao consultar guideline de projetos!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   * @param _
   * @param node
   */

  searchNodes(): void {
    this.files = this.filesOld.filter((node) => this.filterNode(node, this.text, true));
    this.verifyNoData()
  }

  verifyNoData() {
    if(this.files.length == 0){
      this.noData = true;
      return
    }
    this.noData = false;
  }

  private filterNode(node: TreeNode, query: string, includeParents: boolean = false): boolean {
    if(!node?.label){
      return false
    }
    const labelMatches = node?.label?.toLowerCase().includes(query.toLowerCase());

    if (labelMatches) {
      return true;
    }

    if (node.children) {
      const anyChildMatches = node.children.some((child) => this.filterNode(child, query, true));

      if (anyChildMatches) {
        return true;
      }
    }

    return false;
  }


  async flipTree(){
    this.openTree = !this.openTree;
    if(this.openTree){
      return this.expandAll()
    }
    return this.collapseAll()
  }

  async expandAll() {
    this.files.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  async collapseAll() {
    if(!this.files || !this.files.length){
      return
    }
    this.files.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  async loadModal(type: number, node: any = {}, event?: Event){
    this.isShowEdit = false;
    this.isShow = false;

    if(type == 0){
      this.selectedFile = null
      return
    }else if(type == 1){
      await this.showCreateItemModal(node, event)
      return
    }else if(type == 2){
      node = this.selectedFile
      await this.showEditItemModal(node)
      return
    }else if(type == 3){
      await this.showDeleteItemModal(node)
      return
    }
  }

  setUpdateTreeItem (uuid: number, node: any) {
    this.files.forEach((item: any, index: number) => {
      if (uuid == item.uuid) {
        this.files[index] = node
      } else if (item.children.length > 0) {
        this.setUpdateTreeItemRecursive(item.children, uuid, node)
      }
    })
  }

  setUpdateTreeItemRecursive (array: any, uuid: number, node: any) {
    array.forEach((item: any, index: number) => {
      if (uuid == item.uuid) {
        array[index] = node
      } else if (item.children.length > 0) {
        this.setUpdateTreeItemRecursive(item.children, uuid, node)
      }
    })
  }

  findOrganogramNodes(nodes: any[], result: any[] = []): any[] {
    for (const node of nodes) {

      if (node.data.type === 'organogram') {
        result.push({label: node.label, uuid: node.uuid});
      }
  
      if (node.children && node.children.length > 0) {
        this.findOrganogramNodes(node.children, result);
      }
    }
  
    return result;
  }

  async showCreateItemModal(node: any = {}, event?: Event) {
    if(event){
      event.stopPropagation()
    }
    this.selectedFile = node

    this.cdr.detectChanges();
    this.parameters.isEdit = true;
    this.parameters.isCreate = true
    this.parameters.item = { uuid: v4() }
    this.parameters.item.master = this.selectedFile.uuid ?? 'ROOT'
    this.parameters.item.level = node?.level ? node?.level + 1 : 1
    this.parameters.isCreate = true
    this.parameters.item.label = ''
    this.parameters.item.type_project = ''
    this.parameters.item.status = ''
    this.parameters.item.objective = ''
    this.parameters.item.userCreated = ''
    this.parameters.item.created_at= ''
    this.parameters.item.userUpdated = ''
    this.parameters.item.updated_at = ''
    this.parameters.item.is_iterative = false
    this.parameters.item.is_active = false
    this.parameters.optionsArray = this.optionsArray

    this.isShow  = true;
    this.objectModal = { ...this.parameters }
    this.objectModal.is_new = true;
    this.objectModal.guidelineProject = [ ...this.filesOld ]
  }

  async showEditItemModal(node: any = {}, event?: Event) {
    if (!node) {
      return;
    }

    this.cdr.detectChanges();
    this.parameters.item = {...node}
    delete this.parameters.item.children
    delete this.parameters.item.parent
    this.parameters.item = JSON.parse(JSON.stringify({ ...this.parameters.item }));
    this.parameters.isCreate = false
    this.parameters.isEdit = false

    this.parameters.optionsArray = this.optionsArray

    delete this.parameters.item.parent
    delete this.parameters.item.children

    this.objectModal = { ...this.parameters }
    this.objectModal.guidelineProject = this.filesOld
    this.isShowEdit = true;
  }

  /**
   *
   * @param node
   * @param event
   */
  async showDeleteItemModal(node: any = {}, event?: Event) {
    if(event){
      event.stopPropagation();
    }

    this.isShowEdit = false;
    this.isShow = false;

    this.parameters.item = { ...node }
    this.objectModal = { ...this.parameters }
    this.objectModal.files = this.files
    this.objectModal.filesOld = this.filesOld

    if(!node){
      this.openCloseModalDelete(false)
      return
    }
    this.openCloseModalDelete(true)
  }

  openCloseModalDelete (option: boolean = true) {
    this.isShowDelete = option;
  }

  updateFiles(event?: any, ngOnInit?: any) {
    this.text = '';
  
    if(!ngOnInit){
      this.isEdited = true;
    }

    if(event) {
      if (event.files) {
        this.files = event.files;
        this.filesOld = event.filesOld ?? event.files;
      }
      if (event.isShow) {
        this.isShow = event.isShow;
      }
    }

    this.verifyNoData();
    this.noData = this.files.length == 0;

    this.files?.forEach((item: any) => {
      this.recursiveUpdateSize(item, 1);
    });
  }
  
  recursiveUpdateSize(item: any, level: number) {
    item.level = item.level ? item.level : level; 

    if (item.children && item.children.length > 0) {
      item.size = item.children?.reduce((acc: number, child: any) => {
        return acc + this.recursiveUpdateSize(child, level + 1);
      }, 0);
    } else {
      item.size = 0;
    }
    return item.size + 1;
  }
  

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(this.properties.permissions, module, screen, action, false)
  }


  async saveTree(isNew: boolean = true){
    this.isEdited = false;
    this.isLoading = true;
    this.isShow = false;
    this.isShowEdit = false;

    delete this.selectedFile

    await this.setFormData()
    try {
      const response: any = await this.projectGuidelineService.setProjectGuidelineTree(this.formData, this.guidelineProject.uuid)
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
        await this.getGuidelineProjectTree()
        this.isLoading = false;
      } else{
        addAlert('danger', response.message)
        this.isLoading = false;
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
      this.isLoading = false;
    }
  }

  async setFormData() {
    this.formData = new FormData()
    this.formData.append('json', stringify(this.filesOld))
  }

  isResponsive(): boolean {
    return window.innerWidth >= 900;
  }

  getParentCount(node: any): number {
    let parentCount = 0;
    let parentNode = node;

    while (parentNode && parentNode.parent) {
      parentCount++;
      parentNode = parentNode.parent;
    }

    // Adicione 1 para incluir o próprio nó na contagem
    return parentCount + 1;
  }

  getIcon(level: number): string{
    let icon = ''

    switch(level){
      case 1:
        icon = 'fa-square'
        break
      case 2:
        icon = 'fa-circle'
        break
      case 3:
        icon = 'fa-diamond'
        break
      case 4:
        icon = 'fa-minus'
        break
      default:
        icon = ''
        break
    }

    return icon
  }

  async getTreeXls(type: string = '') {
    this.isLoading = true;
    try {
      const response: Blob = await this.projectGuidelineService.getTreeXls({
        environment: this.properties.environment,
        corporation: this.properties.corporation,
        json: type == 'json',
        xls: type == 'xls'
      });

      await this.projectGuidelineService.downloadJsonXls(response, `${this.properties?.corporation?.slug}-project-guideline-tree`, type)
      addAlert('success', this.getLanguages('businessSolution/top/info/export/success'))

    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;
  }
}
