import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IBusinessSolution } from 'src/app/interfaces/IBusinessSolution';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { AuthService } from "../../services/auth/auth.service";
import { EventService } from 'src/app/services/event/event.service';
import { asyncForEach } from 'src/app/helpers/asyncForEach';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UsersService } from 'src/app/services/users/users.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { to64decode } from "../../helpers/base64";
import { CookieService} from "ngx-cookie-service";
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import {ActivatedRoute} from "@angular/router";
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync } from 
 "../../helpers/cookieToJson";
import {addAlert, apiOffline, apiOfflineAsync} from "../../layouts/pages/pages.component";
import {AdminGroup} from "../../app.module";


export interface User {
  id: number;
  name: string;
  lastname: string;
  email: string;
  document: string;
  profile: any;
  corporation: string;
  is_active: string;
  telephone: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent {
  properties: any = {}
  parameters: any = {}

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // noData: boolean = true;

  USER_DATA: any[] = [];
  private subscription: Subscription = new Subscription();

  statusArray: any = [
    {id: 0, name: 'WAITING'},
    {id: 1, name: 'ACTIVE'},
    {id: 2, name: 'INACTIVE'},
  ]

  protected readonly AdminGroup = AdminGroup;

  task: any = { color: 'accent' };

  searchText: string = '';
  isTreeEmpty = true;

  profiles: any = [];
  selectedProfile: any;

  objectModal: any = {
    readonly: false
  };

  filteredDataSource!: MatTableDataSource<any>;

  isShowUserCreate: boolean = false;
  isShowUserEdit: boolean = false;

  isLoading: boolean = true;
  selectedCorporation: any = { name: 'Loading...', slug: ''};
  environment?: any;
  corporation?: any;
  corporations: any = [];

  dataSource: any[] = [/* ... your data source array ... */];
  selectionUsers = new SelectionModel<any>(true, []);
  selectionProfiles = new SelectionModel<any>(true, []);
  selectionApproval = new SelectionModel<any>(true, []);

  quantityUsers: any = { active: 0, inactive: 0, total: 0 }

  userAll: any = {
    currentRowIndex: 0,
    currentPage: 1,
    itemsPerPage: 10,
    searchText: '',
    isLoading: false,
    displayedColumns: ['Name', 'Email', 'Profile', 'Corporation', 'Status', 'Approver'],
    dataSource: {},
    filteredDataSource: {},
  }

  userApproval: any = {
    currentRowIndex: 0,
    currentPage: 1,
    itemsPerPage: 10,
    searchText: '',
    isLoading: false,
    displayedColumns: ['Select', 'Name', 'Email', 'Telephone'],
    dataSource: {},
    filteredDataSource: {},
  }

  isListUsers: boolean = false
  isListProfiles: boolean = false
  isListApproval: boolean = false

  /**
   *
   * @param eventService
   * @param corporationService
   * @param usersService
   * @param cookieService
   * @param profileService
   * @param activatedRoute
   */
  constructor(
    private eventService: EventService,
    private corporationService: CorporationService,
    private usersService: UsersService,
    private cookieService: CookieService,
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute
  ) {
    this.filteredDataSource = new MatTableDataSource(this.USER_DATA);
    this.filteredDataSource.filterPredicate = this.customFilterPredicate();

    this.eventService.eventModalUser.subscribe((param) => {
      this.togleCreateUser();
    });
    this.eventService.eventModalUserEdit.subscribe((param) => {
      this.togleCreateUserEdit();
    });
  }

  /**
   *
   */
  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()

    await this.getCorporations();
    await this.selectButton()

    this.userAll.dataSource = new MatTableDataSource<any>([])
    this.userAll.filteredDataSource = new MatTableDataSource<any>([])
    this.userApproval.dataSource = new MatTableDataSource<any>([])
    this.userApproval.filteredDataSource = new MatTableDataSource<any>([])

    if (this.properties.me.is_admin != AdminGroup.OTHERS) {
      this.userAll.displayedColumns = ['Name', 'Email', 'Admin', 'Profile', 'Corporation', 'Status', 'Approver']
    }
    if (this.getSystemPermission('register', 'users', 'update')) {
      this.userAll.displayedColumns.unshift('Select')
    }
  }

  /**
   *
   * @param file
   */
  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  /**
   *
   * @param accepted
   */
  async feedbackUser(accepted: boolean = true){
    let selectedIds: any[] = [];

    if(this.selectionApproval.selected.length > 0 && this.isListApproval){
      this.selectionApproval.selected.forEach((user: any) => {
        selectedIds.push(user.uuid);
      });
    }

    try {
      const response:any = await this.usersService.setUserStatus(selectedIds,accepted ? 'ACTIVE' : 'DENIED');
      if ((response && [201, 202].includes(response.status)) || [400].includes(response.status)) {
        addAlert('success', response.message)
        await this.selectButton([400].includes(response.status) ? 2 : 0)
        await this.getUsers();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   */
  customFilterPredicate(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      const searchTermLower = filter.toLowerCase();
      return (
        data.name.toLowerCase().includes(searchTermLower) ||
        data.email.toLowerCase().includes(searchTermLower) ||
        (data.corporation && data.corporation.name.toLowerCase().includes(searchTermLower)) ||
        data.profile.toLowerCase().includes(searchTermLower)
      );
    };
    return filterFunction;
  }

  /**
   *
   * @param selectNumber
   */
  async selectButton(selectNumber: number = 0) {

    if(selectNumber == 0){
      this.isListApproval = false
      this.isListUsers = true
      await this.getUsers()

      return
    }
    // this.isListProfiles = selectNumber === 1
    this.isListUsers = false
    this.isListApproval = true
    await this.getUsers('WAITING')

  }

  /**
   *
   */
  isAllSelectedUsers() {
    const numSelected = this.selectionUsers.selected.length;
    const numRows = this.userAll.filteredDataSource.data.length;
    return numSelected === numRows;
  }

  /**
   *
   */
  toggleAllRowsUsers() {

    if (this.isAllSelectedUsers()) {
      this.selectionUsers.clear();
      return;
    }

    this.selectionUsers.selected.splice(this.selectionUsers.selected.indexOf(this.properties.me.uuid))

    this.selectionUsers.select(...this.userAll.filteredDataSource.data);
  }

  /**
   *
   * @param index
   * @param row
   */
  checkboxLabelUsers(index: number = 0, row?: User): string {
    if (!row) {
      return `${this.isAllSelectedUsers() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionUsers.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

  /**
   *
   */
  isAllSelectedProfiles() {
    const numSelected = this.selectionProfiles.selected.length;
    const numRows = this.filteredDataSource.data.length;
    return numSelected === numRows;
  }

  /**
   *
   */
  toggleAllRowsProfiles() {
    if (this.isAllSelectedProfiles()) {
      this.selectionProfiles.clear();
      return;
    }

    this.selectionProfiles.select(...this.filteredDataSource.data);
  }

  /**
   *
   * @param index
   * @param row
   */
  checkboxLabelProfiles(index: number = 0, row?: User): string {
    if (!row) {
      return `${this.isAllSelectedProfiles() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionProfiles.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

  /**
   *
   */
  isAllSelectedApproval() {
    const numSelected = this.selectionApproval.selected.length;
    const numRows = this.userApproval.filteredDataSource.data.length;
    return numSelected === numRows;
  }

  /**
   *
   */
  toggleAllRowsApproval() {
    if (this.isAllSelectedApproval()) {
      this.selectionApproval.clear();
      return;
    }

    this.selectionApproval.select(...this.userApproval.filteredDataSource.data);
  }

  /**
   *
   * @param index
   * @param row
   */
  checkboxLabelApproval(index: number = 0, row?: User): string {
    if (!row) {
      return `${this.isAllSelectedApproval() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionApproval.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

  /**
   *
   */
  async getCorporations() {
    try {
      const response: any = await this.corporationService.getCorporationTree().toPromise();
      if (response && response.data && response.data.result) {
        this.corporations = response.data.result;
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   * @param event
   */
  togleCreateUser(event?: Event) {
    if (this.corporations.length == 0) {
      return;
    }

    this.parameters = {
      ...this.objectModal,
    }

    this.isShowUserCreate = !this.isShowUserCreate;

    if(event){
      event.stopPropagation();
    }
  }

  /**
   *
   * @param event
   * @param readonly
   * @param uuid
   */
  async togleCreateUserEdit(event?: Event, readonly: boolean = false, uuid?: string): Promise<any> {
    if (!this.properties.permissions['register']['users'].read && readonly && uuid) {
      return;
    }
    if(event) {
      event.stopPropagation();
    }

    let selectedIds: any[] = [];

    this.parameters = {}
    this.parameters.readonly = readonly;
    this.parameters.isEditUser = true;
    this.parameters.isEditProfile = false;
    this.parameters.quantityUsers = this.selectionUsers.selected.length;
    this.parameters.quantityProfiles = this.selectionProfiles.selected.length;
    this.parameters.isOneUser = this.selectionUsers.selected.length == 1 || uuid;
    this.parameters.user = {}
    this.parameters.corporationsprofiles = []

    if (uuid || this.parameters.isOneUser) {
      await this.getUser(uuid ?? this.selectionUsers.selected[0].uuid)
      this.isShowUserEdit = !this.isShowUserEdit;
      return
    }
    if (!uuid && !this.parameters.isOneUser) {
      if(this.selectionUsers.selected.length > 0 && this.isListUsers){
        this.selectionUsers.selected.forEach((user: any) => {
          selectedIds.push(user.uuid);
        });
      }

      this.parameters.users = selectedIds;
      this.isShowUserEdit = !this.isShowUserEdit;
    }
  }

  /**
   *
   */
  isShowEdit(): boolean {
    this.parameters.quantityUsers = this.selectionUsers.selected.length;
    this.parameters.quantityProfiles = this.selectionProfiles.selected.length;

    return (this.isListUsers && this.parameters.quantityUsers > 0) || (this.isListProfiles && this.parameters.quantityProfiles > 0)
  }

  /**
   *
   * @param status
   */
  async getUsers(status: any = null) {
    await this.getUsersQuantity()
    if (this.corporations.length == 0) {
      return;
    }
    this.isLoading = true;
    this.subscription.add(
      this.usersService.getUsers(status)
        .subscribe(
          (response: any) => {
            if (response && response.data) {
              this.USER_DATA = response.data;
              if(this.isListUsers){
                this.userAll.dataSource = new MatTableDataSource<any>(response.data)
                this.userAll.filteredDataSource = new MatTableDataSource<any>(response.data)
              }
              if(this.isListApproval){
                this.userApproval.dataSource = new MatTableDataSource<any>(response.data)
                this.userApproval.filteredDataSource = new MatTableDataSource<any>(response.data)
              }
              this.selectionUsers = new SelectionModel<any>(true, []);
              this.selectionProfiles = new SelectionModel<any>(true, []);
              this.selectionApproval = new SelectionModel<any>(true, []);
            } else {
              throw new Error('Erro ao consultar usuários!\nConsulta o administrador do sistema!');
            }
          },
          (error) => {
            apiOffline(error)
          }
        )
    );
    this.isLoading = false;
  }

  /**
   *
   */
  async getUsersQuantity () {
    try {
      const response = await this.usersService.getUsersQuantity()

      if (response && response.data) {
        this.quantityUsers = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   * @param id
   */
  async getUser(id: number) {
    this.isLoading = true;

    try {
      const response: any = await this.usersService.getUser(id);
      if (response && response.data) {
        this.isLoading = false;
        this.parameters.user = response.data;
      }
    } catch (error: any) {
      this.isLoading = false;
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   */
  async getProfiles() {
    try {
      const response: any = await this.profileService.getProfiles();

      if (response && response.data) {
        this.profiles = response.data;
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      this.isLoading = false;
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   * @param corporation
   */
  async choseDomainData(corporation: any) {

    if (corporation.access){
      await this.selectButton()
      await this.getUsers()
      await this.getUsersQuantity();
      return
    }
  }

  /**
   *
   * @param _
   * @param node
   */
  hasChild = (_: number, node: IBusinessSolution) => !!node.info && node.info.length > 0;

  /**
   *
   * @param number
   * @param type
   */
  choseItemsPage(number: number){
    if(this.isListUsers){
      return this.userAll.itemsPerPage = number;
    }

    return this.userApproval.itemsPerPage = number;
  }

  /**
   *
   * @param newPage
   * @param type
   */
  onPageChange(newPage: number){
    if(this.isListUsers){
      return this.userAll.currentPage = newPage;
    }

    return this.userApproval.currentPage = newPage;
  }

  /**
   *
   * @param type
   */
  getDisplayedRows(): any[] {
    if(this.isListUsers){
      this.searchUser()
      const startIndex = (this.userAll.currentPage - 1) * this.userAll.itemsPerPage;
      const endIndex = startIndex + this.userAll.itemsPerPage;
      return this.userAll.filteredDataSource.data.slice(startIndex, endIndex);
    }else if(this.isListApproval){
      this.searchUser()
      const startIndex = (this.userApproval.currentPage - 1) * this.userApproval.itemsPerPage;
      const endIndex = startIndex + this.userApproval.itemsPerPage;
      return this.userApproval.filteredDataSource.data.slice(startIndex, endIndex);
    }
    return []
  }

  /**
   *
   * @param type
   */
  getTotalPages(): number {
    if(this.isListUsers){
      return Math.ceil(this.userAll.filteredDataSource.data.length / this.userAll.itemsPerPage);
    }
    return Math.ceil(this.userApproval.filteredDataSource.data.length / this.userApproval.itemsPerPage);
  }

  /**
   *
   * @param event
   * @param type
   */
  async checkValue(event: any) {
    let total = this.getTotalPages()
    if(this.isListUsers){
      if (this.userAll.currentPage > total) {
        this.userAll.currentPage = total;
      }
      return
    }
    if (this.userApproval.currentPage > total) {
      this.userApproval.currentPage = total;
    }
  }

  /**
   *
   */
  searchUser() {
    if(this.isListUsers){
      let searchText = this.searchText.toLowerCase()
      this.userAll.filteredDataSource.data = this.userAll.dataSource.data.filter(
        (item: { id: any; name: any; telephone: any; email: any; }) =>
        (item.name && item.name.toLowerCase().includes(searchText))
        || (item.email && item.email.toLowerCase().includes(searchText))
        || (item.telephone && item.telephone.toLowerCase().includes(searchText))
      )
    }
    if(this.isListApproval){
      let searchText = this.searchText.toLowerCase()
      this.userApproval.filteredDataSource.data = this.userApproval.dataSource.data.filter(
        (item: { id: any; name: any; telephone: any; email: any; }) =>
        (item.name && item.name.toLowerCase().includes(searchText))
        || (item.email && item.email.toLowerCase().includes(searchText))
        || (item.telephone && item.telephone.toLowerCase().includes(searchText))
      )
    }

  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(localStorage.getItem('permissions'), module, screen, action)
  }

  displayedColumns(){
    if(this.isListUsers){
      return this.userAll.displayedColumns
    }
    if(this.isListApproval){
      return this.userApproval.displayedColumns
    }
  }

  getItemsPerPage(){
    if(this.isListUsers){
      return this.userAll.itemsPerPage
    }

    return this.userApproval.itemsPerPage
  }

  getCurrentPages(): number{
    if(this.isListUsers){
      return this.userAll.currentPage
    }

    return this.userApproval.currentPage
  }

  setSelection(element: any){
    if(this.isListUsers){
      return this.selectionUsers.toggle(element)
    }

    return this.selectionApproval.toggle(element)
  }

  getSelection(element: any){
    if(this.isListUsers){
      return this.selectionUsers.isSelected(element)
    }

    return this.selectionApproval.isSelected(element)
  }

  toggleAllRow() {
    if(this.isListUsers){
      return this.toggleAllRowsUsers()
    }

    return this.toggleAllRowsApproval()
  }

  isAllSelected(){
    if(this.isListUsers){
      return this.isAllSelectedUsers()
    }

    return this.isAllSelectedApproval()
  }

  noData(): boolean{
    if(this.isListUsers){
      if(this.userAll.filteredDataSource.data && this.userAll.filteredDataSource.data.length > 0){
        return false;
      }else{
        return true;
      }
    }else if(this.isListApproval){
      if(this.userApproval.filteredDataSource.data && this.userApproval.filteredDataSource.data.length > 0){
        return false;
      }else{
        return true;
      }
    }
    return false
  }

  async setUserApprover(event: Event, user: any){
    if(event){
      event.stopPropagation()
    }

    try {
      user.is_approval = !user.is_approval
      const response:any = await this.usersService.setUserCorporationApprover(user);
      if ((response && [201, 202].includes(response.status)) || [400].includes(response.status)) {
        addAlert('success', response.message)
        await this.selectButton([400].includes(response.status) ? 2 : 0)
        await this.getUsers();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

  }
}
