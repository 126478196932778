<div class="all-content shadow" id="sidebar_component">
  <div class="itens">
    <div class="item">
      <i class="fas fa-bars" (click)="toggle(true)"></i>
    </div>
    <div class="item" *ngFor="let module of properties['modules']">
      <span class="module"> {{ module['slug'] == 'home' ? '' : getLanguageParam(module['name']) }} </span>
      <ng-container *ngFor="let screen of properties['screens'][module['slug']]">
        <div class="screen"
        *ngIf="getSystemPermission(module['slug'], screen['slug'], 'screen_permission') && screen['slug']!= 'settings'"
        (click)="navigate(screen['url'])">
          <div class="circle"></div>
          <i [ngClass]="[getClassItem(screen['icon']), ((screen['url'] === getCurrentUrl()) || (screen['url'] == '/home' && getCurrentUrl() == '/')) ? 'clicked' : '']"></i>
          <span [attr.id]="screen.slug" [ngClass]="((screen['url'] === getCurrentUrl()) || (screen['url'] == '/home' && getCurrentUrl() == '/')) ? 'clicked' : ''" >
            {{ getLanguageParam(screen['name']) }}          
          </span>
        </div>

        <div class="submodule"
          [ngStyle]="{ 'display': 'none'}"
          *ngIf="getSystemPermission(module['slug'], screen['slug'], 'screen_permission') && screen['slug'] == 'settings'" >
          <!-- <div class="circle"></div> -->
          <!-- <i [ngClass]="[getClassItem(screen['icon']), ((screen['url'] === getCurrentUrl()) || (screen['url'] == '/home' && getCurrentUrl() == '/')) ? 'clicked' : '']"></i> -->
          <span [attr.id]="screen.slug" [ngClass]="((screen['url'] === getCurrentUrl()) || (screen['url'] == '/home' && getCurrentUrl() == '/')) ? 'clicked' : ''" >
            {{ getLanguageParam(screen['name']) }}
          </span>
          <ng-container *ngFor="let sub of screen.screens">
            <div class="screen sub" (click)="navigate(sub['url'])"

            *ngIf="getSystemPermission(module['slug'], sub['slug'], 'screen_permission')"
            >
              <div class="circle"></div>
              <i [ngClass]="[getClassItem(sub['icon']), ((sub['url'] === getCurrentUrl())) ? 'clicked' : '']"></i>
              <span [attr.id]="sub.slug" [ngClass]="((sub['url'] === getCurrentUrl())) ? 'clicked' : ''" >
                {{ getLanguageParam(sub['name']) }}
              </span>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="bottom tag">
      <span>UI: {{ versions?.version_ui }}</span>
      <span>API: {{ versions?.version_api }}</span>
    </div>
    <div class="bottom">
      <img src="../../../assets/logo.png" class="img">
      <span [ngStyle]="isOpen ? { 'display': 'flex!important'} : { 'display': 'none!important'} "> <img class="title-img" src="../../../assets/title.png"> </span>
    </div>
  </div>
</div>
