<div class="all-content">
   <div class="top">
        <!-- <div class="toggle"></div> -->
        <div class="button-top-right">
            <button class="save" (click)="conclude()">
                {{ getLanguages('save') }}
            </button>
            <button class="save cancel" (click)="conclude()">
                {{ getLanguages('cancel') }}
            </button>
            <i class="fas fa-xmark" (click)="exitModal()"></i>
        </div>
    </div>
    <div class="row">
        <span class="title">
            {{ getLanguages('configurations/title') }}
        </span>
    </div>

    <div class="row">
        <div class="item item-1">
            <span class="title-item">{{ getLanguages('configurations/general') }}:</span>
        </div>
    </div>

    <div class="row">
        <div class="item" [ngClass]="getSystemPermission('administrator', 'environments', 'create') ? 'item-3' : 'item-2'" >
            <span class="title-item">{{ getLanguages('configurations/language') }}</span>
            <button class="button-menu" [matMenuTriggerFor]="languageMenu">
                <span>
                    {{ selectedLanguage?.toUpperCase() }}
                </span>
                <i class="fa-solid fa-caret-down"></i>
            </button>
    
            <mat-menu #languageMenu="matMenu">
                <div class="menu">
                    <!-- <div class="input" (click)="$event.stopPropagation()">
                    <input type="text" [(ngModel)]="searchProcessOwner" placeholder="{{ getLanguages('search') }}">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    </div> -->
                    <div class="scroll">
                    <ng-container *ngFor="let language of languageArray">
                        <!-- <ng-container *ngIf="processOwner.name.toLowerCase().includes(searchProcessOwner.toLowerCase())"> -->
                        <div class="item-menu" (click)="choseItemSelect('language', language)">
                            <span [ngClass]="language.lang == selectedLanguage ? 'valid-span' : 'invalid-span'">
                            {{ language.lang.toUpperCase() }} 
                            </span>
                        </div>
                        <!-- </ng-container> -->
                    </ng-container>
                    </div>
                </div>
            </mat-menu>
        </div>

        <div class="item item-3" *ngIf="getSystemPermission('administrator', 'environments', 'create')">
            <span class="title-item">{{ getLanguages('configurations/environment') }}</span>
            <button class="button-menu" [matMenuTriggerFor]="environmentMenu">
                <span>
                    {{ selectedEnvironment?.name }}
                </span>
                <i class="fa-solid fa-caret-down"></i>
            </button>
    
            <mat-menu #environmentMenu="matMenu">
                <div class="menu">
                    <div class="input" (click)="$event.stopPropagation()">
                        <input type="text" [(ngModel)]="searchEnvironment" placeholder="{{ getLanguages('search') }}">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div class="scroll">
                    <ng-container *ngFor="let environment of environmentArray">
                        <ng-container *ngIf="environment.name.toLowerCase().includes(searchEnvironment.toLowerCase())">
                            <div class="item-menu" (click)="choseItemSelect('environment', environment)">
                                <span [ngClass]="environment?.uuid == selectedEnvironment?.uuid ? 'valid-span' : 'invalid-span'">
                                {{ environment.name }} 
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                    </div>
                </div>
            </mat-menu>
        </div>

        <div class="item" [ngClass]="getSystemPermission('administrator', 'environments', 'create') ? 'item-3' : 'item-2'" >
            <span class="title-item">{{ getLanguages('configurations/corporation') }}</span>
            <button class="button-menu" [matMenuTriggerFor]="corporationMenu">
                <span>
                    {{ selectedCorporation?.name }}
                </span>
                <i class="fa-solid fa-caret-down"></i>
            </button>
    
            <mat-menu #corporationMenu="matMenu">
                <div class="menu">
                    <div class="input" (click)="$event.stopPropagation()">
                        <input type="text" [(ngModel)]="searchCorporation" placeholder="{{ getLanguages('search') }}">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div class="scroll">
                    <ng-container *ngFor="let corporation of corporationArray">
                        <ng-container *ngIf="corporation.name.toLowerCase().includes(searchCorporation.toLowerCase())">
                            <div class="item-menu" (click)="choseItemSelect('corporation', corporation)">
                                <span [ngClass]="corporation?.uuid == selectedCorporation?.uuid ? 'valid-span' : 'invalid-span'">
                                {{ corporation.name }} 
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>

     <!-- <div class="row content">
        <div class="div-table">
            <p-table [value]="getRows()" class="table">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="td-center-auto">

                        </th>
                        <th>
                            <span class="columnTitle"> {{ getLanguages('name') }} </span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-element let-rowIndex="rowIndex">
                    <tr *ngIf="!element.is_delete">
                        <td class="td-center-auto">
                            <mat-checkbox
                                class="checkbox"
                                (click)="$event.stopPropagation(); setSelection(element)"
                                [color]="task.color"
                                [checked]="getSelection(element)"
                                >
                            </mat-checkbox>
                        </td>
                        <td>
                            <input [disabled]="element.in_use" class="columnText" type="text" [(ngModel)]="element.name">
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </div>
        <div class="div-paginator">
            <div class="paginator">
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
                </span>
                <button [matMenuTriggerFor]="menu" class="itens-per-page">
                    {{ getItemsPerPage()}}
                </button>
                <mat-menu #menu="matMenu" >
                  <button mat-menu-item (click)="choseItemsPage(5)">5</button>
                  <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                  <button mat-menu-item (click)="choseItemsPage(20)">20</button>
                </mat-menu>
                <span>
                      {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
                  </span>
                <span class="totalPages">
                      {{ getTotalNumberPages() }}
                  </span>
                <button
                  [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() === 1"
                  (click)="setCurrentPages(getCurrentPages() - 1); getDisplayedRows();">
                  <i class="fa-solid fa-angle-left"></i>
                </button>
                <input type="number" disabled [ngModel]="getCurrentPages()" (blur)="getTotalPages()">
                <button
                  [ngClass]="getCurrentPages() >= getTotalNumberPages()  ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() >= getTotalNumberPages()"
                  (click)="setCurrentPages(getCurrentPages() + 1);getTotalPages();">
                  <i class="fa-solid fa-angle-right"></i>
                </button>
            </div>
        </div>
    </div> -->

</div>


