import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { EnvironmentsService } from "../../../../services/environments/environments.service";
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { stringify } from 'circular-json';
import { v4 } from 'uuid';
import { FunctionService } from 'src/app/services/function.service';

@Component({
  selector: 'app-create-instance',
  templateUrl: './create-instance.component.html',
  styleUrls: ['./create-instance.component.scss']
})
export class CreateInstanceComponent {
  @Input() properties: any = {}

  @Output() toggleInstanceCreateModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getInstances: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('fileInput') fileInput!: ElementRef;


  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  statusArray = [ { value: true , label: {en: 'Active', pt: 'Ativo'} }, { value: false , label: {en: 'Inactive', pt: 'Inativo'}}]
  selectedStatus: any =  {};

  saveEnvironment: any = {
    name: '',
    acronym: '',
    is_active: false
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  displayedImage: any;

  formData: FormData = new FormData()
  file: any = null
  showCrop = false;
  isLoading: boolean = false

  /**
   *
   * @param cookieService
   * @param environmentService
   */
  constructor(
    private cookieService: CookieService,
    private environmentService: EnvironmentsService,
    private sharedData: FunctionService
  ) {

  }

  /**
   *
   */
  async ngOnInit(){

  }

  /**
   *
   * @param file
   */
  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    if(!to64decode(localStorage.getItem('language'))?.lang){
      return ''
    }
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  /**
   *
   * @param status
   */
  choseStatus(status: any){
    this.selectedStatus.name = status.value ? {en: 'Active', pt: 'Ativo'} : {en: 'Inactive', pt: 'Inativo'}
    this.saveEnvironment.is_active = status.value
  }

  /**
   *
   * @param hide
   */
  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.getInstances.emit()
    this.toggleInstanceCreateModal.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  /**
   *
   */
  classValidation() {
    let inputs: any = []

    if (!this.saveEnvironment.name) {
      inputs.push(getLanguageCookie('instances/content/table/name', localStorage.getItem('language')))
    }

    if (this.selectedStatus.name == null) {
      inputs.push(getLanguageCookie('instances/content/table/status', localStorage.getItem('language')))
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false;
    }
    return true
  }

  /**
   *
   */
  async setEnvironment(){
    this.isLoading = true;

    try {
      if (!this.classValidation()) {
        return;
      }
      await this.setFormData()
      const response = await this.environmentService.setEnvironment(this.formData)
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message);
        this.exitModal()
      } else {
        addAlert('danger', response.message);
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

    this.isLoading = false;

  }

  fileChangeEvent(event: any): void {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const fileSize = fileInput.files[0].size;
      const maxSizeInBytes = 2 * 1024 * 1024;

      if (fileSize > maxSizeInBytes) {
        addAlert('orange', this.getLanguages('error/maxSizeBytes'))
        fileInput.value = '';

        return
      }
    }


    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    const file = event.target.files[0];

    if (!allowedFormats.includes(file.type)) {
      addAlert('orange', this.getLanguages('error/onlyPng'))
      return;
    }

    this.imageChangedEvent = event;
    this.showCrop = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  saveAndClose(flag: boolean = true){
    this.showCrop = false;

    if(!flag){
      return
    }

    this.displayedImage = URL.createObjectURL(this.croppedImage.blob);


    const blob = this.croppedImage.blob;
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(blob);
    fileReader.onload = () => {
      let arrayBuffer = fileReader.result as ArrayBuffer;
      this.file = new File([arrayBuffer], `${v4()}.png`, { type: 'image/png' })
    };
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  getName(){
    return this.properties.environment.name.substring(0, 2).toUpperCase()
  }

  async setFormData() {
    this.formData = new FormData()
    this.formData.append('body', stringify(this.saveEnvironment))
    this.formData.append('file', this.file)
  }

}
