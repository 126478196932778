import { Component, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { v4 } from "uuid";
import { addAlert } from "../../../../layouts/pages/pages.component";

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['name','action'];
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  is_new_rule: boolean = true;

  currentRule: any = {}


  constructor(
    private cookieService: CookieService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    this.dataSource.data = this.parameters.item.rules ?? []
    this.newRule();
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isData(){
    return this.dataSource.data.length > 0
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dataSource.data.slice(startIndex, endIndex);
  }

  getColumns(){
    return this.displayedColumns.slice(1)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.currentPage = newPage;
  }

  getTotalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.itemsPerPage);
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  async selectRule(item: any){
    this.currentRule = { ...item }
    this.is_new_rule = false;
  }

  async newRule(){
    this.currentRule = {
      uuid: v4(),
      name: '',
      description: ''
    };

    this.is_new_rule = true;
  }

  async saveRule(){
    let inputs: any = []

    if(!this.parameters.isEdit){
      return
    }

    if (!this.currentRule.name || this.currentRule.name == '') {
      inputs.push('Name')
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return
    }

    if(this.is_new_rule){
      this.dataSource.data.push(this.currentRule)
    }else{
      this.dataSource.data.find((item: any, index: number) => {
        if(item.uuid == this.currentRule.uuid){
          this.dataSource.data[index] = this.currentRule
        }
      })
    }

    await this.newRule()
  }

  async deleteRule(uuid: string) {
    this.dataSource.data = this.dataSource.data.filter(item => item.uuid !== uuid);
    this.parameters.item.rules = this.dataSource.data
    await this.newRule()
  }
}
