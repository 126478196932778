<div class="all-content">
    <div class="top">
      <div class="title">
        {{ parameters.isCreate ? getLanguages('project_guideline/add') : parameters.isEdit ? getLanguages('project_guideline/edit') : getLanguages('project_guideline/view')}}
      </div>
  
      <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="buttons">
      <div class="save" (click)="setTechnologiesTree()" *ngIf="(isEdit || parameters.isCreate) && getSystemPermission('operation', 'business_solutions', 'update')">
        {{ getLanguages('save')}}
      </div>
      <div class="save" [ngClass]="isEdit? 'edit' : '' " (click)="editBusinessTree()" *ngIf="!parameters.isCreate && getSystemPermission('operation', 'business_solutions', 'update')">
        {{ isEdit ? getLanguages('cancel') : getLanguages('edit') }}
      </div>
    </div>
  
    <div class="row">
        <div class="item item-2"> 
            <span> {{ getLanguages('project_guideline/name') }} </span>
            <input [disabled]="!parameters.isEdit" type="text" [(ngModel)]="parameters.item.label">
        </div>
        <div class="item item-2"> 
            <span> {{ getLanguages('project_guideline/type') }} </span>
            <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="typeMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
                <span>
                  {{ getDisplayName() }}
                </span>
                <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
            </button>
    
            <mat-menu #typeMenu="matMenu">
              <div class="menu">
                <div class="input" (click)="$event.stopPropagation()">
                  <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll" *ngIf="typeArray.length > 0">
                  <ng-container *ngFor="let type of typeArray; let i = index">
                    <ng-container *ngIf="shouldUseGetNames(type) ? matchesSearch(getNames(type.name), searchType) : matchesSearch(type.name, searchType)">
                      <div class="item-menu" (click)="choseItemSelect('type', type)">
                        <span [ngClass]="shouldUseGetNames(type) ? (getNames(type.name) === getNames(selectedType.name) ? 'valid-span' : 'invalid-span') : (type.name === selectedType.name ? 'valid-span' : 'invalid-span')">
                          {{ shouldUseGetNames(type) ? getNames(type.name) : type.name }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>                  
                </div>
              </div>
            </mat-menu>
            
          
          
        </div>
    </div>

    <div class="row">
        <div class="item item-1"> 
            <span> {{ getLanguages('project_guideline/objective') }} </span>
            <textarea [disabled]="!parameters.isEdit" [(ngModel)]="parameters.item.objective"></textarea>
        </div>
    </div>

    <div class="row" *ngIf="!parameters.is_new">
        <div class="item item-2"> 
            <span> {{ getLanguages('businessSolution/modal/addCode/userCreated') }} </span>
            <button class="button-menu disabled" disabled>
                <span>
                  {{ parameters.item?.userCreated }}
                </span>
              </button>
        </div>
        <div class="item item-2"> 
            <span> {{ getLanguages('businessSolution/modal/addCode/created_at') }} </span>

            <button class="button-menu disabled" disabled>
                <span>
                  {{ parameters.item?.created_at }}
                </span>
            </button>
        </div>
    </div>

    <div class="row" *ngIf="!parameters.is_new">
        <div class="item item-2"> 
            <span> {{ getLanguages('businessSolution/modal/addCode/userUpdated') }} </span>
            <button class="button-menu disabled" disabled>
                <span>
                    {{ parameters.item?.userUpdated }}
                </span>
            </button>
        </div>
        <div class="item item-2"> 
            <span> {{ getLanguages('businessSolution/modal/addCode/updated_at') }} </span>            
            <button class="button-menu disabled" disabled>
                <span>
                  {{ parameters.item?.updated_at }}
                </span>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="item item-2"> 
            <span> {{ getLanguages('project_guideline/iterative') }} </span>
            <input [disabled]="!parameters.isEdit" type="checkbox" id="checkbox" (click)="parameters.item.is_iterative = !parameters.item.is_iterative" [(ngModel)]="parameters.item.is_iterative" [ngClass]="{'valid-check' : parameters.item.is_iterative, 'pointer' : parameters.isEdit}">
        </div>
        <div class="item item-2"> 
            <span> {{ getLanguages('project_guideline/active') }} </span>            
            <input [disabled]="!parameters.isEdit" type="checkbox" id="checkbox" (click)="parameters.item.is_active = !parameters.item.is_active" [(ngModel)]="parameters.item.is_active" [ngClass]="{'valid-check' : parameters.item.is_active, 'pointer' : parameters.isEdit}">
        </div>
    </div>
</div>


<div class="background-modals" *ngIf="createType">
    <div class="modal-create-type">
      <div class="modal-create-top">
        <div class="modal-create-toggle"></div>
  
        <div class="div-icon-button">
          <div class="save" (click)="setGuidelineProjectType()">
            {{ getLanguages('save')}}
          </div>
          <i class="fas fa-xmark" (click)="createType = false"></i>
        </div>
      </div>
  
      <div class="modal-create-row">
        <div class="modal-create-title">
          {{ getLanguages('project_guideline/title_new_type') }}
        </div>
      </div>
  
      <div class="modal-create-row">
        <div class="modal-create-item">
          <span> {{ getLanguages('project_guideline/new_type') }}</span>
          <input type="text" [(ngModel)]="newType.name">
        </div>
      </div>
    </div>
  </div>
