<div class="all-content">
    <div [ngClass]="!loading ? 'title': 'no-title' ">
        {{ getTitle() }} 
    </div>
    <section *ngIf="!loading">
        <div class="arrow">
            <i class="fas fa-chevron-left" (click)="scrollLeft()"></i>
        </div>
        <div class="carroussel" #scrollContainer>
            <ng-container *ngFor="let item of cardArray">
                <div class="card" (click)="flipCard(item)">
                    <div [ngClass]="item.flip ? 'flip' : ''" id="card">
                        <div class="front face" [ngStyle]="!item.flip ? {'z-index': '101', 'transition': '1s'} : {'z-index': '100', 'transition': '1s'}">
                            <div class="value">
                                {{ item.value }}
                            </div>

                            <div class="title">
                                {{ getNames(item.name) }}
                            </div>

                            <div class="icon-ai">
                                <span class="i">
                                    ai
                                </span>
                            </div>
                        </div>
                        <div class="back face"  [ngStyle]="item.flip ? {'z-index': '101', 'transition': '1s'} : {'z-index': '100', 'transition': '1s'}">
                            <ng-container *ngIf="type">
                                <div class="value">
                                    {{ item.value }}
                                </div>
                                <div class="title">
                                    {{ getNames(item.name) }}
                                </div>
                                <div class="icon-ai">
                                    <span class="i">
                                        ai
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!type">
                                <div class="value">
                                    {{ item.back ? item.back.value : '' }}
                                </div>
    
                                <div class="title">
                                    {{ getNames(item.back ? item.back.name : '') }}                            
                                </div>
                                <div class="icon-ai">
                                    <span class="i">
                                        ai
                                    </span>
                                </div>
                            </ng-container>
                           
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="arrow">
            <i class="fas fa-chevron-right" (click)="scrollRight()"></i>
        </div>
    </section>
    
    <section *ngIf="loading">
        <div class="arrow">
            <i class="fas fa-chevron-left"></i>
        </div>
        <div class="carroussel">
            <ng-container *ngFor="let item of cardNoArray">
                <div class="no-card">
                    
                </div>
            </ng-container>
        </div>
        <div class="arrow">
            <i class="fas fa-chevron-right"></i>
        </div>
    </section>
</div>
