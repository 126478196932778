import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import {IResponse} from "../../interfaces/IResponse";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstancesService {
  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // outros cabeçalhos personalizados aqui, se necessário
    })
  };

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) {
      this.API_PATH = environment.apiUrl;
  }

  /**
   *
   * @param error
   */
  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return null;
  }

  /**
   *
   * @param route
   */
  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  /**
   *
   */
  // async getInstances () {
  //   try {
  //     return await this.httpClient.get<IResponse>(
  //       `${this.API_PATH}environments`
  //     ).toPromise();
  //   } catch (error: any) {
  //     return await this.redirectError(error)
  //   }
  // }

  getInstances(): Observable<IResponse> {
    const url = `${this.API_PATH}environments`;

    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

  async setInstances () {
    try {
      return await this.httpClient.get<IResponse>(
        `${this.API_PATH}environments`
      ).toPromise();
    } catch (error: any) {
      return await this.redirectError(error)
    }
  }
}
