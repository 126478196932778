import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { EventService } from 'src/app/services/event/event.service';
import { getLanguageCookie } from 'src/assets/language';
import { InstancesService } from "../../services/instances/instances.service";
import { formatDate } from "@angular/common";
import { maskDate } from "../../helpers/formats";
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync } from 
 "../../helpers/cookieToJson";
import {apiOffline, apiOfflineAsync} from "../../layouts/pages/pages.component";
import { FunctionService } from 'src/app/services/function.service';
@Component({
  selector: 'app-instances',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.scss']
})
export class InstancesComponent {
  properties: any = {}
  parameters: any = {}

  noData: boolean = false;
  isLoading: boolean = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private subscription: Subscription = new Subscription();

  currentPage = 1;
  itemsPerPage = 10;

  isShowInstanceCreate: boolean = false;
  isShowInstanceEdit: boolean = false;
  isShowInstanceUser: boolean = false;

  task: any = {
    color:  'accent'
  };

  statusArray: any = [
    {id: 1, name: 'ACTIVE'},
    {id: 2, name: 'INACTIVE'},
  ]

  searchText: string = '';

  dataSource: any;
  filteredDatasource: any;

  displayedColumns: string[] = ['Status', 'Name', 'Activate On'];

  /**
   *
   * @param cookieService
   * @param eventService
   * @param instancesService
   * @param activatedRoute
   */
  constructor(
    private cookieService: CookieService,
    private eventService: EventService,
    private instancesService: InstancesService,
    private activatedRoute: ActivatedRoute,
    public sharedData: FunctionService
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.filteredDatasource = new MatTableDataSource([]);

    this.eventService.eventModalCreateInstance.subscribe((param) => {
      // this.parameters = param;
      this.toggleInstanceCreateModal();
    });

    this.eventService.eventModalEditInstance.subscribe((param) => {
      // this.parameters = param;
      this.toggleInstanceEditModal();
    });

    this.eventService.eventModalUserInstance.subscribe((param) => {
      // this.parameters = param;
      this.toggleInstanceUserModal();
    });
  }

  /**
   *
   */
  async ngOnInit(){
    await this.getProperties()
    await this.getInstances();
  }

  /**
   *
   */
  async getProperties () {
    this.properties = await localStorageToJsonAsync()
  }

  /**
   *
   * @param file
   */
  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  /**
   *
   */
  async getInstances() {
    this.dataSource = new MatTableDataSource<any>();
    this.isLoading = true

    this.subscription.add(
      this.instancesService.getInstances()
        .subscribe(
          (response: any) => {
            if (response && response.data) {
              this.dataSource = new MatTableDataSource<any>(response.data)
              this.filteredDatasource = new MatTableDataSource<any>(response.data)
              this.sharedData.triggerGetUserInstanceReload();
            }else {
              throw new Error('A resposta não contém o campo "data"');
            }
          },
          (error) => {
            apiOffline(error)
          }
        )
    );
    this.isLoading = false
  }

  /**
   *
   * @param number
   */
  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  /**
   *
   * @param newPage
   */
  onPageChange(newPage: number): void {
    this.currentPage = newPage;
  }

  /**
   *
   */
  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredDatasource.data.slice(startIndex, endIndex);
  }

  /**
   *
   */
  getTotalPages() {
    return Math.ceil(this.filteredDatasource.data.length / this.itemsPerPage);
  }

  /**
   *
   * @param event
   */
  async checkValue(event: any) {
    let total = this.getTotalPages()

    if (this.currentPage > total) {
      this.currentPage = total;
    }
  }

  toggleInstanceCreateModal(){
    this.isShowInstanceCreate = !this.isShowInstanceCreate;
  }

  toggleInstanceEditModal(data: any = {}){
    if(!systemPermissions(localStorage.getItem('permissions'), 'administrator', 'environments', 'update')){
      return
    }
    this.parameters.environment = data
    this.isShowInstanceEdit = !this.isShowInstanceEdit;
  }

  toggleInstanceUserModal(data: any = {}){

    if(!this.getSystemPermission('administrator', 'environments', 'enlace_environment_user')){
      return
    }

    this.parameters.environment = data
    this.isShowInstanceUser = !this.isShowInstanceUser;
  }

  searchInstance() {
    // this.applyFilter();
    const searchText = this.searchText.toLowerCase()
    this.filteredDatasource.data = this.dataSource.data.filter( (item: { name: any;}) => item.name.toLowerCase().includes(searchText));
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(localStorage.getItem('permissions'), module, screen, action)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }
}
