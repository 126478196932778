import {cookieToJson} from "../app/helpers/cookieToJson";
import {to64decode} from "../app/helpers/base64";
import { BusinessTreeComponent } from "src/app/pages/business-tree/business-tree.component";

export function getLanguageParam(name: any, language: any) {
  language = to64decode(language)
  return name[language.lang]
}

export function getLanguageCookie(file: string, language: any, getObject: boolean = false) {
    language = to64decode(language)

    let lang: any = 'en'
    if (language && language.lang) {
        lang = language.lang
    }

    return getLanguage(file, lang, getObject)
    }

export function getLanguage(file: string, lang: string = 'en', getObject: boolean = false){
    let split = file.split('/')

    let result = languages

    for(let i = 0; i < split.length; i++) {
        result = result[split[i]]
    }

    return getObject ? result : result[lang];
}

const languages: any =  {

    title: {
        en: 'OnePage',
        pt: 'OnePage',
    },

    null: {
        en: '',
        pt: ''
    },

    conclude: {
        en: "Conclude",
        pt: "Concluir"
    },

    loading: {
        en: 'Loading',
        pt: 'Carregando'
    },

    changeTo: {
        en: 'Changing to ',
        pt: 'Migrando para '
    },

    select: {
        en: 'Select',
        pt: 'Selecione'
    },

    search: {
        en: 'Search',
        pt: 'Pesquisar'
    },

    new: {
        en: 'New',
        pt: 'Novo'
    },

    date: {
        month_year: {
            en: 'month/year',
            pt: 'mês/ano'
        },
    },

    error: {
        onlyPng: {
            en: "Only PNG, JPG and JPEG is accepted",
            pt: "Apenas PNG, JPG e JPEG são aceitos"
        },
        maxSizeBytes: {
            en: 'Image size too large!',
            pt: 'Tamanho da imagem muito grande!',
        },
        invalidStartDate: {
            en: 'Invalid start date!',
            pt: 'Data inicial inválida!'
        }
    },

    statusSystem: {
        en: 'System status',
        pt: 'Status do sistema'
    },

    mandatoryField: {
        en: 'Please fill in the required fields: ',
        pt: 'Por favor preencha os campos obrigatórios: '
    },

    without_permission: {
      en: 'No permission to access!',
      pt: 'Sem permissão para acessar!',
    },

    filter: {
        en: "Filter",
        pt: "Filtrar"
    },

    clear: {
        en: "Clear",
        pt: "Limpar"
    },

    ai: {
        info: {
            en: 'Description to be read by AI',
            pt: 'Descrição que será lida pelo IA'
        }
    },

    soon: {
        en: 'Soon we will have information here.',
        pt: 'Em breve teremos informação aqui'
    },

    home: {
        enterprise: {
            en: "Enterprise",
            pt: "Empresarial"
        },
        journey: {
            en: "Journeys",
            pt: "Jornadas"
        },
        analytical: {
            en: 'Analytical',
            pt: 'Analítico'
        },
        core: {
            en: "Core",
            pt: "Essencial"
        },
        management: {
            en: "Management",
            pt: "Gerencial"
        },
        corporate: {
            en: "Corporate",
            pt: "Corporativo"
        }
    },

    maturity: {
        title: {
            en: 'Maturity questions',
            pt: 'Perguntas de maturidade'
        },
        active: {
            en: 'Active',
            pt: 'Ativo'
        },
        new: {
            en: 'New question',
            pt: 'Nova pergunta'
        }
    },

    attachment: {
        extension: {
            en: 'Extension',
            pt: 'Extensão'
        },
        surname: {
            en: 'Nickname',
            pt: 'Apelido'
        },
        name: {
            en: 'Name',
            pt: 'Nome'
        },
        action: {
            en: 'Actions',
            pt: 'Ações'
        },
        tab: {
            en: 'Tab',
            pt: 'Aba'
        },
        type: {
            en: 'Type',
            pt: 'Tipo',
            
            configuration: {
                en: 'Configuration', 
                pt: 'Como configurar'
            },
            solution_design: {
                en: 'Solution Design', 
                pt: 'Desenho da solução'
            },
            test_plan: {
                en: 'Test Plan', 
                pt: 'Plano de testes'
            },
            approval_criteria: {
                en: 'Approval Criteria', 
                pt: 'Critérios de homologação'     
            }
        },

    },

    delete: {
        en: 'Delete',
        pt: 'Deletar',

        title: {
            en: 'Are you sure you want to delete?',
            pt: 'Tem certeza que deseja excluir?'
        },
        confirm: {
            en: 'Confirm',
            pt: 'Confirmar'
        },
        cancel: {
            en: 'Cancel',
            pt: 'Cancelar'
        },
        persona: {
            en: "All of that personas FTE values ​​will be removed from the business tree!",
            pt: 'Todos os valores de FTE dessa persona serão removidos da árvore de negócios!'
        },
        technologies: {
            en: "Deleting technology impacts the technology tab in the business tree",
            pt: "Apagar tecnologia impacta na aba de tecnologia na árvore de negócios"
        },
        clone: {
            en: 'When deleting, all clones will be deleted',
            pt: 'Ao deletar, todos os clones serão excluídos'
        }
    },

    save: {
        en: 'Save',
        pt: 'Salvar'
    },

    saveAs: {
        en: 'Save as new version',
        pt: 'Salvar como uma nova versão'
    },

    edit: {
        en: 'Edit',
        pt: 'Editar'
    },

    cancel: {
        en: 'Cancel',
        pt: 'Cancelar'
    },

    create: {
        en: 'Create',
        pt: 'Criar'
    },

    name: {
        en: 'Name',
        pt: 'Nome'
    },

    link: {
        en: 'Link',
        pt: 'Link'
    },

    versions: {
        current: {
            en: 'Current',
            pt: 'Atual'
        },
        name: {
            en: 'Name',
            pt: 'Nome'
        },
        last_change: {
            en: 'Last change',
            pt: 'Última modificação'
        },
        delete: {
            en: 'Delete',
            pt: 'Deletar'
        },
        actions: {
            en: 'Actions',
            pt: 'Ações'
        },
        create:{
            name: {
                en: "Create version name",
                pt: "Criar nome para versão"
            }
        },
        edit:{
            name: {
                en: "Edit version name",
                pt: "Editar nome para versão"
            }
        },
    },

    activities: {
        user: {
            en: 'User',
            pt: 'Usuário'
        },
        profile: {
            en: 'Profile',
            pt: 'Perfil'
        },
        screen: {
            en: 'Screen',
            pt: 'Tela'
        },
        action: {
            en: 'Action',
            pt: 'Ação'
        },
        environment: {
            en: 'Environment',
            pt: 'Ambiente'
        },
        corporation: {
            en: 'Organization',
            pt: 'Organização'
        },
        principal: {
            en: 'Principal',
            pt: 'Principal'
        },
        date: {
            en: 'Date',
            pt: 'Data'
        },
        time: {
            en: 'Time',
            pt: 'Horário'
        },
        description: {
            en: 'Description',
            pt: 'Descrição'
        }
    },

    corporations:{
        en: 'Organizations',
        pt: 'Organizações',
        tree: {
            en: 'Organization tree',
            pt: 'Árvore de organizações'
        },
        info: {
            businessSolutions: {
                en: 'Business tree',
                pt: 'Árvore de negócio'
            },
            personas: {
                en: 'Personas',
                pt: 'Personas'
            },
            dimension: {
                en: 'Dimension',
                pt: 'Dimensão'
            },
            edit: {
                en: 'Edit',
                pt: 'Editar'
            },
            newCorporation: {
                en: 'New corporation',
                pt: 'Nova corporação'
            },
            delete: {
                en: 'Delete',
                pt: 'Deletar'  
            }
        }
    },

    auth: {
        login: {
            en: 'Login',
            pt: 'Entrar',

            email: {
                en: 'E-mail',
                pt: 'Email'
            },
            password: {
                en: 'Password',
                pt: 'Senha'
            },
            forgot:{
                en: 'Forgot password?',
                pt: 'Esqueceu a senha?'
            },
            create: {
                en: 'Create account',
                pt: 'Criar conta'
            },
        },
        forgot: {
            en: 'Forgot Password',
            pt: 'Esqueceu a senha',
            sub: {
                en: 'Enter your email address in the field below',
                pt: 'Digite seu endereço de e-mail no campo abaixo'
            },
            type: {
                en: 'Type your email',
                pt: 'Digite seu email'
            },
            send: {
                en: 'Send',
                pt: 'Enviar'
            }
        },
        create: {
            en: 'Register',
            pt: 'Cadastre-se',

            send: {
                en: 'Request registration',
                pt: 'Solicitar registro'
            },
            name: {
                en: 'Name',
                pt: 'Nome'
            },
            lastName: {
                en: 'Last Name',
                pt: 'Último Nome'
            },
            email: {
                en: 'E-mail',
                pt: 'Email'
            },
            document: {
                en: 'Document',
                pt: 'Documento'
            },
            telephone: {
                en: 'Phone',
                pt: 'Telefone'
            },
            login: {
                en: 'Have an account? ',
                pt: 'Possui uma conta? '
            }
        },
        password : {
            en: 'Update Password',
            pt: 'Alterar Senha',

            send: {
                en: 'Update',
                pt: 'Alterar',
            },
            password: {
                en: 'Password',
                pt: 'Senha',
            },
            confirm_password: {
                en: 'Confirm Password',
                pt: 'Confirmar Senha',
            },
            letters: {
                en: 'Password must contain letters',
                pt: 'Senha deve possuir letras',
            },
            numbers: {
                en: 'Password must contain numbers',
                pt: 'Senha deve possuir números',
            },
            lenght: {
                en: 'Password must contain 6 to 12 characters',
                pt: 'Senha deve ter entre 6 e 12 caracteres',
            },
            same: {
                en: 'Password must be the same',
                pt: 'Senhas devem ser iguais',
            }
        },
        alert:{
            password: {
                en: 'Incorrect mail or password',
                pt: 'Usuário ou senha incorretos'
            },
            connection: {
                en: 'Connection error',
                pt: 'Erro de conexão'
            },
            voidInput: {
                en: 'Fill in all fields *',
                pt: 'Preencha todos os campos *'
            }, 
            isEdited: {
                en: "You have changes to the tree, save before exiting",
                pt: "Você tem alterações na árvore, salve antes de sair"
            },
            closeForm: {
                en: "Do you really want to exit without saving? This means losing the changes made",
                pt: "Você realmente deseja sair sem salvar? Isso implica em perder as alterações feitas"
            },
            saveAndClose: {
                en: "Save and close",
                pt: "Salvar e sair"
            },
            closeNotSave: {
                en: "Exit without saving",
                pt: "Sair sem salvar"
            },
            choseVersion: {
                en: "Do you want to save a new version? When you save a new version, it will become the official version that will be displayed in the Business Tree",
                pt: "Deseja salvar uma nova versão? Ao salvar uma nova versão, ela se tornará a versão oficial que será exibida na Árvore de Negócios"
            }
        },
    },

    slugs: {
        environment: {
            en: "Environments",
            pt: "Ambientes"
        },
        corporation: {
            en: "Organizations",
            pt: "Corporações"
        },
        profile: {
            en: "Profiles",
            pt: "Perfis"
        },
        user: {
            en: "Users",
            pt: "Usuários"
        },
        screen: {
            en: "Screens",
            pt: "Telas"
        },
        action: {
            en: "Actions",
            pt: "Ações"
        },
        date: {
            en: "Date",
            pt: "Data"
        }
    },

    errors: {
      system: {
        out: {
          en: 'System unavailable!',
          pt: 'Sistema indisponível!'
        }
      },
      businessTree: {
        create: {
            en: 'Process not created successfully',
            pt: 'Processo não criado com sucesso'
        },
        edit: {
            en: 'Process not edited successfully',
            pt: 'Processo não editado com sucesso'
        },
        delete: {
            en: 'Process not deleted successfully',
            pt: 'Processo não deletado com sucesso'
        },
        SameNameElement: {
            en: 'Sibling elements with the same name',
            pt: "Elementos irmãos com mesmo nome"
        }
      },
      noData: {
        en: 'There is no Data',
        pt: 'Não foram encontrados dados'
      }
    },

    messages: {
        businessTree: {
            create: {
                en: 'Process created successfully',
                pt: 'Processo criado com sucesso',
            }
        }
    },

    profiles: {
        modal: {
            create: {
                en: 'Create profile',
                pt: 'Criar perfil'
            },
            corporateName: {
                en: 'Corporate name',
                pt: 'Nome da corporação'
            },
            profileName: {
                en: 'Profile name',
                pt: 'Nome do perfil'
            },
            clone: {
                en: 'Clone profile',
                pt: 'Clonar perfil'
            },
            status: {
                en: 'Status',
                pt: 'Status'
            },
            description: {
                en: 'Description',
                pt: 'Descrição'
            },
            menu: {
                en: 'Menu',
                pt: 'Menu'
            }
        }
    },

    import: {
        title:{
            en: 'Import .xls or .xlsx',
            pt: 'Importar .xls ou .xlsx'
        },
        push: {
            en: 'Push',
            pt: 'Enviar'
        },
        model:{
            en: 'Download template',
            pt: 'Donwload template'
        },
        text: {
            en: 'Select the file to import',
            pt: 'Selecione o arquivo para importar'
        }

    },

    instances:{
        title: {
            en: 'Environments',
            pt: 'Ambientes'
        },
        content: {
            table: {
                name: {
                    en: 'Name',
                    pt: 'Nome',
                },
                status: {
                    en: 'Status',
                    pt: 'Status',
                },
                acronym: {
                    en: 'Acronyn',
                    pt: 'Sigla',
                },
                activateOn: {
                    en: 'Activate On',
                    pt: 'Ativo Em',
                }
            },
            image: {
                en: 'Image',
                pt: 'Imagem'
            },
            upload_image: {
                en: 'Upload image',
                pt: 'Upload de imagem'
            }
        },
        modal: {
            create: {
                title: {
                    en: 'Create Instance',
                    pt: 'Criar Instância'
                }
            },
            edit: {
                title: {
                    en: 'Edit Instance',
                    pt: 'Editar Instância'
                },
                remove: {
                    en: 'Delete Instance',
                    pt: 'Apagar Instância'
                }
            },
            user: {
                title: {
                    en: 'Association Users to Environments',
                    pt: 'Associação de Usuários com Ambientes'
                },
                instances: {
                    en: 'Environments',
                    pt: 'Ambientes'
                },
                register: {
                    en: 'Register',
                    pt: 'Registrar'
                },
                newUser: {
                    en: 'New user',
                    pt: 'Novo usuário'
                },
                nameInstance: {
                    en: 'Name Environments',
                    pt: 'Nome das Ambientes'
                },
                search: {
                    en: 'Search',
                    pt: 'Pesquisar'
                },
                id: {
                    en: 'ID',
                    pt: 'ID'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                profile: {
                    en: 'Profile',
                    pt: 'Perfil'
                },
                corporation: {
                    en: 'Organization',
                    pt: 'Organização'
                },
                telephone: {
                    en: 'Phone',
                    pt: 'Telefone'
                },
                email: {
                    en: 'Email',
                    pt: 'E-mail'
                },
                access: {
                    en: 'Access',
                    pt: 'Acesso'
                },
                viewUser: {
                    title: {
                        en: 'User',
                        pt: 'Usuário'
                    },
                    name: {
                        en: 'Name',
                        pt: 'Nome'
                    },
                    phone: {
                        en: 'Phone',
                        pt: 'Telefone'
                    },
                    email: {
                        en: 'Email',
                        pt: 'E-mail'
                    },
                    instances: {
                        en: 'Environments',
                        pt: 'Ambientes'
                    }
                }
            }
        },
    },

    pages: {
        menu: {
            dashboard: {
                en: 'Dashboard',
                pt: 'Painel'
            },
            businessSolution: {
                en: 'Business tree',
                pt: 'Árvore de negócio'
            },
            foundation: {
                en: 'Foundation',
                pt: 'Instituição',
            },
            corporation: {
                en: 'Organization',
                pt: 'Organização'
            },
            users: {
                en: 'Users',
                pt: 'Usuários'
            },
            profile: {
                en: 'Profiles',
                pt: 'Perfis'
            },
            adm: {
                en: 'Administrator',
                pt: 'Administrador'
            },
            instances: {
                en: 'Environments',
                pt: 'Ambientes'
            },
            standards: {
                en: "Process guideline",
                pt: "Guideline de processo"
            },
            personas: {
                en: 'People',
                pt: 'Pessoas',
                es: 'Personas',
            },
            technologies: {
                en: 'Technologies',
                pt: 'Tecnologias',
                es: 'Tecnologias',
            }
        },
        modules: {
            home: {
                en: 'Home',
                pt: 'Página inicial',
            },
            administrator: {
                en: 'Administrator',
                pt: 'Administrador',
            },
            register: {
                en: 'Register',
                pt: 'Registro',
            },
            settings: {
                en: 'Settings',
                pt: 'Configurações',
            },
            operation: {
                en: 'Operation',
                pt: 'Operação',
            },
        },
        screens: {
          administrator: {
            environments: {
              en: 'Environment',
              pt: 'Ambiente'
            },
            activities: {
              en: 'Activity log',
              pt: 'Log de atividades'
            },
            versioning: {
              en: 'Versioning',
              pt: 'Versionamento'
            },
            clipboard: {
              en: 'Clipboard',
              pt: 'Área de transferência'
            },
          },
          register: {
            corporations: {
              en: 'Organization',
              pt: 'Organização'
            },
            profiles: {
              en: 'Profile',
              pt: 'Perfis'
            },
            users: {
              en: 'Users',
              pt: 'Usuários'
            },
          },
          operation: {
            business_solutions: {
              en: 'Business tree',
              pt: 'Árvore de negócio'
            },
          },
          settings: {
            maturity: {
              en: 'Maturity',
              pt: 'Maturidade'
            },
            standards: {
                en: "Process guideline",
                pt: "Guideline de processo"
            }
          },
          home: {
            home: {
              en: 'Home',
              pt: 'Página inicial',
            },
          }
        },
        info: {
            language: {
                en: 'Choose Language',
                pt: 'Trocar Idioma'
            },
            instance: {
                en: 'Choose Instance',
                pt: 'Trocar Ambiente'
            },
            user: {
                en: 'User Config',
                pt: 'Configurações de Usuário'
            },
            config: {
                en: 'System Config',
                pt: 'Configurações do Sistema'
            }
        }
    },

    configurations: {
        title: {
            en: 'User settings',
            pt: 'Configurações de usuário'
        },
        general: {
            en: "General Settings",
            pt: "Configurações gerais"
        },
        language: {
            en: "Language",
            pt: "Idioma"
        },
        corporation: {
            en: "Favorite corporation",
            pt: "Organização favorita"
        },
        environment: {
            en: "Environment",
            pt: "Ambiente"
        }
    },

    businessSolution: {
        version: {
            name: {
                en: "Version name",
                pt: "Nome da versão"
            }
        },
        top: {
            settings: {
                en: 'Settings',
                pt: 'Configurações'
            },
            indicators: {
                en: "Indicators",
                pt: "Indicadores"
            },
            journeys: {
                en: "Journeys",
                pt: "Jornadas"
            },
            info: {
                corporation: {
                    en: 'Filter corporation',
                    pt: 'Filtrar corporação'
                },
                dimension: {
                    en: 'Filter dimension',
                    pt: 'Filtrar dimensão'
                },
                export: {
                    en: 'Export',
                    pt: 'Exportar',

                    xls: {
                        en: 'Export xls',
                        pt: 'Exportar xls'
                    },
                    json:{
                        en: 'Export JSON',
                        pt: 'Exportar JSON'
                    },
                    success: {
                        en: 'Successfully exported',
                        pt: 'Exportado com sucesso'
                    }
                },
                totalNumberProcess: {
                    en: "Total number of process",
                    pt: "Número total de processos"
                },
                import: {
                    en: 'Import',
                    pt: 'Importar'
                },
                finished: {
                    en: 'Finished',
                    pt: 'Concluir'
                },
                copy: {
                    en: 'Copy',
                    pt: 'Copiar'
                },
                cut: {
                    en: 'Cut',
                    pt: 'Cortar'
                },
                goToOriginNode: {
                    en: 'Go to source node',
                    pt: 'Ir para nó de origem'
                },
                clone: {
                    en: 'Clone',
                    pt: 'Clonar'
                },
                add: {
                    en: 'New process',
                    pt: 'Novo processo'
                },
                cloned: {
                    en: 'Clone',
                    pt: 'Clone'
                },
                is_cloned: {
                    en: 'Cloned',
                    pt: 'Clonado',

                    info: {
                        en: 'All changes will be replicated to your clones',
                        pt: 'Todas as alterações serão replicadas em seus clones'
                    }
                },
                paste: {
                    en: 'Paste',
                    pt: 'Colar'
                },
                loading: {
                    en: 'Load data',
                    pt: 'Carregar dados'
                },
                template: {
                    en: 'Import template',
                    pt: 'Importar template'
                },
                copy_to_clipboard: {
                    en: "Copy to clipboard",
                    pt: "Copiar para área de transferência"
                },
                delete_process: {
                    en: "Delete process",
                    pt: "Deletar processo"
                },
                diagram: {
                    en: "Diagram",
                    pt: "Diagrama"
                }
            }
        },
        content: {
            processTree: {
                en: 'Process tree',
                pt: 'Árvore de processos'
            },
            top: {
                en: 'Business tree',
                pt: 'Árvore de negócio'
            },

            info: {
                expand: {
                    en: 'Expand tree',
                    pt: 'Expandir árvore'
                },
                collapse: {
                    en: 'Collapse tree',
                    pt: 'Recolher árvore'
                },
                create:{
                    en: 'Create process',
                    pt: 'Criar processo'
                },
                guideline: {
                    en: 'Configure process guideline to create your business tree',
                    pt: 'Configure o guideline de processo para criar sua árvore de negócio'
                },
                goToGuideline: {
                    en: "Go to process guideline",
                    pt: "Ir para guideline de processo"
                },
                goToTechnologies: {
                    en: "Go to Technologies",
                    pt: "Ir para Tecnologias"
                }
            }
        },
        modal: {
            upload: {
                title: {
                    en: 'Import data',
                    pt: 'Importar dados'
                },
                select: {
                    en: 'Select document',
                    pt: 'Selecionar documento'
                },
                push: {
                    en: 'Push',
                    pt: 'Enviar'
                }
            },
            delete:{
                title: {
                    en: 'Attention!',
                    pt: 'Atenção!'
                },
                content: {
                    en: 'Do you really want to delete the component?',
                    pt: 'Você realmente deseja excluir o componente?'
                },
                cancel: {
                    en: 'Cancel',
                    pt: 'Cancelar'
                },
                confirm: {
                    en: 'Confirm',
                    pt: 'Confirmar'
                }
            },
            create: {
                title: {
                    en: 'Create',
                    pt: 'Criar'
                }
            },
            edit: {
                title: {
                    en: 'Edit',
                    pt: 'Editar'
                }
            },
            view: {
                title: {
                    en: 'view',
                    pt: 'Visualizar'
                }
            },
            addCode: {
                title: {
                    en: 'Description',
                    pt: 'Descrição'
                },
                created_at: {
                    en: 'Inclusion date',
                    pt: 'Data inclusão' 
                },
                userCreated: {
                    en: "Included by",
                    pt: "Incluído por"
                },
                updated_at: {
                    en: 'Last update date',
                    pt: 'Data última atualização' 
                },
                userUpdated: {
                    en: 'Last updated by',
                    pt: 'Última atualização por' 
                },
                organogram_responsible: {
                    en: 'Responsible organogram',
                    pt: 'Área responsável'
                },
                organogram_executing: {
                    en: 'Executing organogram',
                    pt: 'Área executora'
                },
                business: {
                    en: 'Business',
                    pt: 'Empresa'
                },
                reference: {
                    en: 'Reference',
                    pt: 'Referencia'
                },
                code: {
                    en: 'Code',
                    pt: 'Código'
                },
                process: {
                    en: 'Process Name',
                    pt: 'Nome do Processo'
                },
                id: {
                    en: 'ID',
                    pt: 'ID'
                },
                rules: {
                    en: 'Procedures',
                    pt: 'Procedimentos'
                },
                extendedAttributes: {
                    en: 'Extended Atributes',
                    pt: 'Atributos Extendidos'
                },
                ruleType: {
                    en: 'Rule Type',
                    pt: 'Tipo de Regra'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                status: {
                    en: 'Status',
                    pt: 'Status'
                },
                actions: {
                    en: 'Actions',
                    pt: 'Ações'
                },
                scenario: {
                    en: 'Scenario',
                    pt: 'Cenário'
                },
                behavior: {
                    en: 'Behavior',
                    pt: 'Comportamento'
                },
                client: {
                    en: 'Client',
                    pt: 'Cliente'
                },
                type: {
                    en: 'Tipo',
                    pt: 'Type'
                },
                configurations: {
                    en: 'Configurations',
                    pt: 'Configurações'
                },
                integrations: {
                    en: 'Integrations',
                    pt: 'Integrações'
                },
                indicators: {
                    en: 'Analytical',
                    pt: 'Analítico'
                },
                attachments: {
                    en: 'New attachment',
                    pt: 'Novo anexo'
                },
                rulesDrawing: {
                    en: 'Rules Drawing',
                    pt: 'Desenho de Regras'
                },
                importance: {
                    en: "Relevance",
                    pt: "Relevância"
                },
                frequency: {
                    en: "Frequency",
                    pt: "Frequência"
                },
                fte: {
                    en: "FTE",
                    pt: "FTE"
                },
                fte_cost: {
                    en: "FTE cost",
                    pt: "Custo FTE"
                },
                sla: {
                    en: "SLA",
                    pt: "SLA"
                },
                sla_unit: {
                    en: "SLA unit",
                    pt: "Unidade de SLA"
                },
                description: {
                    en: "Description",
                    pt: "Descrição"
                },
                link: {
                    en: "External link",
                    pt: "Link externo"
                },
                strategy: {
                    en: "Strategic objectives",
                    pt: "Objetivos estratégicos"
                },
                classification: {
                    en: "Breed",
                    pt: "Classe"
                },
                processOwner: {
                    en: "User creation",
                    pt: "Usuário criação"
                },
                rpa_percentage: {
                    en: "RPA",
                    pt: "RPA",
                    info: {
                        en: "100%: Fully robotizable, 50%: Partially robotizable, 0%: Not robotizable",
                        pt: "100%: Totalmente robotizável, 50%: Parcialmente robotizável, 0%: Não robotizável"
                    }
                },
                icon: {
                    en: "Icon",
                    pt: "Ícone"
                }
            },

            skills: {
                title: {
                    en: 'Skills',
                    pt: 'Competências'
                },
                addRow: {
                    en: "Add row",
                    pt: "Adicionar linha"
                },
                search: {
                    en: "Search skills",
                    pt: "Buscar competências"
                }
            },
            strategy: {
                title: {
                    en: 'Strategy',
                    pt: 'Estratégia'
                },
                business: {
                    en: 'Business',
                    pt: 'Empresa'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                process: {
                    en: 'Process',
                    pt: 'Processo'
                },
                scope: {
                    en: 'Scope',
                    pt: 'Escopo'
                },
                description: {
                    en: 'Description',
                    pt: 'Descrição'
                },
                itensPage:{
                    en: 'Items',
                    pt: 'Itens'
                },
                totalPages:{
                    en: 'Total',
                    pt: 'Total'
                },
                info: {
                    scope: {
                        en: 'It refers to the set of goals, objectives, activities, and deliverables that are defined and documented at the beginning of a project.',
                        pt: 'Isso se refere ao conjunto de metas, objetivos, atividades e entregas que são definidos e documentados no início de um projeto.'
                    },
                    newColumn: {
                        en: 'Add Column',
                        pt: 'Adicionar Coluna'
                    },
                    newRow: {
                        en: 'Add Row',
                        pt: 'Adicionar Linha'
                    },
                    removeColumn: {
                        en: 'Remove Column',
                        pt: 'Remover Coluna'
                    },
                    removeRow: {
                        en: 'Remove Rows',
                        pt: 'Remover Linhas'
                    }
                }
            },
            organogram: {
                title: {
                    en: 'Organogram',
                    pt: 'Organograma'
                },
                business: {
                    en: 'Business',
                    pt: 'Empresa'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                process: {
                    en: 'Process',
                    pt: 'Processo'
                },
                scope: {
                    en: 'Scope',
                    pt: 'Escopo'
                },
                description: {
                    en: 'Description',
                    pt: 'Descrição'
                },
                info: {
                    scope: {
                        en: 'It refers to the set of goals, objectives, activities, and deliverables that are defined and documented at the beginning of a project.',
                        pt: 'Isso se refere ao conjunto de metas, objetivos, atividades e entregas que são definidos e documentados no início de um projeto.'
                    }
                }
            },
            people: {
                title: {
                    en: 'Personas',
                    pt: 'Personas'
                },
                business: {
                    en: 'Business',
                    pt: 'Empresa'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                process: {
                    en: 'Process',
                    pt: 'Processo'
                },
                scope: {
                    en: 'Scope',
                    pt: 'Escopo'
                },
                description: {
                    en: 'Description',
                    pt: 'Descrição'
                },
                positions: {
                    en: 'Positions and Functions',
                    pt: 'Posições e Funções'
                },
                fte: {
                    en: 'FTE',
                    pt: 'FTE'
                },
                organization: {
                    en: 'Organization Chart',
                    pt: 'Organograma Chart'
                },
                frequency: {
                    en: 'Frequency',
                    pt: 'Frequência'
                },
                sla: {
                    en: 'SLA',
                    pt: 'SLA'
                },
                slaUnity: {
                    en: 'SLA Unity',
                    pt: 'Unidade de SLA'
                },
                info: {
                    scope: {
                        en: 'It refers to the set of goals, objectives, activities, and deliverables that are defined and documented at the beginning of a project.',
                        pt: 'Isso se refere ao conjunto de metas, objetivos, atividades e entregas que são definidos e documentados no início de um projeto.'
                    },
                    positions: {
                        en: 'Positions and functions is related to the responsibilities, tasks, and roles performed by individuals within an organization.',
                        pt: 'Posições e funções estão relacionadas às responsabilidades, tarefas e papéis desempenhados por indivíduos dentro de uma organização.'
                    },
                    fte: {
                        en: 'In business solution, FTE (Full-Time Equivalent) is a measure used to determine the workload or size of a team required to carry out specific activities or processes.',
                        pt: 'Em soluções empresariais, FTE (Equivalente a Tempo Integral) é uma medida usada para determinar a carga de trabalho ou o tamanho de uma equipe necessária para realizar atividades ou processos específicos.'
                    },
                    organization: {
                        en: 'It refers to a graphical and structural representation of the hierarchy and relationships between departments, positions, and functions within an organization.',
                        pt: 'Isso se refere a uma representação gráfica e estrutural da hierarquia e das relações entre departamentos, cargos e funções dentro de uma organização.'
                    },
                    frequency: {
                      en: 'In business solution, the concept of frequency refers to how often certain activities or events occur within a given period of time.',
                      pt: 'Em soluções empresariais, o conceito de frequência se refere a com que frequência certas atividades ou eventos ocorrem dentro de um determinado período de tempo.'
                    },
                    sla: {
                        en: 'In business management, a Service Level Agreement (SLA) Unit refers to a time metric used to establish commitments and performance standards regarding the services provided by an organization.',
                        pt: 'Na gestão empresarial, uma Unidade de Acordo de Nível de Serviço (SLA) refere-se a uma métrica de tempo usada para estabelecer compromissos e padrões de desempenho relacionados aos serviços prestados por uma organização.'
                    },
                    slaUnity: {
                        en: 'In business management, an SLA (Service Level Agreement) Unit refers to a time metric used to establish commitments and performance standards regarding the services provided by an organization.',
                        pt: 'Na gestão empresarial, uma Unidade de SLA (Acordo de Nível de Serviço) refere-se a uma métrica de tempo usada para estabelecer compromissos e padrões de desempenho relacionados aos serviços prestados por uma organização.'
                    },
                    newColumn: {
                        en: 'Add Column',
                        pt: 'Adicionar Coluna'
                    },
                    newRow: {
                        en: 'Add Row',
                        pt: 'Adicionar Linha'
                    },
                    removeColumn: {
                        en: 'Remove Column',
                        pt: 'Remover Coluna'
                    },
                    removeRow: {
                        en: 'Remove Rows',
                        pt: 'Remover Linhas'
                    }
                },
                itensPage:{
                    en: 'Items',
                    pt: 'Itens'
                },
                totalPages:{
                    en: 'Total',
                    pt: 'Total'
                },

            },
            technology: {
                title: {
                    en: 'Technology',
                    pt: 'Tecnologia'
                },
                business: {
                    en: 'Business',
                    pt: 'Empresa'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                process: {
                    en: 'Process',
                    pt: 'Processo'
                },
                scope: {
                    en: 'Scope',
                    pt: 'Escopo'
                },
                description: {
                    en: 'Description',
                    pt: 'Descrição'
                },
                corporation: {
                    en: 'Organization Chart',
                    pt: 'Organograma Chart'
                },
                slaPlatform: {
                    en: 'SLA Platform',
                    pt: 'Plataforma de SLA'
                },
                app: {
                    en: 'App Name',
                    pt: 'Nome do aplicativo '
                },
                externalLink: {
                    en: 'External Link',
                    pt: 'Link Externo'
                },
                info: {
                    sla: {
                        en: 'The concept of an SLA (Service Level Agreement) platform in business solutions refers to a software or technology solution that helps organizations manage and monitor their SLAs with customers or service providers.',
                        pt: 'O conceito de uma plataforma de SLA (Acordo de Nível de Serviço) em soluções empresariais se refere a uma solução de software ou tecnologia que ajuda as organizações a gerenciar e monitorar seus SLAs com clientes ou provedores de serviços.'
                    },
                    organization: {
                        en: 'It refers to a graphical and structural representation of the hierarchy and relationships between departments, positions, and functions within an organization.',
                        pt: 'Isso se refere a uma representação gráfica e estrutural da hierarquia e das relações entre departamentos, cargos e funções dentro de uma organização.'
                    },
                    scope: {
                        en: 'It refers to the set of goals, objectives, activities, and deliverables that are defined and documented at the beginning of a project.',
                        pt: 'Isso se refere ao conjunto de metas, objetivos, atividades e entregas que são definidos e documentados no início de um projeto.'
                    },
                    newColumn: {
                        en: 'Add Column',
                        pt: 'Adicionar Coluna'
                    },
                    newRow: {
                        en: 'Add Row',
                        pt: 'Adicionar Linha'
                    },
                    removeColumn: {
                        en: 'Remove Column',
                        pt: 'Remover Coluna'
                    },
                    removeRow: {
                        en: 'Remove Rows',
                        pt: 'Remover Linhas'
                    }
                },
                itensPage:{
                    en: 'Items',
                    pt: 'Itens'
                },
                totalPages:{
                    en: 'Total',
                    pt: 'Total'
                },
                technology: {
                    en: 'Technology',
                    pt: 'Tecnologia'
                },
                module: {
                    en: 'Module/component',
                    pt: 'Módulo/component'
                },
                routine: {
                    en: 'Routine',
                    pt: 'Rotina'
                },
                aplication: {
                    en: 'Aplication',
                    pt: 'Aplicação'
                },
                transaction: {
                    en: 'Transaction',
                    pt: 'Transação'
                },
                clip: {
                    en: 'Clip',
                    pt: 'Clip'
                },
            },
            icons: {
                title: {
                    en: "Select icon",
                    pt: "Selecionar ícone"
                },
                confirm: {
                    en: "Confirm",
                    pt: "Confirmar"
                }
            },
            microservice: {
                title: {
                    en: 'MicroService',
                    pt: 'Micro Serviço'
                },
                business: {
                    en: 'Business',
                    pt: 'Empresa'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                process: {
                    en: 'Process',
                    pt: 'Processo'
                },
                scope: {
                    en: 'Scope',
                    pt: 'Escopo'
                },
                description: {
                    en: 'Description',
                    pt: 'Descrição'
                },
                info: {
                    scope: {
                        en: 'It refers to the set of goals, objectives, activities, and deliverables that are defined and documented at the beginning of a project.',
                        pt: 'Isso se refere ao conjunto de metas, objetivos, atividades e entregas que são definidos e documentados no início de um projeto.'
                    }
                }
            },
            maturity: {
                title: {
                    en: 'Maturity',
                    pt: 'Maturidade'
                },
                maturity: {
                    en: 'Process maturity percentage',
                    pt: 'Percentual de maturidade do processo'
                }
            },
            attachments: {
                title: {
                    en: 'Attachments',
                    pt: 'Anexos'
                },
            },
            indicators: {
                title: {
                    en: 'Analytical',
                    pt: 'Analítico'
                },
                name: {
                    en: "Name",
                    pt: "Nome"
                },
                absolute: {
                    en: "Absolute",
                    pt: "Absoluto"
                },
                maximum: {
                    en: "Maximum",
                    pt: "Máximo"
                },
                minimum: {
                    en: "Minimum",
                    pt: "Mínimo"
                },
                average: {
                    en: "Average",
                    pt: "Médio"
                },
                frequency: {
                    en: "Frequency",
                    pt: "Frequência"
                },
                start_date: {
                    en: "Start date",
                    pt: "Data início"
                },
                end_date: {
                    en: "End date",
                    pt: "Data fim"
                },
                description: {
                    en: "Description",
                    pt: "Descrição"
                },
                new_indicator: {
                    en: "New indicator",
                    pt: "Novo indicador"
                },
                save_indicator: {
                    en: "Save indicator",
                    pt: "Salvar indicador"
                }
            },
            complements: {
                title: {
                    en: 'Chaining',
                    pt: 'Encadeamento'
                },
                new_complement: {
                    en: 'New chaining',
                    pt: "Novo encadeamento"
                },
                save_complement: {
                    en: "Save chaining",
                    pt: "Salvar encadeamento"
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                type: {
                    en: 'Type',
                    pt: 'Tipo'
                }
            },
            configurations: {
                title: {
                    en: "FAQ",
                    pt: "FAQ"
                },
                new_question: {
                    en: "New question",
                    pt: "Nova pergunta"
                },
                save_question: {
                    en: "Save question",
                    pt: "Salvar pergunta"
                },
                question: {
                    en: "Question",
                    pt: "Pergunta"
                },
                answer: {
                    en: "Answer",
                    pt: "Resposta"
                },
                action: {
                    en: "Action",
                    pt: "Ação"
                },
                new_answer: {
                    en: "New answer",
                    pt: "Nova resposta"
                }
            },
            integrations: {
                title: {
                    en: "Integrations",
                    pt: "Integrações"
                },
                new_integration: {
                    en: "New integration",
                    pt: "Nova integração"
                },
                save_integration: {
                    en: "Save integration",
                    pt: "Salvar integração"
                },
                name: {
                    en: "Name",
                    pt: "Nome"
                },
                description: {
                    en: "Description",
                    pt: "Descrição"
                },
                type: {
                    en: "Type",
                    pt: "Tipo"
                },
                to: {
                    en: "To",
                    pt: "De"
                },
                from: {
                    en: "From",
                    pt: "Para"
                },
                script: {
                    en: "Technical script",
                    pt: "Script técnico"
                }
            },
            rules: {
                title: {
                    en: "Procedures",
                    pt: "Procedimentos"
                },
                new_rule: {
                    en: "New procedure",
                    pt: "Novo procedimento"
                },
                save_rule: {
                    en: "Save procedure",
                    pt: "Salvar procedimento"
                },
                name: {
                    en: "Name",
                    pt: "Nome"
                },
                description: {
                    en: "Description",
                    pt: "Descrição"
                }
            },
            extendedAttributes: {
                title: {
                    en: "Extended Attributes",
                    pt: "Atributos Extendidos"
                },
                name: {
                    en: "Attribute name",
                    pt: "Nome do atributo"
                },
                content: {
                    en: "Content",
                    pt: "Conteúdo"
                },
                inclusion_date: {
                    en: "Inclusion date",
                    pt: "Data inc."
                },
                last_change_date: {
                    en: "Last change date",
                    pt: "Data ult. alt."
                },
                newAttribute: {
                    en: 'New attribute',
                    pt: 'Novo atributo'
                },
                createAttribute: {
                    en: 'Create attribute',
                    pt: 'Criar atributo'
                },
                type: {
                    en: 'Type',
                    pt: 'Tipo'
                },
                possibleValues: {
                    en: 'Possible values',
                    pt: 'Valores possíveis'
                },
                placeholderValue: {
                    en: 'Value 01; Value 02; Value 03; ...; Value N',
                    pt: 'Valor 01; Valor 02; Valor 03; ...; Valor N'
                },
                extended_attribute_already_registered: {
                    en: "Extended attribute already registered",
                    pt: "Atributo extendido já cadastrada"
                }
            },
            transactions: {
                title: {
                    en: "Skills",
                    pt: "Competências"
                },
                new_transaction: {
                    en: "New transaction",
                    pt: "Nova transação"
                },
                save_transaction: {
                    en: "Save transaction",
                    pt: "Salvar transação"
                },
                name: {
                    en: "Name",
                    pt: "Nome"
                },
                description: {
                    en: "Description",
                    pt: "Descrição"
                }
            },
            project: {
                title: {
                    en: "Project",
                    pt: "Projeto"
                },
                project: {
                    en: 'Level 1',
                    pt: 'Nível 1'
                },
                subproject: {
                    en: 'Level 2',
                    pt: 'Nível 2'
                },
                table: {
                    task_name: {
                        en: "Task name",
                        pt: "Nome da tarefa"
                    },
                    concluded: {
                        en: '% concluded',
                        pt: '% concluído'
                    },
                    plan: {
                        en: '% planned to measurement',
                        pt: '% planejado até medição'
                    },
                    duration: {
                        en: 'Duration',
                        pt: 'Duração'
                    },
                    start_date: {
                        en: 'Start date',
                        pt: 'Data início'
                    },
                    end_date: {
                        en: 'End date',
                        pt: 'Data fim'
                    },
                    name_task_null: {
                        en: 'Tasks with an empty name will not be saved',
                        pt: 'Tarefas com nome vazio não serão salvas'
                    },
                    action: {
                        en: 'Action',
                        pt: 'Ação'
                    },
                    guideline: {
                        en: 'Configure Project Guideline',
                        pt: 'Configure o Guideline de Projeto'
                    },
                }
            }
        }
    },
    corporation: {
        content: {
            top: {
                en: 'Organization',
                pt: 'Organização'
            },
        },
        dimension: {
            title: {
                en: 'Dimensions',
                pt: 'Dimensões'
            },
            strategy: {
                en: 'Strategy',
                pt: 'Estratégia'
            },
            people: {
                en: 'Personas',
                pt: 'Personas'
            },
            technology: {
                en: 'Technology',
                pt: 'Tecnologia'
            },
            business: {
                en: 'Business',
                pt: 'Empresa'
            },
            skills: {
                en: "Skills",
                pt: "Competências"
            }
        },
        modal: {
            address: {
                en: 'Address',
                pt: 'Endereço'
            },
            edit: {
                en: 'Edit Organization',
                pt: 'Editar Organização'
            },
            create: {
                en: 'Create Organization',
                pt: 'Criar Organização'
            },
            principal: {
                en: 'Principal',
                pt: 'Principal'
            },
            newType: {
                title: {
                    en: 'Create Organization Type',
                    pt: 'Criar Tipo de Organização'
                },
                en: 'New Type',
                pt: 'Novo Tipo'
            },
            acronym: {
                en: 'Acronym',
                pt: 'Sigla'
            },
            newSegment: {
                title: {
                    en: 'Create Business Segment',
                    pt: 'Criar Segmento'
                },
                en: 'New Business Segment',
                pt: 'Novo Segmento'
            },
            stateRegistration: {
                en: 'State Registration',
                pt: 'Registro Estadual'
            },
            municipalRegistration: {
                en: 'Municipal Registration',
                pt: 'Registro Municipal'
            },
            telephone: {
                en: 'Telefone',
                pt: 'Telefone'
            },
            referenceParent: {
                en: 'Reference Parent',
                pt: 'Referência Pai'
            },
            record: {
                en: 'Record',
                pt: 'CNPJ'
            },
            corporationName: {
                en: 'Organization name',
                pt: 'Nome da corporação'
            },
            fantasyName: {
                en: 'Fantasy name',
                pt: 'Nome fantasia'
            },
            type: {
                en:'Type',
                pt:'Tipo'
            },
            followUp: {
                en: 'Business segment',
                pt: 'Segmento comercial'
            },
            openingDate: {
                en: 'Opening date',
                pt: 'Data de abertura'
            },
            domain: {
                en: 'Domain',
                pt: 'Domínio'
            },
            site: {
                en: 'Site',
                pt: 'Site'
            },
            status: {
                en: 'Status',
                pt: 'Status'
            },
            zipCode: {
                en: 'Zip code',
                pt: 'CEP'
            },
            country: {
                en: 'Country',
                pt: 'País'
            },
            state: {
                en: 'State',
                pt: 'Estado'
            },
            city: {
                en: 'City',
                pt: 'Cidade'
            },
            neighborhood: {
                en: 'Neighborhood',
                pt: 'Bairro'
            },
            publicPlace: {
                en: 'Public place',
                pt: 'Logradouro'
            },
            number: {
                en: 'Number',
                pt: 'Número'
            },
            complement: {
                en: 'Complement',
                pt: 'Complemento'
            },
            info: {
                type: {
                    en: "Choose Organization Type",
                    pt: "Selecione o Tipo da Organização"
                },
                followUp: {
                    en: "Choose Business Segment" ,
                    pt: "Selecione o Segmento da Organização"
                },
                parent: {
                    en: "Choose Organization Parent" ,
                    pt: "Selecione a Organização Pai"
                }
            },
            delete:{
                title: {
                    en: 'Attention!',
                    pt: 'Atenção!'
                },
                content: {
                    en: 'Do you really want to delete the component?',
                    pt: 'Você realmente deseja excluir o componente?'
                },
                cancel: {
                    en: 'Cancel',
                    pt: 'Cancelar'
                },
                confirm: {
                    en: 'Confirm',
                    pt: 'Confirmar'
                }
            }
        }
    },

    clipboard: {
        title: {
            en: "Processes on the clipboard",
            pt: "Processos na área de transferência"
        },
        description: {
            en: "Processes remain on the clipboard for 24 hours",
            pt: "Processos permanecem na área de transferência por 24 horas"
        },
        link: {
            en: "Link copied to clipboard successfully!",
            pt: "Link copiado para área de transferência com sucesso!"
        }
    },

    users: {
        content: {
            top: {
                en: 'Users',
                pt: 'Usuários'
            },
            active: {
                en: 'Active users',
                pt: 'Usuários ativos'
            },
            inactive: {
                en: 'Inactive users',
                pt: 'Usuários inativos'
            },
            registred: {
                en: 'Registered users',
                pt: 'Usuários registrados'
            },
            title: {
                en: 'User table',
                pt: 'Tabela de usuários'
            },
            userList: {
                en: 'User list',
                pt: 'Tabela de usuários'
            },
            profile: {
                en: 'Profile',
                pt: 'Perfis'
            },
            approval: {
                en: 'Approval list',
                pt: 'Lista de aprovação'
            },
            table: {
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                email: {
                    en: 'Email',
                    pt: 'E-mail'
                },
                profile: {
                    en: 'Profile',
                    pt: 'Perfil'
                },
                corporation: {
                    en: 'Organization',
                    pt: 'Organização'
                },
                status: {
                    en: 'Status',
                    pt: 'Status',
                    true: {
                        en: 'Active',
                        pt: 'Ativo',
                    },
                    false: {
                        en: 'Inactive',
                        pt: 'Inativo',
                    },
                    waiting: {
                        en: 'Waiting',
                        pt: 'Aguardando',
                    }
                },
                telephone: {
                    en: 'Telefone',
                    pt: 'Telefone'
                },
                approver: {
                    en: 'Approver',
                    pt: 'Aprovador'
                },
                admin: {
                    en: 'Administrator',
                    pt: 'Administrador',
                    master: {
                      en: 'Administrator Master',
                      pt: 'Administrator Master',
                    },
                    environment: {
                      en: 'Administrator Environment',
                      pt: 'Administrator Environment',
                    },
                    others: {
                      en: 'Comum User',
                      pt: 'Comum User',
                    },
                },
            },
        },
        modal: {
            approve: {
                en: "Approve",
                pt: "Aprovar"
            },
            refuse: {
                en: "Refuse",
                pt: "Recusar"
            },
            create: {
                title: {
                    en: 'Create user',
                    pt: 'Criar Usuário'
                },
                name: {
                    en: 'Name',
                    pt: 'Nome'
                },
                last_name: {
                    en: 'Last Name',
                    pt: 'Último Nome'
                },
                email: {
                    en: 'Corporate email',
                    pt: 'E-mail Corporativo '
                },
                telephone: {
                    en: 'Telefone',
                    pt: 'Telefone'
                },
                document: {
                    en: 'Document',
                    pt: 'Documento'
                },
                profile: {
                    en: 'Profile',
                    pt: 'Perfil'
                },
                corporation: {
                    en: 'Organization',
                    pt: 'Organização'
                },
                status: {
                    en: 'Status',
                    pt: 'Status'
                },
                administrator: {
                    en: "Environment Administrator",
                    pt: "Administrador do Ambiente"
                }
            },
            edit: {
                title: {
                    en: 'Edit User',
                    pt: 'Editar Usuário'
                }
            },
            view: {
                title: {
                    en: 'View User',
                    pt: 'Visualizar Usuário'
                }
            }
        }
    },

    log: {
        en: 'Activities log',
        pt: 'Log de atividades',

        selectInstance: {
            en: 'Must be select an instance',
            pt: 'Deve selecionar uma instância'
        },
        selectCorporate: {
            en: 'Must be select a corporation',
            pt: 'Deve selecionar uma corporação'
        },
        interval: {
            en: 'Interval',
            pt: 'Intervalo'
        }
    },

    guideline: {
        en: "Guideline business tree",
        pt: "Guideline árvore de negócio",
        
        banner: {
            en: 'The Guideline allows for the customization of functionalities to meet your business needs. At this stage, you can adjust the Business Tree tool. Its main goal is to accommodate and organize the processes of a corporation, enabling management and strategic insights. Create the entities corresponding to each level of a tree.',
            pt: "O Guideline permite parametrização de funcionalidades para atender às necessidades do seu negócio. Nessa etapa, poderá ser ajustada a ferramenta Árvore de Negócio. Seu principal objetivo é comportar e organizar os processos de uma corporação, sendo possível administrar e ter insights estratégicos. Crie as entidades referente a cada nível de uma árvore" 
        },

        mandatory: {
            en: 'Mandatory',
            pt: "Obrigatório"
        },

        level: {
            en: 'Level',
            pt: "Nível"
        },

        entity: {
            en: "Entity",
            pt: "Entidade"
        },

        level_composition: {
            en: 'Level composition',
            pt: 'Composição do nível'
        },

        add_level: {
            en: "Parameterize level",
            pt: "Parametrizar nível"
        },

        settings_panel: {
            en: "Settings panel",
            pt: "Painel de configurações"
        },

        import: {
            en: "Do you want to import a guideline from another corporation or use the default one?",
            pt: "Deseja importar um guideline de outra corporação ou usar o padrão?"
        },

        form: {
            level_1: {
                en: 'Level 1 of your process structure should represent a macro grouping (journey, value chain, etc.), serving as a foundation for organizing and analyzing information. In this fundamental layer, the broadest categories encompassing the entire set of processes will be defined.',
                pt: 'O Nível 1 da sua estrutura de processos deve representar um agrupamento macro (jornada, cadeia de valor etc.), servindo como base para a organização e análise das informações. Nessa camada fundamental, serão definidas as categorias mais amplas que englobam o conjunto de processos como um todo.'
            },
            level_2: {
                en: 'Level 2 of your process structure should represent a broad view of a stage from Level 1, typically encompassing more than one sector, department, and function.',
                pt: 'O Nível 2 da sua estrutura de processos deve representar uma visão ampla de uma etapa do Nível 1, normalmente abrangendo mais de um setor, departamento e função.'
            },
            level_3: {
                en: 'Level 3 of your process structure should represent a broad view of a sub-stage from Level 2, typically encompassing a second or third-level functional area of the organizational chart.',
                pt: 'O Nível 3 da sua estrutura de processos deve representar uma visão ampla de uma sub etapa do Nível 2, normalmente abrangendo uma área funcional de segundo ou terceiro nível do organograma.'
            },
            level_4: {
                en: 'Level 4 of your process structure should represent a view of a set of interlinked tasks from Level 3.',
                pt: 'O Nível 4 da sua estrutura de processos deve representar uma visão de um conjunto de tarefas encadeadas do Nível 3.'
            },
            level_5: {
                en: 'Level 5 of your process structure should represent a view of a set of interlinked tasks from Level 4.',
                pt: 'O Nível 5 da sua estrutura de processos deve representar uma visão de um conjunto de tarefas encadeadas do Nível 4.'
            },
            level_6: {
                en: 'Level 6 of your process structure should represent a detailed view of individual tasks within the sets defined in Level 5.',
                pt: 'O Nível 6 da sua estrutura de processos deve representar uma visão detalhada das tarefas individuais dentro dos conjuntos definidos no Nível 5.'
            },
            level_7: {
                en: 'Level 7 of your process structure should represent a more granular view of specific activities within the tasks defined in Level 6.',
                pt: 'O Nível 7 da sua estrutura de processos deve representar uma visão mais granular das atividades específicas dentro das tarefas definidas no Nível 6.'
            },
            level_8: {
                en: 'Level 8 of your process structure should represent the step-by-step actions required to complete the activities defined in Level 7.',
                pt: 'O Nível 8 da sua estrutura de processos deve representar as ações passo a passo necessárias para completar as atividades definidas no Nível 7.'
            },
            level_9: {
                en: 'Level 9 of your process structure should represent a detailed view of the execution procedures for the actions defined in Level 8.',
                pt: 'O Nível 9 da sua estrutura de processos deve representar uma visão detalhada dos procedimentos de execução para as ações definidas no Nível 8.'
            },
            level_10: {
                en: 'Level 10 of your process structure should represent the fine-grained specifications and standards for the procedures defined in Level 9.',
                pt: 'O Nível 10 da sua estrutura de processos deve representar as especificações e padrões detalhados para os procedimentos definidos no Nível 9.'
            },
            name: {
                en: "Entity name",
                pt: "Nome da entidade"
            },
            acronym: {
                en: "Acronym",
                pt: "Sigla"
            },
            obligatoriness: {
                en: "Obligatoriness",
                pt: 'Obrigatoriedade'
            },
            form_areas: {
                en: 'Form areas',
                pt: "Áreas do formulário"
            },
            display: {
                en: 'display',
                pt: "Exibir" 
            }
        }
    },
    choseCurrent: {
        title: {
            en: 'Do you want to change the main tree?',
            pt: 'Deseja trocar a árvore principal?'
        }
    },
    notFound: {
        text: {
            en: 'The route could not be found',
            pt: 'A rota não pôde ser encontrada'
        },
        n404: {
            en: '404',
            pt: '404'
        }
    },
    technologies: {
        en: "Technologies",
        pt: "Tecnologias",

        tree: {
            en: "Technology tree",
            pt: "Árvore de tecnologias"
        },
        add: {
            en: "New",
            pt: "Novo"
        },
        edit: {
            en: "Edit",
            pt: "Editar"
        },
        view: {
            en: "View",
            pt: "Visualizar"
        },
        delete: {
            en: "Delete",
            pt: "Deletar"
        },
        name: {
            en: "Name",
            pt: "Nome"
        },
        specification: {
            en: 'Specifications',
            pt: 'Especificações'
        },
        landscape: {
            en: 'Technology landscape',
            pt: 'Natureza da tecnologia'
        },
        version: {
            en: 'Version',
            pt: 'Versão'
        },
        manufacturer: {
            en: 'Manufacturer',
            pt: 'Fabricante'
        },
        date_installation: {
            en: 'Date installation',
            pt: 'Data instalação'
        },
        name_module: {
            en: 'Name module/component',
            pt: 'Nome módulo/componente'
        },
        id_trasaction: {
            en: 'Trasaction id',
            pt: 'Id da transação'
        },
        title_new_landscape: {
            en: 'New technology landscape',
            pt: "Nova natureza da tecnologia"
        },
        new_landscape: {
            en: "Name technology landscape",
            pt: "Nome da natureza da tecnologia"
        }, 
        errors: {
            landscape_already_registered: {
                en: "Landscape technology already registered",
                pt: "Natureza da tecnologia já cadastrada"
            }
        },
        import: {
            en: 'Would you like to import a technology tree from another corporation?',
            pt: 'Deseja importar uma árvore de tecnologias de outra corporação?'
        },
        name_platform: {
            en: 'Platform/installation/system name',
            pt: 'Nome plataforma/instalação/sistema'
        },
        name_technology_null: {
            en: 'Technologies with an empty name will not be saved',
            pt: 'Tecnologias com nome vazio não serão salvas'
        },
        technologies_empty: {
            en: 'Configure the Technologies initially',
            pt: 'Configure as Tecnologias primeiro'
        }
    },
    personas: {
        en: "Personas",
        pt: "Personas",

        tree: {
            en: "Personas tree",
            pt: "Árvore de personas"
        },
        add: {
            en: "New persona",
            pt: "Nova persona"
        },
        edit: {
            en: "Edit persona",
            pt: "Editar persona"
        },
        view: {
            en: "Persona view",
            pt: "Visualizar persona"
        },
        delete: {
            en: "Delete persona",
            pt: "Deletar persona"
        },
        name: {
            en: "Persona name",
            pt: "Nome da persona"
        },
        cost: {
            en: "FTE Cost",
            pt: "Custo FTE"
        },
        fte: {
            en: 'Amount of fte',
            pt: "Quantidade de FTE"
        },
        type: {
            en: 'Type',
            pt: "Tipo"
        },
        skills: {
            en: "Skills",
            pt: "Competências"
        },
        import: {
            en: 'Would you like to import a persona tree from another corporation?',
            pt: 'Deseja importar uma árvore de personas de outra corporação?'
        }
    },
    project_guideline: {
        en: "Project guideline",
        pt: "Guideline de projeto",

        tree: {
            en: "Project guideline tree",
            pt: "Árvore de guideline de projeto"
        },
        add: {
            en: "New",
            pt: "Novo"
        },
        edit: {
            en: "Edit",
            pt: "Editar"
        },
        view: {
            en: "View",
            pt: "Visualizar"
        },
        delete: {
            en: "Delete",
            pt: "Deletar"
        },
        name: {
            en: "Name",
            pt: "Nome"
        },
        import: {
            en: 'Would you like to import a project guideline tree from another corporation?',
            pt: 'Deseja importar uma árvore de guideline project de outra corporação?'
        },
        type: {
            en: 'Type',
            pt: 'Tipo'
        },
        objective: {
            en: 'Description / objective',
            pt: 'Descrição / objetivo'
        },
        alert: {
            en: 'name',
            pt: 'nome' 
        },
        iterative: {
            en: 'Iterative',
            pt: "Iterativo"
        },
        active: {
            en: 'Active',
            pt: 'Ativo'
        },
        title_new_type: {
            en: 'New project type',
            pt: "Novo tipo de projeto"
        },
        new_type: {
            en: "Name project type",
            pt: "Nome do tipo de projeto"
        },
        errors: {
            type_already_registered: {
                en: "Project type already registered",
                pt: "Tipo de projeto já cadastrado"
            }
        },
    },
}
