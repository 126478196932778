<div class="all-content" *ngIf="!showCrop">
    <div class="top">
      <div class="toggle"></div>

      <div class="div-icon-button">
        <div class="save" (click)="setEnvironment()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="exitModal()"></i>
      </div>
      </div>
      <div class="row">
        <div class="title">
          {{ getLanguages('instances/modal/create/title') }}
        </div>
      </div>

    <!-- <div class="row image">
      <div class="item w-1 div-circle">
        <span>{{ getLanguages('instances/content/image') }}: </span>
        <div class="circle-image">
          <img [src]="displayedImage" *ngIf="displayedImage"/>
          <span *ngIf="!displayedImage">
            <i class="fas fa-upload"></i>
          </span>
        </div>
        <div class="attachments">
          <div class="button-upload pointer" (click)="openFileInput()">
            <i class="fas fa-image"></i>
            {{ getLanguages('instances/content/upload_image') }}
          </div>
          <input type="file" class="button-upload" #fileInput style="display: none" (change)="fileChangeEvent($event)">
        </div>
      </div>


    </div> -->

    <div class="row">
        <div class="item w-1">
            <span>{{ getLanguages('instances/content/table/name') }}: </span>
            <input type="text" [(ngModel)]="saveEnvironment.name">
        </div>
    </div>
    <div class="row">
        <div class="item w-1">
            <span>{{ getLanguages('users/modal/create/status') }} </span>
            <div class="select-nav" [matMenuTriggerFor]="status">
              <span>
                  <ng-container>
                    {{ selectedStatus && selectedStatus.name ? getNames(selectedStatus.name) : getLanguages('search') }}
                  </ng-container>
              </span>
              <i class="fas fa-caret-down"></i>
            </div>
            <mat-menu #status="matMenu">
              <div class="menu">
                <div class="scroll">
                  <ng-container *ngFor="let status of statusArray">
                      <div class="item-menu" (click)="choseStatus(status)">
                        <span [ngClass]="{'valid-span' : status.value == saveEnvironment.status}">
                          {{ getNames(status.label) }}
                        </span>
                      </div>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
          </div>
    </div>
</div>


<div class="showImageCrop" *ngIf="showCrop">
  <div class="content-crop">
    <i class="fas fa-xmark" (click)="saveAndClose(false)"></i>
    <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 4"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    ></image-cropper>

    <button (click)="saveAndClose()">
      {{ getLanguages('save')}}
    </button>

  </div>
</div>
