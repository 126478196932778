import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { AddCodeComponent } from '../../tree/add-code/add-code.component';
import { CookieService } from 'ngx-cookie-service';
import { getLanguageCookie } from 'src/assets/language';
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { v4 } from 'uuid';
import { to64decode } from 'src/app/helpers/base64';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import * as moment from 'moment';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

@Component({
  selector: 'app-crud-technologies',
  templateUrl: './crud-technologies.component.html',
  styleUrls: ['./crud-technologies.component.scss']
})
export class CrudTechnologiesComponent {
  @Output() toggleModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getTechnologiesTree: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();

  @Input() properties: any = {}
  @Input() parameters: any = {}

  isEdit: boolean = false;
  temp: any = {}

  newNode: any
  selectedLandscape: any = {
    name: ''
  }

  newLandscape = {name: ''};

  createLandscape: boolean = false
  corporationLandscapeArray: any = []

  searchLandscape: string = ''
  /**
   *
   * @param cookieService
   */
  constructor(
    private cookieService: CookieService,
    private corporationService: CorporationService
  ) {

  }

  /**
   *
   */
  async ngOnInit() {
    this.temp = JSON.parse(JSON.stringify(this.parameters.item));

    this.selectedLandscape.name = this.temp.label

    await this.getCorporationLandscape()
  }

  async getCorporationLandscape() {
    try {
      const response: any = await this.corporationService.getCorporationLandscape();
      if (response && response.data) {
        await this.initCorporationLandscape();
        this.corporationLandscapeArray.push(...sortArrayOfObjects(response.data))

      } else {
        throw new Error('Erro ao consultar corporations landscape!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async setCorporationLandscape(){
    let flag = false;

    this.corporationLandscapeArray.forEach((item: any) => {
      if(item.name == this.newLandscape.name){
        addAlert('orange', this.getLanguages('technologies/errors/landscape_already_registered'))
        flag = true
        return
      }
    });

    if(flag){
      return
    }

    try {
      const response:any = await this.corporationService.setCorporationLandscape(this.newLandscape);
      if (response && [201, 202].includes(response.status) ) {
        addAlert('success', response.message)
        await this.getCorporationLandscape();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.createLandscape = false;
    this.newLandscape.name = ''
  }

  async initCorporationLandscape(){
    this.corporationLandscapeArray = []
    if(this.properties?.permissions?.register?.corporations?.create){
      this.corporationLandscapeArray.push({name: 'New +'}) 
    }
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }
    this.toggleModal.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  async editBusinessTree() {
    this.isEdit = !this.isEdit;
    this.parameters.isEdit = this.isEdit

    if(!this.isEdit){
      this.parameters.item = JSON.parse(JSON.stringify(this.temp));
      this.selectedLandscape.name = this.temp.label
    }
  }

  async parametersValidation () {
    let inputs: any = []

    if(!this.parameters.item.label){
      inputs.push(this.getLanguages('technologies/name'))
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  async setTechnologiesTree() {
    if (!await this.parametersValidation()) {
      return
    }
    
    if (this.parameters.isCreate){
      

      this.newNode = {
        uuid: v4(),
        data: this.parameters.item.data,
        label: this.parameters.item.label,
        master: this.parameters.item.master,
        rows: this.parameters.item.rows,
        children: [],
        ...this.parameters.item
      }

      if (this.newNode.master == 'ROOT') {
        this.parameters.technologies.push(this.newNode)
      } else {
        this.recursiveForEach(this.parameters.technologies)
      }

      this.updateFiles.emit({ files: this.parameters.technologies } )
      await this.modalClose()
      return
    }

    this.updateTree(this.parameters.technologies, this.parameters.item);

    this.updateFiles.emit({ files: this.parameters.technologies, item: this.parameters.item });
    await this.modalClose();
  }

  recursiveForEach(array: any){
    array.forEach((item: any) => {
      if(item.uuid == this.parameters.item.master){
        item.children.push(this.newNode)
      }else{
        if(item.children.length > 0){
          this.recursiveForEach(item.children)
        }
      }
    });
  }

  updateTree(array: any, updatedItem: any) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      if (item.uuid === updatedItem.uuid) {
        array[i] = {
          ...item,
          ...updatedItem,
        };
      } else if (item.children && item.children.length > 0) {
        this.updateTree(item.children, updatedItem);
      }
    }
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(this.properties.permissions, module, screen, action, false)
  }

  choseLandscapeCorporation(type: any) {
    if(type.name == "New +"){
      this.createLandscape = true;
      return
    }
    this.selectedLandscape.name = type.name;
    this.parameters.item.corporation_landscape_uuid = type.uuid
    this.parameters.item.label = type.name
  }

  formatDate(): void {
    let value = this.parameters.item.date_instalation || '';
    let formattedValue = value.replace(/\D/g, "");  // Remove all non-digit characters

    const maxLength = 6;

    if (formattedValue.length > maxLength) {
      formattedValue = formattedValue.substr(0, maxLength);  // Limit to 6 characters
    }

    let month = '';
    if (formattedValue.length >= 2) {
      month = formattedValue.substr(0, 2);

      // Ensure month is in the range 01 to 12
      const monthNumber = parseInt(month, 10);
      if (monthNumber < 1) {
        month = '01';
      } else if (monthNumber > 12) {
        month = '12';
      }

      value = `${month}`;
    }

    let year = '';
    if (formattedValue.length >= 4) {
      year = formattedValue.substr(2, 4);
      value += `/${year}`;
    }

    this.parameters.item.date_instalation = value;
  }

  restrictToNumbers(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}