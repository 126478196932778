<div class="all-content">
  <div class="top">
    <div class="toggle"></div>
    <i class="fas fa-xmark" (click)="exitModal()"></i>
  </div>
  <div class="row">
    <div class="title">
      <span>{{ parameters.readonly ? getLanguages('users/modal/view/title') : getLanguages('users/modal/edit/title') }} </span>
    </div>
  </div>

  <div class="row" *ngIf="parameters.isOneUser">
    <!-- <div class="item" [ngClass]="user.lastname ? 'w-2' : 'w-1'">
      <span>{{ getLanguages('users/modal/create/name') }}</span>
      <input type="text" [(ngModel)]="user.name" disabled >
    </div> -->
    <!-- <div class="item w-2" *ngIf="user.lastname">
      <span>{{ getLanguages('users/modal/create/last_name') }}</span>
      <input type="text" [(ngModel)]="user.lastname" disabled>
    </div> -->
    <div class="item w-3">
      <span>{{ getLanguages('users/modal/create/name') }} <b> * </b> </span>
      <input type="text" [(ngModel)]="user.name" disabled >
    </div>
    <div class="item w-3">
      <span>{{ getLanguages('users/modal/create/email') }} <b> * </b>  </span>
      <input type="text" [(ngModel)]="user.email" disabled>
    </div>
    <div class="item w-3">
      <span>{{ getLanguages('statusSystem') }} </span>
      <button class="select-nav" [matMenuTriggerFor]="status" [disabled]="parameters.readonly" [ngClass]="parameters.readonly ? 'disabled no-pointer' : ''">
        <span>
            <ng-container>
              {{ selectedStatus && selectedStatus.name ? getNames(selectedStatus.name) : getLanguages('search') }}
            </ng-container>
        </span>
        <i class="fas fa-caret-down"></i>
      </button>
      <mat-menu #status="matMenu">
        <div class="menu">
          <div class="scroll">
            <ng-container *ngFor="let status of statusArray">
                <div class="item-menu" (click)="choseStatus(status)">
                  <span [ngClass]="{'valid-span' : status.value == user.is_active}">
                    {{ getNames(status.label) }}
                  </span>
                </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <!-- <div class="row" *ngIf="parameters.isOneUser">
    <div class="item" [ngClass]="properties.me.is_admin != AdminGroup.OTHERS ? 'w-2' : 'w-1'">
      <span>{{ getLanguages('users/modal/create/email') }} </span>
      <input type="text" [(ngModel)]="user.email" disabled>
    </div>
    <div class="item w-2" *ngIf="properties.me.is_admin != AdminGroup.OTHERS">
      <span>{{ getLanguages('users/modal/create/document') }} </span>
      <input type="text" [(ngModel)]="user.document" (input)="formatCpf($event)">
    </div>
  </div> -->

  <!-- <div class="row" *ngIf="properties.me.is_admin != AdminGroup.OTHERS">
    <div class="item w-2" *ngIf="parameters.isOneUser">
      <span>{{ getLanguages('users/modal/create/telephone') }} </span>
      <input type="text" [(ngModel)]="user.telephone" (input)="formatPhoneNumber($event)">
    </div>
    <div class="item" [ngClass]="parameters.isOneUser ? 'w-2' : 'w-1  '">
      <span>{{ getLanguages('statusSystem') }} </span>
      <button class="select-nav" [matMenuTriggerFor]="status" [disabled]="parameters.readonly" [ngClass]="parameters.readonly ? 'disabled no-pointer' : ''">
        <span>
            <ng-container>
              {{ selectedStatus && selectedStatus.name ? getNames(selectedStatus.name) : getLanguages('search') }}
            </ng-container>
        </span>
        <i class="fas fa-caret-down"></i>
      </button>
      <mat-menu #status="matMenu">
        <div class="menu">
          <div class="scroll">
            <ng-container *ngFor="let status of statusArray">
                <div class="item-menu" (click)="choseStatus(status)">
                  <span [ngClass]="{'valid-span' : status.value == user.is_active}">
                    {{ getNames(status.label) }}
                  </span>
                </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div> -->

  <div class="row">
    <div class="item w-1">
      <span>{{ getLanguages('users/modal/create/profile') }} </span>
    </div>
  </div>
  <div class="table">
    <div class="item-check" *ngIf="properties.me.is_admin != AdminGroup.OTHERS">
      <span>{{ getLanguages('users/modal/create/administrator') }} </span>

      <mat-checkbox
        class="checkbox"
        [color]="task.color"
        [(ngModel)]="environment_administrator"
        [disabled]="parameters.readonly"
        (change)="environment_administrator = !environment_administrator;setUserAdministrator()">
      </mat-checkbox>
    </div>
    <table mat-table [dataSource]="dataSource" [ngClass]="environment_administrator ? 'table-disabled' : '' ">
      <ng-container matColumnDef="Select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <mat-checkbox
            class="checkbox"
            [color]="task.color"
            [(ngModel)]="element.selected"
            [disabled]="parameters.readonly"
            (change)="element.selected = !element.selected;setSelectionCorporationProfile(element)"
            *ngIf="!environment_administrator"
            >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="Corporation">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/corporation') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <span>
              {{element.corporation.name}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Profile">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/profile') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <button class="select-nav nav-table" [matMenuTriggerFor]="selectProfile" [disabled]="!element.selected || parameters.readonly" [ngClass]="!element.selected || parameters.readonly ? 'disabled no-pointer': ''">
              <span>
                  <ng-container>
                    {{ setSelectionCorporationsProfilesArray.length > 0 && element.index >= 0 && setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].profile ? setSelectionCorporationsProfilesArray[element.index].profile.name : getLanguages('search') }}
                  </ng-container>
              </span>
              <i class="fas fa-caret-down"></i>
            </button>
            <mat-menu #selectProfile="matMenu">
              <div class="menu">
                <div class="input" (click)="$event.stopPropagation()" >
                  <input type="text" [(ngModel)]="element.searchProfile" placeholder="{{getLanguages('search')}}">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll">
                  <ng-container *ngFor="let profile of element.profiles">
                    <ng-container *ngIf="profile.name.toLowerCase().includes(element.searchProfile.toLowerCase())">
                      <div class="item-menu" (click)="setSelectionCorporationProfile(null, profile, element.index)">
                        <span [ngClass]="{'valid-span' : profile.name == (setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].profile && setSelectionCorporationsProfilesArray[element.index].profile.name)}">
                          {{ profile.name }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/status') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button class="select-nav nav-table" [matMenuTriggerFor]="status" [disabled]="!element.selected || parameters.readonly" [ngClass]="!element.selected || parameters.readonly ? 'no-pointer': ''">
            <span>
                <ng-container>
                  {{ setSelectionCorporationsProfilesArray.length > 0 && element.index >= 0 && setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].status && setSelectionCorporationsProfilesArray[element.index].status.label ? getNames(setSelectionCorporationsProfilesArray[element.index].status.label) : getLanguages('search') }}
                </ng-container>
            </span>
            <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #status="matMenu">
            <div class="menu">
              <div class="scroll">
                <ng-container *ngFor="let status of statusArray">
                    <div class="item-menu" (click)="setSelectionCorporationProfile(null, null, element.index, { status })">
                      <span [ngClass]="{'valid-span' : status.label == (setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].status && setSelectionCorporationsProfilesArray[element.index].status.label )}">
                        {{ getNames(status.label) }}
                      </span>
                    </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


  <div class="row buttons">
    <div class="save" (click)="setUserRoute()"  *ngIf="!parameters.readonly">
      {{ getLanguages('save')}}
    </div>
  </div>
</div>



