import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtendedAttributeService {
  arameters: any = {}

  API_PATH: string

  requestOptionsFormData: any = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return null;
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  } 

  async getCorporationExtendedAttribute(parameters: any = {}){
    let url = `${this.API_PATH}corporations-extended-attribute`
    if(parameters.corporation_uuid){
      url = `${url}?corporation_uuid=${parameters.corporation_uuid}`
    }
    return await this.httpClient.get<IResponse>(
      url,
    ).toPromise();
  }

  async setCorporationExtendedAttribute(body: any) {
    return await this.httpClient.post<IResponse>(
      `${this.API_PATH}corporations-extended-attribute`,
      body,
      this.requestOptionsFormData
    ).toPromise();
  }
}
