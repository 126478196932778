import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { IResponse } from "../../interfaces/IResponse";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class MaturityService {

  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // outros cabeçalhos personalizados aqui, se necessário
    })
  };

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) {
      this.API_PATH = environment.apiUrl;
  }

  getQuestions(): Observable<any> {
    const url = `${this.API_PATH}corporations-questions`;

    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

  async setQuestions(body: any, lengthData: number) {
    if(!lengthData){
      return await this.httpClient.post<IResponse>(
        `${this.API_PATH}corporations-questions`,
        body,
        this.requestOptions
      ).toPromise();
    }

    return await this.httpClient.put<IResponse>(
      `${this.API_PATH}corporations-questions`,
      body,
      this.requestOptions
    ).toPromise();
  }

}
