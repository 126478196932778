<div class="all-content">
    <div class="top">
        <div class="toggle"></div>
        <div class="button-top-right">
            <button class="save" (click)="conclude()">
                {{ getLanguages('conclude') }}
            </button>
            <i class="fas fa-xmark" (click)="exitModal()"></i>
        </div>
    </div>
    <div class="row">
        <span class="title">
            {{ getLanguages('businessSolution/modal/skills/title') }}
        </span>
    </div>

    <div class="row row-responsive">
        <div class="div-search">
            <div class="search">
                <input type="text" [(ngModel)]="searchText" (keyup.enter)="search()">
            </div>
            <button (click)="search()">
                {{ getLanguages('search') }}
            </button>
        </div>
        <button class="save" (click)="addRow()">
            {{ getLanguages('businessSolution/modal/skills/addRow') }}
        </button>
    </div>


    <div class="row content">
        <div class="div-table">
            <p-table [value]="getRows()" class="table">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="td-center-auto">

                        </th>
                        <th>
                            <span class="columnTitle"> {{ getLanguages('name') }} </span>
                        </th>
                        <!-- <th class="td-delete">
                            <span class="columnTitle"> {{ getLanguages('delete') }} </span>
                        </th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-element let-rowIndex="rowIndex">
                    <tr *ngIf="!element.is_delete">
                        <td class="td-center-auto">
                            <mat-checkbox
                                class="checkbox"
                                (click)="$event.stopPropagation(); setSelection(element)"
                                [color]="task.color"
                                [checked]="getSelection(element)"
                                >
                            </mat-checkbox>
                        </td>
                        <td>
                            <input [disabled]="element.in_use" class="columnText" type="text" [(ngModel)]="element.name">
                        </td>
                        <!-- <td class="td-delete">
                            <i class="fas fa-trash" (click)="removeSkill(element.uuid)"></i>
                        </td> -->
                    </tr>
                </ng-template>

            </p-table>
        </div>
        <div class="div-paginator">
            <div class="paginator">
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
                </span>
                <button [matMenuTriggerFor]="menu" class="itens-per-page">
                    {{ getItemsPerPage()}}
                </button>
                <mat-menu #menu="matMenu" >
                  <button mat-menu-item (click)="choseItemsPage(5)">5</button>
                  <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                  <button mat-menu-item (click)="choseItemsPage(20)">20</button>
                </mat-menu>
                <span>
                      {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
                  </span>
                <span class="totalPages">
                      {{ getTotalNumberPages() }}
                  </span>
                <button
                  [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() === 1"
                  (click)="setCurrentPages(getCurrentPages() - 1); getDisplayedRows();">
                  <i class="fa-solid fa-angle-left"></i>
                </button>
                <input type="number" disabled [ngModel]="getCurrentPages()" (blur)="getTotalPages()">
                <button
                  [ngClass]="getCurrentPages() >= getTotalNumberPages()  ? 'disabled-arrow' : 'arrow'"
                  [disabled]="getCurrentPages() >= getTotalNumberPages()"
                  (click)="setCurrentPages(getCurrentPages() + 1);getTotalPages();">
                  <i class="fa-solid fa-angle-right"></i>
                </button>
            </div>
        </div>
    </div>

</div>

