<div class="all-content">
  <div class="row row-end">
    <div class="button-upload" (click)="newAttribute()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
        {{ getLanguages('businessSolution/modal/extendedAttributes/newAttribute') }}
    </div>
  </div>
  <div class="no-data" *ngIf="!isData()">
    <div class="circle">
      <i class="fas fa-exclamation"></i>
    </div>
    {{ getLanguages('errors/noData') }}
  </div>
  <div class="table-project" *ngIf="isData()">
    <p-table *ngIf="getDisplayedRows().length !== 0" [value]="getDisplayedRows()" class="table-project" [sortField]="'updated_date'" [sortOrder]="1">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name" class="td-35">
            <span> {{ getLanguages('businessSolution/modal/extendedAttributes/name') }} </span>
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="content" class="td-20">
            <span> {{ getLanguages('businessSolution/modal/extendedAttributes/content') }} </span>
            <p-sortIcon field="content"></p-sortIcon>
          </th>
          <th pSortableColumn="inclusion_date" class="td-15">
            <span> {{ getLanguages('businessSolution/modal/extendedAttributes/inclusion_date') }} </span>
            <p-sortIcon field="inclusion_date"></p-sortIcon>
          </th>
          <th pSortableColumn="last_change_date" class="td-15">
            <span> {{ getLanguages('businessSolution/modal/extendedAttributes/last_change_date') }} </span>
            <p-sortIcon field="last_change_date"></p-sortIcon>
          </th>
          <th class="td-15">
            <span> {{ getLanguages('attachment/action') }} </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
        <tr>
          <td class="td-35">
            <div class="item">
              <button class="button-menu-table" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'no-pointer' : ''" [matMenuTriggerFor]="attribute">
                <span>
                  {{ row.name ? row.name : getLanguages('select') }}
                </span>
                <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
              </button>    
              <mat-menu #attribute="matMenu">
                <div class="menu">
                  <div class="input" (click)="$event.stopPropagation()">
                    <input type="text" [(ngModel)]="searchAttribute" placeholder="{{ getLanguages('search') }}">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                  <div class="scroll">
                    <ng-container *ngFor="let item of attributeArray">
                      <ng-container *ngIf="item?.name?.toLowerCase().includes(searchAttribute?.toLowerCase())">
                        <div class="item-menu" (click)="choseItemSelect('attribute', item, row)">
                          <span [ngClass]="item?.name == row?.name ? 'valid-span' : 'invalid-span'">
                            {{ item.name }}
                          </span>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </mat-menu>
            </div>
          </td>
          <td class="td-20">
            <textarea *ngIf="row?.type != 'restricted-text' " [disabled]="!parameters.isEdit" type="text" (input)="autoResize($event, row)" [(ngModel)]="row.content"></textarea>
            <button *ngIf="row?.type == 'restricted-text' " class="button-menu-table" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'no-pointer' : ''" [matMenuTriggerFor]="content">
              <span>
                {{ row.content ? row.content : getLanguages('select') }}
              </span>
              <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
            </button>    
            <mat-menu #content="matMenu">
              <div class="menu">
                <div class="input" (click)="$event.stopPropagation()">
                  <input type="text" [(ngModel)]="searchContent" placeholder="{{ getLanguages('search') }}">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll">
                  <ng-container *ngFor="let item of getArrayContent(row.name)">
                    <ng-container *ngIf="item?.toLowerCase().includes(searchContent?.toLowerCase())">
                      <div class="item-menu" (click)="choseItemSelect('content', item, row)">
                        <span [ngClass]="item == row?.content ? 'valid-span' : 'invalid-span'">
                          {{ item }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
          </td>
          <td class="td-15 disabled-textarea">
            <textarea disabled [(ngModel)]="row.created_date"></textarea>
          </td>
          <td class="td-15 disabled-textarea">
            <textarea disabled [(ngModel)]="row.updated_date"></textarea>
          </td>
          <td class="td-15">
            <div class="align-center" [ngClass]="!parameters.isEdit ? 'disabled-icon' : 'icon'">
              <i class="fas fa-trash pointer" (click)="deleteAttribute(row.uuid)" [ngClass]="!parameters.isEdit ? 'disabled-icon no-pointer' : 'icon'"></i>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="div-paginator" *ngIf="isData()">
    <div class="paginator">
      <span>
        {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
      </span>
      <button [matMenuTriggerFor]="menu" class="itens-per-page">
        {{ getItemsPerPage() }}
      </button>
      <mat-menu #menu="matMenu" >
        <button mat-menu-item (click)="choseItemsPage(5)">5</button>
        <button mat-menu-item (click)="choseItemsPage(10)">10</button>
      </mat-menu>
      <span>
        {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
      </span>
      <span class="totalPages">
        {{ getTotalPages()}}
      </span>
      <button
        [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
        [disabled]="getCurrentPages() === 1"
        (click)="onPageChange(getCurrentPages() - 1, 'strategy')">
        <i class="fa-solid fa-angle-left"></i>
      </button>
      <input type="number" [ngModel]="getCurrentPages()"  (blur)="checkValue()">
      <button
        [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
        [disabled]="getCurrentPages() == getTotalPages()"
        (click)="onPageChange(getCurrentPages() + 1, 'strategy')">
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>  
</div>

<div class="background-modals" *ngIf="isShowCreate">
  <div class="modal-create">
    <div class="modal-create-top">
      <div class="modal-create-toggle"></div>

      <div class="div-icon-button">
        <div class="save" (click)="setAttribute()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="isShowCreate = false"></i>
      </div>
    </div>

    <div class="modal-create-row">
      <div class="modal-create-title">
        {{ getLanguages('businessSolution/modal/extendedAttributes/createAttribute') }}
      </div>
    </div>

    <div class="modal-create-row">
      <div class="modal-create-item item-50">
        <span> {{ getLanguages('businessSolution/modal/extendedAttributes/name') }} <b>*</b> </span>
        <input type="text" [(ngModel)]="newAttributeJson.name">
      </div>
      <div class="modal-create-item item-50">
        <span> {{ getLanguages('businessSolution/modal/extendedAttributes/type') }} <b>*</b> </span>
        <button class="button-menu" [matMenuTriggerFor]="attributeType">
          <span>
            {{ selectedType?.name ? getNames(selectedType.name) : getLanguages('select') }}
          </span>
          <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
        </button>
  
        <mat-menu #attributeType="matMenu">
          <div class="menu">
            <div class="input" (click)="$event.stopPropagation()">
              <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let item of attributeTypeArray">
                <ng-container *ngIf="getNames(item.name).toLowerCase().includes(searchType.toLowerCase())">
                  <div class="item-menu" (click)="choseItemSelect('attributeType', item, newAttributeJson)">
                    <span [ngClass]="item?.slug == selectedType?.slug ? 'valid-span' : 'invalid-span'">
                      {{ getNames(item.name) }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="modal-create-row">
      <div class="modal-create-item item-100">
        <span> {{ getLanguages('businessSolution/modal/extendedAttributes/possibleValues') }} <b *ngIf="selectedType?.slug == 'restricted-text'">*</b> </span>
        <input type="text" placeholder="{{ getLanguages('businessSolution/modal/extendedAttributes/placeholderValue') }}" [disabled]="selectedType?.slug != 'restricted-text'" [ngClass]="selectedType?.slug != 'restricted-text' ? 'disabled' : ''" [(ngModel)]="newAttributeJson.values">
      </div>
  </div>
</div>
