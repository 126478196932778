import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64encode } from 'src/app/helpers/base64';
import { localStorageToJsonAsync }
 from 'src/app/helpers/cookieToJson';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { UsersService } from 'src/app/services/users/users.service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-general-settings',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class GeneralSettingsComponent {  

  @Input() properties: any
  @Output() toggleConfigurations: EventEmitter<any> = new EventEmitter<any>();
  @Output() chooseEnvironment: EventEmitter<any> = new EventEmitter<any>();

  selectedLanguage: any 
  languageArray: any = [
    { lang: 'en', file: 'us.svg'},
    { lang: 'pt', file: 'br.svg'}
  ]

  searchEnvironment: string = ''
  environmentArray: any = []
  backupEnvironment: any
  selectedEnvironment: any = {}

  selectedCorporation: any
  searchCorporation: string = ''
  corporationArray: any = []
  backupCorporationCookie: any

  constructor(private cookieService: CookieService,
    private corporationService: CorporationService,
    private userService: UsersService,
    private authService: AuthService
  ){}

  async ngOnInit(){
    await this.getMe()
    this.selectedLanguage = this.properties.language.lang
    this.selectedEnvironment = this.properties.environment
    this.backupEnvironment = this.properties.environment
    this.properties.users_environments.environments.forEach((item: any)=> {
      if(item.uuid == this.selectedEnvironment.uuid && item.favorite_corporation){
        this.selectedCorporation = item.favorite_corporation
      }
    })
    this.backupCorporationCookie = this.properties.corporation
    this.environmentArray = sortArrayOfObjects(this.properties.users_environments.environments)
    this.getCorporations()
    // this.corporationArray = this.properties.users_corporations.corporations
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(localStorage.getItem('permissions'), module, screen, action)
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  conclude(){
    let flag: boolean = false
    
    if(this.selectedEnvironment != this.backupEnvironment){
      flag = true
    }else{
      this.properties.corporation = this.backupCorporationCookie
    }

    this.userService.setUserMe(this.properties)
    localStorage.setItem('language', to64encode(this.properties.language))

    this.exitModal(flag)
  }

  exitModal(flag: boolean = false){
    if(flag){
      Promise.all([
        this.toggleConfigurations.emit(),
        this.chooseEnvironment.emit(this.selectedEnvironment)
      ])
      return 
    }
    this.toggleConfigurations.emit()
  }

  async getMe() {
    try {
      const response = await this.authService.getMe();

      this.properties = { ...response.data, ...await localStorageToJsonAsync(true) }
      // delete this.properties.corporation.url
      // await this.authService.setCookie(this.properties)
      // await this.userService.setUserMe(this.properties)
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async choseItemSelect(type: string, value: any){
    switch (type) {
      case 'language':
        this.selectedLanguage = value.lang;
        this.properties.language = value
        break;
      case 'environment':
        this.selectedEnvironment = value;
        this.properties.environment = value
        await this.getCorporations()
        delete this.selectedCorporation
        delete this.properties.corporation
        this.properties.users_environments.environments.forEach((item: any)=> {
          if(item.uuid == this.selectedEnvironment.uuid && item.favorite_corporation){
            this.selectedCorporation = item.favorite_corporation
            this.properties.corporation = item.favorite_corporation
            delete this.properties.corporation.url
          }
        })
        break
      case 'corporation':
        this.selectedCorporation = value;
        this.properties.users_environments.environments.forEach((item: any)=> {
          if(item.uuid == this.selectedEnvironment.uuid){
            item.favorite_corporation = this.selectedCorporation
            delete item.favorite_corporation.children 
            this.properties.corporation = item.favorite_corporation
          }
        })
        break;
      default:
        break;
    }
  }

  async getCorporations(){
    this.corporationService.getCorporationTree({ environment: this.selectedEnvironment }).subscribe(
      (response: any) => {
        if (response && response.data && response.data.result) {
          this.corporationArray = sortArrayOfObjects(this.flattenArray(response.data.result));
        }else {
          throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
        }
      },
      (error) => {
        console.error(error);
      }
    )
  }

  flattenArray(data: any[]): any[] {
    let flatArray: any[] = [];
    data.forEach(item => {
      if(this.properties.users_corporations.ids.includes(item.uuid)){
        flatArray.push(item);
      }else if(this.properties.me.is_admin != 'OTHERS'){
        flatArray.push(item);
      }

      if (item.children && item.children.length > 0) {
        flatArray = flatArray.concat(this.flattenArray(item.children));
      }
    });
  
    return flatArray;
  }

}
