import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {


  constructor(
    private router: Router,
    private cookieService: CookieService){}


  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  navigate(){
    this.router.navigate(['/login'])
  }
}
