import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {parse, stringify} from 'circular-json';
import { CookieService } from 'ngx-cookie-service';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { CorporationDimensionsService } from 'src/app/services/corporation-dimensions/corporation-dimensions.service';
import { PersonasService } from 'src/app/services/personas/personas.service';
import { getLanguageCookie } from 'src/assets/language';
import { v4 } from "uuid";
import {asyncForEach} from "../../../../helpers/asyncForEach";
import * as moment from "moment";

@Component({
  selector: 'app-skills-crud',
  templateUrl: './skills-crud.component.html',
  styleUrls: ['./skills-crud.component.scss']
})
export class SkillsCrudComponent {
  @Output() toggleExit: EventEmitter<void> = new EventEmitter<void>();
  @Output() getArray: EventEmitter<any[]> = new EventEmitter<any[]>()

  @Input() parameters: any = {}

  formData: FormData = new FormData()
  currentPageSkills = 1;
  itemsPerPageSkills = 5;
  totalPagesSkills = 1;
  rowsSkills: any = new MatTableDataSource<any>([])
  selectionSkills = new SelectionModel<any>(true, []);
  indexTableSkills: any = { startIndex: 0, endIndex: 0 }
  filteredDatasource: any = new MatTableDataSource<any>([])
  searchText: string = '';
  personas: any = {}
  task: any = {
    color:  'accent'
  };

  constructor(
    private cookieService: CookieService,
    private corporationDimensionsService: CorporationDimensionsService,
    private personasService: PersonasService
  ){}

  async ngOnInit(){
    await Promise.all([
      this.getPersonasTree(),
      this.getDisplayedRows(),
      this.getSkills()
    ])
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async exitModal(){
    this.toggleExit.emit();
  }

  async getSkills(){
    try{
      const response: any = await this.corporationDimensionsService.getDimensions({ corporation: this.parameters.corporation, dimension: 'skills' })
      if(response.data && response.data.skills){
        this.rowsSkills = new MatTableDataSource<any>(response.data.skills)
        await this.getTotalPages()
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

    if(this.rowsSkills){
      this.verifiyCheck()
    }

    this.filteredDatasource.data = this.rowsSkills.data
    await this.getTotalPages()
  }

  verifiyCheck(){
    if(!this.parameters.item.rows.skills){
      return
    }
    this.rowsSkills.data.forEach((item: any, index: number) => {
      this.parameters.item.rows.skills.forEach((param: any) => {
        if(item.uuid == param.uuid){
          item.in_use = true
          this.rowsSkills.data[index] = item
          this.selectionSkills.toggle(item)
        }
      })
    });
  }

  async addRow() {
    this.searchText = ''
    this.rowsSkills.data.push( { uuid: v4(), name: '', in_use: false, created_at: moment(), updated_at: moment() } );
    this.rowsSkills._updateChangeSubscription();
    this.filteredDatasource.data = this.rowsSkills.data
    await this.getTotalPages(true);
  }

  isAllSelected() {
    let numSelected: number = 0
    let numRows: number = 0

    numSelected = this.selectionSkills.selected.length;
    this.filteredDatasource.data.map((item: any) => { if (!item.in_use) numRows++ })

    return numSelected === numRows;
  }

  async getDisplayedRows() {
    this.indexTableSkills.startIndex = (this.currentPageSkills - 1) * this.itemsPerPageSkills;
    this.indexTableSkills.endIndex = this.indexTableSkills.startIndex + this.itemsPerPageSkills;
  }

  async getTotalPages(isLastPage: boolean = false){
    this.totalPagesSkills = Math.ceil(this.filteredDatasource.data.length / this.itemsPerPageSkills)
    if (this.totalPagesSkills == 0) this.totalPagesSkills = 1
    if ((this.currentPageSkills >= this.totalPagesSkills && this.totalPagesSkills > 0) || isLastPage) {
      this.currentPageSkills = this.totalPagesSkills;
    }
    await this.getDisplayedRows()

  }

  async choseItemsPage(number: number) {
    this.currentPageSkills = 1;
    this.itemsPerPageSkills = number;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectionSkills.clear()
    } else {
      this.filteredDatasource.data.map((item: any) => { if (!item.in_use) this.selectionSkills.select(item) })
    }
  }

  getRows(){
    return this.filteredDatasource.data.slice(this.indexTableSkills.startIndex, this.indexTableSkills.endIndex)
  }

  async setSelection(element: any){
    element.in_use = !this.selectionSkills.isSelected(element)
    this.selectionSkills.toggle(element)
  }

  getSelection(element: any){
    return this.selectionSkills.isSelected(element)
  }

  getCurrentPages(): number{
    return this.currentPageSkills
  }

  setCurrentPages(param: number){
    return this.currentPageSkills = param
  }

  getItemsPerPage(): number{
    return this.itemsPerPageSkills
  }

  getTotalNumberPages(){
    return this.totalPagesSkills
  }

  async search() {
    const searchText = this.searchText.toLowerCase()
    this.filteredDatasource.data = this.rowsSkills.data.filter( (item: { name: any;}) => item.name.toLowerCase().includes(searchText));
    this.getTotalPages()
  }

  removeSkill(uuid: string){
    let temp: any = []
    this.rowsSkills.data.forEach((element: any) => {
      if(element.uuid != uuid){
        temp.push(element)
      }
    });

    this.rowsSkills.data = temp
    this.search()
  }

  async conclude(){
    this.getArray.emit(this.parameters.is_search ? this.selectionSkills.selected : this.parameters.files)
    try {
      const response:any = await this.corporationDimensionsService.setDimensions({ skills: this.rowsSkills.data });
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message)

        if(this.parameters.item && !this.parameters.is_search){
          this.parameters.item.rows.skills = this.selectionSkills.selected
          await this.saveTree()
        }

        await this.exitModal()
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async saveTree(){
    await this.setFormData()

    try {
      const response: any = await this.personasService.setPersonasTree(this.formData, this.personas.uuid)
      if ([201, 202].includes(response.status)) {
        // addAlert('success', response.message)
      } else{
        // addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  updateFiles(array: any){
    array.forEach((node: any) => {
      if(this.parameters.item.uuid == node.uuid){
        node.rows.skills = this.selectionSkills.selected
        return
      }
      if(node.children && node.children.length > 0){
        this.updateFiles(node.children)
      }
    })
  }

  async setFormData() {
    this.formData = new FormData()
    this.formData.append('json', stringify(this.parameters.files))
  }

  async getPersonasTree() {
    try {
      const response: any = await this.personasService.getPersonasTree(this.parameters).toPromise();
      if (response && response.data) {
        this.personas = response.data ? parse(response.data) : {}
      } else {
        throw new Error('Erro ao consultar àrvore de personas!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }
}
