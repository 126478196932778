import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { FormStandardsComponent } from 'src/app/components/modal/standards/form-standards/form-standards.component';
import { to64decode } from 'src/app/helpers/base64';
import { localStorageToJsonAsync } from 'src/app/helpers/cookieToJson';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { CorporationsSettingsService } from 'src/app/services/corporations-settings/corporations-settings.service';
import { FunctionService } from 'src/app/services/function.service';
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';
import { VersionsService } from 'src/app/services/versions/versions.service';
import { getLanguageCookie } from 'src/assets/language';
import { v4 } from 'uuid';

@Component({
  selector: 'app-standards',
  templateUrl: './standards.component.html',
  styleUrls: ['./standards.component.scss']
})
export class StandardsComponent {
  showBanner: boolean = true
  
  isLoading!: boolean;
  noData!: boolean;

  showForm: boolean = false
  parameters: any

  slaUnitArray: any = []
  searchSlaUnit: string = '';
  selectedSlaUnit: any = {
    name: {
      en: 'Days',
      pt: 'Dias'
    },
    slug: 'days'
  }

  standards: any = {}

  items: any = {}

  uuid?: string
  guidelineArray: any = [
    {
      level: 1
    },
    {
      level: 2
    },
    {
      level: 3
    },
    {
      level: 4
    },
    {
      level: 5
    },
    {
      level: 6
    },
    {
      level: 7
    },
    {
      level: 8
    },
    {
      level: 9
    },
    {
      level: 10
    }
  ]

  properties: any = {}
  corporationInitialUuid: string = ''
  corporationGuidelineArray: any = [
    {
      uuid: 'default',
      name: 'Default'
    }
  ]
  searchCorporationGuideline: string = ''
  selectedCorporationGuideline: any = {
    name: '',
    uuid: ''
  }
  
  defaultSettings: any = {
    "level_1": {
      "name": "Jornada",
      "tabs": [
          "strategy",
          "people",
          "transactions",
          "technology",
          "rules",
          "maturity",
          "attachments",
          "indicators",
          "complements",
          "configurations",
          "integrations",
          "project"
      ],
      "level": 1,
      "acronym": "JO",
      "mandatory": true,
      "description": ""
    },
    "level_2": {
      "name": "Macroprocesso",
      "tabs": [
          "strategy",
          "people",
          "transactions",
          "technology",
          "rules",
          "maturity",
          "attachments",
          "indicators",
          "complements",
          "configurations",
          "integrations",
          "project"
      ],
      "level": 2,
      "acronym": "MP",
      "mandatory": true,
      "description": ""
    },
    "level_3": {
      "name": "Processo",
      "tabs": [
          "strategy",
          "people",
          "transactions",
          "technology",
          "rules",
          "maturity",
          "attachments",
          "indicators",
          "complements",
          "configurations",
          "integrations",
          "project"
      ],
      "level": 3,
      "acronym": "PR",
      "mandatory": true,
      "description": ""
    },
    "level_4": {
      "name": "Subprocesso",
      "tabs": [
          "strategy",
          "people",
          "transactions",
          "technology",
          "rules",
          "maturity",
          "attachments",
          "indicators",
          "complements",
          "configurations",
          "integrations",
          "project"
      ],
      "level": 4,
      "acronym": "SP",
      "mandatory": true,
      "description": ""
    },
    "level_5": {
      "name": "Cenário de Subprocesso",
      "tabs": [
          "strategy",
          "people",
          "transactions",
          "technology",
          "rules",
          "maturity",
          "attachments",
          "indicators",
          "complements",
          "configurations",
          "integrations",
          "project"
      ],
      "level": 5,
      "acronym": "CS",
      "mandatory": true,
      "description": ""
    },
    "level_6": {
      "name": "Atividade",
      "tabs": [
          "strategy",
          "people",
          "transactions",
          "technology",
          "rules",
          "maturity",
          "attachments",
          "indicators",
          "complements",
          "configurations",
          "integrations",
          "project"
      ],
      "level": 6,
      "acronym": "AT",
      "mandatory": true,
      "description": ""
    }
  };

  constructor(
    private corporationsSettingsService: CorporationsSettingsService,
    private userEnvironmentService: UserEnvironmentsService
    ){
  }

  async ngOnInit(){
    await this.getCorporationSettings()
    this.properties = await localStorageToJsonAsync()
    this.corporationInitialUuid = this.properties.corporation.uuid ?? '-'
    await this.getUserCorporations()
  }

  async getUserCorporations(){
    try{
      let response = await this.userEnvironmentService.getUserCorporations()
      if(response && response.data){
        this.corporationGuidelineArray.push(...sortArrayOfObjects([...response.data.filter((item: any) => item?.uuid != this.properties?.corporation?.uuid)]));
      }
    }catch{

    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  async choseItemSelect(type: string, value: any){
    switch (type) {
      case 'corporationGuideline':
        if(value.uuid == 'default'){
          this.selectedCorporationGuideline = {
            uuid: this.corporationInitialUuid,
            slug: 'default'
          }
          await this.getDefault()
          break;
        }else{
          this.selectedCorporationGuideline = value;
          await this.getCorporationSettings()
          break;
        }
    }
    await this.saveStandards()
  }

  async getDefault(){
    this.noData = false
    this.items = {...this.defaultSettings}
    this.guidelineArray = []
    for(let i = 1; i <= 10; i++){
      this.guidelineArray[i - 1] = {level: i}
    }

    this.guidelineArray = mergeArrays(this.guidelineArray, convertObjectToArray(this.defaultSettings))
    await this.saveStandards()
  }

  async getCorporationSettings() {
    this.noData = false
    try {
      const response: any = await this.corporationsSettingsService.getCorporationService({...this.parameters, corporation_uuid: this.selectedCorporationGuideline.uuid});
      if (response && response.status) {
        if(!this.selectedCorporationGuideline?.uuid){
          this.uuid = response.data.corporation_settings.uuid
        }
        this.items = response.data.corporation_settings.json
        this.guidelineArray = []
        for(let i = 1; i <= 10; i++){
          this.guidelineArray[i - 1] = {level: i}
        }

        this.guidelineArray = mergeArrays(this.guidelineArray, convertObjectToArray(this.items))
        const updatedGuidelineArray = [...this.guidelineArray];
        const backendLevels = response.data.corporation_settings.json;

        for (let levelKey in backendLevels) {
          if (backendLevels.hasOwnProperty(levelKey)) {
            const levelIndex = parseInt(levelKey.split('_')[1], 10) - 1;
            const newItem = backendLevels[levelKey];

            if (levelIndex >= 0 && levelIndex < updatedGuidelineArray.length) {
              updatedGuidelineArray[levelIndex] = {
                ...updatedGuidelineArray[levelIndex],
                ...(typeof newItem === 'object' && newItem !== null ? newItem : {})
              };
            }
          }
        }
        this.guidelineArray = updatedGuidelineArray;
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  addLevel(level: number){
    this.showForm = true

    this.parameters = {
      uuid: this.uuid,
      item: {
        name: "",
        acronym: "",
        mandatory: true,
        description: '',
        tabs: [],
        level: level + 1
      },
      items: this.items ?? {},
      isEdit: true,
    }
  }

  edit(item: any){
    this.showForm = true

    this.parameters = {
      uuid: this.uuid,
      item: {...item},
      items: this.items ?? {},
      isEdit: false,
      formEdit: true
    }
  }

  async updateGuidelineByForm(event: any){
    this.guidelineArray = []
    for(let i = 1; i <= 10; i++){
      this.guidelineArray[i - 1] = {level: i}
    }

    this.guidelineArray = mergeArrays(this.guidelineArray, convertObjectToArray(event))
    this.items = event || {}
  }

  async saveStandards(){
    const convertedObject = convertArrayToObject(this.guidelineArray);
    try {
      const response: any = await this.corporationsSettingsService.setCorporationSettings({...this.parameters, json: convertedObject?.json || {}, uuid: this.uuid})
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
        await this.getCorporationSettings()
      } else{
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }
  
}

interface Item {
  name: string;
  tabs: string[];
  level: number;
  acronym: string;
  mandatory: boolean;
  description: string;
  is_filled: boolean;
}

function convertArrayToObject(array: Item[]) {
  const result: { [key: string]: Item } = {};

  array.forEach((item) => {
    const level = item.level; 
    const key = `level_${level}`;

    if (!result[key] && item.name) {
      result[key] = {
        name: item.name,
        tabs: item.tabs || [],
        level: level,
        acronym: item.acronym,
        is_filled: item.is_filled || false,
        mandatory: item.mandatory || false,
        description: item.description || ""
      };
    }
  });

  return { json: result };
}

function convertObjectToArray(obj: { [key: string]: Item }): Item[] {
  const result: Item[] = [];

  Object.keys(obj).forEach((key) => {
    const item = obj[key];
    result.push({
      name: item.name,
      tabs: item.tabs || [],
      level: item.level,
      acronym: item.acronym,
      is_filled: item.is_filled || false,
      mandatory: item.mandatory || false,
      description: item.description || ""
    });
  });

  return result;
}

function mergeArrays(guidelineArray: any[], arrayToMerge: Item[]): Item[] {
  const mergedArray = guidelineArray.map(guidelineItem => {
    const matchingItem = arrayToMerge.find(item => item.level === guidelineItem.level);

    if (matchingItem) {
      return { ...guidelineItem, ...matchingItem };
    }

    return guidelineItem;
  });

  return mergedArray;
}