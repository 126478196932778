<div class="all-content">
    <div class="top">
        {{ getLanguages('pages/screens/administrator/versioning') }}
    </div>
    <div class="row">
        <div class="div-search">
            <div class="search">
                <input type="text" [(ngModel)]="searchText" (keyup.enter)="search()">
            </div>
            <button (click)="search()">
                {{ getLanguages('search') }}
            </button>
        </div>
    </div>

    <div class="div-table">
        <div class="noData" *ngIf="noData">
            <div class="icon">
                <i class="fa-solid fa-exclamation"></i>
            </div>
            <span>
                {{ getLanguages('errors/noData')}}
            </span>
        </div>
        <div class="table">
            <table mat-table [dataSource]="getDisplayedRows()" *ngIf="!isLoading && !noData">
                <ng-container matColumnDef="current">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('versions/current') }} </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="radio" [ngClass]="getRadioClass(row.is_active)" (click)="choseRadio(row)"></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('versions/name') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.name ? row.name : row.last_change }} </td>
                </ng-container>
                <ng-container matColumnDef="last_change">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('versions/last_change') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.last_change }} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('versions/actions') }} </th>
                    <td mat-cell *matCellDef="let row">
                        <i class="fas fa-trash" *ngIf="row.uuid != clicked.uuid" (click)="delete(row)"></i>
                        <i class="fas fa-pencil" (click)="edit(row)"></i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4"></td>
                </tr>
            </table>
            <div class="loading" *ngIf="isLoading">
                <section>
                    <div class="loading-spinner"></div>
                    <span class="span">
                        {{ getLanguages('loading') }}
                    </span>
                </section>
            </div>



        </div>
        <div class="div-paginator" *ngIf="!noData && !isLoading">
            <div class="paginator">
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
                </span>
                <button [matMenuTriggerFor]="menu" class="itens-per-page">
                    {{ itemsPerPage }}
                </button>
                <mat-menu #menu="matMenu" >
                    <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                    <button mat-menu-item (click)="choseItemsPage(20)">20</button>
                    <button mat-menu-item (click)="choseItemsPage(30)">30</button>
                </mat-menu>
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
                </span>
                <span class="totalPages" >
                    {{ getTotalPages()}}
                </span>
                <button [ngClass]="currentPage === 1 ? 'disabled-arrow' : 'arrow' " [disabled]="currentPage === 1 || isLoading" (click)="onPageChange(currentPage - 1)">
                    <i class="fa-solid fa-angle-left"></i>
                </button>
                <input type="number" [(ngModel)]="currentPageReq" (keydown)="checkValue($event)" disabled>
                <button [ngClass]="currentPage == getTotalPages() ? 'disabled-arrow' : 'arrow' "  [disabled]="currentPage == getTotalPages() || isLoading" (click)="onPageChange(currentPage + 1)">
                    <i class="fa-solid fa-angle-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="background-modals" *ngIf="isShowConfirm || isShowDelete || isShowCreateNameModal">
    <div class="all-content-modal" *ngIf="isShowConfirm">
        <div class="top-modal">
            <div class="toggle-modal"></div>
            <i class="fas fa-xmark" (click)="exitModal(1)"></i>
        </div>
        <div class="row">
            <div class="circle">
                <i class="fas fa-exclamation"></i>
            </div>
        </div>
        <div class="row">
            <span class="title">
                {{ getLanguages('choseCurrent/title') }}
            </span>
        </div>
        <div class="row-100">
            <div class="row-50">
                <span>
                    {{ clicked.name ? clicked.name : '' }}
                </span>
            </div>
            <i class="fas fa-arrow-right"></i>
            <div class="row-50">
                <span>
                    {{ clickedByUser.name}}
                </span>
            </div>
        </div>

        <div class="row buttons-modal ">
            <button class="confirm" (click)="choseRadioConfirm()">
                {{ getLanguages('delete/confirm') }}
            </button>
            <button class="cancel" (click)="exitModal(1)">
                {{ getLanguages('delete/cancel') }}
            </button>
        </div>
    </div>

    <ng-container *ngIf="isShowDelete || isShowCreateNameModal">
        <app-delete-component *ngIf="isShowDelete"  (toggleDeleteVersions)="exitModal(2, $event)" (updateVersions)="getVersions()" [parameters]="objectModal" [type]="3"></app-delete-component>
        <app-name-versions *ngIf="isShowCreateNameModal" (toggleModal)="toggleNameVersions()" (saveVersion)="getVersions($event)" [type]="'edit'" [parameters]="objectModal"></app-name-versions>
    </ng-container>
</div>
