<div class="loading">
    <section>
        <div class="loading-spinner "></div>
        <span>
            <span>{{getLanguages('changeTo')}} {{ instance.name }}</span>
        </span>
    </section>
    
  </div>


