<div class="all-content">
  <div class="top">
    {{ getLanguages('corporations') }}
  </div>
  <div class="row">
    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="text" (keyup.enter)="getCorporationTree()">
      </div>
      <button (click)="getCorporationTree()">
          {{ getLanguages('search') }}
      </button>
    </div>
  </div>

  <div class="content">
    <div class="title">
      <span>
        {{ getLanguages('corporations/tree') }}
      </span>
      <i class="fa-solid fa-chevron-right" [ngClass]="!openTree ? 'openned' : 'closed'" (click)="flipTree()"></i>
    </div>
    <app-loading *ngIf="isLoading"></app-loading>

      <div class="noData" *ngIf="noData && !isLoading">
        <div class="icon">
          <i class="fa-solid fa-exclamation"></i>
        </div>
        <span>
          {{ getLanguages('errors/noData')}}
        </span>
      </div>
    <div class="content-content">
      <div class="tree">
        <p-tree class="ptree"
          [value]="files"
          *ngIf="!isLoading && !noData">
          <ng-template let-node pTemplate="default">
            <div class="pcontent">
              <!-- <div class="ptitle" [ngStyle]="{'width': 'calc(100vw - 30vh - 40px - 1.3 * ' + getParentCount(node) + 'vw)',
                  'min-width': 'calc(500px - 16 * ' + getParentCount(node) + 'px)'}"> -->
              <div class="ptitle">
                <i [ngClass]="getClass(node)"> </i>
                <div [class.first-column]="isFirstColumn(node)">
                  <ng-container *ngFor="let itemArray of node.arrayText">
                    <div [class.highlighted]="itemArray === searchText" style="white-space: pre-wrap;">{{ itemArray }}</div>
                  </ng-container>
                   <ng-container *ngIf="!node.arrayText || node.arrayText.length === 0">
                    {{ (node && node.name ? node.name : '') }}
                  </ng-container>
                </div>
              </div>
              <div class="picons">
                <div class="button" matTooltip="{{ getLanguages('corporations/info/businessSolutions') }}"  (click)="navigate(node, '/business-solutions')"
                *ngIf="node.access && getSystemPermission('operation', 'business_solutions', 'screen_permission')  && (properties.me.is_admin != AdminGroup.OTHERS || properties.corporation.uuid == node.uuid)">
                  <i class='fas fa-folder-tree i'></i>
                </div>
                <div class="button" matTooltip="{{ getLanguages('corporations/info/personas') }}" (click)="navigate(node, '/personas')"
                *ngIf="node.access && getSystemPermission('operation', 'business_solutions', 'screen_permission')  && (properties.me.is_admin != AdminGroup.OTHERS || properties.corporation.uuid == node.uuid)">
                  <i class='fas fa-users i'></i>
                </div>
                <div class="button" matTooltip="{{ getLanguages('corporations/info/dimension') }}" (click)="toggleDimensionModalEdit($event, node)"
                *ngIf="node.access && getSystemPermission('register', 'corporations', 'dimensions') && (properties.me.is_admin != AdminGroup.OTHERS || properties.corporation.uuid == node.uuid)">
                  <i class='fas fa-pager i'></i>
                </div>
                <div class="button" matTooltip="{{ getLanguages('corporations/info/edit') }}" (click)="toggleFoundationModalEdit($event, node)"
                *ngIf="node.access && getSystemPermission('register', 'corporations', 'update') && (properties.me.is_admin != AdminGroup.OTHERS || properties.corporation.uuid == node.uuid)">
                  <i class='fas fa-pencil i'></i>
                </div>
                <div class="button" matTooltip="{{ getLanguages('corporations/info/delete') }}" (click)="toggleFoundationModalDelete($event, node)"
                *ngIf="node.access && !node.is_principal && (node.size == 0 || !node.size) && getSystemPermission('register', 'corporations', 'delete')
                && isShowDelete(node.uuid)
                ">
                  <i class="fas fa-solid fa-trash i"></i>
                </div>
                <div class="button" matTooltip="{{ getLanguages('corporations/info/newCorporation') }}" (click)="toggleFoundationModal($event, node)"
                *ngIf="node.access && getSystemPermission('register', 'corporations', 'create')">
                  <i class='fas fa-plus i'></i>
                </div>
              </div>
            </div>
          </ng-template>
        </p-tree>
      </div>
    </div>
  </div>
</div>


<div class="background-modals" *ngIf="isShowFoundationCreate || isShowFoundationEdit || isShowFoundationDelete || isShowDimensionEdit">
  <ng-container *ngIf="isShowFoundationCreate">
    <app-create-foundation (toggleFoundationModal)="toggleFoundationModal()" [properties]="properties" [parameters]="parameters" (getCorporationTree)="getCorporationTree()"></app-create-foundation>
  </ng-container>

  <ng-container *ngIf="isShowFoundationEdit">
    <app-edit-foundation (toggleFoundationModalEdit)="toggleFoundationModalEdit()" [properties]="properties" [parameters]="parameters" (getCorporationTree)="getCorporationTree()"></app-edit-foundation>
  </ng-container>

  <ng-container *ngIf="isShowFoundationDelete">
    <app-delete-component (toggleFoundationModalDelete)="toggleFoundationModalDelete()" (getCorporationTree)="getCorporationTree()" [parameters]="parameters" [type]="2"></app-delete-component>
  </ng-container>

  <ng-container *ngIf="isShowDimensionEdit">
      <app-create-dimensions (toggleDimensionModalEdit)="toggleDimensionModalEdit()" [properties]="properties" [parameters]="parameters" (getCorporationTree)="getCorporationTree()"></app-create-dimensions>
  </ng-container>
</div>
