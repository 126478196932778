export const asyncForEach = async (array: any, callback: any) => {
  for (let index = 0; index < array.length; index++) {
    try {
      await callback(array[index], index, array);
    }
    catch (error: any) {
      console.error(`Erro na posição ${index}: ${error}`);
      break;
      }
    }
  };
