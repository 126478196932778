import {asyncForEach} from "./asyncForEach";
import {to64decode} from "./base64";

export function cookieToJson (onlyLanguage: boolean = false) {
  let array: any = document.cookie.split("; ")

  let includes: any = ['token']

  let json: any = {}
  asyncForEach(array, (item: any) => {
    if (item == '') return;

    let split: any = item.split('=')

    split[1] = decodeURIComponent(split[1])

    if (split[0] != 'language' && onlyLanguage) return;

    if (split[0] == 'user') split[0] = 'me'

    if (includes.includes(split[0])) {
      json[split[0]] = split[1]
    } else {
      json[split[0]] = to64decode(split[1])
    }
  })
  return json;
}

export async function cookieToJsonAsync (onlyLanguage: boolean = false) {
  let array: any = document.cookie.split("; ")

  let includes: any = ['token']

  let json: any = {}
  await asyncForEach(array, (item: any) => {
    if (item == '') return;

    let split: any = item.split('=')

    split[1] = decodeURIComponent(split[1])

    if (split[0] != 'language' && onlyLanguage) return;

    if (split[0] == 'user') split[0] = 'me'

    if (includes.includes(split[0])) {
      json[split[0]] = split[1]
    } else {
      json[split[0]] = to64decode(split[1])
    }
  })
  return json;
}

export async function localStorageToJsonAsync(onlyLanguage: boolean = false) {
  let keys = Object.keys(localStorage);
  let includes = ['token'];
  let json: any = {};

  await asyncForEach(keys, async (key: string) => {
    let value = localStorage.getItem(key);
    if (value === null || value === undefined || value === '') {
      return;
    }

    // Tratamento para decodificar valores, se necessário
    let decodedValue = decodeURIComponent(value);

    if (key !== 'language' && onlyLanguage) {
      return;
    }

    if (key === 'user') {
      key = 'me';
    }

    if (includes.includes(key)) {
      json[key] = decodedValue;
    } else {
      json[key] = to64decode(decodedValue);
    }
  });

  return json;
}
