<div class="all-content">

    <div class="div-config shadow-component">
      <div class="top">
        <span> {{ getLanguages('businessSolution/top/settings') }} </span>
        <div class="search">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input type="text" [(ngModel)]="searchText" (input)="expandTreeAndHighlight(searchText)">
        </div>
      </div>
  
      <div class="content">
        <div class="left">
  
        </div>
  
        <!-- <div class="right">
          <button matTooltip="{{ getLanguages('businessSolution/top/info/export') }}">
            <i class="fa-solid fa-download"></i>
          </button>
          <button matTooltip="{{ getLanguages('businessSolution/top/info/import') }}">
            <i class="fa-solid fa-upload"></i>
          </button>
          <button matTooltip="{{ getLanguages('businessSolution/top/info/template') }}">
            <i class="fa-solid fa-file-arrow-up"></i>
          </button>
        </div> -->
      </div>
    </div>
  
    <div class="div-tree shadow-component">
      <div class="top">
        <div class="title">
          <span>
            {{ getLanguages('corporation/content/top') }}
          </span>
        </div>
  
        <div class="buttons">
          <button matTooltip="{{ getLanguages('businessSolution/content/info/expand') }}" (click)="expandAllNodes()">
            <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
          </button>
          <button matTooltip="{{ getLanguages('businessSolution/content/info/collapse') }}" (click)="collapseAllNodes()">
            <i class="fa-solid fa-down-left-and-up-right-to-center"></i>
          </button>
          
  
        </div>
  
      </div>
  
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="foundation-tree">
        <mat-tree-node *matTreeNodeDef="let node; let level = level; let first = first; let last = last"
          matTreeNodeToggle>
          <div (click)="expandedNode(node)" class="node" [style.--level]="level"
            [ngClass]="{'closed': !node.expanded, 'selected': node === selectedNode }"
            matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <div class="node-left">
              <div class="icon">
                <i class='{{node.size === 0 ? "fa-solid" : node.expanded}} {{node.icon}}'></i>
              </div>
              <span class="name"  [ngClass]="{'span-principal': node.isPrincipal, '': !node.isPrincipal,}" >
                <ng-container *ngFor="let itemArray of node.arrayText">
                  <span *ngIf="itemArray === searchText" [class.highlighted]="itemArray === searchText">{{itemArray}}</span>
                  <span *ngIf="itemArray !== searchText">{{itemArray}}</span>
                </ng-container>
                <ng-container *ngIf="!node.arrayText || node.arrayText.length === 0">
                  {{ node.name }}
                </ng-container>
              </span>
            </div>
            <div class="icon">
              <button (click)="togleCreateProfileEdit($event, node)" *ngIf="!node.isPrincipal">
                <i class='fas fa-pencil node-icon'></i>
              </button>
              <button (click)="togleCreateProfile($event, node)">
                <i class='fas fa-plus node-icon'></i>
              </button>
              <i class='{{node.size === 0 ? " fas fa-minus " : node.expanded ? "fas fa-chevron-up" : "fas fa-chevron-down"}}'></i>
            </div>
          </div>
        </mat-tree-node>
        <mat-nested-tree-node
          *matTreeNodeDef="let node; let level = level; let first = first; let last = last; when: hasChild">
          <div (click)="expandedNode(node)" class="node" [style.--level]="level"
            [ngClass]="{'closed': !node.expanded, 'selected': node === selectedNode }"
            matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <div class="node-left">
              <div class="icon">
                <i class='{{!treeControl.isExpanded(node) ? node.expanded : "fa-solid"}} {{node.icon}}'></i>
              </div>
              <span class="name"  [ngClass]="{'span-principal': node.isPrincipal, '': !node.isPrincipal,}" >
                <ng-container *ngFor="let itemArray of node.arrayText">
                  <span *ngIf="itemArray === searchText" [class.highlighted]="itemArray === searchText">{{itemArray}}</span>
                  <span *ngIf="itemArray !== searchText">{{itemArray}}</span>
                </ng-container>
                <ng-container *ngIf="!node.arrayText || node.arrayText.length === 0">
                  {{ node.name }}
                </ng-container>
              </span>
            </div>
            <div class="icon">
              <button (click)="togleCreateProfileEdit($event, node)" *ngIf="!node.isPrincipal">
                <i class='fas fa-pencil node-icon'></i>
              </button>
              <button (click)="togleCreateProfile($event, node)">
                <i class='fas fa-plus node-icon'></i>
              </button>
              <i class='{{node.size === 0 ? " fas fa-minus " : treeControl.isExpanded(node) ? "fas fa-chevron-up" : "fas fa-chevron-down"}}'></i>
            </div>
          </div>
          <div [class.foundation-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
  
  
      <div class="div-spinner" *ngIf="isLoading">
        <c-spinner variant="grow" class="spinner"></c-spinner>
        <span>{{ getLanguages('loading') }}</span>
      </div>
    </div>
  </div>
  