import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, Renderer2, HostListener, ViewChildren } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { v4 } from "uuid";
import {addAlert, apiOfflineAsync, setValueById} from "../../../../layouts/pages/pages.component";
import { asyncForEach } from "../../../../helpers/asyncForEach";
import { invertDate } from "../../../../helpers/invertDate";
import * as moment from "moment";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['name','action'];
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  frequencyArray: any = []
  searchFrequency: string = '';
  selectedFrequency: any = {
    name: {
    }
  }

  is_new_indicator: boolean = true;

  currentIndicator: any = {}


  constructor(
    private businessSolutionsService: BusinessSolutionsService,
    private cookieService: CookieService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    await this.getOptionsArray()
    this.dataSource.data = this.parameters.item.indicators ?? []
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isData(){
    return this.dataSource.data.length > 0
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dataSource.data.slice(startIndex, endIndex);
  }

  getColumns(){
    return this.displayedColumns.slice(1)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.currentPage = newPage;
  }

  getTotalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.itemsPerPage);
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  choseItemSelect(type: string, value: any, item: any){
    switch (type) {
      case 'frequency':
        this.selectedFrequency = value;
        item.frequency = value.slug;
        break;
      default:
        break;
    }
  }

  async getOptionsArray() {
    this.frequencyArray = sortArrayOfObjects(this.parameters.optionsArray.frequency)
  }

  async formatDate(property: any) {
    // @ts-ignore
    let value = document.getElementById(`indicators_${property}`).value
    let formattedValue = value.replace(/\D/g, "");

    const maxLength = 8;

    if (formattedValue.length > maxLength) {
      formattedValue = formattedValue.substr(0, maxLength);
    }

    this.currentIndicator[property] = null

    let day = moment().format('DD')
    if (formattedValue.length >= 0) {
      day = formattedValue.substr(0, 2)
      value = `${day}`
    }

    let month = moment().format('MM')
    if (formattedValue.length >= 2) {
      month = formattedValue.substr(2, 2)
      value += `/${month}`
    }

    let year = moment().format('YYYY')
    if (formattedValue.length >= 4) {
      year = formattedValue.substr(4, 4)
      value += `/${year}`
    }
    setValueById(`indicators_${property}`, value)

    if (formattedValue.length >= 8) this.currentIndicator[property] = value;
  }

  async selectIndicator(item: any){
    this.currentIndicator = { ...item }
    this.is_new_indicator = false;
    this.selectedFrequency = { name: '' }
    this.frequencyArray.forEach((frequency:any) => {
      if(frequency.slug == item.frequency){
        this.selectedFrequency = frequency
      }
    });

    await asyncForEach(['start_date', 'end_date'], async (property: string) => {
      setValueById(`indicators_${property}`, await invertDate(this.currentIndicator[property], 'br'))
    })
  }

  async newIndicator(){
    this.selectedFrequency = { name: '' }
    this.currentIndicator = {
      uuid: v4(),
      name: '',
      absolute: '',
      maximum: '',
      minimum: '',
      average: '',
      frequency: '',
      start_date: '',
      end_date: '',
      description: '',
      technical_script: ''
    };

    setValueById('indicators_start_date')
    setValueById('indicators_end_date')
    this.is_new_indicator = true;
  }

  async saveIndicator(){
    let inputs: any = []

    if(!this.parameters.isEdit){
      return
    }

    if (!this.currentIndicator.name || this.currentIndicator.name == '') {
      inputs.push('Name')
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return
    }

    if(this.is_new_indicator){
      this.dataSource.data.push(this.currentIndicator)
    }else{
      this.dataSource.data.find((item: any, index: number) => {
        if(item.uuid == this.currentIndicator.uuid){
          this.dataSource.data[index] = this.currentIndicator
        }
      })
    }

    await this.setIndicatorDate()
    await this.newIndicator()
  }

  async setIndicatorDate (type: string = 'us') {
    await asyncForEach(this.dataSource.data, async (item: any, index: number) => {
      this.dataSource.data[index] = await this.setIndicatorDateValue(type, item)
    })
  }

  async setIndicatorDateValue (type: string = 'us', item: any, properties = ['start_date', 'end_date']) {
    await asyncForEach(properties, async (property: any) => {
      if (item[property]) item[property] = await invertDate(item[property])
    })
    return item
  }

  async setClearInputDate (property: string) {
    this.currentIndicator[property] = null
    setValueById(`indicators_${property}`)
  }

  async deleteIndicator(uuid: string) {
    this.dataSource.data = this.dataSource.data.filter(item => item.uuid !== uuid);
    this.parameters.item.indicators = this.dataSource.data
    await this.newIndicator()
  }
}
