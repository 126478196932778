import { Injectable, Query } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse, IResponseBusiness } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  parameters: any = {}
  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // outros cabeçalhos personalizados aqui, se necessário
    })
  };

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return null;
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  private getURLSearchParams({
    environment,
    corporation,
    profile,
    user,
    screen,
    action,
    current,
    total,
    text,
    date
  }: any = {}) {

    let params = new URLSearchParams();
    if (environment && environment.length > 0) {
      params.set('environments', JSON.stringify(environment));
    }

    if (corporation && corporation.length > 0) {
      params.set('corporations', JSON.stringify(corporation));
    }

    if (profile && profile.length > 0) {
      params.set('profiles', JSON.stringify(profile));
    }

    if (user && user.length > 0) {
      params.set('users', JSON.stringify(user));
    }

    if (screen && screen.length > 0) {
      params.set('screens', JSON.stringify(screen));
    }

    if (action && action.length > 0) {
      params.set('actions', JSON.stringify(action));
    }

    if (current) {
      params.set('current', current);
    }

    if (total) {
      params.set('total', total);
    }

    if (text) {
      params.set('text', text);
    }

    if (date && date.start) {
      params.set('date_start', date.start);
    }

    if (date && date.end) {
      params.set('date_end', date.end);
    }
    return params.toString();
  }

  getFilterStructure(): Observable<IResponse> {
    const url = `${this.API_PATH}activities/filter/structure`
    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

  getFilter(data: any){
    const url = `${this.API_PATH}activities/filter?${this.getURLSearchParams({ ...data })}`
    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

  getActivities(data: any){
    const url = `${this.API_PATH}activities?${this.getURLSearchParams({ ...data })}`
    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

}
