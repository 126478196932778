<div class="all-content">
  <div class="top">
    <div class="toggle-user"></div>
    <div class="div-icon-button">
      <!-- <div class="save" (click)="saveUsersEnvironments()" id="button_save_environment">
        {{ getLanguages('save')}}
      </div> -->
      <i class="fas fa-xmark" (click)="exitModal()" id="exit-icon-environment"></i>
    </div>
  </div>

  <div class="row">
    <div class="title">
      {{ getLanguages('instances/modal/user/title')}}
    </div>
  </div>

  <!-- <div class="row">
    <div class="roll-user">
      <span [ngClass]="viewTab == 1 ? 'active' : ''" (click)="choseViewTab(1)"> {{ getLanguages('instances/modal/user/instances')}} </span>
      <span [ngClass]="viewTab == 2 ? 'active' : ''" (click)="choseViewTab(2)"> {{ getLanguages('instances/modal/user/register')}} </span>
    </div>
  </div> -->

  <div class="row config">
    <div class="item w-2"*ngIf="viewTab == 1">
      <span> {{ getLanguages('instances/modal/user/nameInstance')}} </span>
      <input type="text" [ngModel]="parameters.environment.name" disabled >
    </div>
    <div class="item w-2" *ngIf="viewTab == 2">
      <span> {{ getLanguages('instances/modal/user/nameInstance')}} </span>
      <div class="select-nav" [matMenuTriggerFor]="selectInstance" >
        <span>
          <ng-container *ngFor="let instance of selectedInstancesNames; let last = last">
            {{ instance }}{{ !last ? ', ' : '' }}
          </ng-container>
        </span>
        <i class="fas fa-caret-down"></i>
      </div>
      <mat-menu #selectInstance="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchInstance" placeholder="Search" (ngModelChange)="filterInstance()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let instance of filteredInstanceArray">
                <div class="item-menu"
                (click)="toggleInstanceArray(instance, $event)"
                [ngClass]="instance.click || instance.slug == parameters.environment.slug ? 'clicked-instance' : '' "
                *ngIf="instance?.name?.toLowerCase().includes(searchInstance?.toLowerCase())">
                <mat-checkbox [checked]="instance.click || (instance.slug == parameters.environment.slug)" [disabled]="instance.slug == parameters.environment.slug" [color]="task.color"></mat-checkbox>
                  <span>
                    {{ instance.name }}
                  </span>
                </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>

    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="searchText">
      </div>
      <button (click)="searchUser()">
          {{ getLanguages('search') }}
      </button>
    </div>

    <div class="div-search button-new-user">
      <button (click)="togleCreateUser()" id="new_users">
          {{ getLanguages('instances/modal/user/newUser') }}
      </button>
    </div>

  </div>

  <div class="row content">
    <div class="div-table" *ngIf="isData() && !isLoading">
      <table mat-table [dataSource]="getDisplayedRows()" class="table">
<!--        <ng-container matColumnDef="Id">-->
<!--          <th mat-header-cell *matHeaderCellDef>-->
<!--            <span> {{ getLanguages('instances/modal/user/id') }} </span> </th>-->
<!--          <td mat-cell *matCellDef="let element" (click)="initModalViewUser(element)" class="pointer">-->
<!--            <span> {{element.id}} </span>-->
<!--          </td>-->
<!--        </ng-container>-->

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>
              <span> {{ getLanguages('instances/modal/user/name') }} </span> </th>
            <td mat-cell *matCellDef="let element" (click)="initModalViewUser(element)" class="pointer">
            <span> {{element.name}} </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Telephone">
            <th mat-header-cell *matHeaderCellDef>
              <span> {{ getLanguages('instances/modal/user/telephone') }} </span> </th>
            <td mat-cell *matCellDef="let element" (click)="initModalViewUser(element)" class="pointer">
                <span> {{element.telephone}} </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef >
              <span>  {{ getLanguages('instances/modal/user/email') }} </span> </th>
            <td mat-cell *matCellDef="let element" (click)="initModalViewUser(element)" class="pointer">
              <span> {{element.email}} </span>
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="Access">
          <th mat-header-cell *matHeaderCellDef >
            <span>  {{ getLanguages('instances/modal/user/access') }} </span> </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox *ngIf="element.uuid != properties.me.uuid"
              [checked]="element.access"
              [color]="task.color"
              (click)="choseAccess(element)"
              class="checkbox"
              >
              {{task.name}}
            </mat-checkbox>
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="userInstance.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: userInstance.displayedColumns;"></tr>
      </table>
    </div>
    <div class="no-data" *ngIf="!isData() && !isLoading">

      <div class="circle">
        <i class="fas fa-exclamation"></i>
      </div>

      {{ getLanguages('errors/noData') }}
    </div>
    <div class="loading" *ngIf="isLoading">
      <section>
          <div class="loading-spinner"></div>
          <span class="span">
              {{ getLanguages('loading') }}
          </span>
      </section>
  </div>
  </div>


  <div class="row" *ngIf="isData() && !isLoading">
    <div class="div-paginator">
      <div class="paginator">
        <span>
          {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
        </span>
        <button [matMenuTriggerFor]="menu" class="itens-per-page">
          {{ getItemsPerPage() }}
        </button>
        <mat-menu #menu="matMenu" >
          <button mat-menu-item (click)="choseItemsPage(5)">5</button>
          <button mat-menu-item (click)="choseItemsPage(10)">10</button>
        </mat-menu>
        <span>
          {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
        </span>
        <span class="totalPages">
          {{ getTotalPages()}}
        </span>
        <button
          [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() === 1"
          (click)="onPageChange(getCurrentPages() - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <input type="number" [ngModel]="getCurrentPages()"  (blur)="checkValue($event)">
        <button
          [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() == getTotalPages()"
          (click)="onPageChange(getCurrentPages() + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="save" (click)="saveUsersEnvironments()">
      {{ getLanguages('save')}}
    </div>
  </div> -->
</div>

<div class="background-modals" *ngIf="isShowUser || isShowCreateUser">
  <app-view-user *ngIf="isShowUser" (toggleInstanceViewUserModal)="toggleInstanceViewUserModal($event)"  [parameters]="dataUser"></app-view-user>
  <app-create-user *ngIf="isShowCreateUser" (toggleUserModal)="togleCreateUser()" [properties]="properties" [parameters]="parameters" (getUsers)="getUsers()"></app-create-user>
</div>
