<div class="all-content">
    <div class="top">
        {{ getLanguages('maturity/title') }}
    </div>
    <div class="row">    
        <div class="div-search">
            <div class="search">
                <input type="text" [(ngModel)]="searchText" (keyup.enter)="search()">
            </div>
            <button (click)="search()">
                {{ getLanguages('search') }}
            </button>
        </div>   
        <div class="buttons-maturity">
            <button (click)="setQuestions()" class="button" [disabled]="!isEdited" [ngClass]="!isEdited ? 'disabled no-pointer' : ''">
                {{ getLanguages('save') }}
            </button>
            <button (click)="newQuestion()" class="button">
                {{ getLanguages('maturity/new') }}
            </button>
        </div>
    </div>

    <div class="cards">
        <div class="item" *ngFor="let card of getDisplayedRows()">
            <textarea #textarea *ngIf="card.is_new" type="text" class="text-input" [(ngModel)]="card.question" (input)="autoResize($event)"></textarea>
        
            <span class="span" *ngIf="!card.is_new">
                {{ card.question }}
            </span>

            <div class="icons-card">
                <div class="input">
                    <span> {{ getLanguages('maturity/active') }} </span>
                    <input checked type="checkbox" id="checkbox" [(ngModel)]="card.is_active" (click)="isEdited = true" [ngClass]="{'valid-check' : card.is_active}">
                </div>
            </div>
        </div>
        <div class="loading" *ngIf="isLoading">
            <section>
                <div class="loading-spinner"></div>
                <span class="span"> 
                    {{ getLanguages('loading') }}
                </span>
            </section>
        </div>
    
        <div class="noData" *ngIf="filteredDatasource.data.length == 0">
            <div class="icon">
                <i class="fa-solid fa-exclamation"></i>
            </div>
            <span>
                {{ getLanguages('errors/noData')}}
            </span>
        </div>
    </div>
    
    <div class="div-paginator" *ngIf="filteredDatasource.data.length > 0 && !isLoading">
        <div class="paginator">
            <span>
                {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
            </span>
            <button [matMenuTriggerFor]="menu" class="itens-per-page">
                {{ itemsPerPage }}
            </button>
            <mat-menu #menu="matMenu" >
                <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                <button mat-menu-item (click)="choseItemsPage(20)">20</button>
                <button mat-menu-item (click)="choseItemsPage(30)">30</button>
            </mat-menu>
            <span>
                {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
            </span>
            <span class="totalPages" >
                {{ getTotalPages()}}
            </span>
            <button [ngClass]="currentPage === 1 ? 'disabled-arrow' : 'arrow' " [disabled]="currentPage === 1 || isLoading" (click)="onPageChange(currentPage - 1)">
                <i class="fa-solid fa-angle-left"></i>
            </button>
            <input type="number" [(ngModel)]="currentPageReq" (keydown)="checkValue($event)" disabled>
            <button [ngClass]="currentPage == getTotalPages() ? 'disabled-arrow' : 'arrow' "  [disabled]="currentPage == getTotalPages() || isLoading" (click)="onPageChange(currentPage + 1)">
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </div>
    </div>
</div>
