import { Component, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-loading-instance',
  templateUrl: './loading-instance.component.html',
  styleUrls: ['./loading-instance.component.scss']
})
export class LoadingInstanceComponent {
  @Input() instance!: any;

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  constructor(
    private cookieService: CookieService,
    ) {}

  async ngOnInit(){

  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }
}
