import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { EventService } from 'src/app/services/event/event.service';
import { InstancesService } from 'src/app/services/instances/instances.service';
import { getLanguageCookie } from 'src/assets/language';
import {UserEnvironmentsService} from "../../../../services/userEnvironments/user-environments.service";
import {EnvironmentsService} from "../../../../services/environments/environments.service";
import {UsersService} from "../../../../services/users/users.service";
import {asyncForEach} from "../../../../helpers/asyncForEach";
import {HttpHeaders} from "@angular/common/http";
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';


@Component({
  selector: 'app-user-instances',
  templateUrl: './user-instances.component.html',
  styleUrls: ['./user-instances.component.scss']
})
export class UserInstancesComponent {
  @Input() properties: any = {}
  @Input() parameters: any = {}

  @Output() toggleInstanceUserModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getInstances: EventEmitter<void> = new EventEmitter<void>();

  private header: any = []
  private requestOptions: any = []

  viewTab = 1;
  instances: any = [{}]
  searchInstance: string = ""
  filteredInstanceArray: any = [{}];

  selectedInstancesNames: any = []
  selectedInstances: any = []

  searchText: any;

  task: any = {
    color:  'accent'
  };

  data: any = {
   //valor daquela instancia passada por parametro
  }

  dataUser: any = {}

  userAll: any = {
    currentRowIndex: 0,
    currentPage: 1,
    itemsPerPage: 5,
    searchText: '',
    isLoading: false,
    // displayedColumns: ['Id', 'Name', 'Telephone', 'Email'],
    displayedColumns: ['Name', 'Telephone', 'Email'],
    dataSource: {},
    filteredDataSource: {},
  }

  userInstance: any = {
    currentRowIndex: 0,
    currentPage: 1,
    itemsPerPage: 5,
    searchText: '',
    isLoading: false,
    // displayedColumns: ['Id', 'Name', 'Telephone', 'Email'],
    displayedColumns: ['Name', 'Telephone', 'Email'],
    dataSource: {},
    filteredDataSource: {},
  }

  isShowCreateUser = false
  isShowUser = false;
  isLoading = false;

  /**
   *
   * @param cookieService
   * @param instancesService
   * @param eventService
   * @param environmentsService
   * @param userEnvironmentsService
   * @param userService
   */
  constructor(
    private cookieService: CookieService,
    private instancesService: InstancesService,
    private eventService: EventService,
    private environmentsService: EnvironmentsService,
    private userEnvironmentsService: UserEnvironmentsService,
    private userService: UsersService
  ) {
    this.header['Content-Type'] = 'application/json';
    this.requestOptions = { headers: new HttpHeaders(this.header) };
  }

  /**
   *
   */
  async ngOnInit(){
    this.userAll.dataSource = new MatTableDataSource<any>([])
    this.userAll.filteredDataSource = new MatTableDataSource<any>([])
    this.userInstance.dataSource = new MatTableDataSource<any>([])
    this.userInstance.filteredDataSource = new MatTableDataSource<any>([])

    await this.getInstance()
    await this.getUsers();

    await this.selectedInstancesNames.push(this.parameters.environment.name)
    await this.selectedInstances.push(this.parameters.environment.uuid.toString())
  }

  /**
   *
   * @param file
   */
  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  /**
   *
   * @param hide
   */
  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.getInstances.emit()
    this.toggleInstanceUserModal.emit();
  }

  /**
   *
   * @param event
   */
  toggleInstanceViewUserModal(event?: void) {
    this.isShowUser = !this.isShowUser;
  }

  /**
   *
   * @param user
   */
  initModalViewUser(user: any){
    this.dataUser = user,
    this.isShowUser = true;
  }

  /**
   *
   * @param view
   */
  async choseViewTab(view: number){
    this.viewTab = view;
    this.searchText = ''
    if (this.viewTab == 1) {
      return await this.getUsers();
    }
    await this.getInstance()
    await this.getUsers()
  }

  /**
   *
   */
  async getInstance(){
    try {
      const response: any = await this.environmentsService.getEnvironments()
      if (response.status == 200 && response && response.data) {
        this.instances = response.data
        this.filteredInstanceArray = sortArrayOfObjects(this.instances)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   */
  async getUsers(){
    this.isLoading = true;
    try {
      let response: any
      if (this.viewTab == 1) {
        response = await this.userEnvironmentsService.getUserEnvironments(
          this.properties.me.uuid,
          [this.parameters.environment.uuid.toString()]
        )

        if (response && response.data) {
          this.userInstance.dataSource = new MatTableDataSource<any>(response.data)
          this.userInstance.filteredDataSource = new MatTableDataSource<any>(response.data)
        }
      }

      if (this.viewTab == 2) {
        response = await this.userService.getUsersSelectedEnvironment(this.selectedInstances)

        if (response && response.data) {
          this.userAll.dataSource = new MatTableDataSource<any>(response.data)
          this.userAll.filteredDataSource = new MatTableDataSource<any>(response.data)
        }
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;
  }

  /**
   *
   */
  customFilterPredicate(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      const searchTermLower = filter.toLowerCase();
      return (
        data.name.toLowerCase().includes(searchTermLower) ||
        data.telephone.toLowerCase().includes(searchTermLower) ||
        data.email.toLowerCase().includes(searchTermLower)
      );
    };
    return filterFunction;
  }

  // applyFilter(): void {
  //   this.userAll.filteredDataSource.filter = this.userAll.searchText.trim().toLowerCase();
  // }
  /**
   *
   * @param number
   * @param type
   */

  /**
   *
   * @param newPage
   * @param type
   */
  onPageChange(newPage: number): void {
    if(this.viewTab == 1){
      this.userInstance.currentPage = newPage;
    }
    if(this.viewTab == 2){
      this.userAll.currentPage = newPage;
    }
  }

  /**
   *
   * @param type
   */
  getDisplayedRows(): any[] {
    if(this.viewTab == 1){
      const startIndex = (this.userInstance.currentPage - 1) * this.userInstance.itemsPerPage;
      const endIndex = startIndex + this.userInstance.itemsPerPage;
      return this.userInstance.filteredDataSource.data.slice(startIndex, endIndex);
    }else if(this.viewTab == 2){
      const startIndex = (this.userAll.currentPage - 1) * this.userAll.itemsPerPage;
      const endIndex = startIndex + this.userAll.itemsPerPage;
      return this.userAll.filteredDataSource.data.slice(startIndex, endIndex);
    }
    return []
  }

  /**
   *
   * @param type
   */
  getTotalPages(): number {
    if(this.viewTab == 1){
      return Math.ceil(this.userInstance.filteredDataSource.data.length / this.userInstance.itemsPerPage);
    }else if(this.viewTab == 2){
      return Math.ceil(this.userAll.filteredDataSource.data.length / this.userAll.itemsPerPage);
    }
    return 1
  }

  /**
   *
   * @param event
   * @param type
   */
  async checkValue(event: any) {
    if(this.viewTab == 1){
      let total = await this.getTotalPages()
      if (this.userInstance.currentPage > total) {
        this.userInstance.currentPage = total;
      }
    }else if(this.viewTab == 2){
      let total = await this.getTotalPages()
      if (this.userAll.currentPage > total) {
        this.userAll.currentPage = total;
      }
    }
  }

  /**
   *
   */
  filterInstance() {
    this.filteredInstanceArray = this.instances.filter((intance: { name: string }) =>
      intance.name.toLowerCase().includes(this.searchInstance.toLowerCase())
    );
  }

  /**
   *
   * @param instance
   * @param event
   */
  async toggleInstanceArray(instance: any, event?: Event) {
    if (instance.slug == this.parameters.environment.slug) {
      return;
    }
    if(event){
      event.stopPropagation()
    }

    const clickedInstance = this.instances.find((inst: any) => inst === instance);

    if (clickedInstance) {
      clickedInstance.click = !clickedInstance.click;

      if (clickedInstance.click) {
        this.selectedInstancesNames.push(clickedInstance.name);
        this.selectedInstances.push(clickedInstance.uuid);
      } else {
        const index = this.selectedInstancesNames.indexOf(clickedInstance.name);
        if (index !== -1) {
          this.selectedInstancesNames.splice(index, 1);
          this.selectedInstances.splice(index, 1);
        }
      }
    }

    await this.getUsers()
  }

  /**
   *
   * @param user
   */
  choseAccess(user: any){
    user.access = !user.access
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  /**
   *
   */
  async validUsersEnvironmentsAccess () {
    let array = this.viewTab == 1 ? this.userInstance.dataSource.data : this.userAll.dataSource.data;
    let users: any = []
    await asyncForEach(array, async (item: any) => {
      users.push({ user_uuid: item.uuid, status: item.access})
    })

    return users;
  }

  /**
   *
   */
  async saveUsersEnvironments() {
    try {
      let usersArray: any = await this.validUsersEnvironmentsAccess()

      const response: any = await this.userEnvironmentsService.setUsersEnvironments(
        this.viewTab == 1 ? [this.parameters.environment.uuid.toString()] : this.selectedInstances,
        usersArray
      )

      if ([201, 202].includes(response.status))  {
        addAlert('success', response.message);
        await this.modalClose()
      } else {
        addAlert('danger', response.message);
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  /**
   *
   * @param type
   */
  searchUser() {
    // this.applyFilter();

    if(this.viewTab == 1){
      let searchText = this.searchText.toLowerCase()
      this.userInstance.filteredDataSource.data = this.userInstance.dataSource.data.filter(
        (item: { name: any; telephone: any; email: any; }) =>
        (item.name && item.name.toLowerCase().includes(searchText))
        || (item.email && item.email.toLowerCase().includes(searchText))
        || (item.telephone && item.telephone.toLowerCase().includes(searchText))
      )
    }

    if(this.viewTab == 2){
      let searchText = this.searchText.toLowerCase()

      this.userAll.filteredDataSource.data = this.userAll.dataSource.data.filter(
        (item: { name: any; telephone: any; email: any; }) =>
        (item.name && item.name.toLowerCase().includes(searchText))
        || (item.email && item.email.toLowerCase().includes(searchText))
        || (item.telephone && item.telephone.toLowerCase().includes(searchText))
      )
    }
  }

  getItemsPerPage(){
    if(this.viewTab == 1){
      return this.userInstance.itemsPerPage
    }
    return this.userAll.itemsPerPage
  }

  getCurrentPages(): number{
    if(this.viewTab == 1){
      return this.userInstance.currentPage
    }
    return this.userAll.currentPage
  }

  choseItemsPage(number: number, text?: string){
    if(this.viewTab == 1){
      this.userInstance.itemsPerPage = number;
    }else if(this.viewTab == 2){
      this.userAll.itemsPerPage = number;
    }
    this.getDisplayedRows()
  }

  isData(){
    if(this.viewTab == 1){
      return this.userInstance.filteredDataSource.data.length > 0
    }

    return this.userAll.filteredDataSource.data.length > 0
  }

  togleCreateUser(){
    this.isShowCreateUser = !this.isShowCreateUser
  }
}
