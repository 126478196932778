import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { AddCodeComponent } from '../../tree/add-code/add-code.component';
import { CookieService } from 'ngx-cookie-service';
import { getLanguageCookie } from 'src/assets/language';
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { v4 } from 'uuid';
import { to64decode } from 'src/app/helpers/base64';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

@Component({
  selector: 'app-crud-projects',
  templateUrl: './crud-projects.component.html',
  styleUrls: ['./crud-projects.component.scss']
})
export class CrudProjectsComponent {
  @Output() toggleModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getGuidelineProjectTree: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();

  @Input() properties: any = {}
  @Input() parameters: any = {}

  isEdit: boolean = false;
  temp: any = {}

  newNode: any


  statusArray: any = []
  searchStatus: string = '';
  selectedStatus: any = { 
    slug: 'in_backlog', 
    name: { 
      en: 'In backlog', 
      pt: 'Em backlog', 
      es: 'En backlog' 
    } 
  }


  typeArray: any = []
  searchType: string = '';
  selectedType: any = {
    name: {},
  }

  newType = {name: ''};
  createType: boolean = false
  /**
   *
   * @param cookieService
   */
  constructor(
    private cookieService: CookieService,
    private corporationService: CorporationService
  ) {

  }

  /**
   *
   */
  async ngOnInit() {
    this.temp = JSON.parse(JSON.stringify(this.parameters.item));
    await this.initCorporationGuidelineProjectType()
    await this.getGuidelineProjectType()

    this.typeArray.forEach((type:any) => {
      if(type.uuid == this.parameters.item.type_project){
        this.selectedType.name = type.name
      }
    });
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }
    this.toggleModal.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  async editBusinessTree() {
    this.isEdit = !this.isEdit;
    this.parameters.isEdit = this.isEdit

    if(!this.isEdit){
      this.parameters.item = JSON.parse(JSON.stringify(this.temp));
      this.ngOnInit()
    }
  }

  async getGuidelineProjectType() {
    try {
      const response: any = await this.corporationService.getGuidelineProjectType();
      if (response && response.data) {
        const array = [...this.parameters.optionsArray.guidelineProjectTypeEnum, ...response.data]
        this.typeArray.push(...sortArrayOfObjects(array))
      } else {
        throw new Error('Erro ao consultar tipos de projeto!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async initCorporationGuidelineProjectType(){
    this.typeArray = []
    if(this.properties?.permissions?.register?.corporations?.create){
      this.typeArray.push({name: 'New +'}) 
    }

  }

  async setGuidelineProjectType(){
    let flag = false;

    this.typeArray.forEach((item: any) => {
      if(item.name == this.newType.name){
        addAlert('orange', this.getLanguages('project_guideline/errors/type_already_registered'))
        flag = true
        return
      }
    });

    if(flag){
      return
    }

    try {
      const response:any = await this.corporationService.setGuidelineProjectType(this.newType);
      if (response && [201, 202].includes(response.status) ) {
        addAlert('success', response.message)
        await this.initCorporationGuidelineProjectType()
        await this.getGuidelineProjectType();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.createType = false;
    this.newType.name = ''
  }

  choseItemSelect(type: string, value: any){
    switch (type) {
      case 'status':
        this.selectedStatus = value;
        this.parameters.item.status = value.slug;
        break;
      case 'type':
        if(value.name == "New +"){
          this.createType = true;
          return
        }
        this.selectedType.name = value.name;
        this.parameters.item.type_project = value.uuid;
        break;
    }
  }

  async parametersValidation () {
    let inputs: any = []

    if(!this.parameters.item.label){
      inputs.push(this.getLanguages('project_guideline/name'))
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  async setTechnologiesTree() {
    if (!await this.parametersValidation()) {
      return
    }
    
    if (this.parameters.isCreate){
      this.newNode = {
        ...this.parameters.item,
        uuid: v4(),
        data: this.parameters.item.data,
        label: this.parameters.item.label,
        master: this.parameters.item.master,
        children: [],
        created_at: this.parameters.is_new ? this.formatDate(new Date()) : this.parameters.item.created_at,
        updated_at: this.formatDate(new Date()),
        userCreated:this.parameters.is_new ? this.properties?.me?.name : this.parameters.item.userCreated,
        userUpdated: this.properties?.me?.name ?? '',
      }

      if (this.newNode?.master == 'ROOT') {
        this.parameters.guidelineProject.push(this.newNode)
      } else {
        this.recursiveForEach(this.parameters.guidelineProject)
      }

      this.updateFiles.emit({ files: this.parameters.guidelineProject } )
      await this.modalClose()
      return
    }

    this.updateTree(this.parameters.guidelineProject, {...this.parameters.item,
      updated_at: this.formatDate(new Date()),
      userUpdated: this.properties?.me?.name ?? '',
    });

    this.updateFiles.emit({ files: this.parameters.guidelineProject, item: this.parameters.item });
    await this.modalClose();
  }

  recursiveForEach(array: any){
    array.forEach((item: any) => {
      if(item.uuid == this.parameters.item.master){
        item.children.push(this.newNode)
      }else{
        if(item.children.length > 0){
          this.recursiveForEach(item.children)
        }
      }
    });
  }

  updateTree(array: any, updatedItem: any) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      if (item.uuid === updatedItem.uuid) {
        array[i] = {
          ...item,
          ...updatedItem,
        };
      } else if (item.children && item.children.length > 0) {
        this.updateTree(item.children, updatedItem);
      }
    }
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(this.properties.permissions, module, screen, action, false)
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  shouldUseGetNames(item: any): boolean {
    return typeof item.name === 'object'; // Verifica se o name é um objeto
  }

  matchesSearch(name: any, search: string): boolean {
    if (typeof name === 'string') {
      return name.toLowerCase().includes(search.toLowerCase());
    } else if (name?.pt) {
      return name.pt.toLowerCase().includes(search.toLowerCase());
    } else if (name?.en) {
      return name.en.toLowerCase().includes(search.toLowerCase());
    }
    return false;
  }
  getDisplayName(): string {
    if (typeof this.selectedType?.name === 'string') {
      return this.selectedType.name;
    } else if (this.selectedType?.name?.pt) {
      return this.getNames(this.selectedType.name);
    } else {
      return '';
    }
  }
  

}
