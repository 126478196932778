<div class="all-content shadow-component">
    <div class="top">
        <span>
          {{ getLanguages('profiles/modal/create') }}
        </span>
        <div class="buttons">
            <button class="save" (click)="exitModal()">
                <i class="fa-solid fa-floppy-disk"></i>            
            </button>
            <button class="exit" (click)="exitModal()">
                <i class="fas fa-xmark"> </i>
            </button>
        </div>
        
    </div>
    <div class="content shadow-component">
        <div class="row">
            <div class="item-80">
                <span>{{ getLanguages('profiles/modal/corporateName') }}: </span>
                <input type="text">
            </div>
            <div class="item-20">
                <span>{{ getLanguages('profiles/modal/clone') }}: </span>
                <button [matMenuTriggerFor]="selectProfile" class="select-profile">
                  <div class="button-content">
                    <span> {{ selectedProfile.name}} </span>
                    <i class="fa-solid fa-caret-down"></i>
                  </div>
                </button>

                <mat-menu #selectProfile="matMenu">
                  <div class="menu">
                    <div class="input" (click)="$event.stopPropagation()" >
                      <input type="text" [(ngModel)]="searchProfile" placeholder="Search" (ngModelChange)="filterProfiles()">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <ng-container *ngFor="let profile of filteredProfileArray">
                      <button mat-menu-item (click)="choseProfile(profile)">
                        {{ profile.name }}
                      </button>
                    </ng-container>
                  </div>
                </mat-menu>
            </div>
        </div>
        <div class="row">
            <div class="item-80">
                <span>{{ getLanguages('profiles/modal/profileName') }}: </span>
                <input type="text">
            </div>
            <div class="item-20">
                <span>{{ getLanguages('profiles/modal/status') }}: </span>
                <button [matMenuTriggerFor]="selectStatus" matTooltip="Choose Status" class="select-parent">
                    <span> {{ selectedStatus }} </span>
                    <i class="fa-solid fa-caret-down"></i>
                  </button>

                  <mat-menu #selectStatus="matMenu">
                    <ng-container *ngFor="let status of statusArray">
                      <button mat-menu-item (click)="choseStatus(status)">
                        {{ status }}
                      </button>
                    </ng-container>
                  </mat-menu>            
                </div>
        </div>
        <div class="row">
            <div class="item-100">
                <span>{{ getLanguages('profiles/modal/description') }}: </span>
                <textarea></textarea>
            </div>
        </div>
        
        <div class="row">
          <div class="item-100">
            <div class="row div-menu">
              <div class="item-50 left">
                  <span>{{ getLanguages('profiles/modal/menu') }}: </span>
                  <button [matMenuTriggerFor]="selectModule" class="select-profile">
                    <div class="button-content">
                      <span> {{ getModuleName(data.selectedModule) }} </span>
                      <i class="fa-solid fa-caret-down"></i>
                    </div>
                  </button>
  
                  <mat-menu #selectModule="matMenu">
                    <div class="menu">
                      <div class="input" (click)="$event.stopPropagation()" >
                        <input type="text" [(ngModel)]="data.searchModule" placeholder="Search" (ngModelChange)="filterModules()">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <ng-container *ngFor="let module of data.filteredModuleArray">
                        <button mat-menu-item (click)="choseModule(module)">
                          {{ getModuleName(module.name) }}
                        </button>
                      </ng-container>
                    </div>
                  </mat-menu>
              </div>
              <div class="row">
                <div class="item-100" class="div-table">
                  <table mat-table [dataSource]="data.filteredDataSource" class="table">
                    <!-- Screens and Modules Column -->
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef> Modules </th>
                        <td mat-cell *matCellDef="let element;">
                          
                          <div class="div-module">
                            {{ getModuleName(element.name) }} 
                          </div>
                          
                          <div class="div-screens" *ngFor="let screen of element.screens">
                            {{ getModuleName(screen.window)  }}
                          </div>
                          
                        </td>
                    </ng-container>

                    <!-- List Column -->

                    <ng-container matColumnDef="list">
                      <th mat-header-cell *matHeaderCellDef> List </th>
                        <td mat-cell *matCellDef="let element;">
                          
                          <div class="div-checkbox-noborder">
                            <mat-checkbox [color]="task.color"></mat-checkbox>
                          </div>
                          
                          <div class="div-checkbox" *ngFor="let screen of element.screens;">
                            <mat-checkbox [color]="task.color" ></mat-checkbox>
                          </div>
                          
                        </td>
                    </ng-container>

                    <!-- Include Column -->

                    <ng-container matColumnDef="include">
                      <th mat-header-cell *matHeaderCellDef> Include </th>
                        <td mat-cell *matCellDef="let element;">
                          
                          <div class="div-checkbox-noborder">
                            <mat-checkbox [color]="task.color"></mat-checkbox>
                          </div>
                          
                          <div class="div-checkbox" *ngFor="let screen of element.screens;">
                            <mat-checkbox [color]="task.color" [(ngModel)]="screen.create" ></mat-checkbox>
                          </div>
                          
                        </td>
                    </ng-container>

                    <!-- Include Column -->

                    <ng-container matColumnDef="edit">
                      <th mat-header-cell *matHeaderCellDef> Edit </th>
                        <td mat-cell *matCellDef="let element;">
                          
                          <div class="div-checkbox-noborder">
                            <mat-checkbox [color]="task.color"></mat-checkbox>
                          </div>
                          
                          <div class="div-checkbox" *ngFor="let screen of element.screens;">
                            <mat-checkbox [color]="task.color" [(ngModel)]="screen.update" ></mat-checkbox>
                          </div>
                          
                        </td>
                    </ng-container>

                    <!-- Include Column -->

                    <ng-container matColumnDef="delete">
                      <th mat-header-cell *matHeaderCellDef> Delete </th>
                        <td mat-cell *matCellDef="let element;">
                          
                          <div class="div-checkbox-noborder">
                            <mat-checkbox [color]="task.color" ></mat-checkbox>
                          </div>
                          
                          <div class="div-checkbox" *ngFor="let screen of element.screens;">
                            <mat-checkbox [color]="task.color" [(ngModel)]="screen.delete" ></mat-checkbox>
                          </div>
                          
                        </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="data.displayedColumnsModule"></tr>
                    <tr mat-row *matRowDef="let row; columns: data.displayedColumnsModule;"></tr>
                  </table>
                  
                </div>
              </div>
            </div>
          </div>
          
        </div>
    </div>
</div>