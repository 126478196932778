<div class="all-content" *ngIf="!showCrop">
  <div class="top">
    <div class="toggle"></div>

    <div class="div-icon-button">
      <div class="save" (click)="setCorporation()" *ngIf="isEdit">
        {{ getLanguages('save')}}
      </div>
      <div class="save" (click)="editCancel()" [ngClass]="isEdit ? 'edit' : ''">
        {{ isEdit ? getLanguages('cancel'): getLanguages('edit')}}
      </div>
      <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>

  </div>
  <div class="row">
    <div class="title">
      {{ getLanguages('corporation/modal/edit') }}
    </div>
  </div>

  <div class="content-modal-corporation">
    <div class="left">
      <div class="image">
        <div class="icon"  *ngIf="!displayedImage">
          <i class="fas fa-upload"></i>
        </div>

        <img [src]="displayedImage" *ngIf="displayedImage"/>

        <button class="button-upload pointer" [disabled]="!isEdit" [ngClass]="!isEdit ? 'edit no-pointer' : ''" (click)="openFileInput()">
          {{ getLanguages('instances/content/upload_image') }}
        </button>
        <input type="file" class="button-upload" #fileInput style="display: none" (change)="fileChangeEvent($event)">

      </div>
    </div>

    <div class="right">
      <div class="row">
        <div class="item w-3" *ngIf="!isPrincipal">
          <span>{{ getLanguages('corporation/modal/referenceParent') }}</span>

          <button class="select-nav" [matMenuTriggerFor]="select" [disabled]="!isEdit"  [ngClass]=" !isEdit ? 'no-pointer disabled' : ''">
            <span>
                <ng-container *ngIf="selectedCorporation; else searchBlock">
                  {{ selectedCorporation.name }}
                </ng-container>
                <ng-template #searchBlock>
                    {{ getLanguages('search') }}
                </ng-template>
            </span>
            <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #select="matMenu">
            <div class="menu">
              <div class="input" (click)="$event.stopPropagation()">
                <input type="text" [(ngModel)]="corporation.search" placeholder="{{ getLanguages('search') }}" (input)="filterByArray('corporation')">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
                <ng-container *ngFor="let item of corporation.filteredArray">
                    <div class="item-menu" (click)="chooseDomainTree(item)" *ngIf="item.uuid != parameters.uuid">
                      <span [ngClass]="{'valid-span' : item.name == selectedCorporation.name}"> {{ item.name }} </span>
                    </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </div>
        <div class="item" [ngClass]="isPrincipal ? 'w-67' : 'w-3'">
          <span>{{ getLanguages('corporation/modal/corporationName') }} <b> * </b></span>
          <input [disabled]="!isEdit" type="text" id="input_corporation_name" [(ngModel)]="temp.corporation.corporation_name">
        </div>
        <div class="item w-3">
          <span>{{ getLanguages('corporation/modal/acronym') }}</span>
          <input [disabled]="!isEdit" type="text" [(ngModel)]="temp.corporation.acronym">
        </div>
        
      </div>

      <div class="row">
        <div class="item" [ngClass]="!isPrincipal ? 'w-3' : 'w-2'" >
          <span>{{ getLanguages('corporation/modal/followUp') }}</span>
          <button class="select-nav" [matMenuTriggerFor]="select" [disabled]="!isEdit" [ngClass]="!isEdit ? 'no-pointer disabled': ''">
            <span>
                <ng-container *ngIf="selectedSegment.name != 'Select'; else searchBlock">
                  {{ selectedSegment.name }}
                </ng-container>
                <ng-template #searchBlock>
                    {{ getLanguages('search') }}
                </ng-template>
            </span>
            <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #select="matMenu">
            <div class="menu">
              <div class="input" (click)="$event.stopPropagation()">
                <input type="text" [(ngModel)]="searchFollow" placeholder="{{ getLanguages('search') }}">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
                <ng-container *ngFor="let item of segmentArray">
                    <div class="item-menu" (click)="chooseSegment(item)" *ngIf="item.name.toLowerCase().includes(searchFollow.toLowerCase())">
                      <span [ngClass]="{'valid-span' : item.name == selectedSegment.name}"> {{ item.name }} </span>
                    </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </div>
        <div class="item w-3" *ngIf="!isPrincipal">
          <span>{{ getLanguages('corporation/modal/type') }}</span>
          <button class="select-nav" [matMenuTriggerFor]="selectTypeCorporation" [disabled]="!isEdit" [ngClass]="!isEdit ?  'no-pointer disabled' : ''">
            <span>
                <ng-container *ngIf="selectedCorporationType.name != 'Select'; else searchBlock">
                  {{ selectedCorporationType.name }}
                </ng-container>
                <ng-template #searchBlock>
                    {{ getLanguages('search') }}
                </ng-template>
            </span>
            <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #selectTypeCorporation="matMenu">
            <div class="menu">
              <div class="input" (click)="$event.stopPropagation()">
                <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}" (input)="filterByArray('type')">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
                <ng-container *ngFor="let typeCorporation of corporationTypeArray">
                    <div class="item-menu" (click)="chooseTypeCorporation(typeCorporation)" *ngIf="typeCorporation.name.toLowerCase().includes(searchType.toLowerCase())">
                      <span [ngClass]="{'valid-span' : typeCorporation.name == selectedCorporationType.name}"> {{ typeCorporation.name }} </span>
                    </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </div>
        <div class="item" [ngClass]="!isPrincipal ? 'w-3' : 'w-2'">
          <span>{{ getLanguages('corporation/modal/status') }}</span>
          <button class="select-nav" [matMenuTriggerFor]="status" [disabled]="!isEdit" [ngClass]="!isEdit ? 'no-pointer disabled': ''">
            <span>
                <ng-container>
                  {{ getNames(selectedStatus.label ) }}
                </ng-container>
            </span>
            <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #status="matMenu">
            <div class="menu">
              <div class="scroll">
                <ng-container *ngFor="let status of statusArray">
                    <div class="item-menu" (click)="chooseStatus(status)">
                      <span [ngClass]="{'valid-span' : status.value == temp.corporation.is_active}">
                        {{ getNames(status.label) }}
                      </span>
                    </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="background-modals" *ngIf="createType || createSegment">

  <div class="modal-create-type" *ngIf="createType">
    <div class="top">
      <div class="toggle"></div>

      <div class="div-icon-button">
        <div class="save" (click)="setCorporationType()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="createType = false"></i>
      </div>
    </div>

    <div class="row">
      <div class="title">
        {{ getLanguages('corporation/modal/newType/title') }}
      </div>
    </div>

    <div class="row">
      <div class="item">
        <span> {{ getLanguages('corporation/modal/newType') }}</span>
        <input type="text" [(ngModel)]="newType.name">
      </div>
    </div>
  </div>

  <div class="modal-create-type" *ngIf="createSegment">
    <div class="top">
      <div class="toggle"></div>

      <div class="div-icon-button">
        <div class="save" (click)="setSegment()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="createSegment = false"></i>
      </div>
    </div>

    <div class="row">
      <div class="title">
        {{ getLanguages('corporation/modal/newSegment/title') }}
      </div>
    </div>

    <div class="row">
      <div class="item">
        <span> {{ getLanguages('corporation/modal/newSegment') }}</span>
        <input type="text" [(ngModel)]="newSegment.name">
      </div>
    </div>
  </div>
</div>

<div class="showImageCrop" *ngIf="showCrop">
  <div class="content-crop">
    <i class="fas fa-xmark" (click)="saveAndClose(false)"></i>
    <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 4"
    format="png"
    (imageCropped)="imageCropped($event)"
    ></image-cropper>

    <button (click)="saveAndClose()">
      {{ getLanguages('save')}}
    </button>
  </div>
</div>