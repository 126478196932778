import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { ClipboardService } from 'src/app/services/clipboard/clipboard.service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss']
})
export class ClipboardComponent {
  searchText: string = ''

  isLoading!: boolean;
  noData!: boolean;

  dataSource!: MatTableDataSource<any>;
  filteredDatasource!: MatTableDataSource<any>;

  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  constructor(
    private cookieService: CookieService,
    private clipboardService: ClipboardService,
    public router: Router,
    ){
      this.dataSource = new MatTableDataSource();
      this.filteredDatasource = new MatTableDataSource();
  }

  async ngOnInit(){
    await this.getNodeInClipboard()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredDatasource.data.slice(startIndex, endIndex);
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
  }

  onPageChange(newPage: number){
    this.currentPage = newPage;
    this.currentPageReq = this.currentPage;
  }

  getTotalPages() {
    return Math.ceil(this.filteredDatasource.data.length / this.itemsPerPage);
  }

  search() {
    const searchText = this.searchText.toLowerCase()
    this.filteredDatasource.data = this.dataSource.data.filter((item: any) =>
    item.node.label.toLowerCase().includes(searchText.toLowerCase()) ||
    item.corporation.corporation_name.toLowerCase().includes(searchText.toLowerCase()));
  }

  async checkValue(event: any) {
    if (event.key === "Enter") {
      if(this.currentPageReq > this.getTotalPages()){
        this.currentPageReq = this.getTotalPages()
        this.currentPage = this.currentPageReq
        return
      }

      if(this.currentPageReq < 1){
        this.currentPageReq = 1
        this.currentPage = this.currentPageReq
        return
      }

      this.currentPage = this.currentPageReq
    }
  }

  async getNodeInClipboard() {
    this.isLoading = true;

    try {
      const response: any = await this.clipboardService.getNodeInClipboard().toPromise();
      if (response && response.data) {
        this.dataSource.data = response.data
        this.filteredDatasource.data = response.data
      } else {
        throw new Error('Erro ao consultar array de status!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

    this.isLoading = false;
  }

  copyToCookie(node: any){
     localStorage.setItem('node_clipboard', to64encode(node));
    this.router.navigate(['business-solutions']);
  }
}
