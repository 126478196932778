<div class="all-content">
    <div class="content-dimension">
        <div class="no-data" *ngIf="!isData()">

          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>

          {{ getLanguages('errors/noData') }}
        </div>

        <div class="table-attachment" *ngIf="isData()">
            <table *ngIf="getDisplayedRows().length !== 0"  mat-table [dataSource]="getDisplayedRows()" class="table">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="td-90">
                        <span> {{ getLanguages('attachment/name') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-90">
                        <span class="pointer" (click)="selectIndicator(row)"> {{ row.name }} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="td-10">
                        <span> {{ getLanguages('attachment/action') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-10">
                        <button (click)="deleteIndicator(row.uuid)" [disabled]="!parameters.isEdit">
                            <i class="fas fa-trash" [ngClass]="!parameters.isEdit ? 'disabled-icon' : 'icon'"></i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4"></td>
                </tr>
            </table>
        </div>
        <div class="div-paginator" *ngIf="isData()">
          <div class="paginator">
            <span>
              {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
            </span>
            <button [matMenuTriggerFor]="menu" class="itens-per-page">
              {{ getItemsPerPage() }}
            </button>
            <mat-menu #menu="matMenu" >
              <button mat-menu-item (click)="choseItemsPage(5)">5</button>
              <button mat-menu-item (click)="choseItemsPage(10)">10</button>
            </mat-menu>
            <span>
              {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
            </span>
            <span class="totalPages">
              {{ getTotalPages()}}
            </span>
            <button
              [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() === 1"
              (click)="onPageChange(getCurrentPages() - 1, 'strategy')">
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <input type="number" [ngModel]="getCurrentPages()"  (blur)="checkValue()">
            <button
              [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() == getTotalPages()"
              (click)="onPageChange(getCurrentPages() + 1, 'strategy')">
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
    </div>

    <div class="row row-end">
        <div class="button-upload" (click)="newIndicator()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/indicators/new_indicator') }}
        </div>
        <div class="button-upload" (click)="saveIndicator()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/indicators/save_indicator') }}
        </div>
    </div>

    <div class="row">
        <div class="item item-2">
          <span> {{ getLanguages('businessSolution/modal/indicators/name') }} </span>
          <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="currentIndicator.name">
        </div>

        <div class="item item-4">
            <span> {{ getLanguages('businessSolution/modal/indicators/absolute') }} </span>
            <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="currentIndicator.absolute">
          </div>

          <div class="item item-4">
            <span> {{ getLanguages('businessSolution/modal/indicators/maximum') }} </span>
            <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="currentIndicator.maximum">
          </div>
    </div>

    <div class="row">
        <div class="item item-5">
            <span> {{ getLanguages('businessSolution/modal/indicators/average') }} </span>
            <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="currentIndicator.average">
        </div>

        <div class="item item-5">
            <span> {{ getLanguages('businessSolution/modal/indicators/minimum') }} </span>
            <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="currentIndicator.minimum">
        </div>

        <div class="item item-5">
            <span> {{ getLanguages('businessSolution/modal/indicators/frequency') }} </span>
            <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="frequencyMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
              <span>
                {{ getNames(selectedFrequency.name) }}
              </span>
              <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
            </button>

            <mat-menu #frequencyMenu="matMenu">
              <div class="menu">
                <div class="input" (click)="$event.stopPropagation()">
                  <input type="text" [(ngModel)]="searchFrequency" placeholder="{{ getLanguages('search') }}">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll">
                  <ng-container *ngFor="let frequency of frequencyArray">
                    <ng-container *ngIf="getNames(frequency.name).toLowerCase().includes(searchFrequency.toLowerCase())">
                      <div class="item-menu" (click)="choseItemSelect('frequency', frequency, currentIndicator)">
                        <span [ngClass]="getNames(frequency.name) == getNames(selectedFrequency.name) ? 'valid-span' : 'invalid-span'">
                          {{ getNames(frequency.name) }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
        </div>

        <div class="item item-5">
            <span> {{ getLanguages('businessSolution/modal/indicators/start_date') }} </span>
            <div class="date">
                <input id="indicators_start_date" [matDatepicker]="picker_start" (keyup)="formatDate('start_date')" (dateChange)="formatDate('start_date')" [disabled]="!parameters.isEdit">
                <mat-datepicker #picker_start></mat-datepicker>
                <button (click)="setClearInputDate('start_date')" [hidden]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled-icon' : ''">
                  <i class="fa-solid fa-xmark"></i>
                </button>
                <button (click)="picker_start.open()" [hidden]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled-icon' : ''">
                  <i class="fa-solid fa-calendar-days"></i>
                </button>
            </div>
        </div>

        <div class="item item-5">
            <span> {{ getLanguages('businessSolution/modal/indicators/end_date') }} </span>
            <div class="date">
                <input id="indicators_end_date" [matDatepicker]="picker_end" (keyup)="formatDate('end_date')" (dateChange)="formatDate('end_date')" [disabled]="!parameters.isEdit">
                <mat-datepicker #picker_end></mat-datepicker>
                <button (click)="setClearInputDate('end_date')" [hidden]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled-icon' : ''">
                  <i class="fa-solid fa-xmark"></i>
                </button>
                <button (click)="picker_end.open()" [hidden]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled-icon' : ''">
                  <i class="fa-solid fa-calendar-days"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="row">
      <div class="item item-1">
          <section class="position-relative">
            <span class="button-tooltip"> {{ getLanguages('businessSolution/modal/integrations/script') }} <b class="icon-ai"> ai </b> </span>
            <div class="toggle-tooltip margin-left-tooltip">
              <div class="item-op">
                {{ getLanguages('ai/info') }}
              </div>
            </div>
          </section>
          <textarea [disabled]="!parameters.isEdit" [(ngModel)]="currentIndicator.technical_script"></textarea>
      </div>
    </div>

    <div class="row">
        <div class="item item-1">
          <section class="position-relative">
            <span class="button-tooltip"> {{ getLanguages('businessSolution/modal/addCode/description') }} <b class="icon-ai"> ai </b> </span>
            <div class="toggle-tooltip margin-left-tooltip">
              <div class="item-op">
                {{ getLanguages('ai/info') }}
              </div>
            </div>
          </section>
          <textarea [disabled]="!parameters.isEdit" [(ngModel)]="currentIndicator.description"></textarea>
        </div>
    </div>
</div>

