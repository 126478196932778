import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor() { 
  }

  //eventChild = new EventEmitte();
  eventModal = new EventEmitter<void>();
  eventModalEdit = new EventEmitter<void>();
  eventModalDelete = new EventEmitter<void>();
  eventModalFoundation = new EventEmitter<void>();
  eventModalFoundationEdit = new EventEmitter<void>();
  eventModalFoundationDelete = new EventEmitter<void>();
  eventModalUser = new EventEmitter<void>();
  eventModalUserEdit = new EventEmitter<void>();
  eventModalUserDelete = new EventEmitter<void>();
  eventModalDimensionEdit = new EventEmitter<void>();
  eventModalCreateInstance = new EventEmitter<void>();
  eventModalEditInstance = new EventEmitter<void>();
  eventModalUserInstance = new EventEmitter<void>();
  eventModalViewUserInstance = new EventEmitter<void>();
  eventModalImport = new EventEmitter<void>();
  eventModalProfile = new EventEmitter<void>();
  
  async emitModal(param: any){
    await this.eventModal.emit(param);
  }

  async emitModalEdit(param: any){
    await this.eventModalEdit.emit(param);
  }

  async emitDelete(param: any){
    await this.eventModalDelete.emit(param);
  }

  async emit(){
    await this.eventModal.emit();
  }

  async emitModalFoundation(param: any){
    await this.eventModalFoundation.emit(param);
  }

  async emitModalFoundationEdit(param: any){
    await this.eventModalFoundationEdit.emit(param);
  }

  async emitModalFoundationDelete(param: any){
    await this.eventModalFoundationDelete.emit(param);
  }

  async emitModalUser(param: any){
    await this.eventModalUser.emit(param);
  }

  async emitModalUserEdit(param: any){
    await this.eventModalUserEdit.emit(param);
  }

  async emitModalUserDelete(param: any){
    await this.eventModalUserDelete.emit(param);
  }

  async emitModalDimensionEdit(param: any){
    await this.eventModalDimensionEdit.emit(param);
  }

  async emitModalCreateInstance(param: any){
    await this.eventModalCreateInstance.emit(param);
  }

  async emitModalEditInstance(param: any){
    await this.eventModalEditInstance.emit(param);
  }
  
  async emitModalUserInstance(param: any){
    await this.eventModalUserInstance.emit(param);
  }

  async emitModalViewUserInstance(param: any){
    await this.eventModalViewUserInstance.emit(param);
  }

  async emitModalImport(param: any){
    await this.eventModalImport.emit(param);
  }

  async emitModalProfile(param: any){
    await this.eventModalProfile.emit(param);
  }
}
