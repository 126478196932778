import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() type: string = 'success';
  @Input() text: any;
  @Output() remove = new EventEmitter<any>();
  
  getClass(component: string){
    return this.type + ' ' + component
  }

  close(){
    this.remove.emit()
  }


}
