import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { IResponse } from "../../interfaces/IResponse";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { apiOfflineAsync } from 'src/app/layouts/pages/pages.component';

@Injectable({
  providedIn: 'root'
})
export class PageTechnologiesService {
  parameters: any = {}
  API_PATH: string
  isXls: boolean = false;
  isJson: boolean = false

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  requestOptionsFormData: any = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })

  constructor(
    private httpClient: HttpClient,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  /**
   *
   * @param body
   * @param uuid
   */
  async setTechnologiesTree(body: any, uuid: any = null) {
    if (!uuid) {
      return await this.httpClient.post<IResponse>(
        `${this.API_PATH}technologies`,
        body,
        this.requestOptionsFormData
      ).toPromise();
    }
    return await this.httpClient.put<IResponse>(
      `${this.API_PATH}technologies/${uuid}`,
      body,
      this.requestOptionsFormData
    ).toPromise();
  }

  /**
   *
   * @param parameters
   */
  getTechnologiesTree(parameters: any): Observable<IResponse> {
    this.parameters = parameters;
    let url = `${this.API_PATH}technologies`
    if(this.parameters.corporation_uuid){
      url = `${url}?corporation_uuid=${this.parameters.corporation_uuid}`
    }
    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url).pipe(takeUntil(this.ngUnsubscribe));
  }

  async getTreeXls(parameters: any): Promise<any> {
    this.parameters = parameters

    this.isJson = this.parameters.json
    this.isXls = this.parameters.xls

    try {
      let response = await this.httpClient.get<any>(
        `${this.API_PATH}technologies?${this.getURLSearchParams()}`,
        { responseType: 'blob' as 'json' }
      ).toPromise();

      this.isJson = false
      this.isXls = false
      return response;
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async downloadJson(response: any, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = `${fileName}.json`; // Substitua pelo nome desejado para o arquivo
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  async downloadJsonXls(response: any, fileName: string, type: string) {
    let blob: Blob;
    if (type === 'json') {
      return this.downloadJson(response,fileName);
    } else if (type === 'xls') {
      blob = new Blob([response], { type: 'application/vnd.ms-excel' });
    } else {
      throw new Error('Invalid type specified. Only "json" and "xls" are supported.');
    }

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.${type}`;

    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  
  private getURLSearchParams({ dimension, environment, corporation, search }: any = {}) {
    let params = new URLSearchParams();

    if (!environment) {
      environment = this.parameters.environment
    }

    if (!corporation) {
      corporation = this.parameters.corporation
    }

    if (environment && environment.slug) {
      params.set('environment', environment.slug);
    }

    if (corporation && corporation.slug) {
      params.set('corporation', corporation.slug);
    }

    if (dimension) {
      params.set('dimension', dimension);
    }

    if (search) {
      params.set('search', search);
    }

    if (this.isXls) {
      params.set('xls', this.isXls.toString());
    }

    if (this.isJson) {
      params.set('json', this.isJson.toString());
    }

    return params.toString();
  }

  async setTechnologiesImportXls(file: any) {

    let formData = new FormData()
    formData.append('file', file)

    return await this.httpClient.post<any>(
      `${this.API_PATH}technologies/import/xls`,
      formData,
      this.requestOptionsFormData
    ).toPromise()
  }

}