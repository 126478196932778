import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { AddCodeComponent } from '../add-code/add-code.component';
import { CookieService } from 'ngx-cookie-service';
import { getLanguageCookie } from 'src/assets/language';
import { CorporationDimensionsService } from "../../../../services/corporation-dimensions/corporation-dimensions.service";
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import { StrategyComponent } from '../strategy/strategy.component';
import { PeopleComponent } from '../people/people.component';
import { TechnologyComponent } from '../technology/technology.component';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { ComplementsComponent } from '../complements/complements.component';
import { ConfigurationsComponent } from '../configurations/configurations.component';
import { IntegrationsComponent } from '../integrations/integrations.component';
import { RulesComponent } from '../rules/rules.component';
import { IndicatorsComponent } from '../indicators/indicators.component';
import { AbaMaturityComponent } from '../maturity/maturity.component';
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { TransactionComponent } from '../transaction/transaction.component';
import { to64decode } from 'src/app/helpers/base64';
import { ProjectComponent } from '../project/project.component';
import { ExtendedAttributesComponent } from '../extended-attributes/extended-attributes.component';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class EditComponent {
  @ViewChild(AddCodeComponent) addCodeComponent!: AddCodeComponent;
  @ViewChild(StrategyComponent) strategyComponent!: StrategyComponent;
  @ViewChild(PeopleComponent) peopleComponent!: PeopleComponent;
  @ViewChild(TechnologyComponent) technologyComponent!: TechnologyComponent;
  @ViewChild(AbaMaturityComponent) maturityComponent!: AbaMaturityComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent!: AttachmentsComponent;
  @ViewChild(IndicatorsComponent) indicatorsComponent!: IndicatorsComponent;
  @ViewChild(ComplementsComponent) complementsComponent!: ComplementsComponent;
  @ViewChild(ConfigurationsComponent) configurationsComponent!: ConfigurationsComponent;
  @ViewChild(IntegrationsComponent) integrationsComponent!: IntegrationsComponent;
  @ViewChild(RulesComponent) rulesComponent!: RulesComponent;
  @ViewChild(TransactionComponent) transactionComponent!: TransactionComponent;
  @ViewChild(ProjectComponent) projectComponent!: ProjectComponent;
  @ViewChild(ExtendedAttributesComponent) extendedAttributes!: ExtendedAttributesComponent;

  @ViewChild('buttonContainer') buttonContainer!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  @Output() toggleModalEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() getBusinessTree: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitNavigateOriginNode: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() properties: any = {}
  @Input() parameters: any = {}

  protected readonly Object = Object;

  corporation: any = {}

  selectedFlag: any = { lang: 'en', file: 'us.svg' };

  nameProcess!: string;

  arrayDimension: any = []
  isEdit: boolean = false;
  viewDimension: number = 1;
  title = 'Add Code';
  icon = 'fa-diagram-next';

  columns: any = {
    strategy: {},
    people: {},
    technology: {}
  }

  dimensions: any = {
    strategy: [],
    people: [],
    technology: {}
  }

  item: any

  tabArray: any = []

  temp: any = {}
  isChangeTree: boolean = false;
  hasNameError: boolean = false
  /**
   *
   * @param businessSolutionsService
   * @param elementRef
   * @param cookieService
   * @param corporationDimensionsService
   */
  constructor(
    private businessSolutionsService: BusinessSolutionsService,
    private cookieService: CookieService,
    private corporationDimensionsService: CorporationDimensionsService,
  ) {
  }

  /**
   *
   */
  async ngOnInit() {
    this.selectedFlag = to64decode(localStorage.getItem('language'))
    await this.getDimensions()
    this.tabArray = this.parameters.optionsArray.tabArray
    await this.toggleDimension(1)
    await this.initDescription()
    await this.initIndicators()

    if(!this.parameters.item.parent){
      this.parameters.parent = {}
      this.parameters.parent.label = this.parameters.business
    }else{
      this.parameters.parent.label = this.parameters.item.parent.label
    }

    this.temp = JSON.parse(JSON.stringify(this.parameters.item));

    this.parameters.item.level = this.parameters.item.level > 0 ? this.parameters.item.level : 1

    if(this.parameters.guidelineArray[this.parameters.item.level - 1]?.tabs?.length > 0){
      this.tabArray = this.parameters.optionsArray.tabArray.filter(
        (tab: any) => this.parameters.guidelineArray[this.parameters.item.level - 1]?.tabs?.includes(tab.slug) 
        || tab.slug == 'addCode')
    }else{
      this.tabArray = this.parameters.optionsArray.tabArray
    }
  }

  ngAfterViewInit() {
    this.scrollContainer.nativeElement.scrollLeft = 0;
  }

  scrollLeft() {
    this.scrollContainer.nativeElement.scrollLeft -= 250;
  }

  scrollRight() {
    this.scrollContainer.nativeElement.scrollLeft += 250;
  }

  async initDescription(){
    this.parameters.item.description = this.parameters.item.description ?? {}
    this.parameters.item.description.importance = this.parameters.item.description.importance ?? '',
    this.parameters.item.description.frequency = this.parameters.item.description.frequency ?? '',
    this.parameters.item.description.fte = this.parameters.item.description.fte ?? 0,
    this.parameters.item.description.fte_cost = this.parameters.item.description.fte_cost ?? 0,
    this.parameters.item.description.sla_unit = this.parameters.item.description.sla_unit ?? ''
    this.parameters.item.description.sla = this.parameters.item.description.sla ?? '',
    this.parameters.item.description.description = this.parameters.item.description.description ?? '',
    this.parameters.item.description.link = this.parameters.item.description.link ?? '',
    this.parameters.item.description.strategy = this.parameters.item.description.strategy ?? ''
    this.parameters.item.description.people = this.parameters.item.description.people ?? ''
    this.parameters.item.description.skills = this.parameters.item.description.skills ?? ''
    this.parameters.item.description.technology = this.parameters.item.description.technology ?? ''
  }

  async initIndicators(){
    this.parameters.item.indicators = this.parameters.item.indicators ?? []
    this.parameters.item.complements = this.parameters.item.complements ?? []
    this.parameters.item.configurations = this.parameters.item.configurations ?? []
    this.parameters.item.integrations = this.parameters.item.integrations ?? []
    this.parameters.item.rules = this.parameters.item.rules ?? []
    this.parameters.item.transactions = this.parameters.item.transactions ?? []
    this.parameters.item.project = this.parameters.item.project ?? {}
    this.parameters.item.personas = this.parameters.item.personas ?? []
    this.parameters.item.personasClassification = this.parameters.item.personasClassification ?? []
    this.parameters.item.technologies_table = this.parameters.item.technologies_table ?? {}
    this.parameters.item.extendedAttributes = this.parameters.item.extendedAttributes ?? []
  }


  /**
   *
   */
  async getDimensions(){
    try{
      const response: any = await this.corporationDimensionsService.getDimensions()
      if(response.data){
        this.dimensions = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  /**
   *
   * @param number
   */
  async toggleDimension(number: number = 1){
    this.viewDimension = number
  }

  @HostListener('document:click', ['$event.target'])

  ngOnChanges(changes: SimpleChanges) {
    this.exitModal(false)
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    if(this.isEdit){
      this.isChangeTree = true;
      return
    }

    this.toggleModalEdit.emit();
  }

  /**
   *
   */
  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  updateText(json: any) {
    this.item = json.item
  }

  parametersValidation (): boolean {
    let inputs: any = []

    // this.parameters.item.code = this.parameters.inputs.code;
    // if (this.parameters.parent.code) {
    //   this.parameters.item.code = `${this.parameters.parent.code}.${this.parameters.inputs.code}`
    // }
    if(!this.parameters.item.label || this.parameters.item.label == ''){
      inputs.push(this.getLanguages('businessSolution/modal/addCode/process'))
    }
    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      this.hasNameError = true
      return false
    }
    return true
  }

  async editBusinessTree() {
    this.isEdit = !this.isEdit;
    this.parameters.isEdit = this.isEdit

    if(!this.isEdit){
      this.parameters.item = JSON.parse(JSON.stringify(this.temp));
      await this.getComponentDimension()
    }

  }

  async getComponentDimension() {
    switch (this.viewDimension) {
      case 1:
        if (this.addCodeComponent) {
          this.addCodeComponent.ngOnInit();
        }
        break;
      case 2:
        if (this.strategyComponent) {
          this.strategyComponent.ngOnInit();
        }
        break;
      case 3:
        if (this.peopleComponent) {
          this.peopleComponent.ngOnInit();
        }
        break;
      case 4:
        if (this.technologyComponent) {
          this.technologyComponent.ngOnInit();
        }
        break;
      case 5:
        if (this.maturityComponent) {
          this.maturityComponent.ngOnInit();
        }
        break;
      case 6:
        if (this.attachmentsComponent) {
          this.attachmentsComponent.ngOnInit();
        }
        break;
      case 7:
        if (this.indicatorsComponent) {
          this.indicatorsComponent.ngOnInit();
        }
        break;
      case 8:
        if (this.complementsComponent) {
          this.complementsComponent.ngOnInit();
        }
        break;
      case 9:
        if (this.configurationsComponent) {
          this.configurationsComponent.ngOnInit();
        }
        break;
      case 10:
        if (this.integrationsComponent) {
          this.integrationsComponent.ngOnInit();
        }
        break;
      case 11:
        if (this.rulesComponent) {
          this.rulesComponent.ngOnInit();
        }
        break;
      case 12:
        if (this.transactionComponent) {
          this.transactionComponent.ngOnInit();
        }
        break;
      case 13:
        if (this.projectComponent) {
          this.projectComponent.ngOnInit();
        }
        break;
      case 14:
        if (this.extendedAttributes) {
          this.extendedAttributes.ngOnInit();
        }
        break;
      default:
        return null;
    }
    
    return
  }

  async setBusinessTree() {
    await this.approveProcess(false)
  }

  updateTree(array: any, updatedItem: any) {
    let flag = false;
    let findItem: any = {};
    let findI = -1;

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if(item.uuid != updatedItem.uuid && item.master == updatedItem.master && updatedItem.label == item.label){
        this.hasNameError = true;
        addAlert('orange', this.getLanguages('errors/businessTree/SameNameElement'))
        flag = false
        return
      }else if (item.uuid === updatedItem.uuid) {
        findItem = item
        findI = i
        flag = true
      } else if (item.children && item.children.length > 0) {
        this.updateTree(item.children, updatedItem);
      }
    }

    if(flag){
      array[findI] = {
        ...findItem,
        ...updatedItem,
      };
    }
  }

  getDimension(){
    let value = ''
    if (this.viewDimension == 2) value = 'strategy'
    if (this.viewDimension == 3) value = 'people'
    if (this.viewDimension == 4) value = 'transactions'
    if (this.viewDimension == 5) value = 'technology'
    if (this.viewDimension == 6) value = 'rules'
    if (this.viewDimension == 7) value = 'maturity'
    if (this.viewDimension == 8) value = 'attachments'
    if (this.viewDimension == 9) value = 'indicators'
    if (this.viewDimension == 10) value = 'complements'
    if (this.viewDimension == 11) value = 'configurations'
    if (this.viewDimension == 12) value = 'integrations'
    if (this.viewDimension == 13) value = 'project'
    if (this.viewDimension == 14) value = 'extendedAttributes'

    return value == '' ? '' : `- ${this.getLanguages(`businessSolution/modal/${value}/title`)}`
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  async approveProcess(value?: boolean){
    this.hasNameError = false

    this.parameters.item.is_approved = value ?? !this.parameters.item.is_approved
    await this.findClones()
    if(!this.parametersValidation()){
      return
    }
    this.parameters.item.created_at = this.parameters.item.created_at ? this.parameters.item.created_at :  this.formatDate(new Date()),
    this.parameters.item.updated_at = this.formatDate(new Date())
    this.parameters.item.userCreated = this.parameters.item.userCreated ? this.parameters.item.userCreated : this.properties.me.name,
    this.parameters.item.userUpdated = this.properties.me.name,

    await this.updateDateTimeExtendedAttribute()

    this.updateTree(this.parameters.filesOld, this.parameters.item);

    if(!this.hasNameError){
      this.updateFiles.emit({ files: this.parameters.filesOld, item: {...this.parameters.item} });
      this.toggleModalEdit.emit();
    }
  }

  async findClones(){
    const nodeCloned = {
      is_approved: this.parameters.item.is_approved
    };

    const forEachTree = (node: any) => {
      if (node.uuid !== undefined) {
        if (this.parameters.item.clones !== undefined && this.parameters.item.clones.includes(node.uuid)) {
          Object.assign(node, nodeCloned);
        }
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach(forEachTree);
      }
    };

    this.parameters.filesOld.forEach(forEachTree);
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(this.properties.permissions, module, screen, action, false)
  }

  toggleChangeTree(event: any){
    this.isChangeTree = !this.isChangeTree

    if(event){
      return
    }

    if(!this.isChangeTree){
      this.toggleModalEdit.emit();
    }
  }

  getNameEntity(type: string = 'view'){
    let value = ''
    const level = this.parameters?.item?.level > 0 ? this.parameters?.item?.level : 1

    value = this.parameters.guidelineArray[level - 1]?.name

    if(!value || value == undefined){
      value = '' 
    }

    if(type == 'view'){
      value = this.selectedFlag.lang == 'en' ? 
      value + ' ' + this.getLanguages('businessSolution/modal/view/title') :
      this.getLanguages('businessSolution/modal/view/title') + ' ' + value
    }else{
      value = this.getLanguages('businessSolution/modal/edit/title') + ' ' + value
    }
    
    return value
  }

  goToOriginNode(){
    this.emitNavigateOriginNode.emit(this.parameters?.item?.clone_by)
  }

  async updateDateTimeExtendedAttribute(){
    let flag: boolean = false
    this.parameters.item.extendedAttributes.forEach((item: any) => {
      this.temp.extendedAttributes.forEach((temp: any) => {
        if(temp.uuid == item.uuid){
          flag = true
          if (JSON.stringify(temp) !== JSON.stringify(item)) {
            item.updated_date = this.formatDate(new Date())
          }        
        }
      });
      if(!flag){
        item.created_date = this.formatDate(new Date())
        item.updated_date = this.formatDate(new Date())
      }
      flag = false

    });
  }
}
