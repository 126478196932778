import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-changes-tree',
  templateUrl: './changes-tree.component.html',
  styleUrls: ['./changes-tree.component.scss']
})
export class ChangesTreeComponent {
  @Output() toggleModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(private cookieService: CookieService){

  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(){
    this.toggleModal.emit(1)
  }

  saveAndClose(){
    this.save.emit()
    this.toggleModal.emit()
  }

  closeNotSave(){
    this.close.emit()
    // this.exitModal()
  }

}
