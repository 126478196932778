export const sortArrayOfObjects = (arr: any[]): any[] => {
    return arr.sort((a, b) => {
        // Extrai a chave de comparação
        const keyA = a.label || a.slug ||
                     (typeof a.name === 'object' ? (a.name.en || '') : a.name) || '';
        const keyB = b.label || b.slug ||
                     (typeof b.name === 'object' ? (b.name.en || '') : b.name) || '';

        // Compara as chaves
        return keyA.localeCompare(keyB);
    });
}
