<div class="all-content">
    <div class="top">
        <div class="toggle"></div>

        <div class="save" (click)="exitModal()">
            {{ getLanguages('businessSolution/modal/icons/confirm') }}
        </div>

        <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="row">
        <span class="title">
            {{ getLanguages('businessSolution/modal/icons/title') }}
        </span>
    </div>
    <div class="row">
        <div class="icons">
            <div class="icon" *ngFor="let item of icons" [ngClass]="selectedIcon == item.class ? 'clicked' : ''" (click)="choseIcon(item.class)">
                <i [ngClass]="item.class"></i>
           </div>
        </div>
    </div>
</div>
