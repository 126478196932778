import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './layouts/pages/pages.component';
import { PagesRoutingModule } from './layouts/pages/pages-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { BusinessTreeComponent } from './pages/business-tree/business-tree.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth/auth.service';
import { CorporationComponent } from './pages/corporation/corporation.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { CreatePasswordComponent } from './pages/auth/create-password/create-password.component';
import { UsersComponent } from './pages/users/users.component';
import { InstancesComponent } from './pages/instances/instances.component';
import { ProfileComponent } from './pages/profile/profile.component';
import {cookieToJson} from "./helpers/cookieToJson";
import { ActivitiesComponent } from './pages/activities/activities.component';
import { VersionsComponent } from './pages/versions/versions.component';
import { MaturityComponent } from './pages/maturity/maturity.component';
import { StandardsComponent } from './pages/standards/standards.component';
import { ClipboardComponent } from './pages/clipboard/clipboard.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PersonasComponent } from './pages/personas/personas.component';
import { PageTechnologyComponent } from './pages/page-technology/page-technology.component';
import { PageProjectComponent } from './pages/page-project/page-project.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent
  // },
  {
    path: 'password/:uuid',
    component: CreatePasswordComponent
  },
  {
    path: 'home',
    component: PagesComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'pages/menu/dashboard'
    }
  },
  {
    path: '', component: PagesComponent, canActivate: [AuthGuard], children: [
      // {
      //   path: '',
      //   component: DashboardComponent,
      //   canActivate: [AuthGuard],
      //   data: {
      //     breadcrumb: 'pages/menu/dashboard'
      //   }
      // },
      {
        path: 'business-solutions',
        component: BusinessTreeComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/businessSolution'
        }
      },
      {
        path: 'versioning',
        component: VersionsComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/versioning'
        }
      },
      {
        path: 'clipboard',
        component: ClipboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/clipboard'
        }
      },
      {
        path: 'maturity',
        component: MaturityComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/maturity'
        }
      },
      {
        path: 'standard',
        component: StandardsComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/standard'
        }
      },
      {
        path: 'guideline',
        component: StandardsComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/standard'
        }
      },
      {
        path: 'project_guideline',
        component: PageProjectComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/project'
        }
      },
      {
        path: 'personas',
        component: PersonasComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/personas'
        }
      },
      {
        path: 'technologies',
        component: PageTechnologyComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/technologies'
        }
      },
      {
        path: 'corporations',
        component: CorporationComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: ['pages/menu/foundation', 'pages/menu/corporation']
        }
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'pages/menu/users'
        }
      },
      // {
      //   path: 'profiles',
      //   component: ProfileComponent,
      //   canActivate: [AuthGuard],
      //   data: {
      //     breadcrumb: 'pages/menu/profile'
      //   }
      // },
      {
        path: 'environments',
        component: InstancesComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: ['pages/menu/adm', 'pages/menu/instances']
        }
      },
      {
        path: 'activities',
        component: ActivitiesComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: ['pages/menu/adm', 'pages/menu/activities']
        }
      },
      // {
      //   path: 'corporation-change',
      //   component: CorporationChangeComponent
      // },
    ]
  },
  { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes), PagesRoutingModule],
  exports: [RouterModule],
  providers: [AuthGuard, AuthService]
})
export class AppRoutingModule { }
