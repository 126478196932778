import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { CorporationDimensionsService } from 'src/app/services/corporation-dimensions/corporation-dimensions.service';
import { FunctionService } from 'src/app/services/function.service';
import { CookieService } from 'ngx-cookie-service';
import {getLanguage, getLanguageCookie} from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {addAlert, apiOfflineAsync} from "../../../../layouts/pages/pages.component";
import {asyncForEach} from "../../../../helpers/asyncForEach";
import { v4 } from "uuid";

@Component({
  selector: 'app-create-dimensions',
  templateUrl: './create-dimensions.component.html',
  styleUrls: ['./create-dimensions.component.scss']
})
export class CreateDimensionsComponent {
  @Input() properties: any = {}
  @Input() parameters: any = {}

  @Output() toggleDimensionModalEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() getCorporationTree: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren('inputCellStrategy') inputCellsStrategy!: QueryList<ElementRef>;
  @ViewChildren('textboxElementStrategy') textboxElementsStrategy!: QueryList<ElementRef>;

  strategy: any = { rowsStrategy: [] }
  skills: any = { rowsSkills: [] }
  technology: any = { rowsTechnology: [] }

  currentPageStrategy = 1;
  currentPageSkills = 1;
  currentPageTechnology = 1;

  itemsPerPageStrategy = 5;
  itemsPerPageSkills = 5;
  itemsPerPageTechnology = 5;

  totalPagesStrategy = 1;
  totalPagesSkills = 1;
  totalPagesTechnology = 1;

  rowsStrategy: any = new MatTableDataSource<any>([])
  rowsSkills: any = new MatTableDataSource<any>([])
  rowsTechnology: any = new MatTableDataSource<any>([])

  selectionStrategy = new SelectionModel<any>(true, []);
  selectionSkills = new SelectionModel<any>(true, []);
  selectionTechnology = new SelectionModel<any>(true, []);

  indexTableStrategy: any = { startIndex: 0, endIndex: 0 }
  indexTableSkills: any = { startIndex: 0, endIndex: 0 }
  indexTableTechnology: any = { startIndex: 0, endIndex: 0 }

  isEdit: boolean = false;

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  viewDimension = 1;

  task: any = {
    color:  'accent'
  };

  dimensions: any = {
    strategy: [],
    skills: [],
    technology: []
  }

  constructor(
    private functionService: FunctionService,
    private corporationService: CorporationService,
    private corporationDimensionsService: CorporationDimensionsService,
    private cookieService: CookieService
  ) { }

  async ngOnInit() {
    await this.getDisplayedRows()
    await this.getDimensions()
  }

  async getDimensions(){
    try{
      const response: any = await this.corporationDimensionsService.getDimensions({ corporation: this.parameters.corporation })
      if(response.data){
        this.dimensions = response.data

        if (!this.dimensions.strategy) {
          this.dimensions.strategy = []
          this.dimensions.strategy.push({name: null})
        }
        if (this.dimensions.strategy.length > 0) {
          this.rowsStrategy = new MatTableDataSource<any>(this.dimensions.strategy)
          await this.getTotalPages()
        }

        if (!this.dimensions.skills) {
          this.dimensions.skills = []
          // this.dimensions.skills.push({name:})
        }
        if (this.dimensions.skills.length > 0) {
          this.rowsSkills = new MatTableDataSource<any>(this.dimensions.skills)
        }

        if (!this.dimensions.technology) {
          this.dimensions.technology = []
          this.dimensions.technology.push({name: null})
        }
        if (this.dimensions.technology.length > 0) {
          this.rowsTechnology = new MatTableDataSource<any>(this.dimensions.technology)
        }

        await this.getTotalPages()
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.toggleDimensionModalEdit.emit();
  }

  async toggleDimension(dimension: number){
    this.viewDimension = dimension
    await this.getTotalPages()
  }

  async addRow() {
    if (this.viewDimension == 1) {
      this.rowsStrategy.data.push( { uuid: v4(), name: ''} );
      this.rowsStrategy._updateChangeSubscription();
      await this.getTotalPages(true);
    }
    if (this.viewDimension == 2) {
      this.rowsSkills.data.push( { uuid: v4(), name: ''} );
      this.rowsSkills._updateChangeSubscription();
      await this.getTotalPages(true);
    }
    if (this.viewDimension == 3) {
      this.rowsTechnology.data.push( { uuid: v4(), name: '', link: ''} );
      this.rowsTechnology._updateChangeSubscription();
      await this.getTotalPages(true);
    }
  }
  isAllSelected() {
    let numSelected: number = 0
    let numRows: number = 0

    if (this.viewDimension == 1) {
      numSelected = this.selectionStrategy.selected.length;
      this.rowsStrategy.data.map((item: any) => { if (!item.in_use) numRows++ })
    }
    if (this.viewDimension == 2) {
      numSelected = this.selectionSkills.selected.length;
      this.rowsSkills.data.map((item: any) => { if (!item.in_use) numRows++ })
    }
    if (this.viewDimension == 3) {
      numSelected = this.selectionTechnology.selected.length;
      this.rowsTechnology.data.map((item: any) => { if (!item.in_use) numRows++ })
    }

    return numSelected === numRows;
  }

  async getDisplayedRows() {
    if (this.viewDimension == 1) {
      this.indexTableStrategy.startIndex = (this.currentPageStrategy - 1) * this.itemsPerPageStrategy;
      this.indexTableStrategy.endIndex = this.indexTableStrategy.startIndex + this.itemsPerPageStrategy;
    }
    if (this.viewDimension == 2) {
      this.indexTableSkills.startIndex = (this.currentPageSkills - 1) * this.itemsPerPageSkills;
      this.indexTableSkills.endIndex = this.indexTableSkills.startIndex + this.itemsPerPageSkills;
    }
    if (this.viewDimension == 3) {
      this.indexTableTechnology.startIndex = (this.currentPageTechnology - 1) * this.itemsPerPageTechnology;
      this.indexTableTechnology.endIndex = this.indexTableTechnology.startIndex + this.itemsPerPageTechnology;
    }
  }

  async getTotalPages(isLastPage: boolean = false){
    if (this.viewDimension == 1) {
      this.totalPagesStrategy = Math.ceil(this.rowsStrategy.data.length / this.itemsPerPageStrategy)
      if (this.totalPagesStrategy == 0) this.totalPagesStrategy = 1
      if ((this.currentPageStrategy >= this.totalPagesStrategy && this.totalPagesStrategy > 0) || isLastPage) {
        this.currentPageStrategy = this.totalPagesStrategy;
      }
    }
    if (this.viewDimension == 2) {
      this.totalPagesSkills = Math.ceil(this.rowsSkills.data.length / this.itemsPerPageSkills)
      if (this.totalPagesSkills == 0) this.totalPagesSkills = 1
      if ((this.currentPageSkills >= this.totalPagesSkills && this.totalPagesSkills > 0) || isLastPage) {
        this.currentPageSkills = this.totalPagesSkills;
      }
    }
    if (this.viewDimension == 3) {
      this.totalPagesTechnology = Math.ceil(this.rowsTechnology.data.length / this.itemsPerPageTechnology)
      if (this.totalPagesTechnology == 0) this.totalPagesTechnology = 1
      if ((this.currentPageTechnology >= this.totalPagesTechnology && this.totalPagesTechnology > 0) || isLastPage) {
        this.currentPageTechnology = this.totalPagesTechnology;
      }
    }
    await this.getDisplayedRows()
  }

  async choseItemsPage(number: number) {
    if (this.viewDimension == 1) {
      this.currentPageStrategy = 1;
      this.itemsPerPageStrategy = number;
    }
    if (this.viewDimension == 2) {
      this.currentPageSkills = 1;
      this.itemsPerPageSkills = number;
    }
    if (this.viewDimension == 3) {
      this.currentPageTechnology= 1;
      this.itemsPerPageTechnology= number;
    }
    await this.getTotalPages()
  }

  toggleAllRows() {
    if (this.viewDimension == 1) {
      if (this.isAllSelected()) {
        this.selectionStrategy.clear()
      } else {
        this.rowsStrategy.data.map((item: any) => { if (!item.in_use) this.selectionStrategy.select(item) })
      }
    }
    if (this.viewDimension == 2) {
      if (this.isAllSelected()) {
        this.selectionSkills.clear()
      } else {
        this.rowsSkills.data.map((item: any) => { if (!item.in_use) this.selectionSkills.select(item) })
      }
    }
    if (this.viewDimension == 3) {
      if (this.isAllSelected()) {
        this.selectionTechnology.clear()
      } else {
        this.rowsTechnology.data.map((item: any) => { if (!item.in_use) this.selectionTechnology.select(item) })
      }
    }
  }

  async setCorporationDimensionsValidations () {
    let inputs: any = []
    await asyncForEach(Object.keys(this.dimensions), async (key: any) => {
      await asyncForEach(this.dimensions[key], async (item: any) => {
        if (item.name.replaceAll(' ', '') == '' && !item.is_delete) {
          inputs.push(`${getLanguageCookie('name', localStorage.getItem('language'))} (${key})`)
        }
      })
    })

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  /**
   *
   */
  async saveCorporationDimensions () {
    if (this.rowsStrategy.data.length > 0 && this.rowsStrategy.data[0].name != null && this.rowsStrategy.data[0].name != undefined) {
      this.dimensions.strategy = this.rowsStrategy.data
    }
    if (this.rowsSkills.data.length > 0 && this.rowsSkills.data[0].name != null && this.rowsSkills.data[0].name != undefined) {
      this.dimensions.skills = this.rowsSkills.data
    }
    if (this.rowsTechnology.data.length > 0 && this.rowsTechnology.data[0].name != null && this.rowsTechnology.data[0].name != undefined) {
      this.dimensions.technology = this.rowsTechnology.data
    }

    if (!await this.setCorporationDimensionsValidations()) {
      return
    }

    try {
      const response:any = await this.corporationDimensionsService.setDimensions(this.dimensions, {
        corporation: this.parameters.corporation
      });
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message)
        this.exitModal()
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async removeSelectedRows() {
    if(this.viewDimension == 1){
      let selectedRowsIdStrategy: number[] = [];

      this.selectionStrategy.selected.forEach((selectedRow: any) => {
        selectedRowsIdStrategy.push(selectedRow.uuid)
      });
      this.rowsStrategy.data.forEach((item: any) => {
        if(selectedRowsIdStrategy.includes(item.uuid)){
          item.is_delete = true
        }
      })

    }else if(this.viewDimension == 2){
      let selectedRowsIdSkills: number[] = [];

      this.selectionSkills.selected.forEach((selectedRow: any) => {
        selectedRowsIdSkills.push(selectedRow.uuid)
      });
      this.rowsSkills.data.forEach((item: any) => {
        if(selectedRowsIdSkills.includes(item.uuid)){
          item.is_delete = true
        }
      })
    }else if(this.viewDimension == 3){
      let selectedRowsIdTechnology: number[] = [];

      this.selectionTechnology.selected.forEach((selectedRow: any) => {
        selectedRowsIdTechnology.push(selectedRow.uuid)
      });
      this.rowsTechnology.data.forEach((item: any) => {
        if(selectedRowsIdTechnology.includes(item.uuid)){
          item.is_delete = true
        }
      })
    }
  }

  getDimension(){
    let value = ''
    if (this.viewDimension == 1) value = 'strategy'
    if (this.viewDimension == 2) value = 'skills'
    if (this.viewDimension == 3) value = 'technology'
    return value == '' ? '' : `- ${this.getLanguages(`businessSolution/modal/${value}/title`)}`
  }

  getRows(){
    if(this.viewDimension == 1){
      return this.rowsStrategy.data.slice(this.indexTableStrategy.startIndex, this.indexTableStrategy.endIndex)
    }
    if(this.viewDimension == 2){
      return this.rowsSkills.data.slice(this.indexTableSkills.startIndex, this.indexTableSkills.endIndex)
    }
    if(this.viewDimension == 3){
      return this.rowsTechnology.data.slice(this.indexTableTechnology.startIndex, this.indexTableTechnology.endIndex)
    }
    return
  }

  setSelection(element: any){
    if(this.viewDimension == 1){
      return this.selectionStrategy.toggle(element)
    }
    if(this.viewDimension == 2){
      return this.selectionSkills.toggle(element)
    }
    if(this.viewDimension == 3){
      return this.selectionTechnology.toggle(element)
    }
    return
  }

  getSelection(element: any){
    if(this.viewDimension == 1){
      return this.selectionStrategy.isSelected(element)
    }
    if(this.viewDimension == 2){
      return this.selectionSkills.isSelected(element)
    }
    if(this.viewDimension == 3){
      return this.selectionTechnology.isSelected(element)
    }
    return
  }

  //
  getCurrentPages(): number{
    if(this.viewDimension == 1){
      return this.currentPageStrategy
    }
    if(this.viewDimension == 2){
      return this.currentPageSkills
    }
    if(this.viewDimension == 3){
      return this.currentPageTechnology
    }
    return 1
  }

  setCurrentPages(param: number){
    if(this.viewDimension == 1){
      return this.currentPageStrategy = param
    }
    if(this.viewDimension == 2){
      return this.currentPageSkills = param
    }
    if(this.viewDimension == 3){
      return this.currentPageTechnology = param
    }
    return
  }

  getItemsPerPage(): number{
    if(this.viewDimension == 1){
      return this.itemsPerPageStrategy
    }
    if(this.viewDimension == 2){
      return this.itemsPerPageSkills
    }
    if(this.viewDimension == 3){
      return this.itemsPerPageTechnology
    }
    return 1
  }

  getTotalNumberPages(){
    if(this.viewDimension == 1){
      return this.totalPagesStrategy
    }
    if(this.viewDimension == 2){
      return this.totalPagesSkills
    }
    if(this.viewDimension == 3){
      return this.totalPagesTechnology
    }
    return 1
  }

  async editCancel(){
    this.isEdit = !this.isEdit

    if(!this.isEdit){
      await this.getDimensions()
    }
  }
}

