import { to64decode } from "./base64"

export function systemPermissions(permission: any, module: string, screen: string, action: string, isbase64: boolean = true):any  {
    if (permission && isbase64){
        permission = to64decode(permission)
    }

    if (!permission || !permission[module] || !permission[module][screen]) {
        return false
    }

    if (['create', 'read', 'update', 'delete', 'screen_permission'].includes(action)) {
      return permission[module][screen][action] ?? false
    }

    if (!permission[module][screen].specials) {
      permission[module][screen]['specials'] = {}
    }

    if (!permission[module][screen]['specials'][action]) {
      permission[module][screen]['specials'][action] = false
    }

    return permission[module][screen]['specials'][action];
}
