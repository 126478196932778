import { Component} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { IBusinessSolution } from 'src/app/interfaces/IBusinessSolution';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { EventService } from 'src/app/services/event/event.service';
import { to64decode, to64encode} from "../../helpers/base64";
import {CookieService} from "ngx-cookie-service";
import { getLanguageCookie } from 'src/assets/language';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { systemPermissions } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync } from 
 "../../helpers/cookieToJson";
import { TreeNode } from 'primeng/api';
// import { AdminGroup } from 'src/app/app.module';

export enum AdminGroup {
  MASTER = 'MASTER',//Administrador do Sistema
  ENVIRONMENT = 'ENVIRONMENT',//Administrador do Ambiente
  OTHERS = 'OTHERS'//Usuários da corporação
}

@Component({
  selector: 'app-corporation',
  templateUrl: './corporation.component.html',
  styleUrls: ['./corporation.component.scss'],
})

export class CorporationComponent {
  properties: any = {}
  parameters: any = {}
  protected readonly AdminGroup = AdminGroup;

  private subscription: Subscription = new Subscription();

  isShowFoundationCreate: boolean = false;
  isShowFoundationEdit: boolean = false;
  isShowFoundationDelete: boolean = false;
  isShowDimensionEdit: boolean = false;

  corporationTree?: any;
  selectedNode: any;
  isSelectedNode = false;
  searchText: string = '';
  isTreeEmpty = true;
  corporation_parent = []

  corporation = []

  files!: TreeNode[];
  noData = true;

  selectedCorporation: any = { name: 'Loading...' };

  // treeControl = new NestedTreeControl<IBusinessSolution>((node: any) => node.info);
  // dataSource = new MatTreeNestedDataSource<IBusinessSolution>();

  isLoading: boolean = false;
  openTree: boolean = false
  text: string = ''
  /**
   *
   * @param eventService
   * @param router
   * @param corporationService
   * @param cookieService
   * @param activatedRoute
   */
  constructor(
    private eventService: EventService,
    private router: Router,
    private corporationService: CorporationService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
  //     this.eventService.eventModalFoundation.subscribe((param) => {
  //       this.toggleFoundationModal();
  //     });

  //     this.eventService.eventModalFoundationEdit.subscribe((param) => {
  //       this.toggleFoundationModalEdit();
  //     });

  //     this.eventService.eventModalFoundationDelete.subscribe((param) => {
  //       this.toggleFoundationModalDelete();
  //     });

  //     this.eventService.eventModalDimensionEdit.subscribe((param) => {
  //       this.toggleDimensionModalEdit();
  //     });
  }

  /**
   *
   */
  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    await this.getCorporationTree()
  }

  /**
   *
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   *
   * @param file
   */
  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isShowDelete(uuid: string): boolean{
    if(uuid == this.properties?.corporation?.uuid){
      return false
    }
    return true
  }

  /**
   *
   * @param event
   * @param node
   */
  toggleFoundationModal(event?: Event, node?: any) {
    this.isShowFoundationCreate = !this.isShowFoundationCreate;
    if (!node) {
      return;
    }

    this.parameters = {}
    this.parameters.is_principal = node.is_principal;
    this.parameters.reference_parent = node;

    if(event){
      event.stopPropagation();
    }
  }

  /**
   *
   * @param event
   * @param node
   */
  toggleFoundationModalEdit(event?: Event, node?: any) {
    this.isShowFoundationEdit = !this.isShowFoundationEdit;
    if (!node) {
      return;
    }

    this.parameters = node;

    if(event){
      event.stopPropagation();
    }
  }

  /**
   *
   * @param event
   * @param node
   */
  toggleFoundationModalDelete(event?: Event, node?: any) {
    this.isShowFoundationDelete = !this.isShowFoundationDelete;
    if (!node) {
      return;
    }

    this.parameters = node;

    if(event){
      event.stopPropagation();
    }
  }

  /**
   *
   * @param event
   * @param node
   */
  toggleDimensionModalEdit(event?: Event, node?: any) {
    this.isShowDimensionEdit = !this.isShowDimensionEdit
    if (!node) {
      return;
    }

    this.parameters = {}
    this.parameters.corporation = node;

    if(event){
      event.stopPropagation();
    }
  }

  /**
   *
   */
  async getCorporationTree() {
    // this.dataSource.data = [];
    this.isLoading = true;

    this.subscription.add(
      this.corporationService.getCorporationTree({ search: this.text })
        .subscribe(
          (response: any) => {
            if (response && response.data && response.data.result) {
                // this.dataSource = response.data.result;
                this.files = sortTreeNodes(response.data.result)
                this.noData = response.data.result.length == 0;
                if(!this.files[0].expanded){
                  this.expandAll()
                }
            }else {
              throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
            }
          },
          (error) => {
            console.error(error);
          }
        )
    );
    this.isLoading = false;
  }

  /**
   *
   * @param nodes
   */
  buildTreeData(nodes: any[]): any[] {
    return nodes.map((node) => {
      const children = node.info ? this.buildTreeData(node.info) : [];
      return {
        ...node,
        info: children
      };
    });
  }

  /**
   *
   * @param corporation
   */
  choseDomainTree(corporation: any) {
    this.corporation = corporation.slug;
    this.corporationTree = undefined
    this.selectedCorporation.name = corporation.name;
  }

  /**
   *
   * @param _
   * @param node
   */
  hasChild = (_: number, node: IBusinessSolution) => !!node.info && node.info.length > 0;

  /**
   *
   * @param node
   */
  async expandedNode(node: any) {
    if (node.is_corporation) {
      this.corporationTree = node;
    }
  }

  /**
   *
   * @param node
   * @param show
   */
  async selectNode(node: any = {}, show = true) {
    this.selectedNode = node;
    if (node == '') {
      this.isSelectedNode = false;
    } else {
      this.isSelectedNode = show;
    }
  }


  /**
   *
   * @param nodes
   * @param treeControl
   */
  collapseRecursive(nodes: any, treeControl: NestedTreeControl<any>) {
    for (const node of nodes) {
      if (treeControl.isExpanded(node)) {
        treeControl.collapse(node);
      }
      if (node.info && node.info.length > 0) {
        this.collapseRecursive(node.info, treeControl);
      }
    }
  }

  /**
   *
   * @param nodes
   * @param searchText
   */
  highlightNodes(nodes: any, searchText: string) {
    for (const node of nodes) {
      node.highlight = false; // Remover destaque de nós anteriores

      if (node.name && searchText && node.name.toLowerCase().includes(searchText.toLowerCase())) {
        node.arrayText = node.name.split(new RegExp(`(.*?)(${searchText})`, 'gi')).filter(Boolean);
      }

      if (node.info && node.info.length > 0) {
        this.highlightNodes(node.info, searchText); // Chamar recursivamente para nós filhos
      }
    }
  }

  /**
   *
   * @param node
   */
  async navigate (node: any, route: string) {
    const corporation = {
      uuid: node.uuid,
      name: node.name,
      corporation_name: node.corporation_name,
      slug: node.slug,
      is_active: node.is_active,
      is_matriz: node.is_matriz,
      is_principal: node.is_principal,
      environment_uuid: node.environment_uuid,
      corporation_uuid: node.corporation_uuid
    }

    localStorage.removeItem('corporation')
     localStorage.setItem('corporation', to64encode(corporation))

    if (to64decode(localStorage.getItem('corporation'))) {
      window.location.href = route
    }
    //erro ao passar o cookie
  }

  getSystemPermission(module: string, screen: string, action: string){
    return systemPermissions(localStorage.getItem('permissions'), module, screen, action)
  }

  async flipTree(){
    this.openTree = !this.openTree;
    if(this.openTree){
      return this.expandAll()
    }
    return this.collapseAll()
  }


  async expandAll() {
    this.files.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  async collapseAll() {
    this.files.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  getParentCount(node: any): number {
    let parentCount = 0;
    let parentNode = node;

    while (parentNode && parentNode.parent) {
      parentCount++;
      parentNode = parentNode.parent;
    }

    // Adicione 1 para incluir o próprio nó na contagem
    return parentCount + 1;
  }

  getClass(node: any): string{
    switch (this.getParentCount(node)) {
      case 1: return 'fas fa-city';
      default: return 'fas fa-building'
    }
  }

  isFirstColumn(node: any): boolean {
    if(node.parent == undefined){
      return true
    }
    return false
  }
}

function sortTreeNodes(tree: any[]): TreeNode[] {
  tree.forEach(node => {
    if (node.children && node.children.length > 0) {
      node.children.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));
      sortTreeNodes(node.children);
    }
  });

  return tree.sort((a, b) => a.name.localeCompare(b.name));
}

function forwardRef(arg0: () => typeof AdminGroup): readonly any[] | import("@angular/core").Type<any> {
  throw new Error('Function not implemented.');
}

