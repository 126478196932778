<div class="all-content">
  <div class="top">
    {{ getLanguages('personas') }}
  </div>
  <div class="row">
    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="text" (keyup.enter)="searchNodes()">
      </div>
      <button (click)="searchNodes()">
          {{ getLanguages('search') }}
      </button>
    </div>
  </div>
  <div class="content">
    <div class="title">
      <span>
        {{ getLanguages('personas/tree') }}
      </span>
      <i class="fa-solid fa-chevron-right" [ngClass]="!openTree ? 'openned' : 'closed'" (click)="flipTree()"></i>
    </div>
    <div class="content-content" >

    <div class="tree" [ngStyle]="{'width': '100%'}">
      <div class="noData" *ngIf="noData && !isLoading">
        <div class="icon">
            <i class="fa-solid fa-exclamation"></i>
        </div>
        <span>
            {{ getLanguages('errors/noData')}}
        </span>
      </div>
      <p-tree class="ptree"
        [value]="files"
        *ngIf="!isLoading && !noData"
        >
          <ng-template let-node pTemplate="default">
            <div class="pcontent">
              <div class="ptitle" [ngStyle]="{'width': 'calc(100% - 2.3 * ' + getParentCount(node) + 'vw)'}">
                <i class="fas" [ngClass]="node.data.type == 'organogram' ? 'fa-sitemap' : 'fa-user-tie'"></i>
                <b> {{ (node && node.label ? node.label : '') }} </b>
                <b *ngIf="node.data.type !== 'organogram'"> {{ getSkillsPercentage(node) }} </b>
              </div>
              <div class="picons">
                <section>
                  <button class="button-tooltip button" [disabled]="!parameters.isEdit"  *ngIf="node.data.type !== 'organogram'" (click)="exitModalSkills(node)" [ngClass]="!parameters.isEdit ? 'no-pointer' : 'pointer'">
                    <i class="fas fa-graduation-cap"></i>
                  </button>
                  <div class="toggle-tooltip">
                    <div class="item-op">
                      {{ getLanguages('personas/skills') }}
                    </div>
                  </div>
                </section>

                <section *ngIf="node?.data?.type !== 'organogram'">
                  <div class="button-tooltip">
                    <button class="button-menu" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled' : ''" [matMenuTriggerFor]="classificationPersona">
                      <span>
                        {{ findClassification(node.uuid) ? getNames(getClassification(findClassification(node.uuid))) : getNames(getClassification('void')) }}
                      </span>
                      <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
                    </button>
                  </div>
                  <!-- <div class="toggle-tooltip margin-right-tooltip">
                    <div class="item-op">
                      {{ getLanguages('personas/fte') }}
                    </div>
                  </div> -->
                  <mat-menu #classificationPersona="matMenu">
                    <div class="menu">
                      <div class="input" (click)="$event.stopPropagation()">
                        <input type="text" [(ngModel)]="searchClassification" placeholder="{{ getLanguages('search') }}">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div class="scroll">
                        <ng-container *ngFor="let item of parameters.optionsArray?.personaParticipationEnum">
                          <ng-container *ngIf="getNames(item.name).toLowerCase().includes(searchClassification.toLowerCase())">
                            <div class="item-menu" (click)="choseItemSelect('persona_classification', item.slug, node.uuid)">
                              <span [ngClass]="item.slug == findClassification(node.uuid) ? 'valid-span' : 'invalid-span'">
                                {{ getNames(item.name) }}
                              </span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </mat-menu>  
                </section>

               
                <section>
                  <div class="button-tooltip">
                        <input type="number" min="0" [ngModel]="findCost(node.uuid)" *ngIf="node.data.type !== 'organogram'" (ngModelChange)="updateCost($event, node.uuid)" [disabled]="!parameters.isEdit || findClassification(node.uuid) == 'void' || findClassification(node.uuid) == ''">
                  </div>
                  <div class="toggle-tooltip margin-right-tooltip">
                    <div class="item-op">
                      {{ getLanguages('personas/fte') }}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </ng-template>
        </p-tree>
        <app-loading *ngIf="isLoading"></app-loading>
      </div>
    </div>
  </div>
</div>

<div class="background-modals" *ngIf="isShowSkills">
  <app-skills-crud (toggleExit)="exitModalSkills()" (getArray)="getArraySkillsPersona($event)" [parameters]="objectModal"></app-skills-crud>
</div>

