<div class="all-content">
    <div class="top">
        <div class="toggle"></div>
        <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="row">
        
    </div>
    <div class="row">
        <span class="title">
            {{ getLanguages('import/title') }}
        </span>
    </div>
    <div class="row">
        <div class="button">
            <span> {{ selectedFileName }} </span>
            <i class="fas fa-xmark" (click)="clearFileSelection()" *ngIf="selectedFileName"></i>
            <div class="icon">
                <i class="fas fa-file"></i>
                <label for="import-icon"></label>
                <input type="file" id="import-icon" (change)="handleFileInput($event)" #fileInputRef accept=".xls, .xlsx">
            </div>
        </div>
    </div>
    <div class="row bottom">
        <button class="active" (click)="getTemplate()">
            {{ getLanguages('import/model') }}
        </button>

        <button [ngClass]="isShowButton ? 'active' : 'inactive'" [disabled]="!isShowButton" (click)="set()">
            {{ getLanguages('import/push') }}
        </button>
    </div>
</div>

<div class="background-modals" *ngIf="isLoading">
    <app-loading></app-loading>
</div>