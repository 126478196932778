<div class="all-content">
    <div class="top">
        <div class="toggle"></div>
        <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="row">
        <div class="circle">
            <i class="fas fa-exclamation"></i>
        </div>
    </div>
    <div class="row">
        <span class="title">
            {{ getLanguages('auth/alert/closeForm') }}
        </span>
    </div>


    <div class="row buttons">
        <button class="confirm" (click)="saveAndClose()">
            {{ getLanguages('auth/alert/saveAndClose') }}
        </button>
        <button class="cancel" (click)="closeNotSave()">
            {{ getLanguages('auth/alert/closeNotSave') }}
        </button>
    </div>
</div>
