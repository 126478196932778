export const environment = {
    production: false,
    development: true,
    local: false,
    homolog: false,
    apiKey: 'devKey',
    apiUrl: 'https://api.dev.make-platform.com/',
    bundleUrl: 'https://mf-bpmn.dev.make-platform.com/bundle.js',
    tls_cert: 'tls-cert-dev.pem',
    tls_key: 'tls-key-dev.pem'
};
