import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import {EnvironmentsService} from "../../../../services/environments/environments.service";
import { ModulesService } from 'src/app/services/modules/modules.service';
import {apiOfflineAsync} from "../../../../layouts/pages/pages.component";

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent {
  @Output() toggleCreateProfileModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getInstances: EventEmitter<void> = new EventEmitter<void>();
  @Input() parameters: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  statusArray = [ 'ACTIVE', 'INACTIVE']
  selectedStatus: string = this.statusArray[1];

  task = {
    color: 'primary'
  }

  profiles = [
    { id: 1 , name: 'Master Profile'},
    { id: 2 , name: 'Administrator'},
    { id: 3 , name: 'User Profile'},
    { id: 4 , name: 'Viewer'}
  ]
  profileArray: any = []
  selectedProfile: any = {id: null, name: 'Select'}
  searchProfile: string = ""
  filteredProfileArray: any = [
    { id: 1 , name: 'Master Profile'},
    { id: 2 , name: 'Administrator'},
    { id: 3 , name: 'User Profile'},
    { id: 4 , name: 'Viewer'}
  ];

  //aqui
  // modules: any = [
  //   {
  //     name: {
  //       en: 'Administrator',
  //       pt: 'Administrador',
  //     },
  //     slug: 'administrator',
  //     is_active: true,
  //     is_admin: true,
  //     order: 1,
  //     icon: 'fa-solid fa-user-gear',
  //     child: [
  //       {
  //         name: {
  //           en: 'Environment',
  //           pt: 'Ambiente',
  //         },
  //         url: 'environment',
  //         slug: 'environment',
  //         is_active: true,
  //         order: 1,
  //         icon: 'fa-solid fa-hotel'
  //       },
  //     ]
  //   },
  //   {
  //     name: {
  //       en: 'Register',
  //       pt: 'Registro',
  //     },
  //     slug: 'register',
  //     is_active: true,
  //     is_admin: false,
  //     order: 2,
  //     icon: 'fa-solid fa-newspaper',
  //     child: [
  //       {
  //         name: {
  //           en: 'Users',
  //           pt: 'Usuários',
  //         },
  //         url: 'users',
  //         slug: 'users',
  //         is_active: true,
  //         order: 1,
  //         icon: 'fa-solid fa-user'
  //       },
  //       {
  //         name: {
  //           en: 'Profile',
  //           pt: 'Perfil',
  //         },
  //         url: 'profile',
  //         slug: 'profile',
  //         is_active: true,
  //         order: 2,
  //         icon: 'fa-regular fa-id-badge'
  //       },
  //     ]
  //   },
  //   {
  //     name: {
  //       en: 'Operation',
  //       pt: 'Operação',
  //     },
  //     slug: 'operation',
  //     is_active: true,
  //     is_admin: false,
  //     order: 3,
  //     icon: 'fa-solid fa-gear',
  //     child: [
  //       {
  //         name: {
  //           en: 'Operation',
  //           pt: 'Operação',
  //         },
  //         url: 'operation',
  //         slug: 'operation',
  //         is_active: true,
  //         order: 1,
  //         icon: 'fa-regular fa-building'
  //       }
  //     ]
  //   }
  // ]


  // permission: any = {
  //     name: 'Estagiario',
  //     modules: [
  //       {
  //         name: {
  //           en: 'Corporation',
  //           pt: 'Empresas'
  //         },
  //         crud: [
  //           {
  //             name: 'read',
  //             permission: 'true'
  //           },
  //           {
  //             name: 'create',
  //             permission: 'true'
  //           },
  //           {
  //             name: 'edit',
  //             permission: 'true'
  //           },
  //           {
  //             name: 'delete',
  //             permission: 'true'
  //           }
  //         ]
  //       }
  //     ]
  // }

  data: any = {
    arrayModules: [],
    selectedModule: {id: null, name: {
      en: 'Select',
      pt: 'Selecione'
    }},
    searchModule: "",
    filteredModuleArray: [],
    displayedColumnsModule: ['name', 'list', 'include', 'edit', 'delete'],
    filteredDataSource: [],
    dataSource: [],
    profile: [],
  }

  /**
   *
   * @param cookieService
   * @param environmentService
   * @param modulesService
   */
  constructor(
    private cookieService: CookieService,
    private environmentService: EnvironmentsService,
    private modulesService: ModulesService
    ){}

  async ngOnInit(){
    await this.getModules();
    await this.getProfileModuleNew();
    this.filterScreens('all');
    this.data.filteredModuleArray.push(
      {
        name: {
          en: 'All',
          pt: 'Todos'
        },
        slug: 'all'
      }
    )
  }

  /**
   *
   * @param file
   */
  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getModuleName(module: any){
    return module[this.selectedFlag.lang]
  }
  /**
   *
   * @param status
   */
  choseStatus(status: string){
    this.selectedStatus = status
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.toggleCreateProfileModal.emit();
  }

  filterProfiles() {
    this.filteredProfileArray = this.profileArray.filter((profile: { name: {en: string}; }) =>
      profile.name.en.toLowerCase().includes(this.searchProfile.toLowerCase())
    );
  }

  choseProfile(profile: any){
    this.selectedProfile = profile;
  }

  async getModules(){
    try {
      const response: any = await this.modulesService.getModules()
      if(response && response.data){
        this.data.arrayModules = response.data
        this.data.filteredModuleArray = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getProfileModuleNew(){
    try {
      const response: any = await this.modulesService.getProfileModuleNew()
      if(response && response.data){
        this.data.dataSource = response.data
        this.data.filteredDataSource = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  filterModules() {
    this.data.filteredModuleArray = this.data.filteredModuleArray.filter((module: { name: {en: string}; }) =>
      module.name.en.toLowerCase().includes(this.data.searchModule.toLowerCase())
    );
  }

  filterScreens(moduleFilter: string) {
    if(moduleFilter == 'all'){
      this.data.filteredDataSource = this.data.dataSource;
      return
    }

    this.data.filteredDataSource = this.data.dataSource.filter((item: any) =>
      item.slug === moduleFilter
    );
  }

  choseModule(module: any){
    this.data.selectedModule = module.name;
    this.filterScreens(module.slug)
  }

  updateCheckbox(){
  }
}
