<div class="all-content" (mouseleave)="updateSlugs()">

  <div class="row buttons">
    <button *ngIf="parameters.isEdit && selectionStrategy.selected.length  > 0" (click)="removeSelectedRows()">{{ getLanguages('businessSolution/modal/strategy/info/removeRow') }}</button>
    <button *ngIf="parameters.isEdit" (click)="addColumn()">{{ getLanguages('businessSolution/modal/strategy/info/newColumn') }}</button>
    <button *ngIf="parameters.isEdit" (click)="addRow()">{{ getLanguages('businessSolution/modal/strategy/info/newRow') }}</button>
  </div>

  <div class="row">
    <div class="div-table">
      <p-table
        [value]="getDisplayedRows()"
        [scrollable]="false"
        [resizableColumns]="false"
        columnResizeMode="expand"
        class="table">
        <ng-template pTemplate="header">
          <tr>
            <th *ngIf="parameters.isEdit" class="matColumn">
              <mat-checkbox class="checkbox checkbox-first"  (click)="toggleAllRowsStrategy()" [color]="task.color" [checked]="isAllSelectedStrategy()"></mat-checkbox>
            </th>
            <ng-container  *ngIf="parameters.isEdit" >
              <th pResizableColumn *ngFor="let column of parameters.item.columns.strategy.columns.slice(1);let columnIndex = index;">
                <ng-container>
                  <input [disabled]="columnIndex == 0 || !parameters.isEdit" class="columnTitle" type="text" [(ngModel)]="parameters.item.columns.strategy.columnsName[column].field" (keydown)="handleCellKeyDown($event, currentRowIndex, columnIndex, column)">
                  <ng-container *ngIf="columnIndex > 0" matTooltip="{{ getLanguages('businessSolution/modal/strategy/info/removeColumn') }}">
                    <i *ngIf="parameters.isEdit" class="fas fa-xmark" (click)="removeColumn(columnIndex)"></i>
                  </ng-container>
                </ng-container>
              </th>
            </ng-container>
            <ng-container  *ngIf="!parameters.isEdit" >
              <th *ngFor="let column of parameters.item.columns.strategy.columns.slice(1);let columnIndex = index;">
                <ng-container>
                  <input [disabled]="columnIndex == 0 || !parameters.isEdit" class="columnTitle" type="text" [(ngModel)]="parameters.item.columns.strategy.columnsName[column].field" (keydown)="handleCellKeyDown($event, currentRowIndex, columnIndex, column)">
                  <ng-container *ngIf="columnIndex > 0">
                    <i *ngIf="parameters.isEdit" class="fas fa-xmark"></i>
                  </ng-container>
                </ng-container>
              </th>
            </ng-container>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-element let-rowIndex="rowIndex">
          <tr>
            <td *ngIf="parameters.isEdit" class="matColumn">
              <mat-checkbox
                class="checkbox"
                (click)="$event.stopPropagation(); selectionStrategy.toggle(element)"
                [color]="task.color" [checked]="selectionStrategy.isSelected(element)">
              </mat-checkbox>
            </td>
            <td
              *ngFor="let column of parameters.item.columns.strategy.columns.slice(1); let columnIndex = index;"
              [attr.uuid]="'cell-' + rowIndex + '-' + column">
              <ng-container>
                <textarea
                  [disabled]="!parameters.isEdit"
                  #textboxElement
                  [ngClass]="columnIndex === 0 ? 'columnFirst' :'columnText'"
                  [class.firstCell]="columnIndex === 0"
                  [attr.uuid]="'mytextarea-' + rowIndex + '-' + column"
                  (input)="adjustTextareaHeight($event,  rowIndex, column)"
                  [(ngModel)]="element[parameters.item.columns.strategy.columnsName[column].slug]"
                  (keydown)="handleCellKeyDown($event, rowIndex, columnIndex, column)">
                </textarea>

                <button class="button-menu" [disabled]="!parameters.isEdit" *ngIf="columnIndex === 0" [matMenuTriggerFor]="strategyMenu">
                  <span>
                    {{ element.corporation_dimension && element.corporation_dimension.name ? element.corporation_dimension.name : getLanguages('select') }}
                  </span>
                  <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
                </button>

                <mat-menu #strategyMenu="matMenu">
                  <div class="menu">
                    <div class="input" (click)="$event.stopPropagation()">
                      <input type="text" [(ngModel)]="search" placeholder="{{ getLanguages('search') }}">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div class="scroll">
                      <ng-container *ngFor="let strategy of filteredCorporationStrategys">
                        <ng-container *ngIf="(!element.corporation_dimension || strategy.name != element.corporation_dimension.name) && strategy.name.toLowerCase().includes(search.toLowerCase())">
                          <div class="item-menu" (click)="choseStrategy(strategy, element)">
                            <span> {{ strategy.name }} </span>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </mat-menu>

                <button
                  tabindex="-1"
                  disabled
                  [disabled]="!parameters.isEdit"
                  [ngClass]="!parameters.isEdit ? 'line-button-disabled' : 'line-button'"
                  (mousedown)="startResizing(rowIndex * parameters.item.columns.strategy.columns.slice(1).length + columnIndex)">
                </button>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="row">
    <div class="div-paginator" *ngIf="strategyRows.data.length > 5">
      <div class="paginator">
          <span>
              {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
          </span>
          <button [matMenuTriggerFor]="menu" class="itens-per-page">
              {{ itemsPerPage }}
          </button>
          <mat-menu #menu="matMenu" >
              <button mat-menu-item (click)="choseItemsPage(5)">5</button>
              <button mat-menu-item (click)="choseItemsPage(10)">10</button>
              <button mat-menu-item (click)="choseItemsPage(15)">15</button>
          </mat-menu>
          <span>
              {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
          </span>
          <span class="totalPages" >
              {{ getTotalPages()}}
          </span>
          <button [ngClass]="currentPage === 1 ? 'disabled-arrow' : 'arrow' " [disabled]="currentPage === 1" (click)="onPageChange(currentPage - 1)">
              <i class="fa-solid fa-angle-left"></i>
          </button>
          <input type="number" [(ngModel)]="currentPage" (keydown)="checkValue()" disabled>
          <button [ngClass]="currentPage == getTotalPages() ? 'disabled-arrow' : 'arrow' "  [disabled]="currentPage == getTotalPages()" (click)="onPageChange(currentPage + 1)">
              <i class="fa-solid fa-angle-right"></i>
          </button>
      </div>
    </div>
  </div>
</div>
