<div class="all-content">
    <div class="title">
        <div class="text left">
            {{ getLanguages('notFound/n404') }}
        </div>

        <div class="divider"></div>

        <div class="text rigth">
            {{ getLanguages('notFound/text') }}
        </div>
    </div>

    <div class="button" (click)="navigate()">
        {{ getLanguages('auth/login') }}
    </div>

    <div class="bottom"></div>
</div>