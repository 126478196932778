<div class="all-content">

    <div class="content-dimension">
        <div class="no-data" *ngIf="!isData()">

          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>

          {{ getLanguages('errors/noData') }}
        </div>

        <div class="table-attachment" *ngIf="isData()">
            <table *ngIf="getDisplayedRows().length !== 0"  mat-table [dataSource]="getDisplayedRows()" class="table">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="td-40">
                        <span> {{ getLanguages('attachment/name') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-40">
                        <span class="pointer" (click)="selectComplement(row)"> {{ row.label }} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef class="td-40">
                        <span> {{ getLanguages('businessSolution/modal/complements/type') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-40">
                        <span>  {{ row.type && row.type.name ? getNames(row.type.name) : '' }} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="td-20">
                        <span> {{ getLanguages('attachment/action') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-20">
                        <button (click)="deleteComplement(row.uuid)" [disabled]="!parameters.isEdit">
                            <i class="fas fa-trash" [ngClass]="!parameters.isEdit ? 'disabled-icon' : 'icon'"></i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4"></td>
                </tr>
            </table>
        </div>
        <div class="div-paginator" *ngIf="isData()">
          <div class="paginator">
            <span>
              {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
            </span>
            <button [matMenuTriggerFor]="menu" class="itens-per-page">
              {{ getItemsPerPage() }}
            </button>
            <mat-menu #menu="matMenu" >
              <button mat-menu-item (click)="choseItemsPage(5)">5</button>
              <button mat-menu-item (click)="choseItemsPage(10)">10</button>
            </mat-menu>
            <span>
              {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
            </span>
            <span class="totalPages">
              {{ getTotalPages()}}
            </span>
            <button
              [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() === 1"
              (click)="onPageChange(getCurrentPages() - 1, 'strategy')">
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <input type="number" [ngModel]="getCurrentPages()"  (blur)="checkValue()">
            <button
              [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() == getTotalPages()"
              (click)="onPageChange(getCurrentPages() + 1, 'strategy')">
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
    </div>

    <div class="row row-end">
        <div class="button-upload" (click)="newComplement()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/complements/new_complement') }}
        </div>
        <div class="button-upload" (click)="saveComplement()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/complements/save_complement') }}
        </div>
    </div>

    <div class="row">
        <div class="item item-60">
            <span> {{ getLanguages('businessSolution/modal/complements/name') }} </span>
            <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="nodeMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
                <span>
                    {{ selectedNode.label ? selectedNode.label : getLanguages('search') }}
                </span>
                <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
            </button>

            <mat-menu #nodeMenu="matMenu">
                <div class="menu">
                    <div class="input" (click)="$event.stopPropagation()">
                    <input type="text" [(ngModel)]="searchNode" placeholder="{{ getLanguages('search') }}">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div class="scroll">
                    <ng-container *ngFor="let node of nodeArray">
                        <ng-container *ngIf="node.label.toLowerCase().includes(searchNode.toLowerCase()) && !isNodeView(node)">
                          <div class="item-menu" (click)="choseItemSelect('node', node, currentComplement)">
                              <span [ngClass]="node.label == selectedNode.label ? 'valid-span' : 'invalid-span'">
                                  {{ node.label }}
                              </span>
                          </div>
                        </ng-container>
                    </ng-container>
                    </div>
                </div>
            </mat-menu>
        </div>

        <div class="item item-40">
          <span> {{ getLanguages('businessSolution/modal/complements/type') }} </span>
          <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="typeMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
            <span>
              {{ getNames(selectedType.name) }}
            </span>
            <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
          </button>

          <mat-menu #typeMenu="matMenu">
            <div class="menu">
              <div class="input" (click)="$event.stopPropagation()">
                <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
                <ng-container *ngFor="let type of typeArray">
                  <ng-container *ngIf="getNames(type.name).toLowerCase().includes(searchType.toLowerCase())">
                    <div class="item-menu" (click)="choseItemSelect('type', type, currentComplement)">
                      <span [ngClass]="getNames(type.name) == getNames(selectedType.name) ? 'valid-span' : 'invalid-span'">
                        {{ getNames(type.name) }}
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </div>
    </div>
</div>

