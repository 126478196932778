import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { apiOfflineAsync } from "../layouts/pages/pages.component";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  API_PATH: string
  isChangeTree: boolean = false;
  isShowModalChangeTree: boolean = false;
  route: string = ''
  
  arrayDuplicateName: any = []

  private reloadDashboard = new BehaviorSubject<boolean>(false);
  public reloadDashboard$ = this.reloadDashboard.asObservable();
  private getUserInstance = new BehaviorSubject<boolean>(false);
  public getUserInstance$ = this.getUserInstance.asObservable();
  private getUserCorporation = new BehaviorSubject<boolean>(false);
  public getUserCorporation$ = this.getUserCorporation.asObservable();

  constructor(private httpClient: HttpClient) {
    this.API_PATH = environment.apiUrl;
  }

  async getCep(cep: string) {
    try {
      return await this.httpClient.get<any>(`${this.API_PATH}functions/${cep}`).toPromise();
    } catch (error: any) {
      return await apiOfflineAsync(error)
    }
  }

  triggerDashboardReload() {
    this.reloadDashboard.next(true);
  }

  triggerGetUserInstanceReload() {
    this.getUserInstance.next(true);
  }

  triggerGetUserCorporationReload() {
    this.getUserCorporation.next(true);
  }

}
